import moment from "moment";

export function set({ key, value, maxAge }) {
  const expires = "max-age=" + maxAge;
  document.cookie = key + "=" + value + ";" + expires + ";path=/";
}

export function get(key) {
  var name = key + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function remove(key) {
  document.cookie = `${key}= ; expires = ${moment().utc()}`;
}
