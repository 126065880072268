import styles from "../../styles/views/_town.scss";
import { _class, parse, truncate, getImage } from "../utilities/helpers";
import Intro from "../partials/Intro";
import Hero from "../partials/Hero";
import TownMap from "../partials/TownMap";
import { Translation } from "../utilities/Translation";
import { connect } from "react-redux";

const cl = _class(styles, "town");

class Town extends React.Component {
  static propTypes = {
    page: PropTypes.object,
    CONTENT: PropTypes.object,
    lang: PropTypes.string,
  };

  static contextTypes = {
    filterByLang: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.slides = props.page.fieldgroup1 || [];
    this.categories = this.getCategories();
    this.categoryNames = Object.keys(this.categories).map((cat) => ({
      label: cat,
      value: cat,
    }));

    this.cards = Object.values(this.categories).reduce(
      (sum, val) => sum.concat(val),
      []
    );

    this.coordinates = {
      lat: parseFloat(props.CONTENT.options.latitude),
      lng: parseFloat(props.CONTENT.options.longitude),
    };
  }

  getCategories = () => {
    const { page, CONTENT } = this.props;
    const children = CONTENT.childrenById(page.id) || [];

    return children.reduce((sum, child) => {
      const key = child.linktitle.toLowerCase();

      if (key.match(/vacation/i)) {
        sum[key] = this.createStayCards(key, child);
      } else {
        sum[key] = CONTENT.childrenById(child.id).map((p) =>
          this.createCard(key, p)
        );
      }

      return sum;
    }, {});
  };

  createCard = (key, page) => {
    const parent = this.props.CONTENT.byId(page.parentid);
    return {
      id: page.id,
      latitude: page.latitude,
      longitude: page.longitude,
      title: page.linktitle,
      cta: parse(page.buttonlink2).link
        ? parse(page.buttonlink2)
        : parse(page.buttonlink1),
      image: getImage(page),
      blurb: truncate(page.blurb1 || "", 140),
      category: key,
      icon: parent.linktitle,
    };
  };

  createStayCards = (key) => {
    const { CONTENT } = this.props;
    const vacationHomesParent = CONTENT.byTemplate("villas").filter(
      this.context.filterByLang
    )[0];
    const vacationHomeCats = CONTENT.childrenById(vacationHomesParent.id);
    const LANG = new Translation(this.props.lang);

    return vacationHomeCats.reduce((sum, category) => {
      let children = CONTENT.childrenById(category.id);

      children.forEach((page) => {
        sum.push({
          id: page.id,
          latitude: page.latitude,
          longitude: page.longitude,
          title: page.linktitle,
          subtitle: page.buttonblurb1,
          cta: { link: page.path, text: LANG("learnMore") },
          image: getImage(page),
          blurb: truncate(page.blurb1 || "", 140),
          category: key,
          subcategory: category.linktitle,
          icon: "stay",
        });
      });

      return sum;
    }, []);
  };

  render() {
    const { page } = this.props;

    return (
      <div className={cl("")}>
        <Hero
          imageData={{ slides: this.slides }}
          videoLinkPresent={page.video_link !== null && page.video_link !== ""}
          videoLink={page.video_link}
          title={page.h1}
        />
        <div className={cl("container")}>
          <Intro subtitle={page.blurb1} />
          <TownMap
            categories={this.categoryNames}
            cards={this.cards}
            mapCoordinates={this.coordinates}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  lang: global.lang,
});

export default connect(mapStateToProps)(Town);
