import { SubmitButton } from "../form/index";
import styles from "../../styles/partials/_forms.scss";
import * as formUtils from "../form/formUtils";
import { _class } from "../utilities/helpers";
import Transition from "../widgets/Transition";
import {
  updateBookingForm,
  clearBookingForm,
  clearError,
  confirmReservation,
  authorizeTransaction,
} from "../redux/synxis/SynxisActions";
import { connect } from "react-redux";
import { Translation } from "../utilities/Translation";
import Loader from "./Loader";
import { countryOptions } from "../config/appConfig";

const cl = _class(styles, "form");

// Based on FormContainer.
class BookingForm extends React.Component {
  static propTypes = {
    bookingDetails: PropTypes.object,
    bookingForm: PropTypes.object,
    reservation: PropTypes.object,
    reservationPending: PropTypes.bool,
    updateBookingForm: PropTypes.func,
    clearBookingForm: PropTypes.func,
    lang: PropTypes.string,
    render: PropTypes.func.required,
    submit: PropTypes.func.required,
    successMessage: PropTypes.string,
    submitText: PropTypes.string,
    secureForm: PropTypes.string,
    orderNumber: PropTypes.string,
    isSecure: PropTypes.bool,
    confirmReservation: PropTypes.func,
    clearError: PropTypes.func,
    hotelDetails: PropTypes.object,
    reservationError: PropTypes.string,
    authorizationPending: PropTypes.bool,
  };

  static defaultProps = {
    render: () => null,
    submit: () => null,
    successMessage: "Thank you for your submission.",
    submitText: "Submit",
  };

  static contextTypes = {
    accessible: PropTypes.bool,
    transitioning: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.LANG = new Translation(props.lang);

    this.state = {
      submitAttempted: false,
      errors: {},
    };
  }

  componentDidMount() {
    if (this.props.bookingDetails && this.props.bookingDetails.guestInfo) {
      this.props.updateBookingForm(this.props.bookingDetails.guestInfo);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.isSuccess(prevProps)) {
      this.setState({ success: true, errors: {} });
    }
  }

  componentWillUnmount() {
    this.props.clearError();
  }

  cardOptions = () => {
    const { hotelDetails } = this.props;

    if (hotelDetails) {
      return hotelDetails.contentLists.paymentMethodList.map((card) => ({
        value: card.Code,
        label: card.Name,
      }));
    }

    return [];
  };
  isSuccess(prevProps) {
    const { reservation, reservationPending } = this.props;
    return (
      prevProps.reservationPending &&
      !reservationPending &&
      reservation &&
      !reservation.ErrorCode
    );
  }

  updateField = (fieldName, fieldValue) => {
    this.props.updateBookingForm({
      [fieldName]: fieldValue,
    });
  };

  updateFieldErrors = (fieldName, hasError) => {
    this.setState((prevState) => ({
      errors: { ...prevState.errors, [fieldName]: hasError },
    }));
  };

  checkForErrors = () => Object.values(this.state.errors).includes(true);

  submit = (e) => {
    const { bookingDetails, bookingForm, reservation } = this.props;

    e.preventDefault();

    if (this.checkForErrors()) {
      return this.setState({ submitAttempted: true });
    }

    this.props.submit(bookingForm, bookingDetails, reservation);
  };

  renderSuccessMessage = () => {
    if (this.state.success) {
      return (
        <div className={`${cl("success")}`}>
          <h3>{this.props.successMessage}</h3>
        </div>
      );
    }
  };

  renderError = () => {
    if (this.props.reservationError) {
      return (
        <div className={cl("error")}>
          <h3>An error occurred.</h3>
          <p>{this.props.reservationError}</p>
          <p>
            Please try again or contact us for assistance with your reservation.
          </p>
        </div>
      );
    }
  };

  renderLoading = () => {
    const {
      reservationPending,
      bookingForm,
      authorizationPending,
    } = this.props;
    if (reservationPending || authorizationPending) {
      const cc_type = bookingForm.cc_type || "";
      return (
        <div className={cl("loader")}>
          <Loader />
          <p>
            Transaction in progress. Please do not navigate away or refresh the
            page.
          </p>
          {cc_type.match(/VI|MC/) && reservationPending && (
            <p>{this.LANG("redirectInProgress")}</p>
          )}
        </div>
      );
    }
  };

  renderSubmit = (props) => {
    const { reservationPending, authorizationPending } = this.props;

    if (!reservationPending && !this.state.success && !authorizationPending) {
      return (
        <SubmitButton
          buttonText={this.props.submitText}
          containerClassName={cl("submit_container")}
          className={cl("submit")}
          {...props}
        />
      );
    }
  };

  renderSecureForm = () => {
    const { secureForm } = this.props;

    if (secureForm) {
      return (
        <div id="secure_form" className={cl("secure_form")}>
          <iframe srcDoc={secureForm}></iframe>
        </div>
      );
    }
  };

  render() {
    const visible = !this.context.transitioning.val;
    const sharedProps = {
      formState: {
        values: this.props.bookingForm,
        errors: this.state.errors,
        submitAttempted: this.state.submitAttempted,
      },
      formFunctions: {
        updateField: this.updateField,
        updateFieldErrors: this.updateFieldErrors,
      },
    };

    return (
      <Transition
        appear
        visible={visible}
        mode="stagger"
        staggerClassName={cl("row")}
        properties={{
          y: ["50%", "0%"],
          opacity: [0, 1],
          autoAlpha: [0, 1],
        }}
      >
        <div>
          <form onSubmit={this.submit}>
            {this.props.render({
              state: this.state,
              functions: this.formFunctions,
              shared: sharedProps,
              utils: formUtils,
              cardOptions: this.cardOptions(),
              countryOptions,
            })}
            <div className={`${cl("row")}`}>
              {this.renderSubmit(sharedProps)}
              {this.renderLoading()}
            </div>

            <div className={`${cl("row")}`}>
              {this.renderSuccessMessage()}
              {this.renderError()}
            </div>
          </form>
          {this.renderSecureForm()}
        </div>
      </Transition>
    );
  }
}

const mapStateToProps = ({ synxis, global }) => ({
  bookingDetails: synxis.bookingDetails,
  reservation: synxis.reservation,
  hotelDetails: synxis.hotelDetails,
  bookingForm: synxis.bookingForm,
  reservationError: synxis.error,
  lang: global.lang,
  secureForm: synxis.authorization && synxis.authorization.markup,
  orderNumber: synxis.authorization && synxis.authorization.orderNumber,
  isSecure: synxis.authorization && synxis.authorization.secure,
  reservationPending: synxis.reservationPending,
  authorizationPending: synxis.authorizationPending,
});

export default connect(mapStateToProps, {
  updateBookingForm,
  clearBookingForm,
  clearError,
  confirmReservation,
  authorizeTransaction,
})(BookingForm);
