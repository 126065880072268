import styles from "../../styles/views/_offer-detail.scss";
import { _class, parse, gaTrackEvent } from "../utilities/helpers";
import Hero from "../partials/Hero";
import Intro from "../partials/Intro";
import HubSpotForm from "../partials/HubSpotForm";

const cl = _class(styles, "offer_detail");

class OfferDetail extends React.Component {
  static propTypes = {
    page: PropTypes.object,
    CONTENT: PropTypes.object,
  };

  static contextTypes = {
    activeLangPage: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.slides = props.page.fieldgroup1 || [];
  }

  componentDidMount() {
    const isFamilyCamp = this.props.page?.slug.includes("family-camp");
    isFamilyCamp && this.fbTrackEvent();
  }

  fbTrackEvent = () => {
    window?.fbq("track", "Lead");
  };

  gaTrackEvent = () => {
    gaTrackEvent({
      category: "Click",
      action: "Completion",
      label: "BookingOffer",
    });
  };

  render() {
    const { page } = this.props;

    return (
      <div className={cl("")}>
        <Hero
          imageData={{ slides: this.slides }}
          videoLinkPresent={page.video_link !== null && page.video_link !== ""}
          videoLink={page.video_link}
        />

        <div className={cl("container")}>
          <Intro
            title={page.h1}
            subtitle={page.h2}
            blurb={page.blurb2}
            cta={parse(page.buttonlink1)}
            onCtaClick={this.gaTrackEvent}
          />
          <HubSpotForm
            formId={this.context.activeLangPage(page, "h6")}
            label="Offer"
          />
        </div>
      </div>
    );
  }
}

export default OfferDetail;
