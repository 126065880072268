import { Link as RRLink } from "react-router-dom";
import ContentProvider from "../containers/ContentProvider";

class Link extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    hash: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onClick: PropTypes.func,
    path: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.string,
    CONTENT: PropTypes.object,
    ariaLabel: PropTypes.string,
    newTab: PropTypes.bool,
    delayLink: PropTypes.bool,
    search: PropTypes.string,
  };

  static defaultProps = {
    path: "",
    onClick: () => null,
    delayLink: true,
  };

  static contextTypes = {
    changeRoute: PropTypes.func,
    transitioning: PropTypes.object,
  };

  formatURL(path) {
    const props = { url: null, target: null };

    if (path && typeof path === "string") {
      if (this.isEmail(path)) {
        props.url = path;
      } else if (this.isExternal(path)) {
        props.url = path;
        props.target = "_blank";
      } else if (this.isFile(path)) {
        props.url = path;
        props.target = "_blank";
      } else if (this.isPhone(path)) {
        props.url = `tel:${path.replace(/\D/g, "")}`;
      }
    }
    return props;
  }

  isId = (path) => typeof parseInt(path) === "number" && !isNaN(parseInt(path));

  getDomain = (url) => url.replace(/https?:\/\//, "").split("/")[0];

  isEmail = (url) => url.search(/mailto:/) !== -1;

  isFile = (url) => url.search(/\.pdf/) !== -1;

  isPhone = (url) => url.search(/tel:/) !== -1;

  isExternal = (path) => {
    const { href } = window.location;
    const sameDomain = this.getDomain(href) === this.getDomain(path);
    return path.search(/https?:\/\//) !== -1 && !sameDomain;
  };

  hasSearch = (path) => {
    if (path && typeof path !== "number") {
      const match = path && path.match(/\?.+/);
      return match ? match[0] : null;
    }
  };

  onClick = (e, content, search = "") => {
    if (this.props.delayLink && content) {
      e.preventDefault();
      this.context.changeRoute(content.path + search);
    }

    this.props.onClick(e);
  };

  renderRRLink = (content) => {
    const { children, path, title, className, ariaLabel, search } = this.props;

    const hasSearch = this.hasSearch(path) || search;

    return (
      <RRLink
        aria-label={ariaLabel}
        className={className}
        onClick={(e) => this.onClick(e, content, hasSearch)}
        to={{ pathname: content.path, search: hasSearch }}
      >
        {children || title || content.linktitle || path}
      </RRLink>
    );
  };

  renderExternalLink = (href) => {
    const {
      children,
      hash,
      path,
      title,
      className,
      onClick,
      ariaLabel,
      newTab,
    } = this.props;

    const { url, target } = this.formatURL(path);

    return (
      <a
        aria-label={ariaLabel}
        className={className}
        onClick={onClick}
        href={href || `${url}${hash ? `#${hash}` : ""}`}
        target={newTab ? "_blank" : target}
        rel="noopener"
      >
        {children || title || path}
      </a>
    );
  };

  renderFallback = () => {
    const { children, path, title, className, ariaLabel } = this.props;

    return (
      <button
        aria-label={ariaLabel}
        role="link"
        className={className}
        onClick={this.onClick}
      >
        {children || title || path}
      </button>
    );
  };

  render() {
    const { path, CONTENT, newTab } = this.props;

    if (!path) {
      return this.renderFallback();
    }

    if (this.isId(path)) {
      return this.renderRRLink(CONTENT.byId(path));
    }

    const isExternal =
      this.isExternal(path) ||
      this.isPhone(path) ||
      this.isEmail(path) ||
      this.isFile(path);

    if (!isExternal && !newTab) {
      const index = path.indexOf("?");
      const formattedPath = index !== -1 ? path.slice(0, index) : path;
      const content = CONTENT.byPath(formattedPath);
      const search = index !== -1 ? path.slice(index) : "";
      return this.renderRRLink(content, search);
    }

    if (isExternal || newTab) {
      return this.renderExternalLink(newTab ? path : undefined);
    }

    return this.renderFallback();
  }
}

export default ContentProvider(Link);
