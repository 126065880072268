import styles from "../../styles/views/_contact.scss";
import { _class, fbTrackEvent, gtagTrackEvent } from "../utilities/helpers";
import Intro from "../partials/Intro";
import HubSpotForm from "../partials/HubSpotForm";
import Hero from "../partials/Hero";

const cl = _class(styles, "contact");

class Contact extends React.Component {
  static propTypes = {
    page: PropTypes.object,
    CONTENT: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.slides = props.page.fieldgroup1 || [];
  }
  render() {
    const { page } = this.props;

    return (
      <div className={cl("")}>
        <Hero
          imageData={{ slides: this.slides }}
          videoLinkPresent={page.video_link !== null && page.video_link !== ""}
          videoLink={page.video_link}
        />
        <div className={cl("container")}>
          <Intro subtitle={page.h1} blurb={page.blurb1} />
          <div className={cl("form_container")}>
            <HubSpotForm
              formId={page.h2}
              label="Contact"
              onSubmit={() => {
                fbTrackEvent("Contact");
                gtagTrackEvent("AW-817146118/EzdZCMv2j-4BEIbS0oUD");
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
