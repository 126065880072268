import API from "./API";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const api = new API({ base: API_PATH });

export const log = async (data, table) => {
  const fp = await FingerprintJS.load();
  const result = await fp.get();

  api.post("booking_logs", { uid: result.visitorId, data, table });
};

export const logCheckoutDetails = ({
  bookingDetails,
  singleAvailability,
  confirmed,
  step,
}) => {
  const booking = bookingDetails
    ? {
        ...bookingDetails,
        cmsRoom: bookingDetails.cmsRoom.id,
        cmsProperty: bookingDetails.cmsProperty.id,
        synxisRoom: undefined,
      }
    : {};

  try {
    log(
      {
        ...booking,
        availability: singleAvailability
          ? singleAvailability.Product
          : undefined,
        confirmed,
        step,
      },
      "booking_logs"
    );
  } catch (e) {
    console.log("Error capturing log: ", e);
  }
};

export const logBookingSearchDetails = (data) => {
  try {
    log(data, "booking_search_logs");
  } catch (e) {
    console.log("Error capturing log: ", e);
  }
};
