import styles from "../../styles/views/_offers.scss";
import { _class, parse } from "../utilities/helpers";
import Hero from "../partials/Hero";
import Intro from "../partials/Intro";
import OfferCard from "../partials/OfferCard";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import Tabs from "../partials/Tabs";
import Reveal from "../widgets/Reveal";
import { calendar } from "../widgets/SVG";
import { connect } from "react-redux";
import { Translation } from "../utilities/Translation";

const cl = _class(styles, "offers");

class Offers extends React.Component {
  static propTypes = {
    page: PropTypes.object,
    CONTENT: PropTypes.object,
    lang: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.LANG = new Translation(props.lang);
    // this.consoleLog = () => console.log("data", props.CONTENT);

    this.ALL = { label: this.LANG("all"), value: this.LANG("all") };

    this.slides = props.page.fieldgroup1 || [];
    this.offers = props.CONTENT.childrenById(props.page.id).filter((offer) => {
      if (offer.h4) {
        let offerEnd = moment(offer.h4);
        return offerEnd.isSameOrAfter(moment().toDate());
      }

      return true;
    });
    this.tabs = this.getTabs();
    this.today = moment().toDate();

    this.state = {
      offers: this.offers,
      startDate: null,
      endDate: null,
      animating: false,
      visible: false,
      tab: {
        [this.ALL.value]: this.ALL,
      },
      nextTab: this.ALL,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.animating && !this.state.animating) {
      this.animateIn();
    }
  }

  onComplete = () => this.setState({ animating: false });

  animateIn = () => {
    const { nextTab, tab } = this.state;

    let newTab = { ...tab };

    if (nextTab.value === this.ALL.value && !newTab[this.ALL.value]) {
      newTab = {
        [this.ALL.value]: this.ALL,
      };
    } else {
      delete newTab[this.ALL.value];
    }

    if (nextTab.value !== this.ALL.value && tab[nextTab.value]) {
      delete newTab[nextTab.value];
    } else {
      newTab[nextTab.value] = nextTab;
    }

    let tabOffers = this.tabFilter(newTab);
    let dateOffers = this.dateFilter(tabOffers);

    this.setState({
      tab: newTab,
      offers: dateOffers,
      visible: true,
    });
  };

  /**
   * Filters venues by the selected tabs
   *
   * @param   {object} tabs              Object of tabs, eg {All: { label, value }}
   *
   * @return  {array}                    collection of filtered venues by tab
   */
  tabFilter = (tabs) => {
    const hasTabs = tabs && Object.keys(tabs).length > 0;
    if (hasTabs && tabs[this.ALL.value]) {
      return this.offers;
    }
    const locationFilteredOffers = this.offers.filter((offer) => {
      const locations = this.getRelation(offer, "buttonswitch1");
      return locations.some((l) => hasTabs && tabs[l.linktitle]);
    });

    return locationFilteredOffers;
  };

  dateFilter = (offers) => {
    const { startDate, endDate } = this.state;

    if (!startDate && !endDate) {
      return offers;
    }

    return offers.filter((offer) => {
      if (offer.h3 && offer.h4) {
        const offerStart = moment(offer.h3);
        const offerEnd = moment(offer.h4);

        const _start = moment(startDate || undefined).toDate();
        const _end = moment(endDate || undefined).toDate();

        if (
          (offerStart.isSameOrBefore(_start) && offerEnd.isSameOrAfter(_end)) ||
          (offerStart.isSameOrAfter(_start) && offerEnd.isSameOrBefore(_end))
        ) {
          return true;
        }
      } else {
        return true;
      }
    });
  };

  getTabs = () => {
    // console.log("offers", this.offers);
    const lodging = this.offers?.reduce((sum, o) => {
      const locations = this.getRelation(o, "buttonswitch1");
      // console.log("locations", locations);
      locations.forEach(
        (l) => (sum[l.linktitle] = { label: l.linktitle, value: l.linktitle })
      );
      return sum;
    }, {});
    // console.log("lodging", lodging);
    return Object.values(lodging);
  };

  getRelation = (page, fieldName) => {
    const { CONTENT } = this.props;
    const parsed = parse(page[fieldName]) || [];
    return parsed.map((item) => CONTENT.byId(item.value));
  };

  /**
   * Handles click event for a tab.
   * Animate out the current collection of venues
   * Kicks off filtering process
   *
   * @param   {object} nextTab       Object of { label, value }
   */
  onTabSelect = (nextTab) => {
    this.setState({
      visible: false,
      animating: true,
      nextTab,
      startDate: null,
      endDate: null,
    });
  };

  handleDayChange = (field, day) => {
    const { startDate, endDate } = this.state;
    const dayBefore = moment(day).subtract(1, "days").toDate();
    const dayAfter = moment(day).add(1, "days").toDate();

    let nextStart;
    let nextEnd;

    if (field === "startDate") {
      nextStart = day;
      nextEnd = endDate ? (day >= endDate ? dayAfter : endDate) : null;
    } else {
      nextStart = day <= startDate ? dayBefore : startDate;
      nextEnd = day;
    }

    this.setState({
      startDate: nextStart,
      endDate: nextEnd,
      visible: false,
      animating: true,
    });
  };

  renderDatePicker = (name) => {
    const { startDate, endDate } = this.state;
    const from = startDate;
    const to = endDate;

    return (
      <div className={cl("date_picker")}>
        <DayPickerInput
          label={"Select Dates"}
          numberOfMonths={1}
          fromMonth={this.today}
          value={
            this.state[name] ||
            (name === "startDate"
              ? this.LANG("startDate")
              : this.LANG("endDate"))
          }
          onDayChange={this.handleDayChange.bind(null, name)}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            fromMonth: name === "endDate" ? moment(from).toDate() : this.today,
            disabledDays: {
              before: name === "endDate" ? from : this.today,
            },
            modifiers: { from, to },
            locale: this.props.lang,
            // localeUtils: MomentLocaleUtils, //NOTE: Not entirely sure why this is throwing an error
          }}
          placeholder={
            name === "startDate" ? this.LANG("startDate") : this.LANG("endDate")
          }
        />
      </div>
    );
  };

  renderOffers = () => {
    return (
      <Reveal
        className={cl("list")}
        mode="stagger"
        staggerClassName={cl("list__item")}
        properties={{
          y: ["50%", "0%"],
          opacity: [0, 1],
          autoAlpha: [0, 1],
        }}
        visible={this.state.visible}
        onComplete={this.onComplete}
        useRevealProp
        onReveal={() => this.setState({ visible: true })}
      >
        {this.state.offers.map((offer) => {
          // console.log("offer", offer);
          const offerImage = {
            src: offer.image1 ? offer.image1 : offer.fieldgroup1[0].image1,
            alt: offer.image1_alt_text
              ? offer.image1_alt_text
              : offer.fieldgroup1[0].image1_alt_text,
          };
          return (
            <li className={cl("list__item")} key={offer.id}>
              <OfferCard
                title={offer.h1}
                blurb={offer.blurb1}
                image={{
                  src: offerImage.src,
                  alt: offerImage.alt,
                }}
                cta={{ text: "Learn More", link: offer.path }}
                lang={this.props.lang}
              />
            </li>
          );
        })}
      </Reveal>
    );
  };

  renderDateFilters = () => (
    <Reveal
      className={cl("dates")}
      mode="stagger"
      staggerClassName={cl("date_picker")}
      properties={{
        x: ["25%", "0%"],
        opacity: [0, 1],
        autoAlpha: [0, 1],
      }}
      reverse
    >
      <Reveal className={cl("calendar")} cssAnimation={["fade"]}>
        {calendar}
      </Reveal>

      {this.renderDatePicker("startDate")}
      {this.renderDatePicker("endDate")}
    </Reveal>
  );

  renderCategories = () => {
    // console.log("tabs", this.tabs);
    return (
      <ul className={cl("categories")}>
        <Tabs
          onClick={this.onTabSelect}
          tabs={this.tabs}
          active={this.state.tab}
          fixedWidth
          lang={this.props.lang}
        />
      </ul>
    );
  };

  render() {
    const { page } = this.props;
    return (
      <div className={cl("")}>
        <Hero
          imageData={{ slides: this.slides }}
          videoLinkPresent={page.video_link !== null && page.video_link !== ""}
          videoLink={page.video_link}
        />

        <div className={cl("container")}>
          <Intro
            title={page.h1}
            subtitle={page.h2}
            blurb={page.blurb2}
            cta={parse(page.buttonlink1)}
          />
          <div className={cl("options")}>
            {this.renderCategories()}
            {this.renderDateFilters()}
          </div>
          {this.renderOffers()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  lang: global.lang,
});

export default connect(mapStateToProps)(Offers);
