// import { lazy } from "react-imported-component";
const Loading = () => <div className={`loadable--loading`} />;
import LoadableVisibility from "react-loadable-visibility/react-loadable";

const importFactory = (importFunction) =>
  LoadableVisibility({ loader: importFunction, loading: Loading });

const requireViews = require.context(
  "views",
  true,
  /^(.*\.(js$))[^.]*$/im,
  "lazy"
);

const views = requireViews.keys().reduce((sum, path) => {
  const name = path.replace(/\.\//, "").replace(/\.js/, "");

  sum[name] = importFactory(() =>
    import(/* webpackChunkName: "[request]" */ `../views/${name}`)
  );

  return sum;
}, {});

export default views;
