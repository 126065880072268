import styles from "../../styles/views/_checkout.scss";
import { _class, numberWithCommas } from "../utilities/helpers";
import { Translation } from "../utilities/Translation";
import Reveal from "../widgets/Reveal";
import { connect } from "react-redux";
import moment from "moment";
import { removePackage } from "../redux/synxis/SynxisActions";
import Modal from "../widgets/Modal";

const cl = _class(styles, "checkout");

class ReservationDetailsWidget extends React.Component {
  static propTypes = {
    bookingDetails: PropTypes.object,
    viewport: PropTypes.object,
    currency: PropTypes.object,
    lang: PropTypes.string,
    rates: PropTypes.object,
  };

  static contextTypes = {
    history: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.LANG = new Translation(props.lang);

    this.state = {
      priceModalOpen: false,
    };
  }

  togglePriceModal = () =>
    this.setState({ priceModalOpen: !this.state.priceModalOpen });

  renderPriceModal = () => {
    const { bookingDetails } = this.props;

    return (
      <Modal
        open={this.state.priceModalOpen}
        close={this.togglePriceModal}
        className={cl("price_modal")}
        background="grey"
      >
        {this.renderDetailsBreakdown()}
        <div className={cl("details__total")}>
          <p>{this.LANG("total")}</p>
          <div>
            <p>
              {this.props.currency.symbol}
              {numberWithCommas(bookingDetails.total)}
            </p>
          </div>
        </div>
      </Modal>
    );
  };

  renderDetailsBreakdown = () => {
    const {
      from,
      to,
      guests,
      bedrooms,
      nights,
      rate,
      subtotal,
      taxes,
      feesBreakdown,
      cmsProperty,
      cmsRoom,
    } = this.props.bookingDetails;

    return (
      <div>
        <h2>{this.LANG("yourStay")}</h2>
        <h3>{cmsProperty.h1}</h3>
        <h4>{cmsRoom.h1}</h4>
        <div className={cl("details__dates")}>
          <div>
            <label>{this.LANG("checkIn")}</label>
            <p>{moment(from).format("LL", "en")}</p>
          </div>
          <div>
            <label>{this.LANG("checkOut")}</label>
            <p>{moment(to).format("LL", "en")}</p>
          </div>
        </div>
        <div className={cl("details__guests")}>
          {guests && (
            <p>
              {guests} {guests != 1 ? this.LANG("guests") : this.LANG("guest")}
            </p>
          )}
          {bedrooms && (
            <p>
              {bedrooms}{" "}
              {bedrooms != 1 ? this.LANG("bedrooms") : this.LANG("bedroom")}
            </p>
          )}
        </div>
        <div className={cl("details__fees")}>
          <div className={cl("details__fees__row")}>
            <p>Rate</p>
            <p>{rate ? this.props.rates[rate].Name || rate : "n/a"}</p>
          </div>
          <div className={cl("details__fees__row")}>
            <p>
              {nights} {nights != 1 ? this.LANG("nights") : this.LANG("night")}
            </p>
            <p>
              {this.props.currency.symbol}
              {numberWithCommas(subtotal || 0)}
            </p>
          </div>
          <div className={cl("details__fees__row")}>
            <p>{this.LANG("taxes")}</p>
            <p>
              {this.props.currency.symbol}
              {numberWithCommas(taxes || 0)}
            </p>
          </div>
          {feesBreakdown &&
            feesBreakdown.map((fee) => (
              <div className={cl("details__fees__row")} key={fee.Code}>
                <p>{fee.Code}</p>
                <p>
                  {this.props.currency.symbol}
                  {numberWithCommas(fee.Amount)}
                </p>
              </div>
            ))}
        </div>
        {/* {this.renderPackages()} */}
      </div>
    );
  };

  renderPackages = () => {
    const { bookingDetails } = this.props;
    if (bookingDetails.packages && bookingDetails.packages.length) {
      return (
        <div className={cl("details__fees")}>
          <h3>Packages</h3>
          {bookingDetails.packages.map((pkg) => {
            return (
              <div className={cl("details__packages__row")} key={pkg.Title}>
                <p>{pkg.Name}</p>
                <p>
                  {this.props.currency.symbol}
                  {numberWithCommas(pkg.Price.TotalAmountIncludingTaxesFees)}
                </p>
              </div>
            );
          })}
        </div>
      );
    }
  };

  renderTotal = () => {
    const { availability, total } = this.props.bookingDetails;

    if (availability || total) {
      return (
        <div className={cl("details__total")}>
          <p>{this.LANG("total")}</p>
          <div className={cl("details__total__inner")}>
            <p>
              {this.props.currency.symbol}
              {numberWithCommas(total || 0)}
            </p>

            {this.props.viewport.is("tablet") && (
              <span onClick={this.togglePriceModal}>View Price Breakdown</span>
            )}
          </div>
        </div>
      );
    }
  };

  render() {
    const { viewport } = this.props;

    return (
      <div>
        {this.renderPriceModal()}
        <Reveal cssAnimation={["fade", "right"]} className={cl("details")}>
          {!viewport.is("tablet") && this.renderDetailsBreakdown()}
          {this.renderTotal()}
        </Reveal>
      </div>
    );
  }
}

const mapStateToProps = ({ synxis, global }) => ({
  bookingDetails: synxis.bookingDetails,
  lang: global.lang,
  currency: global.currency,
  rates: synxis.rates,
});

export default connect(mapStateToProps, {
  removePackage,
})(ReservationDetailsWidget);
