import styles from "../../styles/views/_booking-results.scss";
import {
  _class,
  parse,
  getQueryParams,
  createResultCard,
  gaTrackPage,
} from "../utilities/helpers";
import BookingWidget from "../partials/BookingWidget";
import ResultsBlock from "../partials/ResultsBlock";
import Loader from "../partials/Loader";
import moment from "moment";
import { connect } from "react-redux";
import {
  getAvailabilities,
  deleteAvailabilities,
} from "../redux/synxis/SynxisActions";
import { Translation } from "../utilities/Translation";

const cl = _class(styles, "booking_results");

class BookingResults extends React.Component {
  static propTypes = {
    page: PropTypes.object,
    CONTENT: PropTypes.object,
    location: PropTypes.object,
    getAvailabilities: PropTypes.func,
    rooms: PropTypes.object,
    availabilities: PropTypes.array,
    availabilitiesPending: PropTypes.bool,
    deleteAvailabilities: PropTypes.func,
    lang: PropTypes.string,
    currency: PropTypes.object,
  };

  static contextTypes = {
    filterByLang: PropTypes.func,
    history: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.LANG = new Translation(props.lang);

    this.state = {
      tabs: [],
      results: null,
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    if (!window.location.search) {
      const home = this.props.CONTENT.byTemplate("home").filter(
        this.context.filterByLang
      )[0];
      return this.context.history.push(home.path);
    }

    gaTrackPage("/btt/booking-engine/availability-results");

    const { rooms } = this.props;

    if (rooms) {
      this.fetchAvailabilities();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.rooms &&
      !this.props.availabilities &&
      !this.props.availabilitiesPending
    ) {
      this.fetchAvailabilities();
    }

    // update when we don't have any availabilities yet, we've refreshed availabilities, or search params have changed
    if (
      (prevProps.availabilitiesPending &&
        !this.state.results &&
        this.props.availabilities) ||
      this.props.location.search !== prevProps.location.search
    ) {
      this.displayAvailabilities();
    }

    if (prevProps.location.search !== this.props.location.search) {
      this.props.deleteAvailabilities();
      this.setState({ results: null, loading: true, tabs: [] });
    }
  }

  componentWillUnmount() {
    this.props.deleteAvailabilities();
  }

  getRooms = () =>
    this.props.CONTENT.byTemplate("room")
      .filter(this.context.filterByLang)
      .reduce((sum, room) => {
        room.h4 ? (sum[room.h4] = room) : null;
        return sum;
      }, {});

  getLowestPrice = (prices) =>
    prices.reduce((sum, price) => {
      const newAmount = price.Price.Total.Amount;
      return newAmount < sum || sum === null ? newAmount : sum;
    }, null);

  fetchAvailabilities = () => {
    const bookingDetails = getQueryParams(window.location.search);
    if (!bookingDetails) {
      return this.setState({
        error: "Please input booking parameters.",
        loading: false,
      });
    }

    this.props.getAvailabilities({
      adults: bookingDetails.guests,
      startDate: moment(bookingDetails.from).format("YYYY-MM-DD"),
      endDate: moment(bookingDetails.to).format("YYYY-MM-DD"),
      currencyCode: this.props.currency.code,
    });
  };

  displayAvailabilities = () => {
    const { availabilities } = this.props;

    if (availabilities === false) {
      return this.setState({
        error: "Error fetching availabilities. Please try again.",
        loading: false,
      });
    }

    const results = this.filterResults(availabilities);

    this.setState({
      results,
      loading: false,
    });
  };

  tabMatch(item) {
    const { lodging } = getQueryParams(window.location.search);

    if (lodging) {
      const type = parse(item.selectgroup1, [{}])[0].value || "";

      if (lodging === "hotel") {
        return type === "hotel_room";
      }

      if (lodging === "vacation_rental") {
        return (
          type === "apartment" ||
          type === "villa" ||
          type === "home" ||
          type === "studio"
        );
      }

      if (type.match(/all/i)) {
        return true;
      }
    }

    return true;
  }

  /**
   * Preliminary formatting and filtering of raw availability results returned
   * from Synxis. First filter the availabilities by price from lowest to highest,
   * or by bedrooms by lowest to highest if prices match. Then we format
   * the results into cards with various data points so we can use them in the
   * ResultsBlock copmonent.
   *
   * @param   {array} availabilities     array of availabilities from Synxis
   *
   * @return  {array}                    collection of filtered cards
   */
  filterResults = (availabilities) => {
    const { rooms, CONTENT, currency, lang } = this.props;

    const CMS_ROOMS = this.getRooms();

    // format into object since availabilities can have rooms with multiple rates
    const formatted = (availabilities || [])
      .sort((a, b) => {
        const priceA = this.getLowestPrice(a.Product.Prices.Daily);
        const priceB = this.getLowestPrice(b.Product.Prices.Daily);

        // if prices are equal, sort by guest count?
        // if (priceA === priceB) {
        //   const aCode = a.Product.Room.Code;
        //   const bCode = b.Product.Room.Code;

        //   const roomA = (CMS_ROOMS[aCode] = CMS_ROOMS[aCode]);
        //   const roomB = (CMS_ROOMS[bCode] = CMS_ROOMS[bCode]);

        //   if (roomA && roomB && rooms[roomA.h4] && rooms[roomB.h4]) {
        //     return (
        //       rooms[roomA.h4].Details.GuestLimit.Value -
        //       rooms[roomB.h4].Details.GuestLimit.Value
        //     );
        //   }
        // }

        return priceA - priceB;
      })
      .reduce((sum, item) => {
        const code = item.Product.Room.Code;
        const page = CMS_ROOMS[code];

        const tabMatch = page && this.tabMatch(page);
        const isPrivate = page && page.checkbox1;

        if (page && tabMatch && !isPrivate) {
          const parent = CONTENT.byId(page.parentid);
          const price = this.getLowestPrice(item.Product.Prices.Daily);

          sum[code] = {
            ...(sum[code] || {}),
            [item.Product.Rate.Code]: createResultCard({
              page,
              category: this.LANG(
                parse(page.selectgroup1, [{}])[0].name || "Other"
              ),
              location: parent.h1,
              lang: lang,
              currency: currency,
              synxisRoom: rooms[page.h4],
              path: page.path + window.location.search,
              icon: "stay",
              price,
            }),
          };
        }

        return sum;
      }, {});

    // use RACK or first available rate.
    // keep
    return Object.keys(formatted).map((key) => {
      const rates = formatted[key];
      if (!rates.RACK2) {
        let firstRate = Object.keys(rates)[0];
        return rates[firstRate];
      }

      return rates.RACK2;
    });
  };

  renderLoading = () => this.state.loading && <Loader />;

  renderError = () => {
    if (this.state.error) {
      return (
        <div className={cl("error")}>
          <p>{this.state.error}</p>
        </div>
      );
    }
  };

  render() {
    const { page } = this.props;
    const { results } = this.state;

    return (
      <div className={cl("")}>
        <div className={cl("widget")}>
          <h1>{page.h1}</h1>
          <div className={cl("widget__container")}>
            <BookingWidget type="small" CONTENT={this.props.CONTENT} />
          </div>
        </div>
        <div className={cl("container")}>
          {this.renderLoading()}
          {this.renderError()}
          {results && (
            <ResultsBlock
              resultCards={results}
              view={"map"}
              filters={["View", "Price", "Bedrooms", "MoreFilters", "Sort"]}
              checkboxOptions={["Views", "Amenities", "Collections"]}
              tabs={"results"}
              page={this.props.page}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ synxis, global }) => ({
  availabilities: synxis.availabilities,
  availabilitiesPending: synxis.availabilitiesPending,
  rooms: synxis.rooms,
  lang: global.lang,
  currency: global.currency,
});

export default connect(mapStateToProps, {
  getAvailabilities,
  deleteAvailabilities,
})(BookingResults);
