import styles from "../../styles/partials/_offer-card.scss";
import { _class } from "../utilities/helpers";
import Image from "../widgets/Image";
import CTA from "./CTA";
import Link from "../widgets/Link";
import { Translation } from "../utilities/Translation";

const cl = _class(styles, "offer_card");

OfferCard.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    align: PropTypes.string,
  }),
  title: PropTypes.string,
  blurb: PropTypes.string,
  cta: PropTypes.shape({
    link: PropTypes.string,
    text: PropTypes.string,
  }),
  lang: PropTypes.string,
};

OfferCard.defaultProps = {
  title: "",
  image: {},
  blurb: "",
};

export default function OfferCard({ image, title, blurb, cta, lang }) {
  const LANG = new Translation(lang);

  return (
    <div className={cl("")}>
      <Link className={cl("image")} path={cta.link}>
        <Image src={image.src} alt={image.alt} align={image.align} />
      </Link>
      <div className={cl("content")}>
        <h3>{title}</h3>
        <p>{blurb}</p>
      </div>
      {cta && cta.link && (
        <div className={cl("cta")}>
          <CTA link={cta.link} text={LANG("learnMore")} />
        </div>
      )}
    </div>
  );
}
