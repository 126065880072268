import { _class } from "../utilities/helpers";
import styles from "../../styles/partials/_image-grid2.scss";
import Image from "../widgets/Image";
import Reveal from "../widgets/Reveal";

const cl = _class(styles, "image_grid");

ImageGrid2.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      align: PropTypes.string,
    })
  ),
};

ImageGrid2.defaultProps = {
  images: [],
};

export default function ImageGrid2({ images }) {
  return (
    <Reveal
      className={cl("")}
      mode={"stagger"}
      staggerClassName={cl("image")}
      properties={{ opacity: [0, 1], autoAlpha: [0, 1], y: ["50%", "0%"] }}
    >
      {images.map((item) => {
        return (
          <li className={cl("image")} key={item.id}>
            <Image
              src={item.image1}
              alt={item.image1_alt_text}
              align={item.image1_anchor}
            />
          </li>
        );
      })}
    </Reveal>
  );
}
