import styles from "../../styles/partials/_town-card.scss";
import { _class } from "../utilities/helpers";
import { Translation } from "../utilities/Translation";
import Image from "../widgets/Image";
import CTA from "./CTA";

const cl = _class(styles, "town_card");

TownCard.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    align: PropTypes.string,
  }),
  title: PropTypes.string,
  cta: PropTypes.shape({
    link: PropTypes.string,
    text: PropTypes.string,
  }),
  category: PropTypes.string,
  blurb: PropTypes.string,
  subcategory: PropTypes.string,
  lang: PropTypes.string,
};

TownCard.defaultProps = {
  title: "",
  image: {},
  blurb: "",
  category: "",
  cta: {},
};

export default function TownCard({
  image,
  title,
  cta,
  category,
  blurb,
  subcategory,
  lang,
}) {
  const LANG = new Translation(lang);
  return (
    <div className={`${cl("")}`}>
      <div className={cl("image")}>
        <Image src={image.src} alt={image.alt} align={image.align} />
      </div>
      <div className={cl("content")}>
        <span className={cl("location")}>
          {LANG(category)}
          {subcategory ? ` - ${subcategory}` : ""}
        </span>
        <h3 className={cl("title")}>{title}</h3>
        <div
          className={cl("blurb")}
          dangerouslySetInnerHTML={{ __html: blurb }}
        />
        <div className={cl("bottom")}>
          {cta.link && <CTA link={cta.link} text={cta.text} size="xsmall" />}
        </div>
      </div>
    </div>
  );
}
