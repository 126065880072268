import { _class } from "../utilities/helpers";
import styles from "../../styles/partials/_more-places.scss";
import Carousel from "../widgets/Carousel";

const cl = _class(styles, "more_places");

MorePlaces.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
};

MorePlaces.defaultProps = {
  items: [],
};

MorePlaces.contextTypes = {
  viewport: PropTypes.object,
};

export default function MorePlaces({ items, title }, { viewport }) {
  let slidesToShow = items.length >= 4 ? 4 : items.length;

  if (viewport.is("medium")) {
    slidesToShow = 3;
  }

  if (viewport.is("tablet")) {
    slidesToShow = 2;
  }

  if (viewport.is("small")) {
    slidesToShow = 1;
  }

  return (
    <div className={cl("")}>
      <div className={cl("container")}>
        {title && <h2>{title}</h2>}
        <div className={cl("carousel")}>
          <Carousel
            slides={items}
            caption={"linktitle"}
            linkable
            settings={{
              arrows: true,
              slidesToShow,
            }}
            parallax={false}
            getImage
          />
        </div>
      </div>
    </div>
  );
}
