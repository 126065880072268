import * as Storage from "./Storage";

export function setLanguage(lang) {
  Storage.setItem("lang", lang);
}

export function getLanguage() {
  return Storage.getItem("lang");
}

export function detectLanguage(languages) {
  const userLang = window.navigator.language;
  return languages.find((lang) => userLang.match(new RegExp(lang, "i")));
}

export function setInitialLanguage(languages) {
  languages;
  // let lang = "en";

  const stored = getLanguage();

  // language hasn't been set. Detect the language or use fallback.
  if (!stored) {
    // const userLang = detectLanguage(languages);
    // setLanguage(userLang || lang);
    // return userLang || lang;
    const lang = "en";
    setLanguage(lang);
    return lang;
  }

  // Use previously set language.
  return stored;
}

// export function setInitialLanguage() {
// const lang = "en";
// setLanguage(lang);
// return lang;
// }
