import styles from "../../styles/views/_property-detail.scss";
import { _class, parse, gaTrackEvent } from "../utilities/helpers";
import Intro from "../partials/Intro";
import Hero from "../partials/Hero";
import AmenitiesGrid from "../partials/AmenitiesGrid";
import Image from "../widgets/Image";
import VideoPlayer from "../widgets/VideoPlayer";
import Reveal from "../widgets/Reveal";
import MorePlaces from "../partials/MorePlaces";
import { Translation } from "../utilities/Translation";
import { connect } from "react-redux";
import HubSpotForm from "../partials/HubSpotForm";
const cl = _class(styles, "property_detail");

class PropertyDetail extends React.Component {
  static propTypes = {
    page: PropTypes.object,
    CONTENT: PropTypes.object,
    lang: PropTypes.string,
  };

  static contextTypes = {
    filterByLang: PropTypes.func,
    activeLangPage: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.LANG = new Translation(props.lang);
    this.slides = props.page.fieldgroup1 || [];
    this.amenities = parse(props.page.blurb3, []);
    this.bottomImages = props.page.fieldgroup3 || [];
    this.otherHotels = props.CONTENT.byTemplate("property_detail")
      .filter(context.filterByLang)
      .filter(
        (p) => p.id !== props.page.id && props.page.parentid === p.parentid
      );

    this.parent = props.CONTENT.byId(props.page.parentid);
    this.LANG = new Translation(props.lang);
  }

  renderMedia = () => {
    const { page } = this.props;

    return (
      <div className={cl("media")}>
        {page.image1 && (
          <Reveal
            className={cl("media__image")}
            cssAnimation={["fade", "left"]}
          >
            <Image
              src={page.image1}
              alt={page.image1_alt_text}
              align={page.image1_anchor}
            />
          </Reveal>
        )}
        {page.image2 && !page.h3 && (
          <Reveal
            className={cl("media__image")}
            cssAnimation={["fade", "right"]}
          >
            <Image
              src={page.image2}
              alt={page.image2_alt_text}
              align={page.image2_anchor}
            />
          </Reveal>
        )}
        {page.h3 && (
          <Reveal
            className={cl("media__video")}
            cssAnimation={["fade", "right"]}
          >
            <VideoPlayer url={page.h3} backgroundImage={page.image2} />
          </Reveal>
        )}
      </div>
    );
  };

  renderBottomImages = () => {
    return (
      <ul className={cl("bottom_images")}>
        {this.bottomImages.slice(0, 2).map((item) => (
          <li className={cl("bottom_images__image")} key={item.id}>
            <Image
              src={item.image1}
              alt={item.image1_alt_text}
              align={item.image1_anchor}
            />
          </li>
        ))}
      </ul>
    );
  };

  gaTrackEvent = () => {
    gaTrackEvent({
      category: "Click",
      action: "Completion",
      label: "Booking" + this.props.page.linktitle.replace(" ", ""),
    });
  };

  render() {
    const { page } = this.props;

    return (
      <div className={cl("")}>
        <Hero
          imageData={{ slides: this.slides }}
          videoLinkPresent={page.video_link !== null && page.video_link !== ""}
          videoLink={page.video_link}
        />
        <div className={cl("container")}>
          <Intro
            title={page.h1}
            subtitle={page.h2}
            blurb={page.blurb1}
            cta={parse(page.buttonlink1)}
            onCtaClick={this.gaTrackEvent}
          />
          {this.renderMedia()}
          <AmenitiesGrid
            title={this.LANG("amenitiesAndFeatures")}
            items={this.amenities}
          />
          {this.renderBottomImages()}
          <HubSpotForm
            formId={this.context.activeLangPage(page, "h5")}
            label="Property"
          />
        </div>
        <MorePlaces
          title={
            this.parent.template === "villas"
              ? this.LANG("otherVacationRentals")
              : this.LANG("otherHotels")
          }
          items={this.otherHotels}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  lang: global.lang,
});

export default connect(mapStateToProps)(PropertyDetail);
