import CTA from "./CTA";
import { _class } from "../utilities/helpers";
import Image from "../widgets/Image";
import styles from "../../styles/partials/_image-and-text.scss";
import Reveal from "../widgets/Reveal";
import Carousel from "../widgets/Carousel";

const cl = _class(styles, "image_and_text");

ImageAndText.propTypes = {
  title: PropTypes.string,
  blurb: PropTypes.string,
  cta: PropTypes.shape({
    link: PropTypes.string,
    text: PropTypes.string,
  }),
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    align: PropTypes.string,
  }).isRequired,
  imageAlign: PropTypes.string,
  className: PropTypes.string,
  images: PropTypes.array,
};

ImageAndText.defaultProps = {
  title: "",
  blurb: "",
  imageAlign: "left",
  className: "",
};

export default function ImageAndText({
  title,
  blurb,
  cta,
  image,
  imageAlign,
  className,
  images,
}) {
  const alignClass = imageAlign === "left" ? styles.left : styles.right;

  const imgReveal = ["fade", imageAlign == "left" ? "left" : "right"];
  const contentReveal = ["fade", imageAlign == "left" ? "right" : "left"];

  const renderMedia = () => {
    if (images && images.length) {
      return (
        <Reveal cssAnimation={imgReveal} className={cl("images")}>
          <Carousel
            slides={images}
            parallax={false}
            settings={{ arrows: true, dots: true, autoplay: true }}
          />
        </Reveal>
      );
    }

    if (image) {
      return (
        <Reveal cssAnimation={imgReveal} className={cl("image")}>
          <Image src={image.src} alt={image.alt} align={image.align} />
        </Reveal>
      );
    }

    return null;
  };

  return (
    <div className={`${cl("")} ${alignClass} ${className}`}>
      {renderMedia()}
      <Reveal cssAnimation={contentReveal} className={cl("content")}>
        <h2 className={cl("title")}>{title}</h2>
        <div
          className={cl("blurb")}
          dangerouslySetInnerHTML={{ __html: blurb }}
        />
        {cta && cta.link && (
          <CTA
            link={cta.link}
            text={cta.text}
            type="dark"
            className={cl("cta")}
          />
        )}
      </Reveal>
    </div>
  );
}
