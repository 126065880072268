// DEPRECATED

import styles from "../../styles/views/_properties.scss";
import { _class, getImage } from "../utilities/helpers";
import Intro from "../partials/Intro";
import Image from "../widgets/Image";
import Link from "../widgets/Link";
import ResultsBlock from "../partials/ResultsBlock";
import { Translation } from "../utilities/Translation";
import { connect } from "react-redux";

const cl = _class(styles, "properties");

class Properties extends React.Component {
  static propTypes = {
    page: PropTypes.object,
    CONTENT: PropTypes.object,
    lang: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.LANG = new Translation(props.lang);

    this.categories = this.getCategories();
    this.locations = props.CONTENT.childrenById(props.page.id) || [];
    this.categoryNames = Object.keys(this.categories);
    this.cards = Object.values(this.categories).reduce(
      (sum, val) => sum.concat(val),
      []
    );

    this.coordinates = {
      lat: parseFloat(props.CONTENT.options.latitude),
      lng: parseFloat(props.CONTENT.options.longitude),
    };
  }

  getCategories = () => {
    const { page, CONTENT } = this.props;
    const children = CONTENT.childrenById(page.id) || [];

    return children.reduce((sum, child) => {
      const key = child.linktitle.toLowerCase();

      sum[key] = CONTENT.childrenById(child.id).map((p) =>
        this.createCard(key, p)
      );
      return sum;
    }, {});
  };

  createCard = (key, page) => {
    return {
      id: page.id,
      latitude: page.latitude,
      longitude: page.longitude,
      title: page.linktitle,
      cta: { link: page.path, text: this.LANG("view") },
      image: getImage(page),
      blurb: page.blurb1,
      category: key,
    };
  };

  renderLocationImages = () => {
    return (
      <ul className={cl("locations")}>
        {this.locations.map((item) => {
          const image = getImage(item);

          return (
            <li className={cl("locations__item")} key={item.id}>
              <Link path={item.path}>
                <div className={cl("locations__item__image")}>
                  <Image src={image.src} alt={image.alt} align={image.align} />
                </div>
                <p>{item.linktitle}</p>
              </Link>
            </li>
          );
        })}
      </ul>
    );
  };

  render() {
    const { page } = this.props;

    return (
      <div className={cl("")}>
        <div className={cl("container")}>
          <Intro title={page.h1} blurb={page.blurb1} />
          {this.renderLocationImages()}
          <ResultsBlock
            locations={this.locations}
            filters={[
              page.checkbox1 && "View",
              "Price",
              "Bedrooms",
              "MoreFilters",
            ]}
            categories={this.categoryNames}
            cards={this.cards}
            mapCoordinates={this.coordinates}
            showFilters={page.checkbox1}
            view={page.checkbox1 ? "map" : "list"}
            page={this.props.page}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  lang: global.lang,
});

export default connect(mapStateToProps)(Properties);
