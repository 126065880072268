import { Waypoint } from "react-waypoint";
import Transition from "./Transition";
import styles from "../../styles/partials/_reveal.scss";

/*

For animating the reveal of a component when it scrolls into view.
Use cssAnmation for simple transitions on a single component.
Use properties for more complex animations or staggering.

Reveal can be controlled internally or by a parent component (prop).

*/

export default class Reveal extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    properties: PropTypes.object,
    className: PropTypes.string,
    duration: PropTypes.number,
    delay: PropTypes.number,
    mode: PropTypes.string,
    staggerClassName: PropTypes.string,
    reverse: PropTypes.bool,
    cssAnimation: PropTypes.array,
    useRevealProp: PropTypes.bool,
    visible: PropTypes.bool,
    onReveal: PropTypes.func,
    onComplete: PropTypes.func,
    scrollableAncestor: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
    bottomOffset: PropTypes.string,
  };
  static defaultProps = {
    reveal: true,
    className: "",
    delay: 0,
    staggerClassName: "",
    onReveal: () => null,
    onComplete: () => null,
    scrollableAncestor: window,
    bottomOffset: "12%",
  };

  static contextTypes = {
    accessible: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      reveal: false,
    };

    this.waypointProps = {
      onEnter: this.onEnter,
      scrollableAncestor: props.scrollableAncestor,
      bottomOffset: props.bottomOffset,
    };
  }

  componentDidMount() {
    // if (this.context.accessible && this.props.onComplete) {
    //   this.props.onComplete();
    //   this.props.onReveal();
    // }
  }

  onEnter = () => {
    if (this.props.useRevealProp) {
      return this.props.onReveal();
    }

    this.setState({ reveal: true });
  };

  renderCSSAnimation = () => {
    const { cssAnimation, children, className } = this.props;

    let revealedStyle = this.state.reveal ? styles.revealed : "";
    let revealClasses = cssAnimation
      .map((c) => styles[`reveal_${c}`])
      .join(" ");
    let classes = `${className} ${styles.reveal} ${revealClasses} ${revealedStyle}`;

    return (
      <div className={classes}>
        <Waypoint {...this.waypointProps} />
        {children}
      </div>
    );
  };

  render() {
    const {
      children,
      properties,
      className,
      duration,
      delay,
      mode,
      staggerClassName,
      reverse,
      useRevealProp,
      visible,
      onComplete,
      cssAnimation,
    } = this.props;

    if (cssAnimation && cssAnimation.length) {
      return this.renderCSSAnimation();
    }

    return (
      <Transition
        visible={useRevealProp ? visible : this.state.reveal}
        properties={properties}
        duration={duration}
        delay={delay}
        mode={mode}
        staggerClassName={staggerClassName}
        reverse={reverse}
        onComplete={onComplete}
      >
        <div className={className}>
          <Waypoint {...this.waypointProps} />
          {children}
        </div>
      </Transition>
    );
  }
}
