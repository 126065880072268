import styles from "../../styles/partials/_print-page-form.scss";
import { _class } from "../utilities/helpers";
import Reveal from "../widgets/Reveal";
import Modal from "../widgets/Modal";
import HubSpotForm from "./HubSpotForm";
import { Translation } from "../utilities/Translation";

const cl = _class(styles, "print_page_form");

PrintPageForm.propTypes = {
  formId: PropTypes.string,
  page: PropTypes.object,
  token: PropTypes.string,
  open: PropTypes.bool,
  toggle: PropTypes.func,
  lang: PropTypes.string,
  id: PropTypes.string,
};

PrintPageForm.defaultProps = {};

export default function PrintPageForm({
  formId,
  token,
  open,
  toggle,
  lang,
  id,
}) {
  const LANG = new Translation(lang);
  return (
    <div className={cl("")}>
      <Modal open={open} close={() => toggle(false)} className={cl("modal")}>
        <div className={cl("modal__container")}>
          <Reveal className={`${cl("cta")}`} cssAnimation={["fade"]}>
            <p>{LANG("submitInfo")}</p>
            <HubSpotForm
              formId={formId}
              onSubmit={() => {
                setTimeout(() => {
                  window.open(`/download-pdf?id=${id}&token=${token}`);
                }, 1000);
              }}
            />
          </Reveal>
        </div>
      </Modal>
    </div>
  );
}
