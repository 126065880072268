import ReactPlayer from "react-player";
export default class VideoPlayer extends React.Component {
  static propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    url: PropTypes.string,
    playing: PropTypes.bool,
    controls: PropTypes.bool,
    volume: PropTypes.number, //between 0 and 1
    style: PropTypes.object,
    config: PropTypes.object,
    backgroundImage: PropTypes.string,
  };

  static defaultProps = {
    config: {
      vimeo: {
        playerOptions: {
          title: false,
          byline: false,
        },
      },
    },
    width: "100%",
    height: "100%",
    controls: true,
    playing: true,
    volume: 0,
  };

  render() {
    return (
      <ReactPlayer
        url={this.props.url}
        playing={this.props.playing}
        width={this.props.width}
        height={this.props.height}
        volume={this.props.volume}
        style={this.props.style}
        controls={this.props.controls}
        config={this.props.config}
        light={this.props.backgroundImage}
      />
    );
  }
}
