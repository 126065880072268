import styles from "../../styles/views/_landing_page.scss";
import { _class, parse, getFieldGroupImage } from "../utilities/helpers";
import Hero from "../partials/Hero";
import BookingWidget from "../partials/BookingWidget";
import Reveal from "../widgets/Reveal";
import Intro from "../partials/Intro";
import ImageAndText from "../partials/ImageAndText";
import Quote from "../partials/Quote";
import Link from "../widgets/Link";
import Image from "../widgets/Image";
import Slick from "react-slick";

const cl = _class(styles, "landing_page");

LandingPage.propTypes = {
  page: PropTypes.object,
  CONTENT: PropTypes.object,
};

export default function LandingPage({ page, CONTENT }) {
  const parsed = parse(page.blurb3, []);
  const quotes = page.fieldgroup5 || [];

  const settings = {
    arrows: true,
    infinite: true,
    autoPlay: true,
  };

  const experiences = parsed.map((exp) => {
    const item = CONTENT.byId(exp.value);
    return {
      ...getFieldGroupImage(item.fieldgroup1),
      link: item.path,
      title: item.h1,
      type: parse(item.selectgroup1),
    };
  });

  const renderIntro = () => (
    <div className={cl("intro")}>
      <Intro blurb={page.blurb1} />
    </div>
  );

  const renderQuotes = () =>
    quotes.length ? (
      <div className={cl("quotes")}>
        <Slick {...settings}>
          {quotes.map((quote) => (
            <Quote key={quote.id} text={quote.blurb1} author={quote.h1} />
          ))}
        </Slick>
      </div>
    ) : null;

  const renderHeroCarousel = () => (
    <Hero
      title={page.h1}
      imageData={{ slides: this.slides, mask: true, parallax: true }}
      videoLinkPresent={page.video_link !== null && page.video_link !== ""}
      videoLink={page.video_link}
    >
      <Reveal
        className={cl("booking_widget")}
        properties={{
          opacity: [0, 1],
          autoAlpha: [0, 1],
          y: ["50%", "0%"],
        }}
        delay={1000}
        duration={1000}
      >
        <BookingWidget type="small" CONTENT={CONTENT} />
      </Reveal>
    </Hero>
  );

  const renderExperiences = () => {
    return (
      <div className={cl("experiences")}>
        <h2>Experiences</h2>
        <div className={cl("experiences__container")}>
          {experiences.map((item) => (
            <Reveal
              className={cl("experiences__card")}
              cssAnimation={["fade", "bottom"]}
              key={item.id}
            >
              <Link path={item.link}>
                <Image src={item.src} alt={item.alt} align={item.align} />
                <h3>{item.title}</h3>
              </Link>
            </Reveal>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={cl("")}>
      {renderHeroCarousel()}
      <div className={cl("container")}>
        {renderIntro()}
        {page.h2 && (
          <ImageAndText
            className={cl("image_text")}
            title={page.h2}
            blurb={page.blurb2}
            images={page.fieldgroup2}
            cta={parse(page.buttonlink1)}
            imageAlign={"left"}
          />
        )}
        {page.h3 && (
          <ImageAndText
            className={cl("image_text")}
            title={page.h3}
            blurb={page.blurb4}
            images={page.fieldgroup3}
            cta={parse(page.buttonlink2)}
            imageAlign={"right"}
          />
        )}
        {page.h4 && (
          <ImageAndText
            className={cl("image_text")}
            title={page.h4}
            blurb={page.blurb4}
            images={page.fieldgroup4}
            cta={parse(page.buttonlink3)}
            imageAlign={"left"}
          />
        )}
        {renderQuotes()}
        {renderExperiences()}
      </div>
    </div>
  );
}
