import styles from "../../styles/views/_las-catalinas.scss";
import { _class, parse } from "../utilities/helpers";
import Image from "../widgets/Image";
import Intro from "../partials/Intro";
import Hero from "../partials/Hero";
import Grid from "../partials/Grid";
import Quote from "../partials/Quote";
import Reveal from "../widgets/Reveal";
import MorePlaces from "../partials/MorePlaces";
import VideoPlayer from "../widgets/VideoPlayer";
import { Translation } from "../utilities/Translation";
import { connect } from "react-redux";

const cl = _class(styles, "las_catalinas");

class LasCatalinas extends React.Component {
  static propTypes = {
    page: PropTypes.object,
    CONTENT: PropTypes.object,
    lang: PropTypes.string,
  };

  static contextTypes = {
    filterByLang: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.LANG = new Translation(props.lang);
    this.slides = props.page.fieldgroup1 || [];
    this.gridImages = props.page.fieldgroup2 || [];
    this.gridItems = props.page.fieldgroup3 || [];
    this.experiences = this.getExperiences();
  }

  getExperiences = () => {
    return this.props.CONTENT.byTemplate("experience_detail")
      .filter(this.context.filterByLang)
      .map((p) => {
        if (p.fieldgroup1 && p.fieldgroup1.length) {
          return {
            ...p,
            image1: p.fieldgroup1[0].image1,
            image1_alt_text: p.fieldgroup1[0].image1_alt_text,
            image1_anchor: p.fieldgroup1[0].image1_anchor,
          };
        }

        return p;
      });
  };
  renderImageGrid = () => {
    return (
      <Reveal
        className={cl("image_grid")}
        staggerClassName={cl("image_grid__image")}
        mode={"stagger"}
        properties={{
          y: ["50%", "0%"],
          opacity: [0, 1],
          autoAlpha: [0, 1],
        }}
      >
        {this.gridImages.slice(0, 3).map((item) => {
          if (item.h1) {
            return (
              <div className={cl("image_grid__video")} key={item.id}>
                <VideoPlayer url={item.h1} playing={false} />
              </div>
            );
          }
          return (
            <div className={cl("image_grid__image")} key={item.id}>
              <Image
                src={item.image1}
                alt={item.image1_alt_text}
                align={item.image1_anchor}
              />
            </div>
          );
        })}
      </Reveal>
    );
  };

  renderGettingHere = () => {
    const { page } = this.props;
    return (
      <div className={cl("getting_here")}>
        <Reveal
          className={cl("getting_here__image")}
          properties={{
            y: ["-50%", "0%"],
            opacity: [0, 1],
            autoAlpha: [0, 1],
          }}
          delay={250}
        >
          <Image
            src={page.image1}
            alt={page.image1_alt_text}
            align={page.image1_anchor}
          />
        </Reveal>
        <Reveal
          className={cl("getting_here__content")}
          properties={{
            x: ["50%", "0%"],
            opacity: [0, 1],
            autoAlpha: [0, 1],
          }}
          delay={250}
        >
          <h2>{page.h2}</h2>
          <div dangerouslySetInnerHTML={{ __html: page.blurb3 }} />
        </Reveal>
      </div>
    );
  };
  render() {
    const { page } = this.props;

    return (
      <div className={cl("")}>
        <Hero
          imageData={{ slides: this.slides }}
          videoLinkPresent={page.video_link !== null && page.video_link !== ""}
          videoLink={page.video_link}
        />
        <div className={cl("container")}>
          <Intro
            title={page.h1}
            blurb={page.blurb1}
            cta={parse(page.buttonlink1)}
          />
          {this.renderImageGrid()}
          <Quote text={page.blurb2} author={page.h3} />
          {this.renderGettingHere()}
          <Grid
            decoration
            title={this.LANG("usefulTips")}
            items={this.gridItems}
          />
        </div>
        <MorePlaces
          title={this.LANG("discoverLasCatalinas")}
          items={this.experiences}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  lang: global.lang,
});

export default connect(mapStateToProps)(LasCatalinas);
