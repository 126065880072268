import { AppContext } from "./appContextProvider";

const ContentProvider = (Component) =>
  class DataProvider extends React.Component {
    render() {
      return (
        <AppContext.Consumer>
          {(value) =>
            !!value.content && (
              <Component CONTENT={value.content} {...this.props} />
            )
          }
        </AppContext.Consumer>
      );
    }
  };

export default ContentProvider;
