import SelectInput from "../widgets/SelectInput";

HeaderSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.object,
  onSelect: PropTypes.func,
  className: PropTypes.string,
};

HeaderSelect.defaultProps = {
  options: [],
  onSelect: () => null,
  className: "",
};

export default function HeaderSelect({ options, value, onSelect, className }) {
  return (
    <div className={className}>
      <SelectInput
        value={value}
        placeholder={""}
        options={options}
        onSelect={onSelect}
        classNamePrefix={"header_select"}
        className={"header_select"}
      />
    </div>
  );
}
