import { _class } from "../utilities/helpers";
import styles from "../../styles/partials/_grid.scss";
import * as SVG from "../widgets/SVG";
import Reveal from "../widgets/Reveal";

const cl = _class(styles, "grid");

Grid.propTypes = {
  title: PropTypes.string,
  decoration: PropTypes.bool,
  items: PropTypes.array,
  short: PropTypes.bool,
};

Grid.defaultProps = {
  items: [],
};

export default function Grid({ title, decoration, items, short }) {
  return (
    <Reveal
      className={`${cl("")} ${short ? styles.short : ""}`}
      mode="stagger"
      staggerClassName={cl("item")}
      properties={{
        y: ["50%", "0%"],
        opacity: [0, 1],
        autoAlpha: [0, 1],
      }}
    >
      {renderTitle(title, decoration, short)}
      <div className={cl("inner")}>
        {items.map((item, index) => {
          return (
            <div
              className={`${cl("item")} ${short ? styles.short : ""}`}
              key={item.id || index}
            >
              <div className={cl("item__icon")}>{SVG[item.h2]}</div>
              <h3 className={cl("item__title")}>{item.h1}</h3>
              {!short && <p className={cl("item__text")}>{item.blurb1}</p>}
            </div>
          );
        })}
      </div>
    </Reveal>
  );
}

function renderTitle(title, decoration, short) {
  return (
    <div className={`${cl("title_wrapper")}`}>
      {decoration && (
        <Reveal
          cssAnimation={["fade", "left"]}
          className={cl("decor")}
          delay={750}
        />
      )}
      <Reveal
        className={`${cl("title")} ${short ? styles.short : ""}`}
        cssAnimation={["fade", "top"]}
      >
        <h2>{title}</h2>
      </Reveal>

      {decoration && (
        <Reveal
          cssAnimation={["fade", "right"]}
          className={cl("decor")}
          delay={750}
        />
      )}
    </div>
  );
}
