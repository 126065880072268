import styles from "../../styles/partials/_hero-carousel.scss";
import { _class } from "../utilities/helpers";
import Carousel from "../widgets/Carousel";
import Reveal from "../widgets/Reveal";

const cl = _class(styles, "hero_carousel");

HeroCarousel.propTypes = {
  slides: PropTypes.array,
  title: PropTypes.string,
  className: PropTypes.string,
  settings: PropTypes.object,
  accessible: PropTypes.bool,
  mask: PropTypes.bool,
  children: PropTypes.node,
  parallax: PropTypes.bool,
};

HeroCarousel.defaultProps = {
  slides: [],
  title: "",
  className: "",
  settings: {
    dots: false,
    arrows: true,
  },
};

export default function HeroCarousel(
  { slides, className, settings, mask, parallax },
  { accessible }
) {
  parallax;

  if (!slides || (slides && slides.length < 1)) {
    return false;
  }

  const maskStyle = mask ? styles.mask : "";
  return (
    <Reveal
      className={`${accessible ? cl("ada") : cl("")} ${className} ${maskStyle}`}
      properties={{
        opacity: [0, 1],
        autoAlpha: [0, 1],
      }}
      duration={2000}
    >
      <div className={cl("carousel_container")}>
        <Carousel
          slides={slides}
          settings={settings}
          parallax={false}
          keyboardControl={settings.keyboardControl}
        />
      </div>
    </Reveal>
  );
}
