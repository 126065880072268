import styles from "../../../styles/views/_checkout.scss";
import { _class, gaTrackPage } from "../../utilities/helpers";
import { Translation } from "../../utilities/Translation";
import Reveal from "../../widgets/Reveal";
import CreateBookingForm from "../CreateBookingForm";
import { connect } from "react-redux";

const cl = _class(styles, "checkout");

class CheckoutForm extends React.Component {
  static propTypes = {
    lang: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);

    this.LANG = new Translation(props.lang);
  }

  componentDidMount() {
    gaTrackPage("/btt/booking-engine/guest-details");
  }

  render() {
    return (
      <div className={`${cl("step")} ${styles.two}`}>
        <Reveal cssAnimation={["fade", "top"]}>
          <h1>{this.LANG("guestDetails")}</h1>
        </Reveal>

        <CreateBookingForm />
      </div>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  lang: global.lang,
});

export default connect(mapStateToProps)(CheckoutForm);
