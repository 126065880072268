import styles from "../../styles/views/_group-travel.scss";
import { _class, parse, gtagTrackEvent } from "../utilities/helpers";
import Intro from "../partials/Intro";
import Hero from "../partials/Hero";
import ImageGrid from "../partials/ImageGrid2";
import HubSpotForm from "../partials/HubSpotForm";

const cl = _class(styles, "group_travel");

class GroupTravel extends React.Component {
  static propTypes = {
    page: PropTypes.object,
    CONTENT: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.slides = props.page.fieldgroup1 || [];
    this.gridImages = props.page.fieldgroup2 || [];
  }

  render() {
    const { page } = this.props;

    return (
      <div className={cl("")}>
        <Hero
          imageData={{ slides: this.slides }}
          videoLinkPresent={page.video_link !== null && page.video_link !== ""}
          videoLink={page.video_link}
        />
        <div className={cl("container")}>
          <Intro
            title={page.h1}
            blurb={page.blurb1}
            cta={parse(page.buttonlink1)}
          />
          <ImageGrid images={this.gridImages.slice(0, 4)} />
          <div className={cl("form_container")}>
            <Intro subtitle={page.h2} blurb={page.blurb2} />
            <HubSpotForm
              formId={page.h3}
              label="GroupTravel"
              onSubmit={() => {
                gtagTrackEvent("AW-817146118/wii0CL77j-4BEIbS0oUD");
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default GroupTravel;
