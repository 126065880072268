import Slick from "react-slick";
import Image from "widgets/Image";
import { Parallax } from "react-parallax";
import Link from "./Link";
import { getImage } from "../utilities/helpers";
import VideoPlayer from "./VideoPlayer";
// when using the carousel in your component:
// wrap the carousel in a container
// it will inherit the size of it's wrapper :)

class Carousel extends React.Component {
  static propTypes = {
    slides: PropTypes.array,
    settings: PropTypes.object,
    className: PropTypes.string,
    keyboardControl: PropTypes.bool,
    parallax: PropTypes.bool,
    caption: PropTypes.string,
    linkable: PropTypes.bool,
    getImage: PropTypes.bool,
  };

  static defaultProps = {
    settings: {
      arrows: true,
      dots: true,
    },
    parallax: true,
  };

  imageComponentFor(slide) {
    let imgProps = {
      align: slide?.image1_anchor,
      src: slide?.image1,
      alt: slide?.image1_alt_text,
    };

    if (slide?.video_url) {
      return <VideoPlayer url={slide.video_url} playing={false} />;
    }

    if (this.props.getImage) {
      imgProps = getImage(slide);
    }

    if (this.props.linkable) {
      return (
        <Link className={"carousel_link"} path={slide.path}>
          <Image {...imgProps} />
        </Link>
      );
    }
    return <Image {...imgProps} />;
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("keydown", this.handleKeyDown);
    }
  }

  handleKeyDown = (e) => {
    if (this.props.keyboardControl && this.slick) {
      if (
        !document.activeElement ||
        document.activeElement.tagName !== "INPUT"
      ) {
        if (e.key === "ArrowLeft" || e.key === "Left") {
          this.slick.slickPrev();
        } else if (e.key === "ArrowRight" || e.key === "Right") {
          this.slick.slickNext();
        }
      }
    }
  };

  render() {
    const {
      slides,
      className,
      parallax,
      caption,
      linkable,
      settings,
    } = this.props;
    if (!slides || !slides.length) {
      return null;
    }

    if (parallax) {
      return (
        <Parallax
          blur={0}
          bgImage={slides[0].image1}
          bgImageAlt={slides[0].image1_alt || "alt"}
          strength={100}
        >
          <div className={`carousel_parallax`} />
        </Parallax>
      );
    }

    if (slides.length === 1) {
      return (
        <div
          className={`carousel-with-one-image ${className}`}
          style={{ padding: 0 }}
        >
          {this.imageComponentFor(slides[0])}
          {caption && (
            <span className="image-caption">{slides[0][caption]}</span>
          )}
        </div>
      );
    }

    return (
      <div className={`carousel ${className}`}>
        <Slick ref={(r) => (this.slick = r)} {...settings} lazyLoad>
          {slides && slides.map((slide, i) => {
            return (
              <div
                key={i}
                className={`carousel_slide ${linkable ? "linkable" : ""}`}
              >
                {this.imageComponentFor(slide)}
                {caption && (
                  <span className="image-caption">{slide[caption]}</span>
                )}
              </div>
            );
          })}
        </Slick>
      </div>
    );
  }
}

export default Carousel;
