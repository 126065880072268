import Select from "react-select";
import styles from "../../styles/widgets/_select-input.scss";

class SelectInput extends React.Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    className: PropTypes.string,
    classNamePrefix: PropTypes.string,
    isSearchable: PropTypes.bool,
    isClearable: PropTypes.bool,
    isMulti: PropTypes.bool,
    isDisabled: PropTypes.bool,
  };

  static defaultProps = {
    options: [],
    onSelect: () => null,
  };

  render() {
    const {
      value,
      options,
      placeholder,
      onSelect,
      className,
      classNamePrefix,
      isSearchable,
      isClearable,
      isMulti,
      isDisabled,
    } = this.props;

    return (
      <div className={styles.select_input}>
        <Select
          value={value}
          options={options}
          placeholder={placeholder}
          onChange={onSelect}
          inputProps={{ readOnly: true }}
          className={className}
          classNamePrefix={classNamePrefix}
          isSearchable={isSearchable}
          blurInputOnSelect
          isClearable={isClearable}
          isMulti={isMulti}
          isDisabled={isDisabled}
        />
      </div>
    );
  }
}

export default SelectInput;
