import ErrorHandler from "./ErrorHandler";
import LabelContainer from "./LabelContainer";

class FileInput extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    textFormatting: PropTypes.func,
    disabled: PropTypes.bool,
    inputClassName: PropTypes.string,
    containerClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    errorMessageClassName: PropTypes.string,
    inputStyle: PropTypes.object,
    labelStyle: PropTypes.object,
    errorMessageStyle: PropTypes.object,
    onChange: PropTypes.func,
    children: PropTypes.node,
    placeholder: PropTypes.string,
    errorMessage: PropTypes.string,
    validator: PropTypes.func,
    label: PropTypes.string,
    labelOnBottom: PropTypes.bool,
    accept: PropTypes.string,
    innerRef: PropTypes.func,
    formState: PropTypes.object.isRequired,
    formFunctions: PropTypes.object.isRequired,
    formName: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.header = { "content-type": "multipart/form-data" };
  }

  componentDidMount() {
    this.props.formFunctions.changePostHeader(this.header);
    let hasError = false;
    this.props.formFunctions.updateField(this.props.name, "");
    this.props.formFunctions.updateFieldErrors(this.props.name, hasError);
  }

  inputChange = e => {
    let value = e.target.files[0];
    this.props.formFunctions.updateField(
      this.props.name,
      value,
      this.props.onChange
    );
  };

  renderFileName() {
    const file = this.props.formState.values[this.props.name];
    return file ? file.name : "";
  }

  render() {
    const {
      containerClassName,
      inputClassName,
      errorMessageClassName,
      labelClassName,
      label,
      labelOnBottom,
      inputStyle,
      errorMessageStyle,
      labelStyle,
      disabled,
      name,
      formState,
      formFunctions,
      placeholder,
      validator,
      errorMessage,
      accept,
      innerRef
    } = this.props;
    const displayError =
      formState.errors[name] && formState.submitAttempted && !formState.success;
    const fieldId = `${name}_${this.props.formName}`;
    return (
      <div className={`field ${containerClassName}`}>
        {!labelOnBottom && (
          <LabelContainer
            {...this.props}
            name={fieldId}
            className={labelClassName}
            style={labelStyle}
          >
            {label}
          </LabelContainer>
        )}
        <input
          type="file"
          style={{ display: "none" }}
          disabled={disabled}
          name={name}
          id={fieldId}
          onChange={e => this.inputChange(e)}
          value={""}
          accept={accept}
        />
        <button
          className={`${inputClassName} ${displayError ? "error" : ""}`}
          style={inputStyle}
          ref={innerRef}
          onClick={e => e.previousSibling.click()}
          onKeyUp={e => (e.key === "Enter" ? e.previousSibling.click() : false)}
        >
          {this.renderFileName()}
          {!formState.values[name] && placeholder}
        </button>
        {labelOnBottom && (
          <LabelContainer
            {...this.props}
            name={fieldId}
            className={labelClassName}
            style={labelStyle}
          >
            {label}
          </LabelContainer>
        )}
        {validator && (
          <ErrorHandler
            name={name}
            formState={formState}
            errorHandler={validator}
            formFunctions={formFunctions}
            className={errorMessageClassName}
            style={errorMessageStyle}
          >
            {errorMessage}
          </ErrorHandler>
        )}
      </div>
    );
  }
}

export default FileInput;
