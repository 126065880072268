import TYPES from "./GlobalTypes";
import { currencies } from "../../config/appConfig";
import moment from "moment";
import "moment/locale/es";

moment.locale("en");

const CASES = {};

const INITIAL_STATE = {
  currency: { code: "usd", symbol: "$", iso: "840" },
  lang: "en",
  languages: ["en", "es"],
  printPageToken: `${Math.floor(Math.random() * 100000000)}`,
};

export default function GlobalReducer(state = INITIAL_STATE, action) {
  if (CASES[action.type]) {
    return CASES[action.type](state, action);
  }
  return state;
}

CASES[TYPES.SET_CURRENCY] = (state, { payload }) => ({
  ...state,
  currency: currencies.find((rec) => rec.code === payload),
});

CASES[TYPES.SET_LANG] = (state, { payload }) => {
  moment.locale(payload);
  return {
    ...state,
    lang: payload,
  };
};

CASES[TYPES.REGISTER_LANGUAGES] = (state, { payload }) => ({
  ...state,
  languages: payload,
});
