import InputRange from "react-input-range";

RangeSlider.propTypes = {
  value: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  currency: PropTypes.object,
};

RangeSlider.defaultProps = {
  value: {
    min: 0,
    max: 100,
  },
  min: 0,
  max: 5000,
  onChange: () => null,
  currency: {},
};

export default function RangeSlider({ min, max, value, onChange, currency }) {
  return (
    <div className={"range_slider"}>
      <InputRange
        formatLabel={(value) => `${currency.symbol}${value}`}
        minValue={min}
        maxValue={max}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}
