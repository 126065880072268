import styles from "../../styles/partials/_quick-booking.scss";
import {
  _class,
  capitalize,
  reservationOptions,
  staySubtotal,
  stayLength,
  stayFees,
  stayFeesBreakdown,
  stayTaxes,
  stayTotal,
  stayNightlyRate,
  renderSynxisError,
  numberWithCommas,
  parse,
} from "../utilities/helpers";
import CTA from "./CTA";
import SelectInput from "../widgets/SelectInput";
import DayPickerInput from "react-day-picker/DayPickerInput";
import moment from "moment";
import Reveal from "../widgets/Reveal";
import { Translation } from "../utilities/Translation";
import Loader from "./Loader";
import {
  deleteAvailability,
  deleteAvailabilities,
  getAvailability,
  updateBookingDetails,
  getLeadAvailability,
} from "../redux/synxis/SynxisActions";
import { connect } from "react-redux";
import { formatDate } from "react-day-picker/moment";
import MomentLocaleUtils from "react-day-picker/moment";
import "moment/locale/es";
import { arrow } from "../widgets/SVG";
import {
  logCheckoutDetails,
  logBookingSearchDetails,
} from "../utilities/Logger";

import { bookingDisabled, bookingDisabledFormUrl } from "../config/appConfig";

const cl = _class(styles, "quick_booking");

class QuickBooking extends React.Component {
  static propTypes = {
    getAvailability: PropTypes.func,
    singleAvailability: PropTypes.object,
    singleAvailabilityPending: PropTypes.bool,
    updateBookingDetails: PropTypes.func,
    promotions: PropTypes.object,
    bookingDetails: PropTypes.object,
    currency: PropTypes.object,
    lang: PropTypes.string,
    CONTENT: PropTypes.object,
    rates: PropTypes.array,
    getLeadAvailability: PropTypes.func,
    deleteAvailability: PropTypes.func,
    leadAvailability: PropTypes.object,
    availabilityFailure: PropTypes.string,
    page: PropTypes.object,
  };

  static defaultTypes = {
    onSubmit: () => null,
  };

  static contextTypes = {
    history: PropTypes.object,
    filterByLang: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.LANG = new Translation(props.lang);

    this.guestOptions = reservationOptions(
      "guest",
      (props.bookingDetails.synxisRoom &&
        props.bookingDetails.synxisRoom.Details.GuestLimit.Value) ||
        2,
      1,
      props.lang
    );

    const rooms = props.bookingDetails.bedrooms;

    this.roomOptions = [
      {
        label: `${rooms} ${
          rooms !== 1 ? this.LANG("bedrooms") : this.LANG("bedroom")
        }`,
        value: rooms || 1,
      },
    ];

    this.promoTimeout = 0;

    this.today = moment().toDate();

    this.state = {
      errors: {
        lodging: false,
        guests: false,
        dates: false,
      },
      promo: "",
      toolTip: null,
    };
  }
  componentDidMount() {
    this.getRate();
    this.getLeadAvailability(this.props.bookingDetails.from);

    // this.getLeadAvailability(moment("2025-08-01").toDate());
  }

  componentDidUpdate(prevProps) {
    const {
      singleAvailability,
      bookingDetails,
      updateBookingDetails,
      singleAvailabilityPending,
      // getLeadAvailability,
      availabilityFailure,
    } = this.props;
    // console.log("availabilityFailure", availabilityFailure);
    // console.log("singleAvailability", singleAvailability);

    if (!prevProps.singleAvailability && singleAvailability) {
      updateBookingDetails({
        nights: stayLength(bookingDetails.to, bookingDetails.from),
        subtotal: staySubtotal(singleAvailability),
        taxes: stayTaxes(singleAvailability),
        fees: stayFees(singleAvailability),
        feesBreakdown: stayFeesBreakdown(singleAvailability),
        total: stayTotal(singleAvailability),
        rate: singleAvailability.Product.Rate.Code,
        nightly: stayNightlyRate(singleAvailability),
      });
    }

    if (
      moment(prevProps.bookingDetails.from).format("YYYY-MM-DD") !==
        moment(bookingDetails.from).format("YYYY-MM-DD") ||
      moment(prevProps.bookingDetails.to).format("YYYY-MM-DD") !==
        moment(bookingDetails.to).format("YYYY-MM-DD")
    ) {
      this.getLeadAvailability();
    }

    if (
      !singleAvailability &&
      !singleAvailabilityPending &&
      !availabilityFailure
    ) {
      this.getRate();
    }
  }

  toggleToolTip = (key) => this.setState({ toolTip: key });

  guestsValue = () => {
    const { synxisRoom, guests } = this.props.bookingDetails;

    return synxisRoom
      ? reservationOptions(
          "guest",
          synxisRoom.Details.GuestLimit.Value,
          1,
          this.props.lang
        ).find((opt) => parseInt(opt.value) === parseInt(guests)) || null
      : null;
  };

  getRate = () => {
    const { getAvailability, page } = this.props;
    const { from, to, guests, roomCode } = this.props.bookingDetails;

    // make sure we fetch availabilities for the current room
    if (from && to && guests && page.h4 === roomCode) {
      getAvailability(page.h4, {
        adults: guests,
        startDate: moment(from).format("YYYY-MM-DD"),
        endDate: moment(to).format("YYYY-MM-DD"),
        accessCode: this.state.promo,
        accessType: "promotion",
      });
    }
  };

  getLeadAvailability = (date) => {
    const { getLeadAvailability, page } = this.props;
    const { guests } = this.props.bookingDetails;

    let startDate;

    const day = moment(date).startOf("day");
    const startMonth = moment(date).startOf("month");
    const today = moment();

    if (startMonth.isSameOrAfter(today)) {
      startDate = startMonth.format("YYYY-MM-DD");
    } else if (today.isSameOrAfter(day)) {
      startDate = today.format("YYYY-MM-DD");
    } else {
      startDate = day.format("YYYY-MM-DD");
    }

    getLeadAvailability({
      adults: guests || 1,
      startDate,
      endDate: moment(date)
        .add(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD"),
      accessCode: this.state.promo,
      accessType: "promotion",
      numRooms: 1,
      roomCode: page.h4,
      onlyCheckRequested: true,
      lengthOfStay: 1,
      key: this.leadKey(date),
    });
  };

  submit = (e) => {
    const {
      singleAvailability,
      bookingDetails,
      availabilityFailure,
    } = this.props;
    e.preventDefault();

    const { guests, bedrooms, from, to, cmsRoom } = bookingDetails;

    if (singleAvailability && bookingDetails && !availabilityFailure) {
      const checkoutPage = this.props.CONTENT.byTemplate("checkout").filter(
        this.context.filterByLang
      )[0];

      logCheckoutDetails({
        bookingDetails,
        singleAvailability,
        step: "QUICK_BOOKING",
      });

      const lodging = parse(cmsRoom.selectgroup2, [])[0];

      logBookingSearchDetails({
        checkinDate: from,
        checkoutDate: to,
        guests,
        bedrooms,
        path: window.location.pathname,
        accommodation: lodging ? lodging.value : "unset",
      });

      return this.context.history.pushTransition(checkoutPage.path);
    }

    return this.getRate();
  };

  handleSelect = (selected) => {
    const { updateBookingDetails, deleteAvailability } = this.props;

    deleteAvailability();

    updateBookingDetails({
      subtotal: null,
      taxes: null,
      total: null,
      fees: null,
      feesBreakdown: null,
      availability: null,
      rate: null,
      packages: [],
      guests: selected.value,
    });
  };

  handleDayChange = (field, val) => {
    const {
      deleteAvailability,
      updateBookingDetails,
      bookingDetails,
    } = this.props;

    const { from, to } = bookingDetails;
    const day = moment(val);
    const startDate = moment(from);
    const endDate = moment(to);
    let fromDate, toDate;

    if (field === "from" && day >= endDate.toDate()) {
      fromDate = day;
      toDate = moment(val).startOf("day").add(1, "days");
    }

    if (field === "to" && day <= startDate.toDate()) {
      toDate = day;
      fromDate = moment(val).startOf("day").subtract(1, "days");
    }

    if (field === "from" && day < endDate.toDate()) {
      fromDate = day;
      toDate = endDate;
    }

    if (field === "to" && day > startDate.toDate()) {
      fromDate = startDate;
      toDate = day;
    }

    deleteAvailability();

    updateBookingDetails({
      ...(bookingDetails || {}),
      from: fromDate.toDate(),
      to: toDate.toDate(),
      nights: stayLength(fromDate.toDate(), toDate.toDate()),
      availability: null,
      taxes: null,
      total: null,
      fees: null,
      feesBreakdown: null,
      rate: null,
      subtotal: null,
      nightly: null,
      packages: [],
    });

    if (field === "from") {
      const picker = document.querySelector(`#date_to input`);
      picker && picker.focus();
    }
  };

  // debounced to prevent the loading indicator from appearing
  // after every keystroke because we're fetching new availability
  onPromoChange = (e) => {
    const {
      deleteAvailability,
      updateBookingDetails,
      bookingDetails,
    } = this.props;
    const promo = e.target.value;

    this.setState({ promo });

    if (this.promoTimeout) clearTimeout(this.promoTimeout);

    this.promoTimeout = setTimeout(() => {
      deleteAvailability();

      updateBookingDetails({
        ...(bookingDetails || {}),
        availability: null,
        taxes: null,
        total: null,
        fees: null,
        feesBreakdown: null,
        rate: null,
        subtotal: null,
        nightly: null,
        packages: [],
        promo,
      });
    }, 500);
  };

  leadKey = (date) =>
    `${this.props.page.h4}_${moment(date)
      .startOf("month")
      .format("YYYY-MM-DD")}`;

  onMonthChange = (date) => {
    const { leadAvailability } = this.props;
    const key = this.leadKey(date);

    if (!leadAvailability || (leadAvailability && !leadAvailability[key])) {
      this.getLeadAvailability(date);
    }
  };

  renderPromoInput = () => (
    <div className={`${cl("promo")}`}>
      <input
        type="text"
        onChange={this.onPromoChange}
        value={this.state.promo}
        placeholder={this.LANG("promo")}
      />
      <div>
        {this.props.bookingDetails.rate === this.state.promo && (
          <span>{this.LANG("promoApplied")}</span>
        )}
      </div>
    </div>
  );

  nodeparture = (date) => {
    const { leadAvailability } = this.props;

    const today = moment().format("D");
    const month = moment().format("M");
    const day = moment(date).format("D");
    const dateMonth = moment(date).format("M");
    const key = this.leadKey(date);

    if (leadAvailability && leadAvailability[key]) {
      const index = parseInt(day) - (month !== dateMonth ? 1 : parseInt(today));
      const val = leadAvailability[key][index];

      if (val && val.Price && val.Price.length) {
        return val.Failures.find((f) => f.Cause === "NoDeparture");
      }
    }
  };

  renderDatePicker = (name) => {
    const { bookingDetails } = this.props;
    const { from, to } = bookingDetails;
    const dateErrorStyle = this.state.errors[name] ? styles.error : "";

    const day = moment(bookingDetails[name]).toDate();
    let month;

    const disabledDays = [
      {
        before:
          name === "to" ? moment(from).add(1, "days").toDate() : this.today,
      },
    ];
    // console.log("disabledDays", disabledDays);

    if (name === "to") {
      month = moment(from).toDate();
    }

    if (name === "from") {
      month = moment(to).toDate();
    }

    return (
      <div
        className={`${cl("date_picker")} ${dateErrorStyle} `}
        id={"date_" + name}
      >
        <DayPickerInput
          label={this.LANG("selectDates")}
          numberOfMonths={1}
          value={day}
          formatDate={formatDate}
          format="YYYY-MM-DD"
          onDayChange={this.handleDayChange.bind(null, name)}
          hideOnDayClick={name === "to"}
          dayPickerProps={{
            selectedDays: [from, { from: from, to: to }],
            modifiers: { nodeparture: this.nodeparture },
            disabledDays,
            month,
            fromMonth: name === "to" ? moment(from).toDate() : this.today,
            locale: this.props.lang,
            localeUtils: MomentLocaleUtils,
            renderDay: this.renderDay.bind(null, name),
            onMonthChange: this.onMonthChange,
          }}
          placeholder={capitalize(name)}
          showOverlay={true}
        />
      </div>
    );
  };

  renderDay = (name, date) => {
    const { leadAvailability } = this.props;

    const day = moment(date).format("D");
    const key = this.leadKey(date);

    let failures = [];
    if (leadAvailability && leadAvailability[key]) {
      // const index = parseInt(day);

      //Lets find the ArrivalDate key value that matches the date. The value is coming in as a string with the format YYYY-MM-DD
      const arrivalDate = leadAvailability[key].find(
        (day) => day.ArrivalDate === moment(date).format("YYYY-MM-DD")
      );

      if (arrivalDate && arrivalDate.Price && arrivalDate.Price.length) {
        failures = arrivalDate.Failures.reduce(
          (sum, fail) => sum.concat(styles[fail.Cause]),
          []
        ).filter((failure) => failure !== undefined);
      }
      // if (val && val.Price && val.Price.length) {
      //   failures = val.Failures.reduce(
      //     (sum, fail) => sum.concat(styles[fail.Cause]),
      //     []
      //   ).filter((failure) => failure !== undefined);
      // }
      // console.log(
      //   "%c date/index/leadAvailability/arrivalDate/key/failures",
      //   "background: navy; color: aqua",
      //   moment(date).format("YYYY-MM-DD"),
      //   leadAvailability,
      //   // val,
      //   arrivalDate,
      //   key,
      //   failures
      // );
    }

    return (
      <div className={`${cl("date_picker__day")}`}>
        <p>{day}</p>
        {failures.map((fail) => {
          return <div className={`${styles.failure} ${fail}`} key={fail} />;
        })}
      </div>
    );
  };

  renderLodgingOptions = () => {
    const { bedrooms } = this.props.bookingDetails;
    const { errors } = this.state;
    const guestsErrorStyle = errors.guests ? styles.error : "";

    return (
      <div className={cl("lodging_details")}>
        <div className={`${cl("guests")} ${guestsErrorStyle}`}>
          <SelectInput
            value={this.guestsValue()}
            placeholder={this.LANG("guests") + "s"}
            options={this.guestOptions}
            onSelect={this.handleSelect}
            className={"quick_booking_select"}
            classNamePrefix={"quick_booking_select"}
          />
        </div>
        <div className={`${cl("rooms")}`}>
          <p>
            {bedrooms}{" "}
            {bedrooms == 1 ? this.LANG("bedroom") : this.LANG("bedrooms")}
          </p>
        </div>
      </div>
    );
  };

  renderSubtotals = () => {
    const { currency, rates } = this.props;
    const {
      nights,
      subtotal,
      taxes,
      feesBreakdown,
      rate,
    } = this.props.bookingDetails;

    return (
      <div className={cl("fees")}>
        <div className={cl("fees__row")}>
          <p>{this.LANG("rate")}</p>
          <p>
            {rates && rates[rate]
              ? this.LANG(rates[rate].Name)
              : this.LANG(rate)}
          </p>
        </div>
        <div className={cl("fees__row")}>
          <p>
            {nights} {nights != 1 ? this.LANG("nights") : this.LANG("night")}
          </p>
          <p>
            {!!subtotal && currency.symbol}
            {numberWithCommas(subtotal || 0)}
          </p>
        </div>
        <div className={cl("fees__row")}>
          <p>{this.LANG("taxes")}</p>
          <p>
            {!!taxes && currency.symbol}
            {numberWithCommas(taxes || 0)}
          </p>
        </div>

        {feesBreakdown &&
          feesBreakdown.map((fee) => (
            <div className={cl("fees__row")} key={fee.Code}>
              <p>{this.LANG(fee.Code)}</p>
              <p>
                {currency.symbol}
                {numberWithCommas(fee.Amount)}
              </p>
            </div>
          ))}
      </div>
    );
  };

  renderLoading = () => {
    const { singleAvailabilityPending } = this.props;
    return (
      <div
        className={`${cl("loading")} ${
          singleAvailabilityPending ? styles.visible : ""
        }`}
      >
        <Loader />
      </div>
    );
  };

  renderError = () => {
    const {
      singleAvailabilityPending,
      singleAvailability,
      availabilityFailure,
    } = this.props;
    if (availabilityFailure) {
      return (
        <p className={cl("availability_error")}>
          {renderSynxisError(availabilityFailure)}
        </p>
      );
    }
    if (singleAvailability === false && !singleAvailabilityPending) {
      return (
        <p className={cl("no_availability")}>{this.LANG("noAvailability")}</p>
      );
    }
  };

  render() {
    const {
      bookingDetails,
      singleAvailability,
      availabilityFailure,
      currency,
    } = this.props;

    // console.log("bookingDisabled", bookingDisabled);
    return (
      <Reveal cssAnimation={["fade", "right"]} className={cl("")}>
        <form onSubmit={this.submit} data-tip={"hello world"}>
          <h4>{this.LANG("quickBooking")}</h4>
          {bookingDisabled ? (
            <div className={cl("actions")}>
              <CTA
                text={this.LANG("Request Information")}
                type="orange"
                link={
                  bookingDisabledFormUrl !== "" ? bookingDisabledFormUrl : ""
                }
              />
            </div>
          ) : (
            <div>
              <div className={cl("dates")}>
                <div>{this.renderDatePicker("from")}</div>
                <div>{arrow}</div>
                <div>{this.renderDatePicker("to")}</div>
              </div>
              {this.renderLoading()}
              {this.renderLodgingOptions()}
              {this.renderPromoInput()}
              {this.renderSubtotals()}

              <div className={cl("total")}>
                <p>{this.LANG("total")}</p>
                <p>
                  {!!bookingDetails.total && currency.symbol}
                  {numberWithCommas(bookingDetails.total)}
                </p>
              </div>
              {this.renderError()}

              <div className={cl("actions")}>
                <CTA
                  text={
                    singleAvailability && bookingDetails && !availabilityFailure
                      ? this.LANG("bookNow")
                      : this.LANG("checkRate")
                  }
                  onClick={this.submit}
                  type="orange"
                />
              </div>
            </div>
          )}
        </form>
      </Reveal>
    );
  }
}

const mapStateToProps = ({ synxis, global }) => {
  console.log("Synxis Data", synxis);
  return {
    singleAvailability:
      synxis.singleAvailability && synxis.singleAvailability.data,
    availabilityFailure:
      synxis.singleAvailability && synxis.singleAvailability.failure,
    singleAvailabilityPending: synxis.singleAvailabilityPending,
    bookingDetails: synxis.bookingDetails,
    promotions: synxis.promotions,
    lang: global.lang,
    currency: global.currency,
    rates: synxis.rates,
    leadAvailability: synxis.leadAvailability,
  };
};

export default connect(mapStateToProps, {
  getAvailability,
  deleteAvailability,
  deleteAvailabilities,
  updateBookingDetails,
  getLeadAvailability,
})(QuickBooking);
