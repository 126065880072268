import views from "./views";
import ErrorBoundary from "./ErrorBoundary";
import { LazyBoundary } from "react-imported-component";
import { Switch, Route, Redirect } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import RoomPDF from "../views/room_pdf";
import { getQueryParams } from "./helpers";
import SuspenseComponent from "./SuspenseComponent";

export const doNotRoute = {
  town_category: true,
  town_category_item: true,
  root: true,
  options: true,
};

export const useParentRoute = {
  collection_detail: true,
};

export const usesParentRoute = (page) => useParentRoute[page.template];

export default class Routes extends React.PureComponent {
  static propTypes = {
    location: PropTypes.object,
    CONTENT: PropTypes.object,
    activeLang: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.routes = this.buildRoutes();
  }

  isValidRoute = (page) => !doNotRoute[page.template];

  setPage = (page) =>
    usesParentRoute(page) ? this.props.CONTENT.byId(page.parentid) : page;

  buildRoutes = () => {
    return Object.values(this.props.CONTENT.all)
      .map((page) => {
        if (this.isValidRoute(page)) {
          let formattedPage = this.setPage(page);

          if (page.path === "/" || page.template === "home") {
            return this.homeRoute(page.path);
          }

          return this.standardRoute(formattedPage, page);
        }
      })
      .concat(this.pdfDownloadRoute(this.props.CONTENT))
      .concat(this.redirectAllElse);
  };

  pdfDownloadRoute = (CONTENT) => (
    <Route
      exact
      key={"download-pdf"}
      path={"/download-pdf"}
      render={(props) => {
        const params = getQueryParams(window.location.search);

        if (window.location.pathname !== "/download-pdf") {
          return null;
        }

        if (!params || !params.id || !params.token) {
          return <Redirect to="/" />;
        }

        const page = CONTENT.byId(params.id);

        if (!page.id) {
          return <Redirect to="/" />;
        }

        const lang = page.path.match(/^\/es/) ? "es" : "en";

        return <RoomPDF {...props} CONTENT={CONTENT} page={page} lang={lang} />;
      }}
    />
  );

  homeRoute = (path) => {
    const page =
      path === "/es"
        ? this.props.CONTENT.byPath(path)
        : this.props.CONTENT.byId(3);

    return (
      <Route
        exact
        key={page.id}
        path={page.path}
        render={(props) => this.renderComponent(page, props)}
      />
    );
  };

  standardRoute = (page, childPage) => {
    return (
      <Route
        exact
        key={page.id}
        path={childPage.path}
        render={(props) => this.renderComponent(page, props)}
      />
    );
  };

  redirectAllElse = (
    <Route
      path="/"
      key="redirectAllElse"
      render={() => (
        <Redirect
          to={`${window.location.pathname.match(/\/es/) ? "/es" : "/"}`}
        />
      )}
    />
  );

  renderComponent = (page, props) => {
    const view = page.template.toLowerCase();
    const Component = views[view] || views["default"];

    return (
      <LazyBoundary fallback={<div />}>
        <ErrorBoundary>
          <Component page={page} {...props} CONTENT={this.props.CONTENT} />
        </ErrorBoundary>
      </LazyBoundary>
    );
  };

  findPage = (path) => this.props.CONTENT.byPath(path);

  render() {
    const page = this.findPage(window.location.pathname);

    return (
      <TransitionGroup>
        <CSSTransition
          key={this.setPage(page).id}
          timeout={500}
          classNames="page-transition"
        >
          <SuspenseComponent>
            <div className={"page"}>
              <Switch location={this.props.location}>{this.routes}</Switch>
            </div>
          </SuspenseComponent>
        </CSSTransition>
      </TransitionGroup>
    );
  }
}
