import styles from "../../styles/views/_room.scss";
import {
  _class,
  parse,
  getQueryParams,
  gaTrackPage,
} from "../utilities/helpers";
import HeroCarousel from "../partials/HeroCarousel";
import Quote from "../partials/Quote";
import Image from "../widgets/Image";
import VideoPlayer from "../widgets/VideoPlayer";
import AmenitiesGrid from "../partials/AmenitiesGrid";
import CTA from "../partials/CTA";
import Link from "../widgets/Link";
import Modal from "../widgets/Modal";
import Intro from "../partials/Intro";
import HubSpotForm from "../partials/HubSpotForm";
import MorePlaces from "../partials/MorePlaces";
import QuickBooking from "../partials/QuickBooking";
import GalleryModal from "../partials/GalleryModal";
import { Translation } from "../utilities/Translation";
import { connect } from "react-redux";
import MapImage from "../../images/lc_town_map_3.svg";
import LeafletMap from "../partials/LeafletMap";
import Loader from "../partials/Loader";
import {
  createBookingDetails,
  deleteBookingDetails,
  deleteAvailability,
} from "../redux/synxis/SynxisActions";
import moment from "moment";
import * as SVG from "../widgets/SVG";
import PrintPageForm from "../partials/PrintPageForm";
import browser from "bowser";

const cl = _class(styles, "room");

class Room extends React.Component {
  static propTypes = {
    page: PropTypes.object,
    CONTENT: PropTypes.object,
    rooms: PropTypes.object,
    bookingDetails: PropTypes.object,
    createBookingDetails: PropTypes.func,
    deleteBookingDetails: PropTypes.func,
    lang: PropTypes.string,
    deleteAvailability: PropTypes.func,
    printPageToken: PropTypes.string,
  };

  static contextTypes = {
    filterByLang: PropTypes.func,
    viewport: PropTypes.object,
    history: PropTypes.object,
    activeLangPage: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.LANG = new Translation(props.lang);

    this.slides = props.page.fieldgroup2 || [];
    this.media = props.page.fieldgroup1 || [];

    this.parent = props.CONTENT.byId(props.page.parentid);
    this.amenities = parse(props.page.blurb5, []);

    this.childPage = props.CONTENT.childrenById(props.page.id)[0];

    this.otherProperties = props.CONTENT.byTemplate("property_detail")
      .filter(context.filterByLang)
      .filter((p) => p.id !== props.page.parentid);

    this.state = {
      popupOpen: false,
      galleryOpen: false,
      intialSlide: 0,
      printPageFormOpen: false,
    };

    this.isPrivate = props.page.checkbox1;
  }

  // clear availabilities and reset booking details so we fetch the
  // right data in the QuickBooking widget
  componentDidMount() {
    const { bookingDetails, page, rooms } = this.props;
    gaTrackPage("/btt/booking-engine/room-detail");

    this.props.deleteAvailability();

    // reset booking details
    if (rooms && (!bookingDetails || bookingDetails.roomCode !== page.h4)) {
      this.createBookingDetails();
    }
  }

  componentDidUpdate() {
    const { bookingDetails, page, rooms } = this.props;

    if (this.state.popupOpen && !this.context.viewport.is("tablet")) {
      this.togglePopup();
    }

    // reset booking details
    if (rooms && (!bookingDetails || bookingDetails.roomCode !== page.h4)) {
      this.createBookingDetails();
    }
  }

  createBookingDetails = () => {
    const { page, rooms, createBookingDetails } = this.props;
    const { from, to, guests } = getQueryParams(window.location.search, {});
    createBookingDetails({
      cmsRoom: page,
      cmsProperty: this.parent,
      synxisRoom: rooms[page.h4],
      bedrooms: page.h5,
      roomCode: page.h4,
      numRooms: 1,
      from: from
        ? moment(from).startOf("day").toDate()
        : moment().startOf("day").toDate(),
      to: to
        ? moment(to).startOf("day").toDate()
        : moment().startOf("day").add(1, "days").toDate(),
      guests: guests || 1,
      packages: [],
    });
  };

  togglePrintPageForm = (bool) => this.setState({ printPageFormOpen: bool });

  togglePopup = () => this.setState({ popupOpen: !this.state.popupOpen });

  toggleGallery = (initialSlide = 0) =>
    this.setState({ galleryOpen: !this.state.galleryOpen, initialSlide });

  renderMedia = () => {
    return (
      <div className={`${cl("media")}  ${this.isPrivate && cl("is_private")}`}>
        {this.media.slice(0, 3).map((item) => {
          if (item.h1) {
            return (
              <div className={`${cl("media__video")}}`} key={item.id}>
                <VideoPlayer url={item.h1} playing={false} />
              </div>
            );
          }

          return (
            <div className={`${cl("media__image")}}`} key={item.id}>
              <Image
                src={item.image1}
                alt={item.image1_alt_text}
                align={item.image1_anchor}
              />
            </div>
          );
        })}
      </div>
    );
  };

  renderFloorplan = () => {
    const { page } = this.props;
    const floorplan = parse(page.buttonlink1, {});

    if (floorplan.link) {
      return (
        <div className={cl("floorplan__details_only")}>
          <CTA text={this.LANG(floorplan.text)} link={floorplan.link} force />
        </div>
      );
    }

    // hidden until we decide how to handle floor plan images
    // if (floorplan.link) {
    //   return (
    //     <div className={cl("floorplan")}>
    //       <div className={cl("floorplan__image")}>
    //         <Image
    //           src={page.image1}
    //           alt={page.image1_alt_text}
    //           align={page.image1_anchor}
    //         />
    //       </div>
    //       <div className={cl("floorplan__details")}>
    //         <CTA text={floorplan.text} path={floorplan.link} />
    //       </div>
    //     </div>
    //   );
    // }
  };

  renderPrintPageForm = () => {
    return (
      !(browser.msie && parseInt(browser.version) <= 11) &&
      this.parent.h6 && (
        <div className={`${cl("print_page_container")}`}>
          <CTA
            text={this.LANG("downloadPDF")}
            onClick={() => this.togglePrintPageForm(true)}
            size="medium"
          />
        </div>
      )
    );
  };

  render3DTour = (hero) => {
    const { page } = this.props;
    const tour = parse(page.buttonlink2);
    if (tour && !tour.link) return false;
    const tourMarkup = hero ? (
      <div className={`${cl("print_page_container")}`}>
        <CTA text={this.LANG(tour.text)} link={tour.link} size="medium" />
      </div>
    ) : (
      <div className={cl("floorplan__details_only")}>
        <CTA text={this.LANG(tour.text)} link={tour.link} />
      </div>
    );
    return page && tour && tour.link !== "" && tourMarkup;
  };

  renderOtherProperties = () => {
    return (
      <div className={cl("other_properties")}>
        <div className={cl("container")}>
          <h2>{this.LANG("morePlacesToStay")}</h2>
          <ul className={cl("other_properties__list")}>
            {this.otherProperties.slice(0, 4).map((hotel) => (
              <li className={cl("other_properties__list__item")} key={hotel.id}>
                <Link path={hotel.path}>
                  <div className={cl("other_properties__list__item__image")}>
                    <Image
                      src={hotel.image1}
                      alt={hotel.image1_alt_text}
                      align={hotel.image1_anchor}
                    />
                  </div>
                  <h3>{hotel.pagetitle}</h3>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  renderMap = () => {
    const { page } = this.props;
    const { latitude, longitude, h1 } = page;
    return (
      <LeafletMap
        className={cl("map")}
        image={MapImage}
        markers={[
          {
            latitude,
            longitude,
            title: h1,
            icon: "stay",
          },
        ]}
        center={{
          latitude,
          longitude,
        }}
        zoom={1}
      />
    );
  };

  renderReservationsForm = () => {
    const { rooms, page, bookingDetails } = this.props;

    if (this.isPrivate) {
      return null;
    }

    if (rooms && rooms[page.h4] && bookingDetails) {
      return (
        <div className={cl("reservations_form")}>
          <QuickBooking CONTENT={this.props.CONTENT} page={this.props.page} />
        </div>
      );
    }

    return (
      <div className={cl("reservations_form_loader")}>
        <Loader />
        <span>Loading booking options</span>
      </div>
    );
  };

  renderReservations = () => {
    const { viewport } = this.context;
    return (
      <div className={cl("reservations")}>
        {!viewport.is("tablet") && this.renderReservationsForm()}
        {viewport.is("tablet") && (
          <div className={cl("reservations__mobile")}>
            <div>
              <CTA text={this.LANG("addDates")} onClick={this.togglePopup} />
            </div>
            <p>{this.LANG("addDatesForPrices")}</p>
          </div>
        )}
      </div>
    );
  };

  renderFormBlock = () =>
    !this.props.page.checkbox1 && (
      <div className={cl("form_container")}>
        <Intro subtitle={this.LANG("formIntro")} />
        <HubSpotForm
          formId={this.context.activeLangPage(this.props.page, "buttontext1")}
          label="RFP"
        />
      </div>
    );

  renderDisclaimer = () => {
    if (this.props.page.blurb2) {
      return (
        <div className={cl("disclaimer")}>
          {SVG.exclamation}
          <div dangerouslySetInnerHTML={{ __html: this.props.page.blurb2 }} />
        </div>
      );
    }
  };

  render() {
    const { page } = this.props;

    // console.log("Room Details Page::::::::::::::::", page);

    return (
      <div className={cl("")}>
        <PrintPageForm
          formId={this.parent.h6}
          page={this.props.page}
          token={this.props.printPageToken}
          toggle={this.togglePrintPageForm}
          open={this.state.printPageFormOpen}
          lang={this.props.lang}
          childPage={this.childPage}
          id={this.props.page.id}
        />

        <Modal
          open={this.state.popupOpen}
          background="grey"
          close={this.togglePopup}
        >
          {this.renderReservationsForm()}
        </Modal>

        <GalleryModal
          open={this.state.galleryOpen}
          onClose={this.toggleGallery}
          slides={this.slides}
          keyboardControl
        />

        <HeroCarousel
          slides={[this.slides[0]]}
          settings={{
            dots: false,
            arrows: false,
          }}
          parallax={false}
        />
        <div className={cl("container")}>
          <div className={cl("gallery_toggle")}>
            <CTA
              text={this.LANG("viewPhotos")}
              onClick={() => this.toggleGallery(this.state.initialSlide)}
              size="medium"
              type="orange"
            />
            {this.render3DTour(true)}
            {this.renderPrintPageForm()}
          </div>
          <div className={cl("columns")}>
            <div
              className={`${cl("columns__left")} ${
                this.isPrivate ? cl("private") : ""
              }`}
            >
              <h1>{page.h1}</h1>
              <h3 className={cl("subtitle")}>{this.parent.linktitle}</h3>

              <div
                dangerouslySetInnerHTML={{ __html: page.blurb1 }}
                className={cl("description")}
              />
              {this.renderDisclaimer()}
              <Quote text={page.blurb3} author={page.h2} align="left" />
              {this.renderMedia()}
              <AmenitiesGrid
                title={this.LANG("amenitiesAndFeatures")}
                items={this.amenities}
                short
              />
              {this.renderFloorplan()}
              {this.render3DTour(false)}
            </div>
            <div
              className={`${cl("columns__right")} ${
                this.isPrivate ? cl("private") : ""
              }`}
            >
              {this.renderReservations()}
            </div>
          </div>

          {this.renderMap()}
          {this.renderFormBlock()}
        </div>
        {!page.checkbox1 && (
          <MorePlaces
            title={this.LANG("morePlacesToStay")}
            items={this.otherProperties}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ synxis, global }) => ({
  rooms: synxis.rooms,
  bookingDetails: synxis.bookingDetails,
  lang: global.lang,
  printPageToken: global.printPageToken,
});

export default connect(mapStateToProps, {
  createBookingDetails,
  deleteBookingDetails,
  deleteAvailability,
})(Room);
