import { lazy } from "react";
import styles from "../../styles/partials/_footer.scss";
import { _class, parse, gtagTrackEvent } from "../utilities/helpers";
import Link from "../widgets/Link";
import * as SVG from "../widgets/SVG";
import { connect } from "react-redux";
import { Translation } from "../utilities/Translation";
const HubSpotForm = lazy(() => import("./HubSpotForm"));

const cl = _class(styles, "footer");

class Footer extends React.Component {
  static propTypes = {
    CONTENT: PropTypes.object,
    lang: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.options = props.CONTENT.byTemplate("options")[0];
    this.footerNav = this.options.fieldgroup2 || [];
    this.bottomNav = parse(this.options.buttonlink1) || [];
    this.socialLinks = this.options.fieldgroup3 || [];

    this.bottomLinks = this.generateBottomLinks();
    this.navItems = this.generateNavItems();

    this.LANG = new Translation(props.lang);
  }

  setPath = (child) => {
    const page = this.props.CONTENT.byPath(child.path);
    if (page.template == "redirect") {
      const parsed = parse(page.buttonlink1, {});
      return parsed.link ? parsed.link : child.path;
    }
    return child.path;
  };

  generateBottomLinks = () => {
    return this.bottomNav.reduce(
      (sum, item) => {
        const page = this.props.CONTENT.byId(item.value);
        if (page.related) {
          let esPage = this.props.CONTENT.byId(parseInt(page.related));
          sum.es.push(esPage);
        }

        sum.en.push(page);
        return sum;
      },
      { es: [], en: [] }
    );
  };

  generateNavItems = () => {
    const { CONTENT } = this.props;
    return this.footerNav.reduce(
      (sum, item) => {
        const cta = parse(item.buttonlink2, {});
        const titlePage = CONTENT.byId(cta.link);

        let enItem = {
          title: item.h1,
          cta: titlePage && titlePage.path,
          children: [],
        };

        let esItem = {
          title: item.h2,
          cta: titlePage && CONTENT.byId(parseInt(titlePage.related)).path,
          children: [],
        };

        const parsedItem = parse(item.buttonlink1, []);

        if (parsedItem.length) {
          parsedItem.forEach((p) => {
            let content = CONTENT.byId(p.value);
            let esContent = CONTENT.byId(parseInt(content.related));

            enItem.children.push({
              title: content.linktitle,
              path: content.path,
            });

            if (esContent && esContent.id) {
              esItem.children.push({
                title: esContent.linktitle,
                path: esContent.path,
              });
            }
          });
        }
        sum.en.push(enItem);
        sum.es.push(esItem);
        return sum;
      },
      { en: [], es: [] }
    );
  };

  renderContact = () => {
    return (
      <div className={cl("contact")}>
        <div className={cl("contact__icon")}>{SVG.phone}</div>
        <div className={cl("contact__numbers")}>
          <Link
            path={`tel:${this.options.h1}`}
            // title={this.LANG("orgPhoneNumber", this.props.lang)}
            title={`US Toll Free ${this.options.h1}`}
            onClick={() =>
              gtagTrackEvent("AW-817146118/lT1cCI7on-4BEIbS0oUD", {
                phone_conversion_number: this.options.h1,
              })
            }
          />
          <Link
            path={`tel:${this.options.h2}`}
            title={`Costa Rica ${this.options.h2}`}
          />
        </div>
      </div>
    );
  };

  renderNavLinks = () => {
    return (
      <ul className={cl("nav_links")}>
        {this.navItems[this.props.lang].map((item, index) => {
          const title = item.cta ? (
            <Link
              className={cl("nav_links__col__title")}
              path={item.cta}
              title={item.title}
            />
          ) : (
            <span className={cl("nav_links__col__title")}>{item.title}</span>
          );

          return (
            <li className={cl("nav_links__col")} key={index}>
              {title}
              <ul>
                {item.children.map((child) => (
                  <li key={child.path}>
                    <Link path={this.setPath(child)} title={child.title} />
                  </li>
                ))}
              </ul>
            </li>
          );
        })}
      </ul>
    );
  };

  renderEmail = () => {
    const { lang } = this.props;
    return (
      <div className={cl("email")}>
        <div className={cl("email__form")}>
          <h3>{this.LANG("stayInTheKnow", lang)}</h3>
          <HubSpotForm
            formId={lang === "en" ? this.options.h4 : this.options.h5}
            label="Newsletter"
            className="footer"
            agreeColor="white"
            onSubmit={() => {
              gtagTrackEvent("AW-817146118/XceDCNqv_-0BEIbS0oUD");
            }}
          />
        </div>
      </div>
    );
  };

  renderBottomLinks = () => {
    return (
      <ul className={cl("bottom_links")}>
        {this.bottomLinks[this.props.lang].map((item) => (
          <li key={item.id}>
            <Link path={item.path} title={item.linktitle} />
          </li>
        ))}
        <li className={`${cl("credits")}`}>
          <a
            className={cl("credits__link")}
            href="https://www.onceuponatime.agency/hospitality"
            data-txt="Credits"
            target="_blank"
            rel="noopener"
          >
            <span>Once Upon a Time</span>
          </a>
        </li>
      </ul>
    );
  };

  renderSocialLinks = () => {
    return (
      <ul className={cl("social_links")}>
        {this.socialLinks.map((item) => {
          const parsed = parse(item.buttonlink1);

          return (
            <li key={item.id}>
              <Link path={parsed.link}>{SVG[parsed.text]}</Link>
            </li>
          );
        })}
      </ul>
    );
  };

  render() {
    return (
      <footer className={cl("")}>
        <div className={cl("container")}>
          {this.renderContact()}

          <div className={cl("columns")}>
            {this.renderNavLinks()}
            {this.renderEmail()}
          </div>

          <div className={cl("bottom")}>
            {this.renderBottomLinks()}
            {this.renderSocialLinks()}
            <div className={cl("other_links")}>
              <div>
                <p className={cl("copyright")}>
                  COPYRIGHT © DESARROLLO LAS CATALINAS VERDE, DCL
                </p>
                <p className={cl("copyright")}>ALL RIGHTS RESERVED</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  lang: global.lang,
});

export default connect(mapStateToProps)(Footer);
