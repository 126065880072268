import { _class } from "../utilities/helpers";
import styles from "../../styles/partials/_loader.scss";
import Reveal from "../widgets/Reveal";

const cl = _class(styles, "loader");

Loader.propTypes = {};

Loader.defaultProps = {};

export default function Loader() {
  return (
    <Reveal className={cl("")} cssAnimation={["fade"]}>
      <div className={cl("inner")}>
        <div className={cl("lds_grid")}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </Reveal>
  );
}
