import styles from "../../styles/views/_default.scss";
import { _class } from "./helpers";
import CTA from "../partials/CTA";

const cl = _class(styles, "default");

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className={cl("")}>
          <div className={cl("container")}>
            <div className={cl("error_container")}>
              <div>
                <h1>An error occured.</h1>
                <div className={cl("error_content")}>
                  <h4>Sorry about that.</h4>
                  <CTA text="Home" link={"/"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
