import amenities from "./translations/amenities.json";

export const en = {
  _default: "",
  all: "All",
  bedroom: "Bedroom",
  bedrooms: "Bedrooms",
  guest: "Guest",
  guests: "Guests",
  selectDates: "Select Dates",
  total: "Total",
  quickBooking: "Quick Booking",
  loading: "Loading",
  bookNow: "Book Now",
  book: "Book",
  checkRate: "Check Rate",
  night: "Night",
  nights: "Nights",
  taxes: "Taxes",
  fees: "Fees",
  lodging: "Lodging",
  search: "Search",
  accommodation: "Accommodation",
  whereTo: "Where To?",
  checkInDate: "Check-In Date",
  checkOutDate: "Check-Out Date",
  promo: "Promo Code",
  promoApplied: "Code applied.",
  morePlacesToStay: "More Places To Stay",
  addDatesForPrices: "Add Dates For Prices",
  addDates: "Add Dates",
  amenitiesAndFeatures: "Amenities & Features",
  viewPhotos: "View Photos",
  formIntro:
    "Planning the perfect holiday can be a tough task. Share a few details and our team will be in touch.",
  yourRoom: "Your Room",
  guestDetails: "Guest Details",
  confirmation: "Confirmation",
  confirmationEmailed: "A confirmation has also been emailed to",
  backToHome: "Back To Home",
  yourStay: "Your Stay",
  checkIn: "Check In",
  checkOut: "Check Out",
  bookingComplete: "Booking Complete",
  exploreAndExperience: "Explore And Experience",
  experiences: "Experiences",
  hotels: "Hotels",
  flat: "Flat",
  villa: "Villa",
  vacationRentals: "Vacation Rentals",
  "Vacation Rentals": "Vacation Rentals",
  otherVacationRentals: "Other Vacation Rentals",
  home: "Home",
  perNight: "Per Night",
  excludingTaxesFees: "Excluding Taxes & Fees",
  agreeAndContinue: "Agree And Continue",
  noAvailability: "No Availability",
  selectActivity: "Select Activity",
  recommendedForYou: "Recommended For You",
  selected: "Selected",
  subtotal: "Subtotal",
  select: "Select",
  terms: "Terms",
  bookingPolicy: "Booking Policy",
  cancelPolicy: "Cancel Policy",
  reservationNumber: "Reservation Number",
  itineraryNumber: "Itinerary Number",
  viewReservation: "View Reservation",
  emailForBooking: "Email used for booking",
  featuredHotels: "Featured Hotels",
  offers: "Offers",
  noBookingDetails: "No booking details found",
  otherHotels: "Other Hotels",
  packages: "Packages",
  noPackages: "No packages available.",
  addToReservation: "Add to Reservation",
  includingTaxesFees: "Including Taxes & Fees",
  noRates: "No alternate rates available.",
  pleaseCheckAvailability:
    "Please check availability first before viewing rates.",
  contactInformation: "Contact Information",
  passport: "Passport / ID Number",
  paymentInfo: "Payment Information",
  bookingSuccessMessage: "Your reservation was created successfully.",
  bookingSubmitText: "Complete Booking",
  stayInTheKnow: "Stay In The Know",
  sleeps: "Sleeps up to",
  moreFilters: "More Filters",
  Sort: "Sort",
  priceLtH: "Price (Low to High)",
  priceHtL: "Price (High to Low)",
  bedroomsLtH: "Bedrooms (Low to High)",
  bedroomsHtL: "Bedrooms (High to Low",
  apply: "Apply",
  updateSuccessMessage: "Your reservation was updated successfully.",
  updateSubmitText: "Update Booking",
  modifyOrCancel: "To modify or cancel your reservation, please call us.",
  startingAt: "Starting at",
  serviceCharge: "Service Charge",
  cleaningCharge: "Check-in and Check-out Cleaning Charge",
  View: "View",
  Price: "Price",
  People: "People",
  Bedrooms: "Bedrooms",
  "More Filters": "More Filters",
  List: "List",
  Map: "Map",
  Amenities: "Amenities",
  Collections: "Collections",
  "Accommodation Type": "Accommodation Type",
  startDate: "Start Date",
  endDate: "End Date",
  ourHomes: "Our Vacation Homes",
  checkAvailability: "Check Availability",
  callOurTeam: "Or call our travel team directly at:",
  viewVilla: "View Villa",
  learnMore: "Learn More",
  from: "From",
  to: "To",
  ourVacationHomes: "Our Vacation Homes",
  viewHomes: "View Homes",
  agreeToMarketing:
    "By providing your email, you agree to receive marketing communications from Beach Town Travel.",
  orgPhoneNumber: "The US +1-800-372-7494",
  usefulTips: "Useful Tips",
  discoverLasCatalinas: "Discover Las Catalinas",
  Land: "Land",
  "Food & Beverage": "Food & Beverage",
  Wellbeing: "Wellbeing",
  Groups: "Groups",
  Community: "Comunity",
  Couples: "Couples",
  Water: "Water",
  Family: "Family",
  "Sports & Recreation": "Sports & Recreation",
  "Small Groups ": "Small Groups",
  Nature: "Nature",
  "Architecture & Urbanism": "Architecture & Urbanism",
  Outdoor: "Outdoor",
  Beach: "Beach",
  Urban: "Urban",
  Indoor: "Indoor",
  All: "All",
  "Covered Balcony": "Covered Balcony",
  GRILL: "Grill",
  Grill: "Grill",
  "Partial Ocean View": "Partial Ocean View",
  "House Mom Service": "House Mom Service",
  "Full Kitchen": "Full Kitchen",
  Home: "Home",
  "Hotel Room": "Hotel Room",
  rate: "Rate",
  "Lodging Rates": "Lodging Rates",
  "Service fee": "Service Fee",
  "Cleaning fee": "Cleaing Fee",
  clearAll: "Clear All",
  noSearchResults: "No results available. Please try another search.",
  reservationInProgress: "Reservation in Progress",
  reservationInProgressDetails:
    "We detected a previous reservation in progress. Would you like to continue with that reservation?",
  continue: "Continue",
  noThanks: "No Thanks",
  firstName: "First name",
  lastName: "Last name",
  email: "Email",
  contactNumber: "Phone number",
  address: "Address",
  city: "City",
  country: "Country",
  postalCode: "ZIP / postal code",
  idNumber: "ID number",
  additionalComments: "Additional comments",
  cardType: "Card type",
  cardNumber: "Card number",
  cardExpiration: "Expiration date",
  cardName: "Name on card",
  redirectInProgress:
    "Please do not leave this page. You will be redirected shortly...",
  required: "Required",
  notValid: "Not valid",
  submitInfo:
    "Submit your information below to download a copy of this home in PDF format.",
  downloadPDF: "Download PDF",
  pdfDownloadMessage:
    "If your file hasn't downloaded after 10 seconds, please click the Download button.",
  download: "Download",
  noAvailabilities:
    "Sold Out - There are currently no availabilities for these dates.",
  noResults: "No results matched the selected filters.",
};

export const es = {
  _default: "",
  all: "Todos",
  bedroom: "Habitación",
  bedrooms: "Habitaciones",
  guest: "Huésped",
  guests: "Huéspedes",
  selectDates: "Seleccionar fechas",
  total: "Total",
  quickBooking: "Reserva rápida",
  loading: "Cargando",
  bookNow: "Reservar",
  book: "Reservar",
  checkRate: "Ver tarifa",
  night: "Noche",
  nights: "Noches",
  taxes: "Impuestos",
  fees: "Cargos",
  lodging: "Alojamiento",
  search: "Buscar",
  accommodation: "Hospedaje",
  whereTo: "¿A Donde?",
  checkInDate: "Fecha de entrada",
  checkOutDate: "Fecha de salida",
  promo: "Código de promo",
  promoApplied: "Código aplicado",
  morePlacesToStay: "Más opciones de hospedaje",
  addDatesForPrices: "Agregar fechas para ver precios",
  addDates: "Agregar fechas",
  amenitiesAndFeatures: "Amenidades y características",
  viewPhotos: "Ver fotos",
  formIntro:
    "Planear unas vacaciones en perfectas puede ser una tarea difícil. Comparta algunos detalles y nuestro equipo se pondrá en contacto.",
  yourRoom: "Su habitación",
  guestDetails: "Detalles de huésped",
  confirmation: "Confirmación",
  confirmationEmailed:
    "Una confirmación también ha sido enviada por correo electrónico a",
  backToHome: "Vuelta a la página de inicio",
  yourStay: "Su estadía",
  bookingComplete: "Reserva completa",
  exploreAndExperience: "Explorar y Experimentar",
  experiences: "Experiencias",
  hotels: "Hoteles",
  flat: "Apartamento",
  villa: "Villa",
  home: "Casa",
  perNight: "Por Noche",
  excludingTaxesFees: "Sin incluir impuestos ni cargos",
  agreeAndContinue: "Aceptar y continuar",
  noAvailability: "No hay disponibilidad",
  selectActivity: "Seleccionar actividad",
  recommendedForYou: "Recomendado",
  selected: "Seleccionado",
  subtotal: "Subtotal",
  select: "Seleccionar",
  terms: "Términos",
  bookingPolicy: "Política de reserva",
  cancelPolicy: "Política de cancelación",
  reservationNumber: "Número de reservación",
  itineraryNumber: "Número de itinerario",
  viewReservation: "Ver reservación",
  emailForBooking: "Correo electrínico utilizado para reserva",
  featuredHotels: "Hoteles destacados",
  offers: "Ofertas",
  noBookingDetails: "No se encontraron detalles de reserva",
  otherHotels: "Otros hoteles",
  packages: "Paquetes",
  noPackages: "No hay paquetes disponibles",
  addToReservation: "Agregar a reserva",
  includingTaxesFees: "Incluye impuestos y cargos",
  noRates: "No hay tarifas alternativas disponibles",
  pleaseCheckAvailability:
    "Por favor revisar disponibilidad primero, antes de ver tarifas.",
  contactInformation: "Información de contacto",
  passport: "Pasaporte / Número de identificación",
  paymentInfo: "Información de pago",
  bookingSuccessMessage: "Su reserva fue creada con éxito.",
  bookingSubmitText: "Reserva completa",
  stayInTheKnow: "Manténganse al tanto",
  sleeps: "Para hasta",
  moreFilters: "Más filtros",
  Sort: "Acomodar Por",
  priceLtH: "Precio (de menor a mayor)",
  priceHtL: "Precio (de mayor a menor)",
  bedroomsLtH: "Habitaciones (de menor a mayor)",
  bedroomsHtL: "Habitaciones (de mayor a menor)",
  apply: "Aplicar",
  updateSuccessMessage: "Su reserva fue actualizada con éxito.",
  updateSubmitText: "Actualizar reserva",
  modifyOrCancel: "Para modificar o cancelar su reserva por favor contáctenos.",
  startingAt: "Empezando desde",
  serviceCharge: "Cargo por servicio",
  cleaningCharge: "Cargo por limpieza en entrada y salida",
  View: "Ver",
  Price: "Precio",
  People: "Personas",
  Bedrooms: "Habitaciones",
  "More Filters": "Más Filtros",
  List: "Lista",
  Map: "Mapa",
  Amenities: "Amenidades",
  Collections: "Colecciones",
  "Accommodation Type": "Tipo de Hospedaje",
  startDate: "Desde",
  endDate: "Hasta",
  checkAvailability: "Buscar Disponibilidad",
  callOurTeam: "ES Or call our travel team directly at:",
  viewVilla: "Ver Villa",
  learnMore: "Ver Más",
  from: "De",
  to: "Hasta",
  ourVacationHomes: "Nuestros Alquileres Vacacionales",
  "vacation rentals": "Alquileres Vacacionales",
  vacationRentals: "Alquileres Vacacionales",
  otherVacationRentals: "Otros Alquileres Vacacionales",
  ourHomes: "Nuestros Alquileres",
  viewHomes: "Ver Casas",
  agreeToMarketing:
    "Al proporcionar su correo electrónico, acepta recibir comunicaciones de marketing de Beach Town Travel.",
  orgPhoneNumber: "EE.UU +506-2654-4600",
  usefulTips: "Tips Útiles",
  discoverLasCatalinas: "Descubra Las Catalinas",
  Land: "Actividades en Tierra",
  "Food & Beverage": "Comida y Bebida",
  Wellbeing: "Bienestar",
  Groups: "Grupos",
  Community: "Comunidad",
  Couples: "Parejas",
  Water: "Actividades en el Agua",
  Family: "Familia",
  "Sports & Recreation": "Deportes y Recreación",
  "Small Groups": "Grupos Pequeños",
  Nature: "Naturaleza",
  "Architecture & Urbanism": "Arquitectura y Urbanismo",
  Outdoor: "Exterior",
  Beach: "Playa",
  Urban: "Urbano",
  Indoor: "Interior",
  All: "Todas",
  "Covered Balcony": "Balcón cubierto",
  GRILL: "Parrilla",
  Grill: "Parrilla",
  "Partial Ocean View": "Vista parcial al mar",
  "House Mom Service": "Servicio de Housemom",
  "Full Kitchen": "Cocina",
  "Flat / Apt": "Flats/Apartamento",
  Home: "Casa",
  "Hotel Room": "Habitación De Hotel",
  rate: "Tarifa",
  "Lodging Rates": "Tarifa de hospedaje",
  "Service Fee": "Cargo de servicio",
  "Cleaning Fee": "Cargo de limpieza",
  clearAll: "Eliminar todo",
  noSearchResults:
    "No hay resultados disponibles. Favor intentar otra búsqueda.",
  reservationInProgress: "Reserva en proceso",
  reservationInProgressDetails:
    "Detectamos un reserva previa en proceso. ¿Le gustaría continuar con esa reserva anterior?",
  continue: "Continúe",
  noThanks: "No gracias",
  firstName: "Nombre",
  lastName: "Primer apellido",
  email: "Dirección de correo electrónico",
  contactNumber: "Número de teléfono de contacto",
  address: "Dirección",
  city: "Ciudad",
  country: "País",
  postalCode: " Código postal",
  idNumber: "Número de cédula",
  additionalComments: "Comentarios adicionales",
  cardType: "Tipo de tarjeta",
  cardNumber: "Número de tarjeta",
  cardExpiration: "Fecha de expiración",
  cardName: "Nombre en la tarjeta",
  redirectInProgress:
    "Favor no salirse de esta página. Pronto será redirigido...",
  required: "Requerida",
  notValid: "No válido",
  submitInfo: "Rellene sus datos para descargar esta página en formato PDF",
  downloadPDF: "Descargar PDF",
  pdfDownloadMessage:
    "Si su archivo no se ha descargado después de 10 segundos, haga clic en el botón Descargar.",
  download: "Descargar",
  noAvailabilities:
    "Agotado - Actualmente no hay disponibilidad para estas fechas.",
  noResults: "Ningún resultado coincidió con los filtros seleccionados.",
};

export class Translation {
  constructor(lang = "en") {
    this.lang = lang;
    this.en = en;
    this.es = es;
    return this.translate;
  }

  translate = (key = "_default", lang) => {
    return this[lang || this.lang][key] || key;
  };
}

export class AmenityTranslation {
  constructor(lang = "en") {
    this.lang = lang;
    return this.translateAmenity;
  }

  translateAmenity = (key = "") => {
    return this.lang == "en" ? key : amenities[key] || key;
  };
}
