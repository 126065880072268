import styles from "../../styles/partials/_results-filters.scss";
import { _class, getQueryParams } from "../utilities/helpers";
import * as SVG from "../widgets/SVG";
import Transition from "../widgets/Transition";
import CTA from "./CTA";
import Reveal from "../widgets/Reveal";
import Modal from "../widgets/Modal";
import RangeSlider from "./RangeSlider";
import ContentProvider from "../containers/ContentProvider";
import { Translation } from "../utilities/Translation";
import { connect } from "react-redux";
import { AmenityTranslation } from "../utilities/Translation";

const cl = _class(styles, "results_filters");

class ResultsFilters extends React.Component {
  static propTypes = {
    CONTENT: PropTypes.object,
    filters: PropTypes.arrayOf([
      "View",
      "Price",
      "People",
      "Bedrooms",
      "MoreFilters",
      "Sort",
    ]).isRequired,
    activeView: PropTypes.string,
    onToggle: PropTypes.func,
    onUpdate: PropTypes.func,
    onApply: PropTypes.func,
    cards: PropTypes.array,
    checkboxOptions: PropTypes.arrayOf([
      "Amenities",
      "Collections",
      "Accommodations",
    ]).isRequired,
    lang: PropTypes.string,
    currency: PropTypes.object,
  };

  static defaultProps = {
    filters: [],
    activeView: "list",
    onToggle: () => null,
    onUpdate: () => null,
    onApply: () => null,
    cards: [],
    checkboxOptions: [],
  };

  static contextTypes = {
    filterByLang: PropTypes.func,
    accessible: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);

    this.AMENITY_LANG = new AmenityTranslation(props.lang);

    this.icons = {
      Price: SVG.currency,
      Bedrooms: SVG.bed,
      "More Filters": SVG.sliders,
      Map: SVG.map,
      List: SVG.list,
      People: SVG.person,
      Sort: SVG.list,
    };

    this.state = this.initialState();

    this.fields = {
      View: () => null,
      Price: this.renderPriceFilter,
      Bedrooms: this.renderBedroomsFilter,
      MoreFilters: this.renderMoreFilters,
      People: this.renderPeopleFilter,
      Sort: this.renderSortFilter,
    };

    this.applyFilters();

    this.LANG = new Translation(props.lang);

    this.prevState = this.initialState();
  }

  initialState = () => {
    const bedrooms = getQueryParams(window.location.search, {}).bedrooms || 0;
    return {
      PriceOpen: false,
      MinPrice: 0,
      MaxPrice: 5000,
      PeopleOpen: false,
      BedroomsOpen: false,
      Bedrooms: parseInt(bedrooms),
      MoreFiltersOpen: false,
      MoreFilters: this.createMoreFilters(),
      People: {
        "0 - 30": false,
        "30 - 60": false,
        "60 - 100": false,
        "100 - 150": false,
        "150+": false,
      },
      modalVisible: false,
      SortOpen: false,
      Sort: "",
    };
  };

  fieldIsActive = (filter) => {
    const INITIAL_STATE = this.initialState();
    const { MaxPrice, MinPrice, Sort, Bedrooms, MoreFilters } = this.state;
    if (filter === "Price") {
      return (
        MaxPrice !== INITIAL_STATE.MaxPrice ||
        MinPrice !== INITIAL_STATE.MinPrice
      );
    }

    if (filter === "Sort") {
      return Sort !== INITIAL_STATE.Sort;
    }

    if (filter === "Bedrooms") {
      return Bedrooms !== INITIAL_STATE.Bedrooms;
    }

    if (filter === "MoreFilters") {
      return Object.values(MoreFilters["Amenities"] || {})
        .concat(Object.values(MoreFilters["Accommodations"] || {}))
        .concat(Object.values(MoreFilters["Collections"] || {}))
        .concat(Object.values(MoreFilters["Views"] || {}))
        .some((attr) => attr);
    }

    return false;
  };

  // more filter checkboxes - default unchecked
  createMoreFilters = () => {
    const { Views, Amenities, Collections, Accommodations } =
      this.props.checkboxOptions.reduce((sum, opt) => {
        sum[opt] = true;
        return sum;
      }, {});

    const filtered = this.props.cards.reduce((sum, card) => {
      if (Views) {
        sum["Views"] = sum["Views"] || {};
        card.amenities.forEach((a) => {
          if (a.Description.match(/view/i)) {
            sum.Views[a.Description] = false;
          }
        });
      }
      if (Amenities) {
        sum["Amenities"] = sum["Amenities"] || {};
        card.amenities.forEach((a) => {
          if ((Views && !a.Description.match(/view/i)) || !Views) {
            sum.Amenities[a.Description] = false;
          }
        });
      }

      if (Accommodations && card.type) {
        sum["Accommodation Type"] = sum["Accommodation Type"] || {};
        sum["Accommodation Type"][card.type] = false;
      }

      return sum;
    }, {});

    if (Collections) {
      const page = this.props.CONTENT.byTemplate("collections").filter(
        this.context.filterByLang
      )[0];
      filtered.Collections = this.props.CONTENT.childrenById(page.id).reduce(
        (sum, item) => {
          sum[item.h1] = false;
          return sum;
        },
        {}
      );
    }

    return filtered;
  };

  // set all "open" keys to false - closes all open filters
  newState = (val) =>
    Object.keys(this.state).reduce((sum, key) => {
      if (key.match(/open/i)) {
        sum[key] = val || false;
      }
      return sum;
    }, this.state);

  // set all filters to open by default
  toggleFiltersModal = () => {
    const { modalVisible } = this.state;
    if (modalVisible) {
      return this.setState({ ...this.prevState, modalVisible: false });
    }

    this.setState({
      ...this.newState(!modalVisible),
      modalVisible: !modalVisible,
    });
  };

  // opens or closes a filters, and closes any other open filters
  toggleFilter = (name) => {
    const field = `${name.replace(/\s/g, "")}Open`;
    let value = this.state[field];

    const openFilter = Object.keys(this.state).find(
      (key) => key.match(/Open/) && this.state[key] === true && key !== field
    );

    if (openFilter && openFilter !== field) {
      return this.setState({
        ...this.prevState,
        [field]: !value,
        [openFilter]: false,
      });
    }

    if (value) {
      return this.setState({ ...this.prevState, [field]: !value });
    }

    if (name.match(/map|list/i)) {
      value = this.props.activeView === "list" ? "map" : "list";
      return this.props.onToggle("View", value);
    }

    this.setState({
      ...this.newState(),
      [field]: !value,
    });

    this.props.onToggle(field, !value);
  };

  applySort = (Sort) => this.setState({ Sort }, this.applyFilters);

  applyFilters = () => {
    const state = this.newState();
    const newState = { ...state, modalVisible: false };

    this.setState(newState);
    this.prevState = newState;
    this.props.onApply(state);
  };

  clearFilters = () => {
    const state = this.initialState();
    this.setState({ ...state, modalVisible: true });
    this.props.onApply(state);
  };

  updateField = (field, e) => {
    this.setState({ [field]: e.target.value });
    this.props.onUpdate(field, e.target.value);
  };

  updatePrice = (field, e) => {
    const { value } = e.target;

    if (value.match(/[0-9]/) || value === "") {
      this.setState({ [field]: value });
      this.props.onUpdate(field, value);
    }
  };

  updateBeds = (val) => {
    if (val >= 0 && val < 10) {
      this.setState({ Bedrooms: val });
      this.props.onUpdate("Bedrooms", val);
    }
  };

  toggleChecked = (collection, key) => {
    this.setState({
      MoreFilters: {
        ...this.state.MoreFilters,
        [collection]: {
          ...this.state.MoreFilters[collection],
          [key]: !this.state.MoreFilters[collection][key],
        },
      },
    });
  };

  togglePeople = ({ target }) => {
    const key = target.value;
    this.setState({
      People: {
        ...this.state.People,
        [key]: !this.state.People[key],
      },
    });
  };

  renderPriceFilter = (open) => {
    const { MaxPrice, MinPrice, PriceOpen } = this.state;
    return (
      <Transition
        appear
        visible={open || PriceOpen}
        properties={{
          opacity: [0, 1],
          autoAlpha: [0, 1],
        }}
      >
        <div className={cl("price")}>
          <div className={cl("price__inner")}>
            <RangeSlider
              value={{ min: MinPrice, max: MaxPrice }}
              onChange={(val) =>
                this.setState({ MinPrice: val.min, MaxPrice: val.max })
              }
              currency={this.props.currency}
            />

            <div className={cl("price__field")}>
              <div className={cl("price__field__wrapper")}>
                <label>{this.props.currency.symbol}</label>
                <input
                  value={MinPrice}
                  onChange={this.updatePrice.bind(null, "MinPrice")}
                />
              </div>

              <div className={cl("price__field__wrapper")}>
                <label>{this.props.currency.symbol}</label>
                <input
                  value={MaxPrice}
                  onChange={this.updatePrice.bind(null, "MaxPrice")}
                />
              </div>
            </div>
          </div>
          <div className={cl("price__cta")}>
            <p>{this.LANG("perNight")}</p>
            <CTA
              text={this.LANG("apply")}
              onClick={this.applyFilters}
              type="orange"
              size="small"
              className={cl("field_cta")}
            />
          </div>
        </div>
      </Transition>
    );
  };

  renderBedroomsFilter = (open) => {
    const { Bedrooms, BedroomsOpen } = this.state;

    return (
      <Transition
        appear
        visible={open || BedroomsOpen}
        properties={{
          opacity: [0, 1],
          autoAlpha: [0, 1],
        }}
      >
        <div className={cl("beds")}>
          <div className={cl("beds__field")}>
            {Bedrooms !== 0 && (
              <button onClick={this.updateBeds.bind(null, Bedrooms - 1)} />
            )}
            <p>{Bedrooms === 0 ? this.LANG("All") : Bedrooms + "+"}</p>
            <button onClick={this.updateBeds.bind(null, Bedrooms + 1)} />
          </div>
          <CTA
            text={this.LANG("apply")}
            onClick={this.applyFilters}
            type="orange"
            size="small"
            className={cl("field_cta")}
          />
        </div>
      </Transition>
    );
  };

  renderSortFilter = (open) => {
    const { Sort, SortOpen } = this.state;

    const priceFilterEnabled = this.props.filters.find((f) =>
      f.match(/price/i)
    );

    return (
      <Transition
        appear
        visible={open || SortOpen}
        properties={{
          opacity: [0, 1],
          autoAlpha: [0, 1],
        }}
      >
        <div className={cl("sort")}>
          <div className={cl("sort__buttons")}>
            {priceFilterEnabled && (
              <CTA
                text={this.LANG("priceLtH")}
                onClick={() => this.applySort("price_lh")}
                type="dark"
                size="small"
                className={`${cl("sort_cta")} ${
                  Sort === "price_lh" && styles.active
                }`}
              />
            )}
            {priceFilterEnabled && (
              <CTA
                text={this.LANG("priceHtL")}
                onClick={() => this.applySort("price_hl")}
                type="dark"
                size="small"
                className={`${cl("sort_cta")} ${
                  Sort === "price_hl" && styles.active
                }`}
              />
            )}
            <CTA
              text={this.LANG("bedroomsLtH")}
              onClick={() => this.applySort("bedrooms_lh")}
              type="dark"
              size="small"
              className={`${cl("sort_cta")} ${
                Sort === "bedrooms_lh" && styles.active
              }`}
            />
            <CTA
              text={this.LANG("bedroomsHtL")}
              onClick={() => this.applySort("bedrooms_hl")}
              type="dark"
              size="small"
              className={`${cl("sort_cta")} ${
                Sort === "bedrooms_hl" && styles.active
              }`}
            />
          </div>
        </div>
      </Transition>
    );
  };

  renderPeopleFilter = (open) => {
    const { People } = this.state;

    return (
      <Transition
        appear
        visible={open || this.state.PeopleOpen}
        properties={{
          opacity: [0, 1],
          autoAlpha: [0, 1],
        }}
      >
        <div className={cl("people")}>
          <div className={cl("people__field")}>
            {Object.keys(People).map((key) => (
              <li className={cl("more_filters__list__item")} key={key}>
                {this.context.accessible ? (
                  <div className={cl("more_filters__list__item__field")}>
                    <button
                      className={People[key] && styles.active}
                      onClick={() => {
                        this.togglePeople({ target: { value: key } });
                      }}
                    ></button>
                    <span
                      className={`${styles.checkbox} ${
                        People[key] && styles.active
                      }`}
                    />
                    <label>{key}</label>
                  </div>
                ) : (
                  <div className={cl("more_filters__list__item__field")}>
                    <input
                      type="checkbox"
                      value={key}
                      checked={People[key]}
                      onChange={this.togglePeople}
                    />
                    <span
                      className={`${styles.checkbox} ${
                        People[key] && styles.active
                      }`}
                    />
                    <label>{key}</label>
                  </div>
                )}
              </li>
            ))}
          </div>
          <CTA
            text={this.LANG("apply")}
            onClick={this.applyFilters}
            type="orange"
            size="small"
            className={cl("field_cta")}
          />
        </div>
      </Transition>
    );
  };

  renderMoreFilters = (open) => {
    const { MoreFilters } = this.state;

    return (
      <Transition
        appear
        visible={open || this.state.MoreFiltersOpen}
        properties={{
          opacity: [0, 1],
          autoAlpha: [0, 1],
        }}
      >
        <div className={cl("more_filters")}>
          <ul className={cl("more_filters__list")}>
            {Object.keys(MoreFilters).map((key) => {
              return (
                <li className={cl("more_filters__list__item")} key={key}>
                  <p>{this.LANG(key)}</p>

                  <div>
                    {Object.keys(MoreFilters[key]).map((filter) => {
                      return (
                        <div
                          className={cl("more_filters__list__item__field")}
                          key={filter}
                        >
                          {this.context.accessible ? (
                            <div>
                              <button
                                className={
                                  MoreFilters[key][filter] && styles.active
                                }
                                onClick={this.toggleChecked.bind(
                                  null,
                                  key,
                                  filter
                                )}
                              ></button>
                              <span
                                className={`${styles.checkbox} ${
                                  MoreFilters[key][filter] && styles.active
                                }`}
                              />
                              <label>{this.AMENITY_LANG(filter)}</label>
                            </div>
                          ) : (
                            <div>
                              <input
                                type="checkbox"
                                value={filter}
                                checked={MoreFilters[key][filter]}
                                onChange={this.toggleChecked.bind(
                                  null,
                                  key,
                                  filter
                                )}
                              />
                              <span
                                className={`${styles.checkbox} ${
                                  MoreFilters[key][filter] && styles.active
                                }`}
                              />
                              <label>{this.AMENITY_LANG(filter)}</label>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </li>
              );
            })}
          </ul>
          <CTA
            text={this.LANG("apply")}
            onClick={this.applyFilters}
            type="orange"
            size="small"
            className={cl("field_cta")}
          />
        </div>
      </Transition>
    );
  };

  renderLabel = (name, active) => {
    return (
      <div className={`${cl("item__label")} ${active && styles.active} `}>
        {this.icons[name]}
        <label>{this.LANG(name)}</label>
      </div>
    );
  };

  getLabel = (field) => {
    if (field === "View") {
      return this.props.activeView === "list" ? "Map" : "List";
    }

    if (field === "MoreFilters") {
      return "More Filters";
    }

    return field;
  };

  renderFiltersModal = () => {
    return (
      <Modal
        open={this.state.modalVisible}
        close={this.toggleFiltersModal}
        background="grey"
      >
        <div className={cl("modal")}>
          {this.props.filters.reduce((sum, filter) => {
            if (filter.match(/view/i)) {
              return sum;
            }

            const label = this.getLabel(filter);
            const renderField = this.fields[filter];
            const active = this.fieldIsActive(filter);

            sum.push(
              <li
                className={`${cl("item")} ${active && styles.active} ${
                  styles[filter]
                }`}
                key={filter}
              >
                {this.renderLabel(label)}
                {renderField(true)}
              </li>
            );

            return sum;
          }, [])}
          <div className={cl("modal__actions")}>
            <CTA
              text={this.LANG("clearAll")}
              onClick={this.clearFilters}
              type="transparent"
              size="small"
              className={cl("modal_actions__cta")}
            />
            <CTA
              text={this.LANG("apply")}
              onClick={this.applyFilters}
              type="orange"
              size="small"
              className={cl("modal_actions__cta")}
            />
          </div>
        </div>
      </Modal>
    );
  };

  render() {
    return (
      <div className={cl("")}>
        {this.renderFiltersModal()}
        <Reveal
          className={cl("inner")}
          mode="stagger"
          staggerClassName={cl("item")}
          properties={{
            x: ["25%", "0%"],
            opacity: [0, 1],
            autoAlpha: [0, 1],
          }}
          reverse
        >
          <div className={cl("mobile")} onClick={this.toggleFiltersModal}>
            {this.icons["More Filters"]}{" "}
            <button>{this.LANG("moreFilters")}</button>
          </div>
          <ul className={cl("list")}>
            {this.props.filters.map((filter) => {
              const label = this.getLabel(filter);
              const renderField = this.fields[filter];
              const active = this.fieldIsActive(filter);
              return (
                <li
                  className={`${cl("item")} ${active && styles.active}`}
                  key={filter}
                >
                  <button onClick={this.toggleFilter.bind(null, label)}>
                    {this.renderLabel(label)}
                  </button>
                  {renderField()}
                </li>
              );
            })}
          </ul>
        </Reveal>
      </div>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  lang: global.lang,
  currency: global.currency,
});

export default connect(mapStateToProps)(ContentProvider(ResultsFilters));
