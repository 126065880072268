import TYPES from "./SynxisTypes";
import * as Storage from "../../utilities/Storage";

const CASES = {};

const INITIAL_STATE = {
  token: null,
  tokenPending: false,
  hotelDetails: null,
  hotelDetailsPending: false,
  rooms: null,
  roomsPending: false,
  availabilities: null,
  availabilitiesPending: false,
  reservation: null,
  reservationCreated: false,
  reservationPending: false,
  singleAvailability: null,
  singleAvailabilityPending: false,
  promotions: null,
  promotionsPending: false,
  coupons: null,
  couponsPending: false,
  bookingDetails: undefined,
  bookingForm: {},
  rates: null,
  ratesPending: false,
  packages: null,
  packagesPending: false,
  error: null,
  leadAvailability: null,
  leadAvailabilityPending: false,
  authorizationPending: false,
  authorization: null,
};

export default function SynxisReducer(state = INITIAL_STATE, action) {
  if (CASES[action.type]) {
    return CASES[action.type](state, action);
  }
  return state;
}

// TOKEN
CASES[TYPES.GET_TOKEN_PENDING] = (state) => ({
  ...state,
  tokenPending: true,
  error: false,
});

CASES[TYPES.GET_TOKEN_SUCCESS] = (state, action) => ({
  ...state,
  token: action.payload,
  tokenPending: false,
});

CASES[TYPES.GET_TOKEN_FAIL] = (state) => ({ ...state, tokenPending: false });

// AVAILABILITIES
CASES[TYPES.GET_AVAILABILITIES_PENDING] = (state) => ({
  ...state,
  availabilitiesPending: true,
});

CASES[TYPES.GET_AVAILABILITIES_SUCCESS] = (state, action) => ({
  ...state,
  availabilities: action.payload,
  availabilitiesPending: false,
});

CASES[TYPES.GET_AVAILABILITIES_FAIL] = (state) => ({
  ...state,
  availabilitiesPending: false,
});

CASES[TYPES.DELETE_AVAILABILITIES] = (state) => ({
  ...state,
  availabilities: null,
});

// CREATE RESERVATION
CASES[TYPES.CREATE_RESERVATION_PENDING] = (state) => ({
  ...state,
  reservationPending: true,
  error: false,
});

CASES[TYPES.CREATE_RESERVATION_SUCCESS] = (state, action) => {
  const guestInfo = Object.keys(state.bookingForm).reduce((sum, key) => {
    if (!key.match(/cc_/)) {
      sum[key] = state.bookingForm[key];
    }
    return sum;
  }, {});

  const data = {
    ...state.bookingDetails,
    guestInfo,
    confirmationNumber: action.payload,
  };

  Storage.setItem("booking_details", data);

  return {
    ...state,
    bookingDetails: {
      ...data,
    },
  };
};

CASES[TYPES.CREATE_RESERVATION_FAIL] = (state, action) => ({
  ...state,
  reservationPending: false,
  error: action.error,
});

// AUTHORIZE TRANSACTION
CASES[TYPES.AUTHORIZE_TRANSACTION_PENDING] = (state) => ({
  ...state,
  authorizationPending: true,
  error: false,
});

CASES[TYPES.AUTHORIZE_TRANSACTION_SUCCESS] = (state, action) => ({
  ...state,
  authorization: action.payload,
  authorizationPending: false,
});

CASES[TYPES.AUTHORIZE_TRANSACTION_FAIL] = (state, action) => ({
  ...state,
  authorizationPending: false,
  reservationPending: false,
  error: action.error,
});

// CONFIRM RESERVATION
CASES[TYPES.CONFIRM_RESERVATION_PENDING] = (state) => ({
  ...state,
  reservationPending: true,
  error: false,
});

CASES[TYPES.CONFIRM_RESERVATION_SUCCESS] = (state, action) => ({
  ...state,
  reservation: action.payload,
  reservationPending: false,
});

CASES[TYPES.CONFIRM_RESERVATION_FAIL] = (state, action) => ({
  ...state,
  reservationPending: false,
  error: action.error,
});

CASES[TYPES.GET_RESERVATION_PENDING] = (state) => ({
  ...state,
  reservationPending: true,
  error: null,
});

CASES[TYPES.GET_RESERVATION_SUCCESS] = (state, action) => ({
  ...state,
  reservation: action.payload,
  reservationPending: false,
});

CASES[TYPES.GET_RESERVATION_FAIL] = (state, action) => ({
  ...state,
  reservationPending: false,
  error: action.error,
});

CASES[TYPES.DELETE_RESERVATION] = (state) => ({
  ...state,
  reservation: null,
});

CASES[TYPES.CANCEL_RESERVATION_PENDING] = (state) => ({
  ...state,
  reservationPending: true,
  error: false,
});

CASES[TYPES.CANCEL_RESERVATION_SUCCESS] = (state, action) => ({
  ...state,
  reservation: { ...state.reservation, CRS_cancellationNumber: action.payload },
  reservationPending: false,
});

CASES[TYPES.CANCEL_RESERVATION_FAIL] = (state, action) => ({
  ...state,
  reservationPending: false,
  error: action.error,
});

// UPDATE RESERVATION
CASES[TYPES.UPDATE_RESERVATION_PENDING] = (state) => ({
  ...state,
  reservationPending: true,
  error: false,
});

CASES[TYPES.UPDATE_RESERVATION_SUCCESS] = (state) => ({
  ...state,
  reservation: {
    ...state.reservation,
    updated: true,
  },
  reservationPending: false,
});

CASES[TYPES.UPDATE_RESERVATION_FAIL] = (state, action) => ({
  ...state,
  reservationPending: false,
  error: action.error,
});

// ROOMS
CASES[TYPES.GET_ROOMS_PENDING] = (state) => ({ ...state, roomsPending: true });

CASES[TYPES.GET_ROOMS_SUCCESS] = (state, action) => ({
  ...state,
  rooms: action.payload,
  roomsPending: false,
});

CASES[TYPES.GET_ROOMS_FAIL] = (state) => ({ ...state, roomsPending: false });

// HOTEL_DETAILS
CASES[TYPES.GET_HOTEL_DETAILS_PENDING] = (state) => ({
  ...state,
  hotelDetailsPending: true,
});

CASES[TYPES.GET_HOTEL_DETAILS_SUCCESS] = (state, action) => ({
  ...state,
  hotelDetails: action.payload,
  hotelDetailsPending: false,
});

CASES[TYPES.GET_HOTEL_DETAILS_FAIL] = (state) => ({
  ...state,
  hotelDetailsPending: false,
});

// SINGLE_AVAILABILITY
CASES[TYPES.GET_SINGLE_AVAILABILITY_PENDING] = (state) => ({
  ...state,
  singleAvailabilityPending: true,
});

CASES[TYPES.GET_SINGLE_AVAILABILITY_SUCCESS] = (state, action) => ({
  ...state,
  singleAvailability: action.payload,
  singleAvailabilityPending: false,
});

CASES[TYPES.GET_SINGLE_AVAILABILITY_FAIL] = (state) => ({
  ...state,
  singleAvailabilityPending: false,
});

CASES[TYPES.DELETE_SINGLE_AVAILABILITY] = (state) => ({
  ...state,
  singleAvailability: null,
});

// BOOKING
CASES[TYPES.CREATE_BOOKING_DETAILS] = (state, action) => ({
  ...state,
  bookingDetails: action.payload,
});

CASES[TYPES.UPDATE_BOOKING_DETAILS] = (state, action) => ({
  ...state,
  bookingDetails: { ...state.bookingDetails, ...action.payload },
});

CASES[TYPES.DELETE_BOOKING_DETAILS] = (state) => ({
  ...state,
  bookingDetails: null,
});

// PROMOTIONS
CASES[TYPES.GET_PROMOTIONS_PENDING] = (state) => ({
  ...state,
  promotionsPending: true,
});

CASES[TYPES.GET_PROMOTIONS_SUCCESS] = (state, action) => ({
  ...state,
  promotions: action.payload,
  promotionsPending: false,
});

CASES[TYPES.GET_PROMOTIONS_FAIL] = (state) => ({
  ...state,
  promotionsPending: false,
});

// COUPONS
CASES[TYPES.GET_COUPONS_PENDING] = (state) => ({
  ...state,
  couponsPending: true,
});

CASES[TYPES.GET_COUPONS_SUCCESS] = (state, action) => ({
  ...state,
  coupons: action.payload,
  couponsPending: false,
});

CASES[TYPES.GET_COUPONS_FAIL] = (state) => ({
  ...state,
  couponsPending: false,
});

// RATES
CASES[TYPES.GET_RATES_PENDING] = (state) => ({ ...state, ratesPending: true });

CASES[TYPES.GET_RATES_SUCCESS] = (state, action) => ({
  ...state,
  rates: action.payload,
  ratesPending: false,
});

CASES[TYPES.GET_RATES_FAIL] = (state) => ({ ...state, ratesPending: false });

// PACKAGES
CASES[TYPES.GET_PACKAGES_PENDING] = (state) => ({
  ...state,
  packagesPending: true,
});

CASES[TYPES.GET_PACKAGES_SUCCESS] = (state, action) => ({
  ...state,
  packages: action.payload,
  packagesPending: false,
});

CASES[TYPES.GET_PACKAGES_FAIL] = (state) => ({
  ...state,
  packagesPending: false,
});

CASES[TYPES.UPDATE_BOOKING_FORM] = (state, action) => ({
  ...state,
  bookingForm: { ...state.bookingForm, ...action.payload },
});

CASES[TYPES.CLEAR_BOOKING_FORM] = (state) => ({
  ...state,
  bookingForm: {},
});

CASES[TYPES.CLEAR_ERROR] = (state) => ({
  ...state,
  error: null,
});

// GET LEAD AVAILABILITY

CASES[TYPES.GET_LEAD_AVAILABILITY_PENDING] = (state) => ({
  ...state,
  leadAvailabilityPending: true,
  error: null,
});

CASES[TYPES.GET_LEAD_AVAILABILITY_SUCCESS] = (state, action) => ({
  ...state,
  ...state,
  leadAvailability: {
    ...(state.leadAvailability || {}),
    [action.payload.key]: action.payload.leadAvailability,
  },
  leadAvailabilityPending: false,
});

CASES[TYPES.GET_LEAD_AVAILABILITY_FAIL] = (state, action) => ({
  ...state,
  leadAvailabilityPending: false,
  error: action.error,
});
