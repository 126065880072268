class SubmitButton extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    style: PropTypes.object,
    buttonText: PropTypes.string.isRequired,
    formState: PropTypes.object.isRequired,
  };

  render() {
    const {
      formState,
      className,
      containerClassName,
      buttonText,
      style,
    } = this.props;
    return (
      !formState.posting && (
        <div className={`field ${containerClassName}`}>
          <button type="submit" className={className} style={style}>
            <span>{buttonText}</span>
          </button>
          <input
            type="submit"
            style={{ visibility: "hidden", display: "none", height: 0 }}
            disabled
          />
        </div>
      )
    );
  }
}

export default SubmitButton;
