/**
 * This template uses the content from the Room page, Property page, and some content
 * from the RoomPDF page in the CMS to create a PDF.
 *
 * While most of the markup can easily be recreated in the HTML canvas, images need to
 * be converted to Base64.
 *
 * SVG markup is first converted to data:image/svg+xml;base64,
 * then to data:image/png;base64.
 *
 * Regular JPG / PNG images are converted by jspdf in the canvas. CORS is enabled on
 * the AWS S3 bucket that serves the images, otherwise a CORS error is thrown and the
 * images are not rendered in the final PDF due to a tainted canvas.
 *
 */

import styles from "../../styles/views/_room-pdf.scss";
import { _class, parse, getQueryParams } from "../utilities/helpers";
import { connect } from "react-redux";
import { jsPDF } from "jspdf";
import * as SVG from "../widgets/AmenityIcons";
import { exclamation } from "../widgets/SVG";
import ReactDOMServer from "react-dom/server";
import { useEffect, useState } from "react";
import CTA from "../partials/CTA";
import Loader from "../partials/Loader";
import { disableBodyScroll } from "body-scroll-lock";
import domtoimage from "dom-to-image";
import { AmenityTranslation, Translation } from "../utilities/Translation";
import API from "utilities/API";

const cl = _class(styles, "room_pdf");

RoomPDF.propTypes = {
  page: PropTypes.object,
  CONTENT: PropTypes.object,
  lang: PropTypes.string,
  token: PropTypes.string,
  history: PropTypes.object,
};

function RoomPDF({ CONTENT, lang, page }) {
  const params = getQueryParams(window.location.search);
  const property = CONTENT.byId(page.parentid);
  const [loading, setLoading] = useState(true);

  const filename = page.linktitle
    .replace(/\s\|\s/g, "-")
    .replace(/\s/g, "-")
    .toLowerCase();

  const pdfName = `${lang}-${filename}.pdf`;

  const heroImages = page.fieldgroup2 || [];
  const mediaBlockImages = page.fieldgroup1 || [];

  const slicedImages = heroImages
    .slice(0, 2)
    .concat(mediaBlockImages.slice(0, 3));

  const images = slicedImages.map((item) => {
    const img = new Image();
    img.src = item.image1;
    return img;
  });

  useEffect(() => {
    disableBodyScroll();

    setTimeout(async () => {
      try {
        var nodes = [...document.querySelectorAll(".capture")];
        const data = await Promise.all(
          nodes.map((node) => domtoimage.toJpeg(node, { cacheBust: true }))
        );

        for (const url of data) {
          let img = new Image();
          img.src = url;
          let capture = document.getElementById("capture_container");
          capture.appendChild(img);
        }

        save();
      } catch (e) {
        console.error("error processing images", e);
        fetchSavedPDF();
      }
    }, 500);

    setTimeout(() => setLoading(false), 10000);
  }, []);

  function fetchSavedPDF() {
    fetch(`https://s3.amazonaws.com/beachtowntravel/pdfs/${pdfName}`, {
      method: "GET",
    })
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = pdfName;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      })
      .catch((e) => console.log("could not fetch file:", e));
  }

  function save() {
    setLoading(true);

    const capture = document.getElementById("capture_container");

    var pdf = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: [1000, 1300],
    });

    pdf.html(capture, {
      callback: async (doc) => {
        setLoading(false);
        var pageCount = doc.internal.getNumberOfPages();
        doc.deletePage(pageCount);

        const api = new API({
          base: API_PATH,
        });

        const blob = new Blob([doc.output("blob")], {
          type: "application/pdf",
        });

        const file = new File([blob], pdfName, { type: "application/pdf" });

        var fd = new FormData();

        fd.append("file", file);

        api
          .post("cache_pdf", fd, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then(() => console.log("pdf cached"));

        doc.save(pdfName);
      },
    });
  }

  const renderFooter = () => (
    <div className={`${cl("footer")}`}>
      <p>
        <strong>Beach Town Travel.</strong> Las Catalinas, Guanacaste, Costa
        Rica.
      </p>
    </div>
  );

  const renderAmenities = () => {
    const items = parse(page.blurb5, []);
    const LANG = new AmenityTranslation(lang);

    return (
      <div className={cl("amenities")}>
        <h3 className={`${cl("amenities__title")}`}>Amenities and Features</h3>
        <div className={cl("amenities__inner")}>
          {items.map(({ icon, amenity }, index) => {
            const string = ReactDOMServer.renderToStaticMarkup(SVG[icon]);

            var encodedData = window.btoa(string);

            return (
              <div
                className={`${cl("amenities__item")} ${styles.short}`}
                key={index}
              >
                <div className={cl("amenities__item__icon")}>
                  <img
                    src={`data:image/svg+xml;base64,${encodedData}`}
                    className="svg_img"
                  />
                </div>
                <h3 className={cl("amenities__item__title")}>
                  {LANG(amenity)}
                </h3>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderDetailPage1 = () => {
    let index;

    if (page.path.match(/^\/es/)) {
      index = page.blurb1.search(/<h4>Habitaciones/i);
    } else {
      index = page.blurb1.search(/<h4>Bedrooms/i);
    }

    let blurb = page.blurb1.slice(0, index);

    return (
      <section className={`${cl("detail_page")} ${styles.page_1} capture`}>
        <div className={cl("page_1_left")}>
          <img
            src={images[0] && images[0].src}
            className={styles.image_proxy}
          />
          <img src={images[1].src} className={styles.image_proxy} />
        </div>
        <div className={cl("page_1_right")}>
          <h1 className={cl("title")}>{page.h1}</h1>
          <h3 className={cl("subtitle")}>{property.linktitle}</h3>
          <div
            className={cl("content")}
            dangerouslySetInnerHTML={{ __html: blurb }}
          />
        </div>
      </section>
    );
  };

  const renderDetailPage2 = () => {
    const string = ReactDOMServer.renderToStaticMarkup(exclamation);
    const svg = window.btoa(string);

    let index;

    if (page.path.match(/^\/es/)) {
      index = page.blurb1.search(/<h4>Habitaciones/i);
    } else {
      index = page.blurb1.search(/<h4>Bedrooms/i);
    }

    let blurb = page.blurb1.slice(index).replace("h4", "h2");

    return (
      <section className={`${cl("detail_page")} ${styles.page_2} capture`}>
        <div className={cl("page_2_left")}>
          <div
            className={cl("page_2_left__content")}
            dangerouslySetInnerHTML={{ __html: blurb }}
          />
          {renderAmenities()}
        </div>

        <div className={cl("page_2_right")}>
          <div className={cl("page_2_right__images")}>
            <img src={images[2].src} className={styles.image_proxy} />
            <div>
              <img src={images[3].src} className={styles.image_proxy} />
              <img src={images[4].src} className={styles.image_proxy} />
            </div>
          </div>
          <div className={cl("page_2_right__content_wrapper")}>
            {page.blurb2 && (
              <img
                src={`data:image/svg+xml;base64,${svg}`}
                className={cl("svg_img")}
              />
            )}
            <div
              className={cl("page_2_right__content")}
              dangerouslySetInnerHTML={{ __html: page.blurb2 }}
            />
          </div>
        </div>
        {!page.image1 ? renderFooter() : ""}
      </section>
    );
  };

  const renderFloorplans = () => {
    // return floorplans.map((item, index) => {
    //   return (
    //     <section className={`${cl("floorplan")} capture`} key={item.h1}>
    //       <div className={cl("floorplan__image")}>
    //         <img src={floorplans[index].src} className={styles.image_proxy} />
    //       </div>
    //       <div className={cl("floorplan__caption")}>
    //         <p>{item.h1}</p>
    //         <p>{page.linktitle}</p>
    //       </div>
    //       {renderFooter()}
    //     </section>
    //   );
    // });

    return page.image1 ? (
      <section className={`${cl("floorplan")} capture`}>
        <div className={cl("floorplan__image")}>
          <img src={page.image1} className={styles.image_proxy} />
        </div>
        <div className={cl("floorplan__caption")}>
          <p></p>
          <p>{page.linktitle}</p>
        </div>
        {renderFooter()}
      </section>
    ) : (
      <section>{renderFooter()}</section>
    );
  };

  const renderDownloadButton = () => {
    const LANG = new Translation(lang);

    return (
      <div className={styles.download}>
        <p className={styles.download__text}>{LANG("pdfDownloadMessage")}</p>

        {loading && (
          <div className={styles.download__loader}>
            <Loader />
          </div>
        )}

        {!loading && (
          <div className={styles.download__button}>
            <CTA onClick={save} text={LANG("download")} />
          </div>
        )}
      </div>
    );
  };

  const render = () => (
    <div className={cl("")}>
      <div className={cl("container")}>
        {renderDetailPage1()}
        {renderDetailPage2()}
        {renderFloorplans()}
      </div>
      <div className={cl("capture_container")} id="capture_container"></div>
      <div className={styles.mask}>{renderDownloadButton()}</div>
    </div>
  );

  if (!params || !params.token) {
    return null;
  }

  return render();
}

const mapStateToProps = ({ global }) => ({
  token: global.printPageToken,
});

export default connect(mapStateToProps)(RoomPDF);
