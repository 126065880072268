import styles from "../../../styles/views/_checkout.scss";
import {
  _class,
  getFieldGroupImage,
  gaTrackPage,
  fbTrackEvent,
  gtagTrackEvent,
} from "../../utilities/helpers";
import { connect } from "react-redux";
import AlternateRates from "../AlternateRates";
import AddOns from "../AddOns";
import CTA from "../CTA";
import moment from "moment";
import Reveal from "../../widgets/Reveal";
import { Translation } from "../../utilities/Translation";
import Image from "../../widgets/Image";

const cl = _class(styles, "checkout");

class Checkout extends React.Component {
  static propTypes = {
    bookingDetails: PropTypes.object,
    hotelDetails: PropTypes.object,
    goTo: PropTypes.func,
    lang: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      isChecked: false,
      isHover: false,
    };

    this.LANG = new Translation(props.lang);
  }
  componentDidMount() {
    gaTrackPage("/btt/booking-engine/room-confirmation");

    fbTrackEvent("InitiateCheckout");

    gtagTrackEvent("AW-817146118/vIKNCO7cn-4BEIbS0oUD");
  }

  handleCheckboxChange = (event) => {
    this.setState({ isChecked: event.target.checked });
  };

  handleCheckboxClick = () => {
    this.setState((prevState) => ({ isChecked: !prevState.isChecked }));
  };

  handleMouseOver = () => {
    this.setState({ isHover: true });
  };
  handleMouseOut = () => {
    this.setState({ isHover: false });
  };

  renderRoomDetails = () => {
    const { bookingDetails, hotelDetails } = this.props;

    if (bookingDetails && hotelDetails) {
      const attributes = hotelDetails.hotelList[0].Attributes;
      const checkin = attributes.find((a) => a.Name.match("Check-In Time"));
      const checkout = attributes.find((a) => a.Name.match("Check-Out Time"));

      return (
        <Reveal cssAnimation={["fade", "top"]} className={cl("room_details")}>
          <div className={cl("room_details__image")}>
            <Image
              {...getFieldGroupImage(bookingDetails.cmsRoom.fieldgroup2)}
            />
          </div>
          <div>
            <p className={cl("room_details__bedding")}>
              Sleeps {bookingDetails.synxisRoom.Details.GuestLimit.Value}
              <span></span>
              {bookingDetails.synxisRoom.Details.Bedding.map(
                (bed) => `${bed.Quantity} ${bed.Type}`
              ).join(", ")}
            </p>
          </div>

          <div className={cl("room_details__checkin_checkout")}>
            <div>
              <p>Check In:</p>
              <p>{moment(checkin.Value, "HHmm").format("h:mm a")}</p>
            </div>
            <div>
              <p>Check Out:</p>
              <p>{moment(checkout.Value, "HHmm").format("h:mm a")}</p>
            </div>
          </div>

          {/* {bookingDetails.promo && (
            <div className={cl("room_details__bedding")}>
              <p>Promo {bookingDetails.promo} applied.</p>
            </div>
          )} */}
        </Reveal>
      );
    }
  };

  render = () => {
    const { bookingDetails } = this.props;

    const startIndex = bookingDetails.cmsProperty.blurb2.indexOf(
      "<h3>Policies:</h3>"
    );
    const endIndex = bookingDetails.cmsProperty.blurb2.indexOf(
      "<h3>Terms and Conditions:</h3>"
    );
    const contentReq = bookingDetails.cmsProperty.blurb2.substring(
      startIndex,
      endIndex
    );

    return (
      <div className={`${cl("step")} ${styles.one}`}>
        {this.renderRoomDetails()}
        <div
          className={cl("room_details__content")}
          dangerouslySetInnerHTML={{
            __html: bookingDetails.cmsRoom.blurb4 || contentReq,
          }}
        />

        <div className={`${cl("cta_wrapper")}`}>
          <input
            type="checkbox"
            checked={this.state.isChecked}
            onChange={this.handleCheckboxChange}
          />

          <span
            className={`${styles.checkbox} ${
              this.state.isChecked && styles.active
            }`}
            onClick={() => this.handleCheckboxClick()}
          />

          <label>
            <span>
              I agree to the Beach Town Travel &nbsp;
              <a
                href="https://www.beachtowntravel.com/legal"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms & Conditions
              </a>
            </span>
          </label>

          <div className={`${styles["checkbox-btn-box"]}`}>
            {this.state.isHover && !this.state.isChecked && (
              <p className={`${styles["checkbox-text"]}`}>
                Please agree to the Terms and Conditions
              </p>
            )}
            <span
              onMouseOver={this.handleMouseOver}
              onMouseOut={this.handleMouseOut}
              className={`${this.state.isChecked ? styles["active"] : ""}`}
            >
              <CTA
                className={`${cl("cta")} ${
                  !this.state.isChecked ? "disabled" : ""
                }`}
                text={this.LANG("agreeAndContinue")}
                onClick={() => this.state.isChecked && this.props.goTo(2)}
                type={this.state.isChecked ? "orange" : "mediumgrey"}
              />
            </span>
          </div>
        </div>

        <AlternateRates selectedRate={bookingDetails.rate} />
        <AddOns />
      </div>
    );
  };
}

const mapStateToProps = ({ synxis, global }) => ({
  hotelDetails: synxis.hotelDetails,
  bookingDetails: synxis.bookingDetails,
  lang: global.lang,
});

export default connect(mapStateToProps)(Checkout);
