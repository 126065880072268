import styles from "../../styles/views/_who-we-are.scss";
import { _class } from "../utilities/helpers";
import Image from "../widgets/Image";
import Intro from "../partials/Intro";
import Hero from "../partials/Hero";

const cl = _class(styles, "who_we_are");

class WhoWeAre extends React.Component {
  static propTypes = {
    page: PropTypes.object,
    CONTENT: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.slides = props.page.fieldgroup1 || [];
  }

  renderImageColumn = () => {
    const { page } = this.props;

    return (
      <div className={cl("image_column")}>
        <div className={cl("image_wrapper")}>
          <Image src={page.image1} />
        </div>
        <div className={cl("image_wrapper")}>
          <Image src={page.image2} />
        </div>
      </div>
    );
  };

  renderBottomImage = () => (
    <div className={cl("bottom_image")}>
      <Image src={this.props.page.image3} />
    </div>
  );

  renderIntro = (blurb, title) => {
    if (blurb) {
      return (
        <div className={cl("intro")}>
          <Intro title={title} blurb={blurb} />
        </div>
      );
    }
  };

  render() {
    const { page } = this.props;

    return (
      <div className={cl("")}>
        <Hero
          imageData={{ slides: this.slides }}
          videoLinkPresent={page.video_link !== null && page.video_link !== ""}
          videoLink={page.video_link}
        />
        <div className={cl("container")}>
          <Intro title={page.h1} blurb={page.blurb1} />
          {this.renderImageColumn()}
          {this.renderIntro(page.blurb2)}
          {this.renderBottomImage()}
          {this.renderIntro(page.blurb3)}
        </div>
      </div>
    );
  }
}

export default WhoWeAre;
