import { _class } from "../utilities/helpers";
import styles from "../../styles/partials/_image-grid.scss";
import Link from "../widgets/Link";
import Image from "../widgets/Image";
import Reveal from "../widgets/Reveal";

const cl = _class(styles, "image_grid");

ImageGrid.propTypes = {
  reversed: PropTypes.bool,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      align: PropTypes.string,
      link: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};

ImageGrid.defaultProps = {
  images: [],
};

export default function ImageGrid({ images, reversed }) {
  return (
    <ul
      className={`${cl("")} ${reversed ? styles.reversed : ""} ${
        images.length < 4 ? styles.static : styles.dynamic
      }`}
    >
      {images.map((item, index) => {
        const classes = `${cl("image")} ${reversed ? styles.reversed : ""} ${
          images.length < 4 ? styles.static : styles.dynamic
        }`;
        return (
          <Reveal
            className={classes}
            cssAnimation={["fade", "bottom"]}
            key={item.id || index}
          >
            <Link path={item.link}>
              <Image src={item.src} alt={item.alt} align={item.align} />
              <h3>{item.title}</h3>
            </Link>
          </Reveal>
        );
      })}
    </ul>
  );
}
