import styles from "../../styles/partials/_leaflet-map.scss";
import { _class } from "../utilities/helpers";
import L, { CRS } from "leaflet";
import { Map, ImageOverlay, Marker, Popup } from "react-leaflet";
import ReactDOMServer from "react-dom/server";
import * as SVG from "../widgets/SVG";

const cl = _class(styles, "leaflet_map");

const icon = (
  <svg viewBox="0 -256 1792 1792" width="100%" height="100%">
    <g transform="matrix(1,0,0,-1,364.47458,1270.2373)">
      <path
        d="m 768,896 q 0,106 -75,181 -75,75 -181,75 -106,0 -181,-75 -75,-75 -75,-181 0,-106 75,-181 75,-75 181,-75 106,0 181,75 75,75 75,181 z m 256,0 q 0,-109 -33,-179 L 627,-57 q -16,-33 -47.5,-52 -31.5,-19 -67.5,-19 -36,0 -67.5,19 Q 413,-90 398,-57 L 33,717 Q 0,787 0,896 q 0,212 150,362 150,150 362,150 212,0 362,-150 150,-150 150,-362 z"
        id="path3029"
        fill="black"
      />
    </g>
  </svg>
);

class LeafletMap extends React.Component {
  static propTypes = {
    image: PropTypes.string.isRequired,
    markers: PropTypes.arrayOf(
      PropTypes.shape({
        latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        title: PropTypes.string,
        icon: PropTypes.string,
      })
    ),
    center: PropTypes.shape({
      latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    bounds: PropTypes.array,
    icon: PropTypes.node,
    className: PropTypes.string,
    onMarkerClick: PropTypes.func,
    zoom: PropTypes.number,
    activeId: PropTypes.number,
    zoomControls: PropTypes.boolean,
  };

  static defaultProps = {
    markers: [],
    bounds: [
      [0, 0],
      [1000, 1000],
    ],
    center: {
      latitude: 500,
      longitude: 500,
    },
    icon: icon,
    className: "",
    onMarkerClick: () => null,
    zoom: 0,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      scrollZoom: false,
    };

    this.icon = L.divIcon({
      className: cl("icon"),
      html: ReactDOMServer.renderToString(props.icon),
    });

    this.experienceIcon = L.divIcon({
      className: cl("icon"),
      html: ReactDOMServer.renderToString(SVG.experience_marker),
    });

    this.diningIcon = L.divIcon({
      className: cl("icon"),
      html: ReactDOMServer.renderToString(SVG.dining_marker),
    });

    this.shopIcon = L.divIcon({
      className: cl("icon"),
      html: ReactDOMServer.renderToString(SVG.shop_marker),
    });

    this.stayIcon = L.divIcon({
      className: cl("icon"),
      html: ReactDOMServer.renderToString(SVG.home_marker),
    });
  }

  componentDidMount() {
    this.registerMapListeners();
  }

  getIcon = (icon = "") => {
    if (icon.match(/experience|outdoor/i)) {
      return this.experienceIcon;
    }

    if (icon.match(/shop/i)) {
      return this.shopIcon;
    }

    if (icon.match(/vacation|hotel|stay/i)) {
      return this.stayIcon;
    }

    if (icon.match(/restaurant/i)) {
      return this.diningIcon;
    }

    return this.icon;
  };

  handleMarkerClick = (id) => this.props.onMarkerClick(id);

  registerMapListeners = () => {
    const el = document.getElementById("leaflet_map");

    if (el) {
      el.addEventListener("focus", () => this.setState({ scrollZoom: true }));
      el.addEventListener("blur", () => this.setState({ scrollZoom: false }));
    }
  };

  renderMarkers = () => {
    const { markers } = this.props;

    return markers.map((marker) => {
      const lat = parseFloat(marker.latitude) || 500;
      const lng = parseFloat(marker.longitude) || 500;

      return (
        <Marker
          key={marker.id}
          position={[lat, lng]}
          icon={this.getIcon(marker.icon)}
          onClick={this.handleMarkerClick.bind(null, marker.id)}
          opacity={
            this.props.activeId
              ? marker.id === this.props.activeId
                ? 1
                : 0
              : 1
          }
        >
          <Popup>{marker.title}</Popup>
        </Marker>
      );
    });
  };

  render() {
    const { bounds, image, center, zoom, zoomControls } = this.props;

    const lat = parseFloat(center.latitude || 500);
    const lng = parseFloat(center.longitude || 500);
    return (
      <div
        className={`${cl("")} ${this.props.className} ${
          !zoomControls ? styles.hide_zoom : ""
        }`}
      >
        <Map
          id="leaflet_map"
          style={{ height: "100%", width: "100%", background: "#efefef" }}
          center={[lat, lng]}
          minZoom={0}
          maxZoom={2}
          crs={CRS.Simple}
          scrollWheelZoom={this.state.scrollZoom}
          maxBounds={bounds}
          zoom={zoom}
        >
          <ImageOverlay bounds={bounds} url={image} />
          {this.renderMarkers()}
        </Map>
      </div>
    );
  }
}

export default LeafletMap;
