import styles from "../../styles/views/_checkout.scss";
import { _class, getQueryParams, parse } from "../utilities/helpers";
import Link from "../widgets/Link";
import Reveal from "../widgets/Reveal";
import MorePlaces from "../partials/MorePlaces";
import { Translation } from "../utilities/Translation";
import { connect } from "react-redux";
import {
  deleteAvailability,
  deleteReservation,
  deleteBookingDetails,
  clearBookingForm,
  updateBookingDetails,
  confirmReservation,
} from "../redux/synxis/SynxisActions";
import CTA from "../partials/CTA";

import CheckoutRoomDetails from "../partials/checkout/CheckoutRoomDetails";
import CheckoutForm from "../partials/checkout/CheckoutForm";
import CheckoutConfirmation from "../partials/checkout/CheckoutConfirmation";
import CheckoutDetailsWidget from "../partials/checkout/CheckoutDetailsWidget";
import * as Storage from "../utilities/Storage";
import { SojernTracking_ShoppingCart } from "../utilities/SojernTracking";
import { trackingDisabled } from "../config/appConfig";

const cl = _class(styles, "checkout");

class Checkout extends React.Component {
  static propTypes = {
    page: PropTypes.object,
    CONTENT: PropTypes.object,
    bookingDetails: PropTypes.object,
    deleteAvailability: PropTypes.func,
    deleteReservation: PropTypes.func,
    deleteBookingDetails: PropTypes.func,
    updateBookingDetails: PropTypes.func,
    removePackage: PropTypes.func,
    lang: PropTypes.string,
    clearBookingForm: PropTypes.func,
    confirmReservation: PropTypes.func,
    reservation: PropTypes.object,
  };

  static contextTypes = {
    history: PropTypes.object,
    filterByLang: PropTypes.func,
    viewport: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.LANG = new Translation(props.lang);

    this.tabs = [
      this.LANG("yourRoom"),
      this.LANG("guestDetails"),
      this.LANG("confirmation"),
    ];

    this.otherHotels = props.CONTENT.byTemplate("property_detail").filter(
      context.filterByLang
    );

    this.state = {
      activeTab: 1,
      storedBookingDetails: Storage.getItem("booking_details"),
    };
  }

  componentDidMount() {
    const params = getQueryParams(window.location.search, {});

    if (params.authorize && params.iframe) {
      return (window.top.location =
        "/checkout" + window.location.search.replace("iframe=true&", ""));
    }

    // we're redirected during checkout process - don't track the event here
    if (params.authorize && parse(params.Response)) {
      this.goTo(2);
      this.props.updateBookingDetails(this.state.storedBookingDetails);
      this.props.confirmReservation(parse(params.Response));
    }

    const { storedBookingDetails } = this.state;

    if (this.props.bookingDetails && !storedBookingDetails) {
      Storage.setItem("booking_details", this.props.bookingDetails);
      // console.log("stored booking details", this.props.bookingDetails);
      // console.log("total", this.props.bookingDetails.total.toFixed(2));
      if (!trackingDisabled) {
        SojernTracking_ShoppingCart(this.props.bookingDetails.total.toFixed(2));
      }
    }
  }

  componentWillUnmount() {
    if (this.state.activeTab === 3) {
      this.props.deleteBookingDetails();
      this.props.deleteAvailability();
      this.props.deleteReservation();
      this.props.clearBookingForm();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.reservation && this.props.reservation) {
      this.goTo(3);
      Storage.removeItem("booking_details");
    }
  }

  restoreBookingDetails = () => {
    this.props.updateBookingDetails(this.state.storedBookingDetails);
    this.setState({ storedBookingDetails: null });
  };

  rejectBookingDetails = () => {
    Storage.removeItem("booking_details");
    this.setState({ storedBookingDetails: null });

    if (this.props.bookingDetails) {
      Storage.setItem("booking_details", this.props.bookingDetails);
    } else {
      const home = this.props.CONTENT.byTemplate("home").filter(
        this.context.filterByLang
      )[0];
      this.context.history.pushTransition(home.path);
    }
  };

  renderTabs = () => {
    const { activeTab } = this.state;

    if (activeTab !== 3) {
      return (
        <ul className={cl("tabs")}>
          {this.tabs.map((tab, i) => {
            const active = i + 1 === activeTab ? styles.active : "";
            const onClick = () => {
              if (i + 1 !== 3 && activeTab !== 3 + 1 && i + 1 < activeTab) {
                this.goTo(i + 1);
              }
            };
            return (
              <li className={`${cl("tabs__item")} ${active}`} key={i}>
                <Link onClick={onClick} title={tab} />
              </li>
            );
          })}
        </ul>
      );
    }
  };

  goTo = (activeTab) => {
    window.scrollTo(0, 0);
    this.setState({ activeTab });
  };

  renderReservationPrompt = () => {
    return (
      <div className={cl("")}>
        <div className={cl("container")}>
          <div className={cl("previous_booking_details")}>
            <div>
              <h2>{this.LANG("reservationInProgress")}</h2>
              <p>{this.LANG("reservationInProgressDetails")}</p>
              <div className={cl("previous_booking_details__ctas")}>
                <CTA
                  text={this.LANG("continue")}
                  onClick={this.restoreBookingDetails}
                  type="orange"
                  size="medium"
                />
                <CTA
                  text={this.LANG("noThanks")}
                  onClick={this.rejectBookingDetails}
                  type="underline"
                  size="medium"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderNoBookingDetails = () => {
    return (
      <div className={cl("")}>
        <div className={cl("container")}>
          <div className={cl("no_booking_details")}>
            <h2>{this.LANG("noBookingDetails")}</h2>
            <CTA link="/" text="Home" />
          </div>
        </div>
      </div>
    );
  };

  renderSteps = () => {
    const { page } = this.props;
    switch (this.state.activeTab) {
      case 1:
        return <CheckoutRoomDetails page={page} goTo={this.goTo} />;
      case 2:
        return <CheckoutForm page={page} goTo={this.goTo} />;
      case 3:
        return (
          <CheckoutConfirmation
            page={page}
            goTo={this.goTo}
            CONTENT={this.props.CONTENT}
          />
        );
      default:
        return <CheckoutRoomDetails page={page} goTo={this.goTo} />;
    }
  };

  renderMorePlaces = () => {
    if (this.state.activeTab === 3) {
      return (
        <MorePlaces
          title={this.LANG("exploreAndExperience")}
          items={this.otherHotels}
        />
      );
    }
  };

  renderTitle = () => {
    if (this.state.activeTab === 1) {
      return (
        <Reveal className={cl("title")} cssAnimation={["fade"]}>
          <h1>{this.props.bookingDetails.cmsRoom.linktitle}</h1>
        </Reveal>
      );
    }
  };

  render() {
    if (this.state.storedBookingDetails && this.state.activeTab === 1) {
      return this.renderReservationPrompt();
    }

    if (!this.props.bookingDetails) {
      return this.renderNoBookingDetails();
    }

    return (
      <div className={cl("")}>
        <div className={cl("container")}>
          {this.renderTabs()}
          {this.renderTitle()}
          <div className={cl("columns")}>
            <div className={cl("columns__left")}>{this.renderSteps()}</div>
            <div className={cl("columns__right")}>
              <div className={cl("details_widget")}>
                <CheckoutDetailsWidget
                  viewport={this.context.viewport}
                  step={this.state.activeTab}
                />
              </div>
            </div>
          </div>
        </div>
        {this.renderMorePlaces()}
      </div>
    );
  }
}

const mapStateToProps = ({ synxis, global }) => ({
  bookingDetails: synxis.bookingDetails,
  reservation: synxis.reservation,
  reservationPending: synxis.reservationPending,
  hotelDetails: synxis.hotelDetails,
  rooms: synxis.rooms,
  lang: global.lang,
});

export default connect(mapStateToProps, {
  deleteAvailability,
  deleteReservation,
  deleteBookingDetails,
  clearBookingForm,
  updateBookingDetails,
  confirmReservation,
})(Checkout);
