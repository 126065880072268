import styles from "../../styles/views/_search-results.scss";
import { _class, getQueryParams } from "../utilities/helpers";
// import queryString from "query-string";
import * as SVG from "../widgets/SVG";
import Transition from "../widgets/Transition";
import Link from "../widgets/Link";
import { Translation } from "../utilities/Translation";
import { connect } from "react-redux";
const cl = _class(styles, "search_results");

class SearchResults extends React.Component {
  static propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
    page: PropTypes.object,
    CONTENT: PropTypes.object,
    lang: PropTypes.string,
  };

  static contextTypes = {
    history: PropTypes.object,
    filterByLang: PropTypes.func,
  };
  constructor(props, context) {
    super(props, context);

    this.LANG = new Translation(props.lang);

    this.initialValue = this.getInitialValue();

    this.state = {
      animating: false,
      visible: true,
      value: this.initialValue,
      results: this.getResults(this.initialValue),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.location.search !== this.props.location.search &&
      !this.state.animating
    ) {
      this.animateOut();
    }

    if (prevState.animating && !this.state.animating) {
      this.animateIn();
    }
  }

  onDone = () => {
    this.setState({ animating: false });
  };

  animateOut = () => {
    this.setState({ visible: false, animating: true });
  };

  animateIn = () => {
    this.setState(
      {
        value: this.getInitialValue(),
        results: this.getResults(this.getInitialValue()),
      },
      () => this.setState({ visible: true })
    );
  };

  getInitialValue = () => {
    const {
      location: { search },
    } = this.props;

    if (search) {
      const parsed = getQueryParams(search);
      if (parsed.s) {
        return decodeURI(parsed.s);
      }
    }

    return "";
  };

  searchCheck = (searchStr, matchStr) => {
    //TODO: Taking the object from settings and checking if a word is in that object, if it is, then also check the value/key of that object to see if its in the matchStr
    const searchObjectsArray = this.props.CONTENT.byTemplate("options")[0]
      .fieldgroup4;

    return searchStr.split(" ").every((word) => {
      console.log(word);
      const searchObjectToUse = searchObjectsArray.find(
        (item) =>
          item.h2.toLowerCase().includes(word) ||
          item.h4.toLowerCase().includes(word)
      );

      if (searchObjectToUse) {
        const searchStringToUse = word.includes(searchObjectToUse.h4)
          ? searchObjectToUse.h2
          : searchObjectToUse.h4;

        return matchStr.includes(searchStringToUse);
      }

      return matchStr.includes(word);
    });
  };

  getResults = (value) => {
    if (value === "") {
      return [];
    }
    const content = this.props.CONTENT.all;
    let sum = [];

    for (let key in content) {
      let result = {};
      let page = content[key];
      let linktitle = page.linktitle.toLowerCase();
      let pagetitle = page.pagetitle.toLowerCase();
      // let specialChars = page.blurb6 || page.buttontext1;
      let regexString = value.replace(/\s+/g, " ").trim().toLowerCase();

      if (
        page.template.match(
          /(home|root|town_category|town_category_item|checkout|options|booking_results|landing_page|popup)/
        ) ||
        (page.template === "room" && page.checkbox1)
      ) {
        continue;
      }
      //TODO: This will add the special search once approved and we add blurb6
      // if (
      //   this.searchCheck(regexString, linktitle) ||
      //   this.searchCheck(regexString, pagetitle) ||
      //   this.searchCheck(regexString, specialChars)
      // ) {
      if (
        this.searchCheck(regexString, linktitle) ||
        this.searchCheck(regexString, pagetitle)
      ) {
        result.grade = 1;
        sum.push(page);
        continue;
      }

      for (let i = 1; i <= 6; i++) {
        let heading = page[`h${i}`] || "";

        if (this.searchCheck(regexString, heading)) {
          result.grade = 2;
          sum.push(page);
          break;
        }
      }
    }

    return sum
      .sort((a, b) => a.grade - b.grade)
      .filter(this.context.filterByLang);
  };

  handleChange = (e) => this.setState({ value: e.target.value });

  submit = (e) => {
    const { value } = this.state;
    e.preventDefault();

    if (value !== "" && value.length > 2) {
      const searchPage = this.props.CONTENT.byTemplate("search_results").filter(
        this.context.filterByLang
      )[0];
      this.context.history.pushTransition(
        `${searchPage.path}?s=${encodeURI(value)}`
      );
    }
  };

  renderForm = () => {
    const { value } = this.state;
    return (
      <form onSubmit={this.submit} className={cl("form")}>
        <div className={cl("form__icon")}>{SVG.search}</div>
        <input
          className={cl("form__input")}
          type="text"
          value={value}
          onChange={this.handleChange}
          placeholder={"Search..."}
        />
        <input type="submit" value="Go" className={cl("form__submit")} />
      </form>
    );
  };

  renderResults = () => {
    const { results, visible } = this.state;

    let content = (
      <div className={cl("no_results")}>
        <p>{this.LANG("noSearchResults")}</p>
      </div>
    );

    if (results && results.length) {
      content = (
        <ul className={cl("list")}>
          {results.map((page) => {
            return (
              <li className={cl("list__item")} key={page.id}>
                <Link path={page.path}>
                  <h2 className={cl("list__item__title")}>{page.h1}</h2>
                  <p className={cl("list__item__path")}>{page.path}</p>
                  <div
                    className={cl("list__item__blurb ")}
                    dangerouslySetInnerHTML={{
                      __html: page.blurb1
                        ? page.blurb1.slice(0, 400) + "..."
                        : "",
                    }}
                  />
                </Link>
              </li>
            );
          })}
        </ul>
      );
    }
    return (
      <Transition
        visible={visible}
        mode="stagger"
        className={cl("list__item")}
        properties={{
          y: ["50%", "0%"],
          opacity: [0, 1],
          autoAlpha: [0, 1],
        }}
        appear
        onComplete={this.onDone}
      >
        {content}
      </Transition>
    );
  };

  render() {
    const { page } = this.props;
    return (
      <div className={cl("")}>
        <div className={cl("header")}>
          <div className={cl("container")}>
            <div className={cl("header__inner")}>
              <h1>{page.h1}</h1>
              {this.renderForm()}
            </div>
          </div>
        </div>

        <div className={cl("container")}>{this.renderResults()}</div>
      </div>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  lang: global.lang,
});

export default connect(mapStateToProps)(SearchResults);
