export const logo = (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width="72.332px"
    height="26.5px"
    viewBox="113.834 36.667 72.332 26.5"
  >
    <path
      d="M121.212,43.804c0.405,0.409,0.627,0.964,0.618,1.54c0.008,0.469-0.125,0.931-0.382,1.323
  c-0.272,0.399-0.652,0.712-1.097,0.902c-0.531,0.228-1.105,0.339-1.683,0.327c-0.175,0-0.49,0-0.925-0.018
  c-0.435-0.018-0.886-0.02-1.335-0.02c-0.721,0-1.263,0.013-1.634,0.038v-0.263c0.234,0,0.467-0.034,0.691-0.103
  c0.14-0.052,0.249-0.165,0.295-0.307c0.063-0.235,0.091-0.478,0.08-0.721v-6.529c0.011-0.242-0.017-0.485-0.08-0.719
  c-0.046-0.143-0.155-0.257-0.295-0.309c-0.224-0.068-0.457-0.103-0.691-0.103v-0.263c0.364,0.026,0.899,0.039,1.606,0.039
  c0.343,0,0.721-0.004,1.134-0.013c0.414,0,0.696-0.013,0.852-0.013c0.519-0.014,1.036,0.074,1.521,0.26
  c0.378,0.143,0.705,0.396,0.938,0.727c0.213,0.319,0.324,0.697,0.315,1.082c0.003,0.496-0.166,0.977-0.479,1.361
  c-0.319,0.403-0.826,0.711-1.52,0.925v0.027C120.113,43.102,120.803,43.378,121.212,43.804z M119.453,42.363
  c0.288-0.39,0.431-0.911,0.43-1.562s-0.146-1.142-0.436-1.471c-0.291-0.327-0.76-0.49-1.407-0.49
  c-0.209-0.011-0.419,0.021-0.616,0.093c-0.138,0.062-0.241,0.182-0.283,0.327c-0.061,0.23-0.086,0.468-0.077,0.706v2.974h0.886
  C118.665,42.944,119.166,42.751,119.453,42.363z M117.141,47.199c0.042,0.141,0.146,0.255,0.283,0.309
  c0.203,0.065,0.416,0.093,0.629,0.083c1.641,0,2.461-0.769,2.461-2.307c0-0.689-0.188-1.214-0.562-1.573
  c-0.375-0.359-0.969-0.54-1.783-0.54h-1.105v3.327C117.053,46.734,117.079,46.971,117.141,47.199z"
    />
    <path
      d="M128.018,47.176c-0.231,0.275-0.519,0.498-0.842,0.654c-0.351,0.17-0.737,0.256-1.127,0.25
  c-0.57,0.015-1.132-0.133-1.621-0.426c-0.459-0.289-0.823-0.707-1.044-1.201c-0.249-0.561-0.372-1.169-0.359-1.783
  c-0.015-0.68,0.109-1.355,0.366-1.985c0.213-0.522,0.574-0.972,1.038-1.294c0.465-0.308,1.013-0.465,1.57-0.453
  c0.788,0,1.394,0.238,1.82,0.716c0.426,0.477,0.639,1.202,0.64,2.175h-4.113c-0.034,0.279-0.049,0.56-0.045,0.841
  c-0.012,0.52,0.086,1.037,0.286,1.517c0.159,0.391,0.417,0.733,0.749,0.995c0.279,0.222,0.624,0.344,0.98,0.347
  c0.881,0,1.525-0.438,1.933-1.315l0.263,0.101C128.403,46.63,128.236,46.923,128.018,47.176z M127.083,42.376
  c-0.067-0.32-0.213-0.618-0.423-0.868c-0.177-0.209-0.438-0.329-0.712-0.327c-0.271,0-0.534,0.092-0.747,0.26
  c-0.255,0.213-0.453,0.487-0.574,0.797c-0.172,0.425-0.273,0.875-0.299,1.333h2.864C127.213,43.17,127.176,42.768,127.083,42.376z"
    />
    <path
      d="M132.964,41.479c-0.256-0.201-0.577-0.301-0.902-0.279c-0.621,0-1.057,0.156-1.307,0.469
  c0.369,0.089,0.626,0.424,0.616,0.804c0.011,0.196-0.065,0.388-0.208,0.523c-0.157,0.131-0.358,0.199-0.562,0.189
  c-0.2,0.009-0.393-0.074-0.524-0.226c-0.132-0.167-0.199-0.376-0.189-0.59c0-0.343,0.206-0.656,0.618-0.938
  c0.246-0.156,0.513-0.275,0.792-0.354c0.342-0.097,0.695-0.144,1.051-0.14c0.322-0.006,0.643,0.039,0.951,0.132
  c0.241,0.067,0.461,0.191,0.643,0.362c0.197,0.199,0.34,0.445,0.413,0.716c0.086,0.37,0.124,0.749,0.113,1.127v3.655
  c-0.012,0.164,0.021,0.328,0.093,0.476c0.07,0.102,0.189,0.158,0.312,0.147c0.103,0.001,0.205-0.023,0.296-0.07
  c0.105-0.058,0.205-0.127,0.295-0.206l0.147,0.225c-0.154,0.115-0.283,0.205-0.386,0.27c-0.11,0.067-0.229,0.119-0.353,0.153
  c-0.15,0.042-0.306,0.062-0.462,0.059c-0.425,0-0.72-0.109-0.884-0.327c-0.176-0.248-0.263-0.548-0.247-0.851
  c-0.214,0.386-0.536,0.701-0.926,0.907c-0.34,0.174-0.716,0.266-1.098,0.268c-0.287,0.001-0.57-0.054-0.835-0.164
  c-0.247-0.1-0.458-0.27-0.608-0.49c-0.159-0.245-0.238-0.533-0.227-0.825c-0.009-0.303,0.076-0.602,0.244-0.854
  c0.183-0.253,0.417-0.464,0.688-0.619c0.382-0.221,0.779-0.415,1.188-0.582l0.629-0.257c0.262-0.095,0.507-0.234,0.722-0.412
  c0.136-0.11,0.217-0.274,0.222-0.449v-0.817C133.281,42.009,133.176,41.665,132.964,41.479z M132.964,44.155
  c-0.148,0.085-0.462,0.243-0.938,0.474c-0.224,0.105-0.439,0.229-0.643,0.37c-0.184,0.137-0.337,0.311-0.449,0.511
  c-0.131,0.242-0.195,0.516-0.186,0.791c-0.023,0.314,0.081,0.625,0.289,0.863c0.203,0.2,0.48,0.306,0.765,0.292
  c0.582,0,1.076-0.332,1.484-0.995v-2.583c-0.085,0.117-0.196,0.212-0.325,0.278H132.964z"
    />
    <path
      d="M141.106,47.132c-0.215,0.284-0.49,0.516-0.806,0.68c-0.358,0.184-0.757,0.276-1.16,0.267
  c-0.525,0.006-1.041-0.141-1.484-0.423c-0.455-0.299-0.816-0.72-1.044-1.214c-0.266-0.58-0.397-1.212-0.382-1.85
  c-0.011-0.654,0.122-1.302,0.389-1.898c0.235-0.525,0.613-0.973,1.09-1.294c0.471-0.31,1.023-0.471,1.587-0.462
  c0.539-0.015,1.07,0.13,1.528,0.417c0.412,0.279,0.618,0.618,0.618,1.016c0.011,0.2-0.06,0.396-0.196,0.542
  c-0.132,0.131-0.312,0.202-0.499,0.196c-0.188,0.006-0.372-0.057-0.516-0.176c-0.143-0.122-0.22-0.303-0.209-0.49
  c-0.004-0.172,0.054-0.34,0.163-0.473c0.102-0.124,0.24-0.212,0.396-0.253c-0.109-0.18-0.274-0.318-0.469-0.396
  c-0.223-0.106-0.466-0.162-0.712-0.164c-0.328,0.002-0.646,0.11-0.907,0.309c-0.327,0.269-0.569,0.625-0.699,1.028
  c-0.204,0.608-0.298,1.248-0.276,1.889c0,1.089,0.176,1.882,0.526,2.379c0.298,0.467,0.814,0.748,1.368,0.744
  c0.381-0.002,0.753-0.116,1.07-0.327c0.371-0.256,0.662-0.613,0.838-1.028l0.25,0.09C141.466,46.562,141.31,46.863,141.106,47.132z"
    />
    <path
      d="M145.381,41.306c0.396-0.248,0.854-0.375,1.32-0.369c0.613,0,1.078,0.163,1.394,0.49c0.173,0.189,0.295,0.417,0.358,0.665
  c0.079,0.349,0.114,0.705,0.104,1.062v3.515c-0.009,0.195,0.025,0.39,0.1,0.57c0.063,0.134,0.177,0.238,0.315,0.289
  c0.189,0.062,0.389,0.091,0.588,0.083v0.276c-0.817-0.033-1.35-0.051-1.58-0.051s-0.742,0.018-1.479,0.051v-0.276
  c0.176,0.007,0.351-0.021,0.515-0.083c0.125-0.057,0.224-0.161,0.273-0.289c0.065-0.183,0.095-0.376,0.087-0.57v-3.799
  c0.004-0.267-0.021-0.534-0.077-0.795c-0.045-0.203-0.154-0.386-0.312-0.521c-0.194-0.145-0.435-0.215-0.676-0.199
  c-0.343-0.004-0.678,0.103-0.954,0.306c-0.292,0.218-0.518,0.511-0.654,0.848c-0.162,0.39-0.242,0.809-0.235,1.23v2.941
  c-0.008,0.194,0.021,0.387,0.086,0.57c0.05,0.127,0.146,0.23,0.27,0.289c0.163,0.062,0.337,0.091,0.511,0.083v0.276
  c-0.737-0.033-1.227-0.051-1.471-0.051c-0.243,0-0.776,0.018-1.58,0.051v-0.276c0.199,0.007,0.398-0.021,0.587-0.083
  c0.139-0.051,0.252-0.155,0.315-0.289c0.075-0.181,0.109-0.375,0.1-0.57V39.17c0-0.407-0.074-0.702-0.223-0.884
  c-0.148-0.182-0.408-0.273-0.779-0.273v-0.268c0.278,0.026,0.549,0.039,0.817,0.039c0.463,0.015,0.926-0.048,1.368-0.186v4.92
  C144.634,42.026,144.954,41.601,145.381,41.306z"
    />
    <path
      d="M153.189,40.261c-0.003-0.562-0.035-1.124-0.096-1.683c0.727,0.026,1.965,0.039,3.712,0.039s2.979-0.013,3.694-0.039
  c-0.061,0.626-0.091,1.187-0.09,1.683c0,0.441,0.012,0.801,0.037,1.08h-0.302c-0.038-0.511-0.125-1.018-0.26-1.513
  c-0.085-0.302-0.282-0.56-0.552-0.719c-0.264-0.153-0.668-0.229-1.211-0.229h-0.713v7.483c-0.014,0.271,0.021,0.541,0.104,0.799
  c0.065,0.167,0.201,0.295,0.372,0.351c0.277,0.078,0.564,0.116,0.853,0.114v0.263c-0.463-0.025-1.107-0.038-1.933-0.038
  c-0.878,0-1.526,0.013-1.946,0.038v-0.263c0.289,0.002,0.576-0.037,0.854-0.114c0.171-0.056,0.308-0.185,0.372-0.351
  c0.082-0.258,0.117-0.529,0.104-0.799v-7.476h-0.714c-0.542,0-0.946,0.075-1.211,0.229c-0.27,0.16-0.468,0.417-0.552,0.719
  c-0.135,0.495-0.223,1.002-0.26,1.513h-0.303C153.176,41.065,153.189,40.703,153.189,40.261z"
    />
    <path
      d="M165.461,41.315c0.467,0.273,0.838,0.685,1.061,1.178c0.518,1.296,0.518,2.742,0,4.038
  c-0.223,0.492-0.594,0.901-1.061,1.173c-0.965,0.499-2.111,0.499-3.077,0c-0.466-0.272-0.835-0.682-1.059-1.173
  c-0.519-1.296-0.519-2.742,0-4.038c0.223-0.493,0.593-0.905,1.059-1.178C163.348,40.81,164.498,40.81,165.461,41.315z
  M162.674,42.008c-0.307,0.544-0.46,1.38-0.46,2.506s0.153,1.958,0.46,2.495c0.307,0.538,0.723,0.807,1.25,0.806
  c0.525-0.001,0.942-0.27,1.25-0.806c0.306-0.537,0.459-1.369,0.459-2.495s-0.153-1.962-0.459-2.506
  c-0.308-0.54-0.725-0.809-1.25-0.809C163.396,41.199,162.98,41.469,162.674,42.008z"
    />
    <path
      d="M176.533,41.155c0.389,0,0.723-0.013,1.001-0.039v0.263c-0.186,0.022-0.358,0.106-0.49,0.239
  c-0.174,0.197-0.3,0.432-0.366,0.686l-1.906,5.654h-0.226l-2.087-5.294l-1.871,5.294h-0.219l-2.158-5.827
  c-0.068-0.23-0.197-0.437-0.373-0.6c-0.112-0.087-0.25-0.136-0.392-0.139v-0.276c0.383,0.056,0.77,0.084,1.157,0.083
  c0.389,0,0.958-0.028,1.709-0.083v0.276c-0.215-0.006-0.43,0.011-0.641,0.052c-0.14,0.033-0.246,0.146-0.27,0.288
  c-0.041,0.159,0.002,0.414,0.125,0.765l1.317,3.785l1.89-5.216h0.305l2.018,5.325l1.234-3.771c0.068-0.192,0.109-0.394,0.121-0.598
  c0.012-0.184-0.074-0.36-0.228-0.463c-0.231-0.119-0.488-0.181-0.749-0.18v-0.263C175.894,41.142,176.26,41.155,176.533,41.155z"
    />
    <path
      d="M181.046,41.312c0.396-0.251,0.856-0.382,1.325-0.375c0.613,0,1.078,0.163,1.394,0.49c0.169,0.19,0.29,0.418,0.352,0.665
  c0.079,0.348,0.115,0.705,0.106,1.062v3.515c-0.01,0.195,0.023,0.39,0.1,0.57c0.062,0.137,0.177,0.243,0.318,0.294
  c0.189,0.062,0.389,0.091,0.588,0.083v0.276c-0.805-0.033-1.332-0.051-1.58-0.051s-0.734,0.018-1.471,0.051v-0.276
  c0.174,0.007,0.348-0.021,0.51-0.083c0.125-0.058,0.222-0.161,0.27-0.289c0.066-0.183,0.096-0.376,0.087-0.57v-3.804
  c0.005-0.267-0.02-0.534-0.073-0.795c-0.043-0.203-0.151-0.386-0.309-0.521c-0.197-0.146-0.439-0.216-0.684-0.199
  c-0.348-0.005-0.688,0.105-0.965,0.313c-0.293,0.224-0.519,0.522-0.654,0.865c-0.158,0.386-0.236,0.8-0.232,1.217v2.923
  c-0.008,0.194,0.021,0.388,0.087,0.57c0.051,0.128,0.149,0.232,0.274,0.29c0.164,0.062,0.338,0.091,0.514,0.083v0.276
  c-0.737-0.033-1.229-0.051-1.471-0.051s-0.775,0.018-1.58,0.051v-0.276c0.199,0.008,0.398-0.021,0.588-0.083
  c0.139-0.052,0.252-0.155,0.314-0.29c0.074-0.18,0.108-0.375,0.1-0.57v-4.118c0-0.407-0.074-0.703-0.223-0.887
  s-0.411-0.275-0.789-0.274v-0.276c0.274,0.026,0.545,0.039,0.816,0.039c0.463,0.013,0.924-0.048,1.368-0.18v1.547
  C180.295,42.031,180.617,41.606,181.046,41.312z"
    />
    <path
      d="M131.809,54.594c-0.003-0.562-0.035-1.125-0.096-1.684c0.728,0.026,1.965,0.039,3.712,0.039s2.979-0.013,3.694-0.039
  c-0.061,0.625-0.091,1.186-0.091,1.684c0,0.436,0.013,0.795,0.039,1.078h-0.302c-0.038-0.512-0.125-1.018-0.26-1.512
  c-0.084-0.303-0.282-0.561-0.552-0.721c-0.263-0.152-0.666-0.229-1.211-0.227h-0.712v7.483c-0.013,0.271,0.021,0.54,0.102,0.799
  c0.065,0.167,0.202,0.295,0.373,0.35c0.278,0.079,0.565,0.118,0.854,0.116v0.263c-0.463-0.026-1.107-0.038-1.933-0.037
  c-0.878,0-1.527,0.013-1.946,0.037v-0.263c0.289,0.002,0.577-0.037,0.855-0.116c0.17-0.055,0.307-0.183,0.373-0.35
  c0.081-0.259,0.116-0.528,0.103-0.799v-7.483h-0.714c-0.544,0-0.948,0.075-1.211,0.227c-0.27,0.16-0.467,0.418-0.552,0.721
  c-0.134,0.494-0.222,1-0.26,1.512h-0.302C131.796,55.394,131.809,55.034,131.809,54.594z"
    />
    <path
      d="M142.494,56.154c0.18-0.252,0.406-0.467,0.665-0.637c0.241-0.162,0.526-0.249,0.817-0.25
  c0.214-0.003,0.426,0.044,0.619,0.138c0.173,0.085,0.32,0.215,0.427,0.376c0.104,0.153,0.162,0.335,0.163,0.521
  c0.006,0.214-0.067,0.423-0.206,0.586c-0.134,0.16-0.336,0.25-0.545,0.242c-0.181,0.002-0.355-0.063-0.49-0.184
  c-0.137-0.125-0.212-0.305-0.206-0.49c0.009-0.301,0.181-0.574,0.449-0.712c-0.063-0.089-0.167-0.138-0.276-0.129
  c-0.354,0.003-0.691,0.146-0.938,0.398c-0.285,0.276-0.508,0.61-0.653,0.98c-0.149,0.346-0.229,0.717-0.237,1.093v2.788
  c0,0.407,0.116,0.688,0.347,0.845c0.232,0.155,0.58,0.233,1.047,0.233v0.276c-1.079-0.033-1.753-0.051-2.023-0.051
  c-0.232,0-0.75,0.018-1.556,0.051v-0.276c0.2,0.008,0.399-0.021,0.588-0.083c0.139-0.052,0.252-0.155,0.315-0.289
  c0.074-0.182,0.108-0.377,0.1-0.572v-4.117c0-0.407-0.075-0.701-0.222-0.887c-0.147-0.184-0.409-0.275-0.781-0.275v-0.276
  c0.274,0.024,0.546,0.038,0.817,0.038c0.463,0.014,0.924-0.047,1.368-0.18v1.645C142.18,56.672,142.318,56.4,142.494,56.154z"
    />
    <path
      d="M149.245,55.811c-0.256-0.201-0.577-0.301-0.902-0.279c-0.621,0-1.057,0.156-1.307,0.469
  c0.368,0.089,0.625,0.424,0.616,0.803c0.011,0.197-0.065,0.389-0.209,0.523c-0.157,0.133-0.357,0.201-0.562,0.19
  c-0.2,0.009-0.393-0.075-0.523-0.226c-0.132-0.168-0.2-0.378-0.189-0.592c0-0.342,0.206-0.653,0.616-0.938
  c0.247-0.155,0.514-0.274,0.794-0.354c0.341-0.097,0.695-0.145,1.05-0.141c0.321-0.005,0.642,0.039,0.95,0.131
  c0.24,0.068,0.46,0.193,0.644,0.363c0.197,0.199,0.339,0.446,0.414,0.717c0.086,0.369,0.124,0.748,0.112,1.128v3.655
  c-0.012,0.163,0.021,0.326,0.094,0.474c0.068,0.103,0.188,0.16,0.312,0.148c0.102,0.001,0.202-0.023,0.293-0.07
  c0.105-0.058,0.205-0.126,0.297-0.206l0.148,0.225c-0.164,0.115-0.283,0.205-0.387,0.271c-0.109,0.066-0.229,0.117-0.352,0.152
  c-0.151,0.041-0.307,0.061-0.463,0.058c-0.425,0-0.72-0.105-0.884-0.317c-0.176-0.247-0.263-0.547-0.247-0.851
  c-0.216,0.383-0.537,0.696-0.925,0.901c-0.341,0.174-0.718,0.265-1.1,0.267c-0.287,0.001-0.57-0.054-0.835-0.163
  c-0.246-0.1-0.457-0.271-0.606-0.49c-0.159-0.245-0.239-0.533-0.229-0.825c-0.009-0.304,0.077-0.602,0.245-0.854
  c0.182-0.254,0.416-0.465,0.686-0.619c0.383-0.221,0.78-0.416,1.189-0.582l0.629-0.256c0.262-0.096,0.506-0.235,0.722-0.412
  c0.135-0.111,0.216-0.275,0.222-0.449v-0.817C149.562,56.342,149.458,55.996,149.245,55.811z M149.245,58.485
  c-0.15,0.086-0.463,0.245-0.938,0.476c-0.224,0.104-0.439,0.228-0.642,0.369c-0.184,0.137-0.337,0.311-0.449,0.512
  c-0.131,0.242-0.195,0.514-0.187,0.789c-0.023,0.314,0.081,0.627,0.29,0.864c0.203,0.199,0.48,0.305,0.765,0.292
  c0.582,0,1.076-0.331,1.483-0.994v-2.584c-0.085,0.116-0.197,0.211-0.325,0.276H149.245z"
    />
    <path
      d="M157.245,55.485c0.386,0,0.718-0.013,0.997-0.038v0.271c-0.18,0.023-0.345,0.107-0.469,0.237
  c-0.175,0.2-0.307,0.435-0.387,0.688l-2.158,5.646h-0.219l-2.422-5.826c-0.076-0.229-0.207-0.434-0.382-0.599
  c-0.108-0.087-0.243-0.137-0.382-0.141v-0.276c0.453,0.035,0.84,0.053,1.156,0.051c0.674,0,1.242-0.016,1.709-0.051v0.271
  c-0.171-0.003-0.342,0.005-0.512,0.023c-0.109,0.01-0.215,0.051-0.302,0.117c-0.085,0.08-0.128,0.193-0.118,0.309
  c0.014,0.199,0.061,0.396,0.141,0.578l1.619,4.002l1.451-3.802c0.084-0.193,0.133-0.4,0.143-0.611
  c0.012-0.181-0.078-0.354-0.232-0.449c-0.229-0.118-0.481-0.18-0.738-0.18v-0.263C156.595,55.471,156.963,55.485,157.245,55.485z"
    />
    <path
      d="M163.756,61.508c-0.231,0.273-0.518,0.496-0.841,0.654c-0.351,0.17-0.737,0.256-1.128,0.25
  c-0.57,0.015-1.133-0.133-1.622-0.427c-0.458-0.289-0.821-0.706-1.044-1.201c-0.248-0.561-0.371-1.169-0.359-1.782
  c-0.016-0.68,0.109-1.355,0.365-1.985c0.215-0.522,0.574-0.972,1.038-1.294c0.466-0.308,1.013-0.466,1.57-0.453
  c0.788,0,1.396,0.238,1.821,0.716s0.64,1.202,0.64,2.175h-4.111c-0.032,0.279-0.047,0.561-0.044,0.842
  c-0.012,0.52,0.086,1.035,0.284,1.514c0.159,0.393,0.417,0.735,0.749,0.998c0.279,0.221,0.623,0.342,0.979,0.346
  c0.882,0,1.526-0.439,1.933-1.316l0.264,0.103C164.143,60.962,163.975,61.255,163.756,61.508z M162.822,56.699
  c-0.068-0.319-0.215-0.617-0.426-0.865c-0.176-0.21-0.438-0.33-0.712-0.328c-0.271,0.001-0.532,0.093-0.745,0.261
  c-0.256,0.213-0.453,0.487-0.575,0.797c-0.173,0.425-0.273,0.874-0.299,1.332h2.866C162.951,57.494,162.914,57.091,162.822,56.699z"
    />
    <path
      d="M167.386,61.575c0.062,0.134,0.175,0.237,0.313,0.289c0.189,0.062,0.389,0.091,0.588,0.083v0.276
  c-0.805-0.034-1.334-0.05-1.593-0.05c-0.258,0-0.788,0.016-1.593,0.05v-0.276c0.199,0.008,0.398-0.021,0.588-0.083
  c0.139-0.052,0.252-0.155,0.315-0.289c0.074-0.181,0.108-0.376,0.1-0.571v-7.518c0-0.406-0.075-0.7-0.222-0.883
  c-0.147-0.184-0.409-0.273-0.781-0.273v-0.276c0.274,0.026,0.545,0.04,0.816,0.04c0.463,0.014,0.926-0.049,1.368-0.188v9.096
  C167.276,61.197,167.311,61.394,167.386,61.575z"
    />
    <rect x="116.508" y="57.994" width="10.559" height="0.432" />
    <rect x="172.935" y="57.994" width="10.558" height="0.432" />
  </svg>
);

export const youtube = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
  </svg>
);

export const pinterest = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M12 0c-6.627 0-12 5.372-12 12 0 5.084 3.163 9.426 7.627 11.174-.105-.949-.2-2.405.042-3.441.218-.937 1.407-5.965 1.407-5.965s-.359-.719-.359-1.782c0-1.668.967-2.914 2.171-2.914 1.023 0 1.518.769 1.518 1.69 0 1.029-.655 2.568-.994 3.995-.283 1.194.599 2.169 1.777 2.169 2.133 0 3.772-2.249 3.772-5.495 0-2.873-2.064-4.882-5.012-4.882-3.414 0-5.418 2.561-5.418 5.207 0 1.031.397 2.138.893 2.738.098.119.112.224.083.345l-.333 1.36c-.053.22-.174.267-.402.161-1.499-.698-2.436-2.889-2.436-4.649 0-3.785 2.75-7.262 7.929-7.262 4.163 0 7.398 2.967 7.398 6.931 0 4.136-2.607 7.464-6.227 7.464-1.216 0-2.359-.631-2.75-1.378l-.748 2.853c-.271 1.043-1.002 2.35-1.492 3.146 1.124.347 2.317.535 3.554.535 6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);

export const instagram = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
  </svg>
);

export const facebook = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
  </svg>
);

export const phone = (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width="19.065px"
    height="13.998px"
    viewBox="0 0 19.065 13.998"
  >
    <g>
      <defs>
        <rect id="SVGID_1_" width="19.065" height="13.998" />
      </defs>
      <clipPath id="SVGID_2_">
        <use xlinkHref="#SVGID_1_" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#SVGID_2_)"
        fill="#FFFFFF"
        d="M9.48,13.992c-1.999,0-3.997,0.004-5.995-0.004
  c-0.288-0.001-0.586-0.024-0.863-0.099c-0.618-0.164-0.949-0.567-0.928-1.208c0.017-0.542,0.096-1.09,0.213-1.619
  C2.174,9.856,2.694,8.76,3.459,7.787c0.544-0.694,1.197-1.272,1.926-1.764C5.642,5.85,5.907,5.689,6.153,5.504
  c0.38-0.287,0.605-0.671,0.705-1.141C6.998,3.718,7.236,3.55,7.891,3.555c1.088,0.01,2.176,0.014,3.264,0.012
  c0.741-0.001,0.874,0.085,1.039,0.807c0.139,0.604,0.504,1.026,1.01,1.343c0.784,0.493,1.519,1.043,2.131,1.747
  c0.792,0.913,1.347,1.953,1.689,3.108c0.186,0.622,0.334,1.253,0.358,1.903c0.031,0.896-0.407,1.394-1.302,1.485
  c-0.235,0.024-0.475,0.035-0.712,0.035c-1.963,0.003-3.926,0.002-5.888,0.002V13.992z M6.458,9.238
  c-0.037,1.646,1.261,3.143,3.061,3.144c1.754,0,3.104-1.386,3.083-3.137c-0.021-1.668-1.428-3.05-3.088-3.033
  C7.854,6.227,6.439,7.628,6.458,9.238"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#FFFFFF"
        d="M9.117,0.001c2.839,0.003,5.15,0.365,7.395,1.117
  c0.503,0.169,0.988,0.412,1.453,0.673c0.654,0.367,0.95,0.995,1.063,1.71c0.094,0.593,0.019,1.162-0.32,1.677
  c-0.291,0.442-0.72,0.637-1.229,0.635c-0.36-0.001-0.72-0.066-1.081-0.097c-0.414-0.035-0.83-0.06-1.244-0.097
  c-1.088-0.097-1.605-0.872-1.466-1.946c0.046-0.348,0.104-0.693,0.158-1.04c0.02-0.12-0.006-0.186-0.138-0.236
  c-0.745-0.289-1.525-0.416-2.313-0.49c-1.001-0.094-2.007-0.118-3.011-0.046C7.351,1.936,6.328,2.076,5.332,2.375
  C5.19,2.417,5.16,2.48,5.183,2.614c0.066,0.405,0.126,0.811,0.181,1.217C5.495,4.811,5.106,5.39,4.127,5.54
  c-0.844,0.13-1.7,0.206-2.553,0.239C0.833,5.808,0.363,5.413,0.144,4.701C-0.058,4.05-0.055,3.4,0.202,2.763
  c0.174-0.431,0.468-0.744,0.897-0.944C2.393,1.22,3.724,0.731,5.124,0.451C6.597,0.156,8.084-0.008,9.117,0.001"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#FFFFFF"
        d="M9.541,7.424c0.99,0.013,1.879,0.923,1.862,1.906
  c-0.019,1.03-0.87,1.858-1.9,1.847c-1.03-0.011-1.853-0.859-1.841-1.899C7.673,8.27,8.543,7.412,9.541,7.424"
      />
    </g>
  </svg>
);

export const search = (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width="21.393px"
    height="21.393px"
    viewBox="0 0 21.393 21.393"
  >
    <g>
      <defs>
        <rect id="SVGID_1_" y="0" width="21.393" height="21.393" />
      </defs>
      <circle
        fill="none"
        stroke="#B3B3B3"
        strokeMiterlimit="10"
        cx="8.455"
        cy="8.455"
        r="7.955"
      />
      <line
        fill="none"
        stroke="#B3B3B3"
        strokeMiterlimit="10"
        x1="21.039"
        y1="21.039"
        x2="14.41"
        y2="14.41"
      />
    </g>
  </svg>
);

export const customer_service = (
  <svg viewBox="0 0 512 512" width="35px" height="45px">
    <path d="m.2 495.7v-14.4h29l1.1-7.2c7.1-44.4 34-52.3 35.2-52.6l120.1-32.8v-65l-2.9-2.5c-12.1-10.6-21-24.2-25.8-39.5l-1.9-6h-49.3v48.4h24.2c4 0 7.2 3.2 7.2 7.2s-3.2 7.2-7.2 7.2h-15.7c-12.6 0-22.9-10.3-22.9-22.9v-172.8c0-32.7 18-66.2 49.4-91.9 31.9-26.1 73.8-41 115-41s83.1 15 115 41c31.3 25.7 49.3 59.1 49.3 91.9v94.3c0 21.3-17.3 38.6-38.6 38.6h-25.3l-1.8 6c-4.7 15.3-13.6 28.9-25.6 39.5l-2.9 2.5v65l120 32.8c1.2.3 28.4 8.4 35.6 52.6l1.2 7.1h28.1v14.4h-510.5zm68.2-60c-2.8 1.1-17.2 8.1-23 35.3l-2.2 10.3h425.1l-2.3-10.3c-4.8-21.6-16-32.6-24.1-35.5l-.3-.1-118.8-32.5-3.5 4.7c-8.5 11.5-26.4 25.2-63.7 25.2-37.4 0-55.4-13.7-63.9-25.3l-3.5-4.7-119.3 32.8zm131.6-42.3.9 1.8c5.7 11.3 23.2 23.3 55 23.3 20.7 0 46-6.2 54.5-23.4l.9-1.8v-48.7l-14.6 14.8c-6.6 6.7-15.7 10.5-25 10.5-.4 0-.8 0-1.1 0h-.3c-4.6 0-8.7.1-12.7.1-3.4.1-6.5.1-9.5.1-16 0-24.2-1.5-33.6-10.8l-14.5-14.2zm55.7-306.3c-48 0-87.1 39.1-87.1 87.1v95.1l.2.8c3 15.4 11.1 29.5 23 39.8 11.2 9.8 17.6 19 22.8 26.5 6.9 9.9 13.4 19.2 26.5 19.2h29.2c13.1 0 19.6-9.3 26.5-19.2 5.2-7.4 11.6-16.6 22.8-26.4 12.1-10.5 20.3-25.1 23-41l.1-.7v-94c.1-48.1-39-87.2-87-87.2zm101.5 174.2h24.2c13.4 0 24.2-10.9 24.2-24.2v-55.6h-48.4zm-251.5 0h48.4v-79.9h-48.4zm150-188.6c24.4 0 48 8.8 66.4 24.8s30.5 38.1 33.9 62.2l1 7.3h48.5v-24.2c0-27.6-16.4-57.7-43.9-80.6-29.4-24.4-67-37.9-106-37.9s-76.7 13.5-106 37.9c-27.5 22.9-43.9 53-43.9 80.6v24.2h48.6l1-7.3c3.5-24.1 15.5-46.2 33.9-62.2 18.5-16 42.1-24.8 66.5-24.8z" />
  </svg>
);

export const cook = (
  <svg viewBox="0 0 475.8 475.8" width="35px" height="45px">
    <path d="m309.8 272.4h-143.8c-4.1 0-7.5 3.4-7.5 7.5v76.8c0 17.4 14.1 31.5 31.5 31.5h95.9c17.4 0 31.5-14.1 31.5-31.5v-76.8c-.1-4.1-3.4-7.5-7.6-7.5zm-24 100.8h-95.8c-9.1 0-16.5-7.4-16.5-16.5v-33.4h95.6c4.1 0 7.5-3.4 7.5-7.5s-3.4-7.5-7.5-7.5h-95.6v-20.9h128.9v20.9h-8c-4.1 0-7.5 3.4-7.5 7.5s3.4 7.5 7.5 7.5h8v33.4c-.1 9.1-7.5 16.5-16.6 16.5zm101.6 75.2-15.8-222.6c-.3-3.9-3.6-7-7.5-7-15.6 0-29.4-10.9-33.1-26v-185.3c0-2-.8-3.9-2.2-5.3s-3.3-2.2-5.3-2.2h-39.6c-4.1 0-7.5 3.4-7.5 7.5v81.5h-77v-81.5c0-4.1-3.4-7.5-7.5-7.5h-39.6c-4.1 0-7.5 3.4-7.5 7.5v185.3c-3.7 15.2-17.5 26.1-33.2 26.1-3.9 0-7.2 3-7.5 7l-15.7 222.5c-.3 3.6 2.1 6.9 5.6 7.8l8.1 2.1c44.3 11.6 90 17.4 135.8 17.4s91.5-5.9 135.8-17.4l8.1-2.1c3.5-.9 5.9-4.2 5.6-7.8zm-17.5-4.6c-43.1 11.3-87.5 17-132 17s-88.9-5.7-132-17l-2.1-.6 14.9-209.9c19.9-2.9 36.6-18 41-38.1.1-.5.2-1.1.2-1.6v-178.6h24.6v81.5c0 4.1 3.4 7.5 7.5 7.5h92c4.1 0 7.5-3.4 7.5-7.5v-81.5h24.5v178.7c0 .5.1 1.1.2 1.6 4.4 20 21.1 35.1 41 38l14.8 210z" />
  </svg>
);

export const time_zone = (
  <svg viewBox="0 0 512 512" width="35px" height="45px">
    <path d="m365 421.7c-41.5 0-79.7-16.9-107.4-47.7l-1.7-1.9-1.7 1.9c-27.4 30.3-66.5 47.7-107.3 47.7-79.7 0-144.6-64.9-144.6-144.7s64.9-144.7 144.7-144.7c40.7 0 79.8 17.4 107.3 47.7l1.7 1.9 1.7-1.9c27.7-30.7 65.8-47.7 107.4-47.7 79.8 0 144.7 64.9 144.7 144.7-.1 79.8-65 144.7-144.8 144.7zm-10.2-273.5c-34.3 2.7-65.5 18.6-87.9 44.8l-1.1 1.3 1 1.4c16.4 24 25 52.1 25 81.2s-8.6 57.2-25 81.2l-1 1.4 1.1 1.3c22.4 26.2 53.6 42.1 87.9 44.8l2.4.2v-19.3c0-4.3 3.5-7.8 7.8-7.8s7.8 3.5 7.8 7.8v19.3l2.4-.2c30.8-2.4 59.8-15.9 81.6-38s35-51.3 37-82.1l.2-2.4h-17.7c-4.3 0-7.8-3.5-7.8-7.8s3.5-7.8 7.8-7.8h17.5l-.2-2.5c-2.8-30.1-16.2-58.5-37.9-79.9s-50.3-34.5-80.5-36.9l-2.4-.2v16c0 4.3-3.5 7.8-7.8 7.8s-7.8-3.5-7.8-7.8v-16zm-207.8 230.6c4.3 0 7.8 3.5 7.8 7.8v19.3l2.4-.2c30.8-2.4 59.8-15.9 81.6-38s35-51.3 37-82.1l.2-2.4h-17.7c-4.3 0-7.8-3.5-7.8-7.8s3.5-7.8 7.8-7.8h17.5l-.2-2.5c-2.8-30.1-16.2-58.5-37.9-79.9s-50.3-34.5-80.5-36.9l-2.4-.2v16c0 4.3-3.5 7.8-7.8 7.8s-7.8-3.5-7.8-7.8v-16l-2.4.2c-30.2 2.4-58.8 15.5-80.5 36.9s-35.1 49.8-37.9 79.9l-.2 2.5h17.5c4.3 0 7.8 3.5 7.8 7.8s-3.5 7.8-7.8 7.8h-17.7l.2 2.4c2 30.9 15.2 60 37 82.1s50.8 35.6 81.6 38l2.4.2v-19.3c0-4.3 3.5-7.8 7.8-7.8z" />
    <path d="m210.7 100.6c-1 0-1.9-.2-2.8-.5-19.4-7.5-39.9-11.2-60.9-11.2-17.2 0-34.3 2.6-50.6 7.6-.8.2-1.5.4-2.3.4-3.4 0-6.4-2.2-7.4-5.5-.6-2-.4-4.1.5-5.9s2.6-3.2 4.6-3.8c17.8-5.5 36.4-8.3 55.2-8.3 22.9 0 45.3 4.1 66.5 12.2 4 1.5 6 6.1 4.5 10.1-1.2 2.9-4.1 4.9-7.3 4.9z" />
    <path d="m220.8 355.4c-2.1 0-4-.8-5.5-2.3l-53.3-53.2-1.5.9c-4.1 2.3-8.8 3.6-13.5 3.6-15.1 0-27.4-12.3-27.4-27.4 0-11.5 7.3-21.8 18.1-25.8l1.5-.5v-43.5c0-4.3 3.5-7.8 7.8-7.8s7.8 3.5 7.8 7.8v43.5l1.5.5c10.8 3.9 18.1 14.3 18.1 25.8 0 3.3-.6 6.6-1.7 9.6l-.5 1.4 54.2 54.2c3 3 3 8 0 11-1.6 1.4-3.5 2.2-5.6 2.2zm-73.8-90.2c-6.5 0-11.8 5.3-11.8 11.8s5.3 11.8 11.8 11.8 11.8-5.3 11.8-11.8-5.3-11.8-11.8-11.8z" />
    <path d="m365 304.3c-15.1 0-27.4-12.3-27.4-27.4 0-11.5 7.3-21.8 18.1-25.8l1.5-.5v-43.5c0-4.3 3.5-7.8 7.8-7.8s7.8 3.5 7.8 7.8v43.5l1.5.5c2.3.8 4.4 1.9 6.4 3.3l1.6 1.1 52.6-52.6c1.5-1.5 3.4-2.3 5.5-2.3 2.1 0 4 .8 5.5 2.3 3 3 3 8 0 11l-54.7 54.7.3 1.3c.6 2.2.9 4.6.9 6.9 0 15.3-12.3 27.5-27.4 27.5zm0-39.1c-6.5 0-11.8 5.3-11.8 11.8s5.3 11.8 11.8 11.8 11.8-5.3 11.8-11.8-5.3-11.8-11.8-11.8z" />
  </svg>
);

export const location = (
  <svg viewBox="-39 0 411 411.22243" width="35px" height="45px">
    <path d="m48.433594 288.058594 113.5625 120.953125c1.320312 1.410156 3.167968 2.210937 5.101562 2.210937s3.78125-.800781 5.105469-2.210937l113.554687-120.953125c17.378907-18.511719 30.179688-38.828125 38.042969-60.382813 8.027344-21.640625 11.414063-44.726562 9.941407-67.761719-2.765626-42.089843-20.675782-81.753906-50.417969-111.664062-30.792969-30.890625-72.613281-48.25-116.230469-48.25-43.613281 0-85.433594 17.359375-116.226562 48.25-29.742188 29.910156-47.652344 69.574219-50.421876 111.664062-1.472656 23.035157 1.914063 46.121094 9.941407 67.761719 7.867187 21.554688 20.667969 41.871094 38.046875 60.382813zm-34.019532-127.210938c2.542969-38.714844 19.015626-75.203125 46.367188-102.714844 28.167969-28.253906 66.421875-44.132812 106.3125-44.132812 39.894531 0 78.148438 15.878906 106.3125 44.132812 27.355469 27.511719 43.824219 64 46.367188 102.714844 1.339843 21.085938-1.765626 42.21875-9.125 62.027344-7.210938 19.769531-19.019532 38.476562-35.097657 55.601562l-108.457031 115.523438-108.453125-115.523438c-16.078125-17.125-27.886719-35.832031-35.101563-55.597656-7.355468-19.808594-10.464843-40.945312-9.125-62.03125zm0 0" />
    <path d="m167.09375 277.3125c63.921875 0 115.921875-53.316406 115.921875-118.847656s-52-118.851563-115.921875-118.851563c-63.917969 0-115.921875 53.3125-115.921875 118.847657 0 65.53125 52.003906 118.851562 115.921875 118.851562zm0-223.699219c56.203125 0 101.921875 47.035157 101.921875 104.847657s-45.71875 104.851562-101.921875 104.851562c-56.199219 0-101.921875-47.039062-101.921875-104.851562s45.722656-104.847657 101.921875-104.847657zm0 0" />
    <path d="m148.339844 193.882812c2.6875 2.691407 7.03125 2.738282 9.78125.113282l63.808594-60.945313c2.796874-2.671875 2.898437-7.101562.226562-9.898437-2.667969-2.796875-7.101562-2.898438-9.894531-.226563l-58.859375 56.21875-31.355469-31.355469c-2.734375-2.734374-7.167969-2.734374-9.902344 0-2.734375 2.734376-2.734375 7.167969 0 9.902344zm0 0" />
  </svg>
);

export const sun = (
  <svg viewBox="0 0 129 129" width="35px" height="45px">
    <g>
      <g>
        <path d="m64.5,92.6c15.5,0 28-12.6 28-28s-12.6-28-28-28-28,12.6-28,28 12.5,28 28,28zm0-47.9c11,0 19.9,8.9 19.9,19.9 0,11-8.9,19.9-19.9,19.9s-19.9-8.9-19.9-19.9c0-11 8.9-19.9 19.9-19.9z" />
        <path d="m68.6,23.6v-12.9c0-2.3-1.8-4.1-4.1-4.1s-4.1,1.8-4.1,4.1v12.9c0,2.3 1.8,4.1 4.1,4.1s4.1-1.8 4.1-4.1z" />
        <path d="m60.4,105.6v12.9c0,2.3 1.8,4.1 4.1,4.1s4.1-1.8 4.1-4.1v-12.9c0-2.3-1.8-4.1-4.1-4.1s-4.1,1.8-4.1,4.1z" />
        <path d="m96.4,38.5l9.1-9.1c1.6-1.6 1.6-4.2 0-5.8-1.6-1.6-4.2-1.6-5.8,0l-9.1,9.1c-1.6,1.6-1.6,4.2 0,5.8 0.8,0.8 1.8,1.2 2.9,1.2s2.1-0.4 2.9-1.2z" />
        <path d="m23.5,105.6c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l9.1-9.1c1.6-1.6 1.6-4.2 0-5.8-1.6-1.6-4.2-1.6-5.8,0l-9.1,9.1c-1.6,1.6-1.6,4.2 0,5.8z" />
        <path d="m122.5,64.6c0-2.3-1.8-4.1-4.1-4.1h-12.9c-2.3,0-4.1,1.8-4.1,4.1 0,2.3 1.8,4.1 4.1,4.1h12.9c2.2,1.42109e-14 4.1-1.8 4.1-4.1z" />
        <path d="m10.6,68.7h12.9c2.3,0 4.1-1.8 4.1-4.1 0-2.3-1.8-4.1-4.1-4.1h-12.9c-2.3,0-4.1,1.8-4.1,4.1 0,2.3 1.9,4.1 4.1,4.1z" />
        <path d="m102.6,106.8c1,0 2.1-0.4 2.9-1.2 1.6-1.6 1.6-4.2 0-5.8l-9.1-9.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l9.1,9.1c0.8,0.8 1.9,1.2 2.9,1.2z" />
        <path d="m38.4,38.5c1.6-1.6 1.6-4.2 0-5.8l-9.1-9.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l9.1,9.1c0.8,0.8 1.8,1.2 2.9,1.2s2.1-0.4 2.9-1.2z" />
      </g>
    </g>
  </svg>
);

export const shampoo = (
  <svg viewBox="0 0 480 480" width="35px" height="45px">
    <path d="m412.5 206.8-16.9-14.5c-8.9-7.6-14-18.7-14-30.4v-1.9c4.4 0 8-3.6 8-8v-32c0-13.3-10.7-24-24-24h-32c-13.3 0-24 10.7-24 24v32c0 4.4 3.6 8 8 8v1.9c0 11.7-5.1 22.8-14 30.4l-16.9 14.5c-16 13.7-25.2 33.6-25.2 54.6v162.6c0 30.9 25.1 56 56 56h64c30.9 0 56-25.1 56-56v-162.6c.2-21-9-41-25-54.6zm-86.8-86.8c0-4.4 3.6-8 8-8h32c4.4 0 8 3.6 8 8v24h-48zm96 304c0 22.1-17.9 40-40 40h-64c-22.1 0-40-17.9-40-40v-162.6c0-16.4 7.1-31.9 19.6-42.5l16.9-14.5c12.4-10.6 19.6-26.2 19.6-42.5v-1.9h32v1.9c0 16.3 7.1 31.9 19.6 42.5l16.9 14.5c12.4 10.6 19.6 26.2 19.6 42.5v162.6z" />
    <path d="m124.7 408c-30.9 0-56-25.1-56-56v-44.2c0-30.9 25.1-56 56-56s56 25.1 56 56v44.2c-.1 30.9-25.1 56-56 56zm0-140.2c-22.1 0-40 17.9-40 40v44.2c0 22.1 17.9 40 40 40s40-17.9 40-40v-44.2c-.1-22.1-18-40-40-40z" />
    <path d="m349.7 384c-26.5 0-48-21.5-48-48v-16c0-26.5 21.5-48 48-48s48 21.5 48 48v16c-.1 26.5-21.5 48-48 48zm0-96c-17.7 0-32 14.3-32 32v16c0 17.7 14.3 32 32 32s32-14.3 32-32v-16c0-17.7-14.4-32-32-32z" />
    <path d="m389.3 51.2c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-32c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8z" />
    <path d="m446.9 112.8c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm0-48c-8.8 0-16 7.2-16 16s7.2 16 16 16 16-7.2 16-16-7.2-16-16-16z" />
    <path d="m212.7-5.7h-128c-13.3 0-24 13.5-24 26.8s10.7 26.9 24 26.9h8v35.8h-8c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8v4.2c0 9.1-5.1 17.4-13.3 21.5l-2.1 1c-29.9 14.8-48.8 45.3-48.6 78.7v170.8c0 30.9 25.1 56 56 56h96c30.9 0 56-25.1 56-56v-170.8c.1-33.4-18.8-63.9-48.6-78.7l-2.1-1c-8.1-4-13.3-12.4-13.3-21.5v-4.2c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8h-8v-35.8h53.2c4.4 0 8 3.6 8 8v16c0 4.4 3.6 8 8 8h34.8c4.4 0 8-3.6 8-8v-21.7c-.1-30.9-25.1-56-56-56zm-120 105.5h64v32h-64zm78.1 88 2.1 1c24.5 12.1 39.9 37.1 39.8 64.4v170.8c0 22.1-17.9 40-40 40h-96c-22.1 0-40-17.9-40-40v-170.8c-.1-27.3 15.3-52.2 39.8-64.4l2.1-1c13.6-6.7 22.2-20.6 22.1-35.8v-4.2h48v4.2c-.1 15.2 8.5 29.1 22.1 35.8zm-30.1-104h-32v-35.8h32zm112-19.8h-18.8v-8c0-13.3-10.7-24-24-24h-125.2c-4.4 0-8-6.4-8-10.8s3.6-10.8 8-10.8h128c22.1 0 40 17.9 40 40z" />
  </svg>
);

export const translation = (
  <svg viewBox="0 0 512 512" width="35px" height="45px">
    <path d="m297.4 387.7c-1.1 0-2.1-.2-3.1-.7-3.9-1.7-5.7-6.3-4-10.2l12.9-29.4-2-.9c-2.7-1.3-4.4-4-4.4-7 0-4.3 3.5-7.7 7.7-7.7h5.6l32.8-74.9c1.2-2.8 4-4.6 7.1-4.6s5.8 1.8 7.1 4.6l32.8 74.9h5.6c4.3 0 7.7 3.5 7.7 7.7 0 3-1.7 5.7-4.4 7l-2 .9 12.9 29.4c.8 1.9.9 4 .1 5.9s-2.2 3.4-4.1 4.3c-1 .4-2 .7-3.1.7-3.1 0-5.9-1.8-7.1-4.6l-15.7-35.8h-59.6l-15.7 35.8c-1.3 2.8-4 4.6-7.1 4.6zm29.6-55.9h46l-23-52.5z" />
    <path d="m218.7 242.4c-1.3 0-2.6-.3-3.7-1-21.3-11.7-38.6-30.4-48.5-52.6l-2.1-4.6-2.1 4.6c-9.9 22.2-27.2 40.9-48.5 52.6-1.1.6-2.4 1-3.7 1-2.8 0-5.4-1.5-6.8-4-1-1.8-1.2-3.9-.6-5.9s1.9-3.6 3.7-4.6c26.3-14.4 44.7-40.8 49.2-70.5l.4-2.6h-37.7c-4.3 0-7.7-3.5-7.7-7.7s3.5-7.7 7.7-7.7h38.4v-44c0-4.3 3.5-7.7 7.7-7.7s7.7 3.5 7.7 7.7v43.9h47.7c4.3 0 7.7 3.5 7.7 7.7s-3.5 7.7-7.7 7.7h-47l.4 2.6c4.5 29.7 22.9 56 49.2 70.5 1.8 1 3.1 2.6 3.7 4.6s.3 4.1-.6 5.9c-1.3 2.5-3.9 4.1-6.8 4.1z" />
    <path d="m441 509.7c-1.8 0-3.5-.6-4.9-1.7l-63.9-52.3h-142.2c-21.9 0-39.7-17.8-39.7-39.7v-90.2l-2.6.4c-7.5 1.1-15.2 1.6-22.7 1.6-35.2 0-70-11.7-98.1-32.9l-.9-.7-43.6 11.4c-.6.2-1.3.3-2 .3-2.3 0-4.4-1-5.9-2.8-1.9-2.3-2.4-5.5-1.1-8.2l17.3-37.9-.7-1.1c-18.1-26.8-27.7-58.3-27.7-90.9 0-28.7 7.6-56.9 21.9-81.5.3-.5.7-1 1.1-1.5l.2-.2.1-.2c13.7-22.7 33-41.9 55.7-55.6l.3-.2.2-.3c.6-.6 1.2-1.2 1.9-1.6 24.6-14.1 52.7-21.6 81.3-21.6 89.7 0 162.8 73 162.8 162.8 0 4.8-.2 9.8-.7 14.8l-.2 2.5h143.1c21.9 0 39.7 17.8 39.7 39.7v194c0 21.9-17.8 39.7-39.7 39.7h-21.3v46.2c0 3-1.7 5.7-4.4 7-1 .5-2.1.7-3.3.7zm-116.9-310.2c-6.3 29.1-20.6 56-41.3 77.7-20.6 21.7-46.7 37.2-75.4 44.9l-1.7.5v93.4c0 13.4 10.9 24.3 24.3 24.3h141.2c5.4 0 6.9.1 8.9 1.9l.1.1 53.1 43.4v-37.7c0-4.3 3.5-7.7 7.7-7.7h29c13.4 0 24.3-10.9 24.3-24.3v-194c0-13.4-10.9-24.3-24.3-24.3h-145.5zm-256.3 78.5c1.8 0 3.5.6 4.8 1.7 26.5 21.3 58.4 32.6 92.4 32.6 61.9 0 117.6-39.2 138.6-97.5l1.1-3h-.6c5-15 7.6-30.6 7.6-46.6 0-81-65.9-146.9-147-146.9-81 0-147 65.9-147 146.9 0 15.9 2.5 31.6 7.6 46.6l1.1 3c4.7 13.1 11.3 25.5 19.5 36.8 1.6 2.3 1.9 5.2.8 7.8l-12.4 27.2 31.2-8.1c.8-.3 1.5-.5 2.3-.5z" />
  </svg>
);

export const checkin = (
  <svg viewBox="0 0 642 642" width="35px" height="45px">
    <path d="m326.1 618.2c-1.6 0-3.2-.5-4.5-1.5-1.9-1.4-3-3.7-3-6v-46.7h-96c-4.2 0-7.5-3.4-7.5-7.5v-83.5c0-4.2 3.4-7.5 7.5-7.5s7.5 3.4 7.5 7.5v75.9h88.5v-320.7c0-3.4 2.2-6.3 5.4-7.2l107.7-32.3 22.4-7.2h-224.1v75.4c0 4.2-3.4 7.5-7.5 7.5s-7.5-3.4-7.5-7.5v-83c0-4.2 3.4-7.5 7.5-7.5h284.8c.5 0 1 .1 1.5.2 2.2.4 4.1 1.8 5.1 3.8.6 1.1.9 2.4.9 3.7v382.3c0 3.3-2.2 6.3-5.4 7.2l-181.2 54.2c-.7.3-1.4.4-2.1.4zm7.5-384.4v366.7l166.1-49.7v-366.7z" />
    <path d="m379.5 412.3c-14.5 0-26.2-11.8-26.3-26.3 0-14.5 11.8-26.3 26.3-26.3s26.3 11.8 26.3 26.3-11.8 26.3-26.3 26.3zm0-37.4c-6.2 0-11.2 5-11.2 11.2s5 11.2 11.2 11.2 11.2-5 11.2-11.2-5-11.2-11.2-11.2z" />
    <path d="m203 423.5c-2 0-3.9-.8-5.3-2.2s-2.2-3.3-2.2-5.3.8-3.9 2.2-5.3l38.3-38.3h-142.8c-4.2 0-7.5-3.4-7.5-7.5s3.4-7.5 7.5-7.5h142.8l-38.4-38.4c-1.4-1.4-2.2-3.3-2.2-5.3s.8-3.9 2.2-5.3 3.3-2.2 5.3-2.2 3.9.8 5.3 2.2l51.2 51.2c2.9 2.9 2.9 7.7 0 10.7l-51.2 51.2c-1.3 1.3-3.2 2-5.2 2z" />
  </svg>
);

export const gym = (
  <svg viewBox="0 0 512 512" width="35px" height="45px">
    <path d="m482 206h-18.5v-45c0-22.1-17.9-40-40-40-7.7 0-14.9 2.2-21 6-4.1-17.7-20-31-39-31-22 0-40 17.9-40 40v70h-135v-70c0-22.1-18-40-40-40-18.9 0-34.9 13.2-39 31-6.1-3.8-13.3-6-21-6-22.1 0-40 17.9-40 40v45h-18.5c-16.5 0-30 13.5-30 30v40c0 16.5 13.5 30 30 30h18.5v45c0 22.1 17.9 40 40 40 7.7 0 14.9-2.2 21-6 4.1 17.7 20 31 39 31 22 0 40-17.9 40-40v-70h135v70c0 22.1 18 40 40 40 18.9 0 34.9-13.2 39-31 6.1 3.8 13.3 6 21 6 22.1 0 40-17.9 40-40v-45h18.5c16.5 0 30-13.5 30-30v-40c0-16.5-13.5-30-30-30zm-433.5 80h-18.5c-5.5 0-10-4.5-10-10v-40c0-5.5 4.5-10 10-10h18.5zm60 65c0 5.5-2.2 10.5-5.9 14.1s-8.6 5.9-14.1 5.9c-11 0-20-9-20-20v-190c0-11 9-20 20-20s20 9 20 20zm60 25c0 11-9 20-20 20-11 0-20-9-20-20v-240c0-11 9-20 20-20s20 9 20 20zm154.9-110v20h-134.9v-60h135v20m60 130c0 11-9 20-20 20s-20-9-20-20v-119.5c0 .2 0 .4-.1.6v-2.3c0 .2.1.4.1.6v-119.4c0-11 9-20 20-20 11 0 20 9 20 20zm60-25c0 11-9 20-20 20s-20-9-20-20v-190c0-11 9-20 20-20s20 9 20 20zm48.5-75c0 5.5-4.5 10-10 10h-18.5v-60h18.5c5.5 0 10 4.5 10 10z" />
  </svg>
);

export const euro = (
  <svg viewBox="0 0 512 512" width="35px" height="45px">
    <path d="m346 330.3c-4.6 0-8.3-3.7-8.3-8.3s3.7-8.3 8.3-8.3c81.4 0 147.7-66.3 147.7-147.7s-66.3-147.7-147.7-147.7-147.7 66.3-147.7 147.7c0 4.6-3.7 8.3-8.3 8.3s-8.3-3.7-8.3-8.3c0-90.6 73.7-164.3 164.3-164.3s164.3 73.7 164.3 164.3-73.7 164.3-164.3 164.3z" />
    <path d="m166 510.3c-90.6 0-164.3-73.7-164.3-164.3s73.7-164.3 164.3-164.3c18.2 0 36 3 53.1 8.8.1 0 11.7 3.8 22.8 9.8 30.2 15.8 54.4 40.1 70.1 70.3.4.9 4.9 11.4 9.6 22.4 5.8 17 8.8 34.9 8.8 53-.1 90.6-73.8 164.3-164.4 164.3zm0-312c-81.4 0-147.7 66.3-147.7 147.7s66.3 147.7 147.7 147.7 147.7-66.3 147.7-147.7-66.3-147.7-147.7-147.7z" />
    <path d="m367.9 225.3c-25.7 0-49.1-18.3-58.4-45.4l-.4-1.2h-12.4c-4.6 0-8.3-3.7-8.3-8.3s3.7-8.3 8.3-8.3h9l-.1-1.8c-.2-2.1-.2-4-.2-5.8 0-1.2 0-2.5.1-3.8l.1-1.8h-8.8c-4.6 0-8.3-3.7-8.3-8.3s3.7-8.3 8.3-8.3h11.8l.4-1.2c8.8-28.4 32.5-47.4 59-47.4 10.1 0 20.2 2.8 29.1 8.1 1.9 1.1 3.3 2.9 3.8 5.1.5 2.1.2 4.4-.9 6.3-1.5 2.5-4.2 4.1-7.1 4.1-1.5 0-3-.4-4.2-1.2-6.4-3.8-13.4-5.8-20.6-5.8-17.3 0-33 11.4-40.9 29.6l-1 2.4h44.4c4.6 0 8.3 3.7 8.3 8.3s-3.7 8.3-8.3 8.3h-48.2l-.1 1.6c-.1 1.5-.1 2.8-.1 4 0 2 .1 4 .3 6l.1 1.5h37.4c4.6 0 8.3 3.7 8.3 8.3s-3.7 8.3-8.3 8.3h-33.3l1.2 2.4c8.2 17 23.5 27.6 40 27.6 7.2 0 14.1-1.9 20.6-5.8 1.3-.8 2.8-1.2 4.2-1.2 2.9 0 5.7 1.6 7.1 4.1 2.3 3.9 1 9-2.9 11.4-8.9 5.4-18.9 8.2-29 8.2z" />
    <path d="m387.4 486.4c-.9 0-1.8-.1-2.7-.4l-28.6-9.7c-2.1-.7-3.8-2.2-4.8-4.2s-1.1-4.3-.4-6.3l9.7-28.6c1.1-3.4 4.3-5.6 7.9-5.6.9 0 1.8.1 2.7.4 4.3 1.5 6.7 6.2 5.2 10.5l-3 8.8 4.1-2.6c35.7-22.8 64.4-55.2 82.8-93.7 1.4-2.9 4.3-4.7 7.5-4.7 1.2 0 2.4.3 3.6.8 2 1 3.5 2.6 4.2 4.7s.6 4.3-.3 6.3c-20.4 42.7-52.3 78.2-92.4 102.8l-3.1 1.9 10.3 3.5c4.3 1.5 6.7 6.2 5.2 10.5-1.2 3.3-4.3 5.6-7.9 5.6z" />
    <path d="m44.2 154.4c-1.3 0-2.6-.3-3.8-.9-2-1-3.4-2.8-4.1-4.9s-.5-4.4.6-6.3c20.5-39.1 51.1-71.9 88.7-94.9l3.1-1.9-10.7-3.7c-2.1-.7-3.8-2.2-4.8-4.2s-1.1-4.2-.4-6.3c1.1-3.4 4.3-5.6 7.9-5.6.9 0 1.8.1 2.7.4l28.6 9.7c4.3 1.5 6.7 6.2 5.2 10.5l-9.7 28.6c-1.1 3.4-4.3 5.6-7.9 5.6-.9 0-1.8-.1-2.7-.4-4.3-1.5-6.7-6.2-5.2-10.5l2.9-8.4-4.1 2.6c-33.2 21.2-60.5 51-78.9 86.1-1.4 2.8-4.2 4.5-7.4 4.5z" />
    <path d="m166 434c-4.6 0-8.3-3.7-8.3-8.3v-7.3l-1.4-.3c-20.1-4.1-34.6-21-34.6-40 0-4.6 3.7-8.3 8.3-8.3s8.3 3.7 8.3 8.3c0 9.9 6.7 18.7 17.1 22.5l2.3.8v-48.4l-1.4-.3c-20.1-4.1-34.6-21-34.6-40s14.6-35.8 34.6-40l1.4-.3v-6c0-4.6 3.7-8.3 8.3-8.3s8.3 3.7 8.3 8.3v6l1.4.3c20.1 4.1 34.6 21 34.6 40 0 4.6-3.7 8.3-8.3 8.3s-8.3-3.7-8.3-8.3c0-9.9-6.7-18.7-17.1-22.5l-2.3-.8v48.6l1.4.3c20.1 4.1 34.6 21 34.6 40s-14.6 35.8-34.6 40l-1.4.3v7.3c0 4.4-3.7 8.1-8.3 8.1zm8.3-32.4 2.3-.8c10.4-3.8 17.1-12.6 17.1-22.5s-6.7-18.7-17.1-22.5l-2.3-.8zm-18.9-111.3c-10.4 3.8-17.1 12.6-17.1 22.5s6.7 18.7 17.1 22.5l2.3.8v-46.7z" />
  </svg>
);

export const drink = (
  <svg viewBox="0 0 361 450.4" width="35px" height="45px">
    <path d="m354.2 332.8h-104.8l24.4-228.8c.4-4-2.4-7.2-6.4-7.6-.4 0-.4 0-.8 0h-78.8l4.2-28.3c2-12.8 12.8-22 25.6-22h98.4c4 0 7.2-3.2 7.2-7.2s-3.2-7.2-7.2-7.2h-98.4c-19.6 0-36.4 14.8-39.2 34.4l-4.6 29.9h-158.8c-4 0-6.8 3.2-6.8 7.2v.8l7.6 72.4 28.4 267.6c.4 3.6 3.2 6.4 6.8 6.4h179.6c1.2 0 2.8-.4 4-1.2 60.4 8.8 116.4-32.4 125.2-92.8.8-5.2 1.2-10.8 1.2-16.4 0-4-3.2-7.2-6.8-7.2zm-141.2 54.4 30.8-30.8v43.6c-11.2-1.2-22-5.6-30.8-12.8zm-22.8-40.4h43.2l-30.8 30.4c-6.4-8.4-10.8-19.2-12.4-30.4zm67.6 10 30.8 30.8c-8.8 7.2-19.6 11.6-30.8 12.8zm40.4 20.8-30.8-30.4h43.2c-.8 10.8-5.2 21.6-12.4 30.4zm-150.8-44.8c-4 0-7.2 3.2-7.2 7.2 0 18.8 4.8 37.6 14.4 54h-101.6l-22-206.8c16.4 6.8 81.6 15.2 129.2 6l-11.2 79.2c-.4 4 2 7.2 6 8h.8c3.6 0 6.4-2.4 6.8-6l11.6-84c28-6 52.8-2.4 74.4 10l-14 132.4zm111.2-222.4-8 74.8c-22.8-11.6-48.8-14.8-74-9.6l9.2-65.2zm-86.8 0-9.6 68.4c-56.8 12.4-123.6-2.8-132.8-7.6l-6.4-60.8zm-114.4 326-2.8-28.4h109.6c9.2 11.6 20.4 21.2 32.8 28.4zm193.2 0c-5.6 0-11.6-.4-17.2-1.6-.8 0-1.6-.4-2.8-.4-42-9.2-73.2-44.4-76-87.2h21.6c1.6 17.2 9.2 32.8 20.8 45.2l.4.4s.4 0 .4.4c29.2 28.8 76 28.8 105.2 0l.4-.4.4-.4c12-12.4 19.2-28 20.8-45.2h21.6c-3.2 50-45.2 88.8-95.6 89.2z" />
  </svg>
);

export const star = (
  <svg version="1.1" viewBox="0 0 320 320" width="35px" height="45px">
    <g>
      <path
        stroke="#000000"
        d="M323.6,174c-0.8-1.6-2.8-2.398-4.399-2.8c-9.2,0-88.4-3.2-88.4-95.2c0-2.8-2.399-5.2-5.2-5.2
   c-2.8,0-5.199,2.4-5.199,5.2c0,92-79.2,95.2-88.001,95.2c-1.199,0-2.4,0.401-3.199,1.2c-0.801,0.399-1.201,1.201-1.601,1.6
   c-0.8,1.602-0.8,3.602,0,5.2c0.8,1.601,2.8,2.8,4.8,2.8h0.4c9.2,0,88,3.2,88,95.2c0,2.8,2.4,5.2,5.2,5.2s5.2-2.4,5.2-5.2
   c0-91.599,78.8-95.2,88-95.2c2,0,3.6-1.199,4.8-2.8C324.8,177.601,324.8,175.601,323.6,174 M225.6,238
   c-11.6-38-40-54.398-62.399-61.6c22.399-7.2,50.8-23.6,62.399-61.6c11.601,38,40,54.4,62.4,61.6C265.6,183.601,237.2,200,225.6,238
   "
      />
      <path
        fill="none"
        stroke="#000000"
        strokeWidth="4"
        strokeMiterlimit="10"
        d="M323.6,174
   c-0.8-1.6-2.8-2.398-4.399-2.8c-9.2,0-88.4-3.2-88.4-95.2c0-2.8-2.399-5.2-5.2-5.2c-2.8,0-5.199,2.4-5.199,5.2
   c0,92-79.2,95.2-88.001,95.2c-1.199,0-2.4,0.401-3.199,1.2c-0.801,0.399-1.201,1.201-1.601,1.6c-0.8,1.602-0.8,3.602,0,5.2
   c0.8,1.601,2.8,2.8,4.8,2.8h0.4c9.2,0,88,3.2,88,95.2c0,2.8,2.4,5.2,5.2,5.2s5.2-2.4,5.2-5.2c0-91.599,78.8-95.2,88-95.2
   c2,0,3.6-1.199,4.8-2.8C324.8,177.601,324.8,175.601,323.6,174z M225.6,238c-11.6-38-40-54.398-62.399-61.6
   c22.399-7.2,50.8-23.6,62.399-61.6c11.601,38,40,54.4,62.4,61.6C265.6,183.601,237.2,200,225.6,238z"
      />
      <path
        d="M159.6,70c2.8,0,5.2-2.4,5.2-5.6c0-2.8-2.4-5.2-5.2-5.2c-4.8,0-48-1.6-48-52
   c0-2.8-2.4-5.2-5.2-5.2c-2.8,0-5.2,2.4-5.2,5.2c0,50.4-43.2,52-48,52h-0.4C50,59.6,48,61.6,48,64.4s2.4,5.2,5.2,5.2
   c5.2,0,48,1.6,48,52c0,2.8,2.4,5.2,5.2,5.2c2.4,0.4,4.8-2,4.8-4.8c0-50.4,43.2-52,48-52H159.6z M106,92.8
   c-6.4-15.2-18-23.6-28.8-28.4c11.6-4.8,22.4-14,28.8-28.4c6.4,15.2,18,23.6,28.8,28.4C123.2,69.2,112.4,78.4,106,92.8"
      />
      <path
        fill="none"
        stroke="#000000"
        strokeWidth="4"
        strokeMiterlimit="10"
        d="M159.6,70
   c2.8,0,5.2-2.4,5.2-5.6c0-2.8-2.4-5.2-5.2-5.2c-4.8,0-48-1.6-48-52c0-2.8-2.4-5.2-5.2-5.2c-2.8,0-5.2,2.4-5.2,5.2
   c0,50.4-43.2,52-48,52h-0.4C50,59.6,48,61.6,48,64.4s2.4,5.2,5.2,5.2c5.2,0,48,1.6,48,52c0,2.8,2.4,5.2,5.2,5.2
   c2.4,0.4,4.8-2,4.8-4.8c0-50.4,43.2-52,48-52H159.6z M106,92.8c-6.4-15.2-18-23.6-28.8-28.4c11.6-4.8,22.4-14,28.8-28.4
   c6.4,15.2,18,23.6,28.8,28.4C123.2,69.2,112.4,78.4,106,92.8z"
      />
      <path
        d="M113.6,239.6c-4.8,0-48-1.601-48-52c0-2.8-2.4-5.2-5.2-5.2c-2.8,0-5.2,2.4-5.2,5.2
   c0,50.399-43.2,52-48,52H6.8C4,240,2,242,2,244.8C2,247.6,4.4,250,7.2,250c5.2,0,48,1.601,48,52c0,2.801,2.4,5.2,5.2,5.2
   c2.4,0.8,4.8-1.6,4.8-4.8c0-50.4,43.2-52,48-52h0.4c2.8,0,5.2-2.4,5.2-5.6C118.8,242,116.4,239.6,113.6,239.6 M60,273.6
   C53.2,258.4,42,250,31.2,245.2c11.6-4.8,22.4-14,28.8-28.399C66.4,232,78,240.4,88.8,245.2C77.2,250,66.4,259.2,60,273.6"
      />
      <path
        fill="none"
        stroke="#000000"
        strokeWidth="4"
        strokeMiterlimit="10"
        d="M113.6,239.6
   c-4.8,0-48-1.601-48-52c0-2.8-2.4-5.2-5.2-5.2c-2.8,0-5.2,2.4-5.2,5.2c0,50.399-43.2,52-48,52H6.8C4,240,2,242,2,244.8
   C2,247.6,4.4,250,7.2,250c5.2,0,48,1.601,48,52c0,2.801,2.4,5.2,5.2,5.2c2.4,0.8,4.8-1.6,4.8-4.8c0-50.4,43.2-52,48-52h0.4
   c2.8,0,5.2-2.4,5.2-5.6C118.8,242,116.4,239.6,113.6,239.6z M60,273.6C53.2,258.4,42,250,31.2,245.2c11.6-4.8,22.4-14,28.8-28.399
   C66.4,232,78,240.4,88.8,245.2C77.2,250,66.4,259.2,60,273.6z"
      />
    </g>
  </svg>
);

export const wifi = (
  <svg viewBox="0 0 33 33" width="35px" height="45px">
    <g stroke="#000" strokeMiterlimit="10" strokeWidth=".4">
      <path d="m16.5 29.5c-1.6 0-2.9-1.3-2.9-2.9s1.3-2.9 2.9-2.9 2.9 1.3 2.9 2.9-1.3 2.9-2.9 2.9zm0-4.8c-1 0-1.9.8-1.9 1.9s.8 1.9 1.9 1.9 1.9-.8 1.9-1.9-.9-1.9-1.9-1.9z" />
      <path d="m23.1 20.5c-.1 0-.3 0-.4-.1-1.7-1.7-3.9-2.6-6.3-2.6s-4.6.9-6.3 2.6c-.2.2-.5.2-.7 0s-.2-.5 0-.7c1.9-1.9 4.3-2.9 7-2.9 2.6 0 5.1 1 7 2.9.2.2.2.5 0 .7 0 .1-.2.1-.3.1z" />
      <path d="m27.8 15.8c-.1 0-.3 0-.4-.1-6-6-15.9-6-21.9 0-.2.2-.5.2-.7 0s-.2-.5 0-.7c6.4-6.4 16.9-6.4 23.3 0 .2.2.2.5 0 .7 0 .1-.2.1-.3.1z" />
      <path d="m32.5 11.1c-.1 0-.3 0-.4-.1-8.6-8.7-22.6-8.7-31.2 0-.2.2-.5.2-.7 0s-.2-.5 0-.7c9-9 23.7-9 32.7 0 .2.2.2.5 0 .7-.1.1-.3.1-.4.1z" />
    </g>
  </svg>
);

export const television = (
  <svg viewBox="0 0 512 512" width="35px" height="45px">
    <path
      d="m415.7 463.5h-77.5v-59.5h138.8c17.1 0 31-13.9 31-31v-264.8c0-17.1-13.9-31-31-31h-192.2l48.4-61.4c2.5-3.2 2-7.8-1.2-10.4-3.2-2.5-7.8-2-10.4 1.2l-55.6 70.5h-20l-55.6-70.5c-2.5-3.2-7.2-3.8-10.4-1.2-3.2 2.5-3.8 7.2-1.2 10.4l48.4 61.4-192.2-.1c-17.1 0-31 13.9-31 31v76.3c6.5 7.4 14.8 2.9 14.8-3.5v-72.4c0-4.1 1.4-8.1 4.1-11.1 3.1-3.5 7.5-5.5 12.2-5.5h442c9 0 16.2 7.3 16.2 16.2v264.8c0 4.3-1.7 8.4-4.8 11.5s-7.1 4.8-11.5 4.8h-442c-9 0-16.2-7.3-16.2-16.2v-212.1c0-4.1-3.3-7.4-7.4-7.4s-7.4 3.3-7.4 7.4v212.1c0 17.1 13.9 31 31 31h138.8v59.5h-81.7l-.5 14.8h323.9zm-227.1 0v-59.5h134.9v59.5z"
      stroke="#000"
      strokeMiterlimit="10"
      strokeWidth="5"
    />
    <path d="m223.7 168.8c-5.6-3.6-12.8-3.9-18.7-.7s-9.6 9.4-9.6 16.1v109.6c0 6.7 3.7 12.9 9.6 16.1s13.1 2.9 18.7-.7l84.6-54.8c5.2-3.4 8.4-9.2 8.4-15.4s-3.1-12-8.4-15.4zm-7.4 120.2v-100l77.3 50z" />
  </svg>
);

export const currency = (
  <svg viewBox="-10 -2 35 35" width="35px" height="35px">
    <g>
      <path
        fill="#1D1D1B"
        d="M8.816,3.169c3.716,0.547,5.501,3.315,5.61,6.23h-2.623
  C11.513,6.557,9.29,5.355,7.65,5.355c-2.076,0-4.08,1.64-4.08,4.189c0,3.061,2.441,3.826,5.574,4.81
  c2.768,0.947,5.902,2.841,5.902,6.885c0,3.206-2.368,6.412-6.23,7.032v3.533H6.449v-3.533C2.586,27.87,0.146,24.809,0,21.275h2.804
  c0.22,3.243,2.624,4.773,4.846,4.773c2.587,0,4.591-2.113,4.591-4.59c0-3.571-3.133-4.519-6.157-5.429
  c-2.077-0.619-5.319-2.003-5.319-6.266c0-3.679,2.55-6.047,5.683-6.594V0h2.368V3.169z"
      />
    </g>
  </svg>
);

export const map = (
  <svg viewBox="0 0 26 30" width="35px" height="35px">
    <g>
      <path
        fill="#1D1D1B"
        d="M25.682,30.972l-4.922-8.915c-0.102-0.215-0.277-0.385-0.494-0.476
  l-1.917-0.853l3.566-5.981c0.736-1.436,1.109-2.989,1.109-4.614c0-2.733-1.072-5.296-3.02-7.215C18.097,1.037,15.571,0,12.891,0
  l-0.145,0.002C10.09,0.038,7.589,1.105,5.703,3.006c-1.885,1.901-2.931,4.411-2.947,7.067c-0.01,1.625,0.382,3.258,1.139,4.73
  l3.535,5.925l-1.928,0.858c-0.209,0.093-0.383,0.26-0.47,0.448l-4.94,8.948c-0.172,0.364-0.099,0.802,0.183,1.09
  c0.274,0.28,0.728,0.368,1.075,0.21l5.578-2.329l5.568,2.325c0.246,0.111,0.539,0.112,0.779,0.004l5.577-2.329l5.568,2.326
  c0.125,0.056,0.257,0.084,0.394,0.084c0.259,0,0.511-0.107,0.691-0.291C25.787,31.784,25.858,31.341,25.682,30.972 M20.21,13.834
  l-6.795,11.388c-0.154,0.26-0.395,0.299-0.525,0.299c-0.13,0-0.37-0.039-0.524-0.299l-6.79-11.377
  c-0.595-1.17-0.893-2.435-0.885-3.76c0.026-4.433,3.652-8.088,8.082-8.15h0.115c2.171,0,4.218,0.838,5.761,2.362
  c1.574,1.553,2.442,3.626,2.442,5.837C21.09,11.439,20.794,12.683,20.21,13.834 M8.428,22.401l2.274,3.812
  c0.465,0.778,1.283,1.242,2.187,1.242c0.906,0,1.725-0.464,2.188-1.242l2.274-3.812l1.827,0.813l3.545,6.23l-3.478-1.428
  c-0.247-0.111-0.54-0.113-0.779-0.004l-5.577,2.328l-5.567-2.324c-0.124-0.057-0.257-0.084-0.394-0.084
  c-0.135,0-0.269,0.027-0.383,0.079l-3.489,1.433l3.544-6.23L8.428,22.401z"
      />
      <path
        fill="#1D1D1B"
        d="M12.89,6.556c-1.972,0-3.577,1.605-3.577,3.578
  c0,1.973,1.605,3.578,3.578,3.578c0.954,0,1.852-0.372,2.529-1.049c0.676-0.676,1.048-1.574,1.048-2.529
  C16.468,8.161,14.863,6.556,12.89,6.556 M14.941,10.134c0,1.131-0.92,2.052-2.052,2.052c-0.547,0-1.062-0.214-1.449-0.602
  c-0.388-0.388-0.602-0.903-0.602-1.45c0-1.131,0.92-2.052,2.051-2.052C14.021,8.082,14.941,9.003,14.941,10.134"
      />
    </g>
  </svg>
);

export const sliders = (
  <svg viewBox="0 0 35 35" width="35px" height="35px">
    <g>
      <line
        fill="none"
        stroke="#1D1D1B"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        x1="28.743"
        y1="0"
        x2="28.743"
        y2="31.912"
      />

      <line
        fill="none"
        stroke="#1D1D1B"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        x1="16.704"
        y1="0"
        x2="16.704"
        y2="31.912"
      />

      <line
        fill="none"
        stroke="#1D1D1B"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        x1="4.666"
        y1="0"
        x2="4.666"
        y2="31.912"
      />
      <path
        fill="#FFFFFF"
        d="M28.743,13.621c-2.024,0-3.666-1.641-3.666-3.666
  c0-2.024,1.642-3.665,3.666-3.665c2.023,0,3.666,1.641,3.666,3.665C32.409,11.98,30.767,13.621,28.743,13.621"
      />

      <circle
        fill="none"
        stroke="#1D1D1B"
        strokeWidth="2"
        strokeMiterlimit="10"
        cx="28.743"
        cy="9.956"
        r="3.666"
      />
      <path
        fill="#FFFFFF"
        d="M4.666,13.621C2.642,13.621,1,11.98,1,9.955C1,7.931,2.642,6.29,4.666,6.29
  s3.666,1.641,3.666,3.665C8.332,11.98,6.689,13.621,4.666,13.621"
      />

      <circle
        fill="none"
        stroke="#1D1D1B"
        strokeWidth="2"
        strokeMiterlimit="10"
        cx="4.666"
        cy="9.956"
        r="3.666"
      />
      <path
        fill="#FFFFFF"
        d="M16.704,25.622c-2.024,0-3.666-1.642-3.666-3.666s1.642-3.665,3.666-3.665
  c2.024,0,3.666,1.641,3.666,3.665S18.729,25.622,16.704,25.622"
      />

      <circle
        fill="none"
        stroke="#1D1D1B"
        strokeWidth="2"
        strokeMiterlimit="10"
        cx="16.704"
        cy="21.956"
        r="3.666"
      />
    </g>
  </svg>
);

export const bed = (
  <svg viewBox="0 0 35 35" width="35px" height="35px">
    <path
      d="M34.873,18.261l-2.676-8.419V0.856C32.197,0.444,31.87,0,31.341,0H3.628
  C3.099,0,2.771,0.444,2.771,0.856v8.985l-2.666,8.394C0.043,18.396,0,18.627,0,18.734v11.419c0,0.472,0.384,0.856,0.856,0.856
  c0.472,0,0.857-0.385,0.857-0.856v-2.707h31.543v2.707c0,0.472,0.384,0.856,0.855,0.856c0.473,0,0.857-0.385,0.857-0.856V18.734
  C34.969,18.627,34.925,18.396,34.873,18.261 M1.713,19.528h31.543c-0.001,3.903-0.001,5.525,0,6.206H1.713
  C1.714,25.054,1.714,23.432,1.713,19.528 M6.795,6.464h7.522c1.296,0,2.311,1.015,2.311,2.311v0.331H4.484V8.775
  C4.484,7.479,5.499,6.464,6.795,6.464 M30.484,8.775v0.331H18.341V8.775c0-1.296,1.015-2.311,2.31-2.311h7.523
  C29.47,6.464,30.484,7.479,30.484,8.775 M30.484,5.487c-0.67-0.475-1.473-0.736-2.311-0.736H20.65
  c-1.254,0-2.411,0.579-3.167,1.549c-0.753-0.97-1.911-1.549-3.167-1.549H6.795c-0.839,0-1.641,0.261-2.311,0.736V1.713h26V5.487z
   M30.72,10.818l2.23,6.998H2.018l2.23-6.998H30.72z"
    />
  </svg>
);

export const list = (
  <svg viewBox="0 0 40 40" width="35px" height="35px">
    <g>
      <line
        fill="none"
        stroke="#000000"
        strokeWidth="2"
        strokeMiterlimit="10"
        x1="15.074"
        y1="5.227"
        x2="37.891"
        y2="5.227"
      />

      <line
        fill="none"
        stroke="#000000"
        strokeWidth="2"
        strokeMiterlimit="10"
        x1="15.074"
        y1="19.108"
        x2="37.891"
        y2="19.108"
      />

      <line
        fill="none"
        stroke="#000000"
        strokeWidth="2"
        strokeMiterlimit="10"
        x1="15.074"
        y1="32.989"
        x2="37.891"
        y2="32.989"
      />
      <path
        fill="#FFFFFF"
        d="M9.454,5.227c0,2.334-1.893,4.227-4.227,4.227S1,7.561,1,5.227S2.893,1,5.227,1
  S9.454,2.893,9.454,5.227"
      />

      <circle
        fill="none"
        stroke="#000000"
        strokeWidth="2"
        strokeMiterlimit="10"
        cx="5.227"
        cy="5.227"
        r="4.227"
      />
      <path
        fill="#FFFFFF"
        d="M9.454,32.989c0,2.334-1.893,4.228-4.227,4.228S1,35.323,1,32.989
  s1.893-4.227,4.227-4.227S9.454,30.655,9.454,32.989"
      />

      <circle
        fill="none"
        stroke="#000000"
        strokeWidth="2"
        strokeMiterlimit="10"
        cx="5.227"
        cy="32.989"
        r="4.227"
      />
      <path
        fill="#FFFFFF"
        d="M9.454,19.108c0,2.334-1.893,4.227-4.227,4.227S1,21.442,1,19.108
  c0-2.334,1.893-4.227,4.227-4.227S9.454,16.774,9.454,19.108"
      />

      <circle
        fill="none"
        stroke="#000000"
        strokeWidth="2"
        strokeMiterlimit="10"
        cx="5.227"
        cy="19.108"
        r="4.227"
      />
    </g>
  </svg>
);

export const calendar = (
  <svg viewBox="0 0 512 512" width="35px" height="35px">
    <path
      fill="#fff"
      strokeWidth="10px"
      stroke="#000"
      className="st0"
      d="M468.6,495.9H41.4c-14.6,0-26.4-11.8-26.4-26.4V101.3c0-14.6,11.8-26.4,26.4-26.4h427.2
  c14.6,0,26.4,11.8,26.4,26.4v368.2C495,484.1,483.2,495.9,468.6,495.9z"
    />
    <line
      className="st0"
      x1="15"
      y1="181.9"
      x2="495"
      y2="181.9"
      fill="#fff"
      strokeWidth="10px"
      stroke="#000"
    />
    <g>
      <line
        fill="#fff"
        strokeWidth="10px"
        stroke="#000"
        className="st0"
        x1="111.3"
        y1="29.9"
        x2="111.3"
        y2="119.9"
      />
      <line
        fill="#fff"
        strokeWidth="10px"
        stroke="#000"
        className="st0"
        x1="207.1"
        y1="29.9"
        x2="207.1"
        y2="119.9"
      />
      <line
        fill="#fff"
        strokeWidth="10px"
        stroke="#000"
        className="st0"
        x1="302.9"
        y1="29.9"
        x2="302.9"
        y2="119.9"
      />
      <line
        fill="#fff"
        strokeWidth="10px"
        stroke="#000"
        className="st0"
        x1="398.7"
        y1="29.9"
        x2="398.7"
        y2="119.9"
      />
    </g>
    <rect
      fill="#fff"
      strokeWidth="10px"
      stroke="#000"
      x="87.7"
      y="241.7"
      className="st0"
      width="60.8"
      height="60.8"
    />
    <rect
      fill="#fff"
      strokeWidth="10px"
      stroke="#000"
      x="224.9"
      y="241.7"
      className="st0"
      width="60.8"
      height="60.8"
    />
    <rect
      fill="#fff"
      strokeWidth="10px"
      stroke="#000"
      x="362.2"
      y="241.7"
      className="st0"
      width="60.8"
      height="60.8"
    />
    <rect
      fill="#fff"
      strokeWidth="10px"
      stroke="#000"
      x="87.7"
      y="367.1"
      className="st0"
      width="60.8"
      height="60.8"
    />
    <rect
      fill="#fff"
      strokeWidth="10px"
      stroke="#000"
      x="224.9"
      y="367.1"
      className="st0"
      width="60.8"
      height="60.8"
    />
    <rect
      fill="#fff"
      strokeWidth="10px"
      stroke="#000"
      x="362.2"
      y="367.1"
      className="st0"
      width="60.8"
      height="60.8"
    />
  </svg>
);

export const person = (
  <svg viewBox="0 0 30 30" width="35px" height="35px">
    <g>
      <path
        d="M12.223,0.25c-3.504,0-6.36,2.884-6.36,6.422s2.856,6.423,6.36,6.423s6.36-2.885,6.36-6.423
  S15.727,0.25,12.223,0.25 M12.223,1.761c2.695,0,4.864,2.19,4.864,4.911c0,2.721-2.169,4.911-4.864,4.911
  c-2.695,0-4.864-2.19-4.864-4.911C7.359,3.951,9.528,1.761,12.223,1.761 M12.223,14.606c-3.247,0-6.187,0.886-8.36,2.36
  C1.69,18.442,0.25,20.56,0.25,22.917v7.557c0,0.417,0.335,0.755,0.748,0.755h22.451c0.412,0,0.747-0.338,0.747-0.755v-7.557
  c0-2.357-1.439-4.475-3.612-5.95C18.41,15.492,15.47,14.606,12.223,14.606 M12.223,16.117c2.972,0,5.646,0.834,7.53,2.113
  c1.884,1.278,2.946,2.951,2.946,4.687v6.8H1.747v-6.8c0-1.735,1.062-3.408,2.946-4.687C6.577,16.951,9.251,16.117,12.223,16.117"
      />
      <path
        fill="none"
        stroke="#000000"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M12.223,0.25
  c-3.504,0-6.36,2.884-6.36,6.422s2.856,6.423,6.36,6.423s6.36-2.885,6.36-6.423S15.727,0.25,12.223,0.25z M12.223,1.761
  c2.695,0,4.864,2.19,4.864,4.911c0,2.721-2.169,4.911-4.864,4.911c-2.695,0-4.864-2.19-4.864-4.911
  C7.359,3.951,9.528,1.761,12.223,1.761z M12.223,14.606c-3.247,0-6.187,0.886-8.36,2.36C1.69,18.442,0.25,20.56,0.25,22.917v7.557
  c0,0.417,0.335,0.755,0.748,0.755h22.451c0.412,0,0.747-0.338,0.747-0.755v-7.557c0-2.357-1.439-4.475-3.612-5.95
  C18.41,15.492,15.47,14.606,12.223,14.606z M12.223,16.117c2.972,0,5.646,0.834,7.53,2.113c1.884,1.278,2.946,2.951,2.946,4.687
  v6.8H1.747v-6.8c0-1.735,1.062-3.408,2.946-4.687C6.577,16.951,9.251,16.117,12.223,16.117z"
      />
    </g>
  </svg>
);

export const dining_marker = (
  <svg
    x="0px"
    y="0px"
    width="43.525px"
    height="65.734px"
    viewBox="0 0 43.525 65.734"
    enableBackground="new 0 0 43.525 65.734"
  >
    <g>
      <defs>
        <rect id="SVGID_1_" y="0" width="43.525" height="65.735" />
      </defs>
      <clipPath id="SVGID_2_">
        <use xlinkHref="#SVGID_1_" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#SVGID_2_)"
        fill="#252525"
        d="M42.525,21.762C42.525,10.295,33.229,1,21.763,1C10.296,1,1,10.295,1,21.762
  c0,3.108,0.702,6.046,1.926,8.694l-0.035,0.035L21.763,63.71l18.87-33.219L40.6,30.457C41.823,27.809,42.525,24.871,42.525,21.762"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="none"
        stroke="#252525"
        strokeWidth="2"
        strokeMiterlimit="10"
        d="M42.525,21.762
  C42.525,10.295,33.229,1,21.763,1C10.296,1,1,10.295,1,21.762c0,3.108,0.702,6.046,1.926,8.694l-0.035,0.035L21.763,63.71
  l18.87-33.219L40.6,30.457C41.823,27.809,42.525,24.871,42.525,21.762z"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#FFFFFF"
        d="M27.2,37.072c0.567,0,1.711-0.46,1.711-1.027l-0.684-11.293
  c0.857-1.922,3.375-4.021,3.07-9.204c-0.201-3.397-2.596-5.911-3.348-6.528c-0.076-0.081-0.164-0.148-0.263-0.202
  c-0.038-0.026-0.064-0.043-0.064-0.043v0.009c-0.129-0.06-0.271-0.094-0.423-0.094c-0.567,0-1.027,0.46-1.027,1.027l-0.685,26.33
  C25.488,36.613,26.633,37.072,27.2,37.072"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#FFFFFF"
        d="M13.21,20.005l1.772,0.885c0.202,0.101,0.425,0.176,0.657,0.23l-0.684,15.403
  c0,0.475,1.144,0.86,1.711,0.86c0.567,0,1.711-0.386,1.711-0.86l-0.684-15.481c0.241-0.077,0.473-0.169,0.676-0.293l1.313-0.8
  c0.947-0.578,1.662-1.849,1.662-2.958v-6.902c0-0.566-0.46-1.026-1.027-1.026c-0.566,0-1.026,0.46-1.026,1.026v6.217h-0.571v-6.217
  c0-0.566-0.46-1.026-1.026-1.026s-1.027,0.46-1.027,1.026v6.217h-0.573v-6.217c0-0.566-0.46-1.026-1.026-1.026
  s-1.026,0.46-1.026,1.026v6.217h-0.569v-5.994c0-0.567-0.46-1.027-1.027-1.027s-1.027,0.46-1.027,1.027v6.794
  C11.418,18.248,12.189,19.496,13.21,20.005"
      />
    </g>
  </svg>
);

export const experience_marker = (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="43.525px"
    height="65.734px"
    viewBox="0 0 43.525 65.734"
    enableBackground="new 0 0 43.525 65.734"
  >
    <g>
      <defs>
        <rect id="SVGID_1_" y="0" width="43.525" height="65.735" />
      </defs>
      <clipPath id="SVGID_2_">
        <use xlinkHref="#SVGID_1_" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#SVGID_2_)"
        fill="#252525"
        d="M42.525,21.762C42.525,10.295,33.229,1,21.763,1C10.295,1,1,10.295,1,21.762
  c0,3.108,0.702,6.046,1.926,8.694l-0.035,0.035L21.763,63.71l18.871-33.219l-0.035-0.035
  C41.823,27.809,42.525,24.871,42.525,21.762"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="none"
        stroke="#252525"
        strokeWidth="2"
        strokeMiterlimit="10"
        d="M42.525,21.762
  C42.525,10.295,33.229,1,21.763,1C10.295,1,1,10.295,1,21.762c0,3.108,0.702,6.046,1.926,8.694l-0.035,0.035L21.763,63.71
  l18.871-33.219l-0.035-0.035C41.823,27.809,42.525,24.871,42.525,21.762z"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#FFFFFF"
        d="M19.171,30.386l-3.385,7.203c-0.398,0.847-1.408,1.211-2.256,0.813
  c-0.827-0.391-1.192-1.366-0.837-2.201l5.162-12.003c0.398-0.932,1.476-1.364,2.409-0.966c0.932,0.399,1.365,1.478,0.966,2.41
  C21.223,25.659,19.179,30.368,19.171,30.386"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#FFFFFF"
        d="M16.08,11.548c-0.452-0.336-1.077-0.226-1.388,0.245l-1.085,1.643
  c-0.31,0.471-0.546,1.317-0.522,1.88l0.337,8.296c0.022,0.563,0.503,1.028,1.066,1.032l0.307,0.003
  c0.563,0.005,1.151-0.435,1.306-0.977l2.572-9.006c0.155-0.542-0.089-1.26-0.542-1.596L16.08,11.548z"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#FFFFFF"
        d="M23.713,6.819c-1.68-0.02-3.112,1.42-3.104,3.12
  c0.006,1.622,1.304,2.989,2.92,3.081c1.685,0.095,3.145-1.279,3.189-3.005C26.765,8.287,25.404,6.837,23.713,6.819"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#FFFFFF"
        d="M32.429,20.278l-9.808-6.099c-0.061-0.036-0.133-0.077-0.2-0.108
  c-0.962-0.451-2.105-0.044-2.568,0.909c-0.23,0.27-0.405,0.592-0.5,0.958l-1.978,7.628c-0.083,0.319-0.096,0.641-0.052,0.948
  c-0.12,0.734,0.149,1.51,0.772,2.007l5.461,4.353v6.02c0,0.936,0.759,1.695,1.695,1.695c0.936,0,1.695-0.76,1.695-1.695v-6.833
  c-0.001-0.431-0.166-0.866-0.496-1.193l-0.071-0.072l-4.256-4.247l1.463-5.645l7.081,3.323l-0.93,15.563
  c-0.025,0.4,0.28,0.744,0.681,0.769c0.015,0.001,0.03,0.001,0.044,0.001c0.381,0,0.701-0.297,0.725-0.683l0.915-15.315
  c0.293-0.082,0.559-0.268,0.732-0.548C33.201,21.423,33.02,20.646,32.429,20.278"
      />
    </g>
  </svg>
);

export const shop_marker = (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="43.525px"
    height="65.734px"
    viewBox="0 0 43.525 65.734"
    enableBackground="new 0 0 43.525 65.734"
  >
    <g>
      <defs>
        <rect id="SVGID_1_" y="0" width="43.525" height="65.735" />
      </defs>
      <clipPath id="SVGID_2_">
        <use xlinkHref="#SVGID_1_" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#SVGID_2_)"
        fill="#252525"
        d="M42.525,21.762C42.525,10.295,33.23,1,21.762,1C10.296,1,1,10.295,1,21.762
  c0,3.108,0.702,6.046,1.926,8.694l-0.035,0.035L21.762,63.71l18.872-33.219l-0.035-0.035
  C41.823,27.809,42.525,24.871,42.525,21.762"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="none"
        stroke="#252525"
        strokeWidth="2"
        strokeMiterlimit="10"
        d="M42.525,21.762
  C42.525,10.295,33.23,1,21.762,1C10.296,1,1,10.295,1,21.762c0,3.108,0.702,6.046,1.926,8.694l-0.035,0.035L21.762,63.71
  l18.872-33.219l-0.035-0.035C41.823,27.809,42.525,24.871,42.525,21.762z"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="#FFFFFF"
        d="M29.245,17.378h-2.086v-3.63c0-1.854-1.502-3.356-3.356-3.356h-4.081
  c-1.853,0-3.356,1.502-3.356,3.356v3.631H14.19c-0.31,0-0.572,0.232-0.609,0.541l-1.668,14.076
  c-0.037,0.31,0.205,0.582,0.517,0.582h18.663c0.312,0,0.554-0.274,0.517-0.584l-1.729-13.981
  C29.881,17.663,29.596,17.378,29.245,17.378 M18.572,13.233c0-0.351,0.284-0.635,0.636-0.635h5.11c0.351,0,0.635,0.284,0.635,0.635
  v4.146h-6.381V13.233z"
      />
    </g>
  </svg>
);

export const home_marker = (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="43.525px"
    height="65.734px"
    viewBox="0 0 43.525 65.734"
    enableBackground="new 0 0 43.525 65.734"
  >
    <g>
      <defs>
        <rect id="SVGID_1_" y="0" width="43.525" height="65.735" />
      </defs>
      <clipPath id="SVGID_2_">
        <use xlinkHref="#SVGID_1_" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#SVGID_2_)"
        fill="#252525"
        d="M42.525,21.762C42.525,10.295,33.229,1,21.763,1C10.296,1,1,10.295,1,21.762
 c0,3.108,0.702,6.046,1.926,8.694l-0.035,0.035L21.763,63.71l18.871-33.219l-0.035-0.035
 C41.823,27.809,42.525,24.871,42.525,21.762"
      />
      <path
        clipPath="url(#SVGID_2_)"
        fill="none"
        stroke="#252525"
        strokeWidth="2"
        strokeMiterlimit="10"
        d="M42.525,21.762
 C42.525,10.295,33.229,1,21.763,1C10.296,1,1,10.295,1,21.762c0,3.108,0.702,6.046,1.926,8.694l-0.035,0.035L21.763,63.71
 l18.871-33.219l-0.035-0.035C41.823,27.809,42.525,24.871,42.525,21.762z"
      />
      <polygon
        clipPath="url(#SVGID_2_)"
        fill="#FFFFFF"
        points="19.173,30.568 19.173,22.798 24.353,22.798 24.353,30.568 
 30.828,30.568 30.828,20.208 34.713,20.208 21.763,8.553 8.813,20.208 12.698,20.208 12.698,30.568  "
      />
    </g>
  </svg>
);

export const checkmark = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.25 8.891l-1.421-1.409-6.105 6.218-3.078-2.937-1.396 1.436 4.5 4.319 7.5-7.627z" />
  </svg>
);

export const xmark = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5 15.538l-3.592-3.548 3.546-3.587-1.416-1.403-3.545 3.589-3.588-3.543-1.405 1.405 3.593 3.552-3.547 3.592 1.405 1.405 3.555-3.596 3.591 3.55 1.403-1.416z" />
  </svg>
);

export const exclamation = (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width="43.525px"
    height="43.525px"
    viewBox="0 0 43.525 43.525"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <defs>
        <rect id="SVGID_1_" y="0" width="43.525" height="43.525" />
      </defs>
      <circle
        fill="none"
        stroke="#000000"
        strokeWidth="2"
        strokeMiterlimit="10"
        cx="21.762"
        cy="21.763"
        r="20.763"
      />
      <path
        d="M19.219,34.284c0.01-1.552,1.095-2.666,2.56-2.657c1.552,0.011,2.537,1.138,2.527,2.689
  c-0.01,1.509-1.009,2.666-2.561,2.657C20.237,36.965,19.21,35.793,19.219,34.284 M20.335,28.126L19.859,7.428l4.139,0.026
  l-0.73,20.69L20.335,28.126z"
      />
    </g>
  </svg>
);

export const arrow = (
  <svg x="0px" y="0px" viewBox="0 0 31.49 31.49">
    <path
      fill="#000"
      d="M21.205,5.007c-0.429-0.444-1.143-0.444-1.587,0c-0.429,0.429-0.429,1.143,0,1.571l8.047,8.047H1.111
        C0.492,14.626,0,15.118,0,15.737c0,0.619,0.492,1.127,1.111,1.127h26.554l-8.047,8.032c-0.429,0.444-0.429,1.159,0,1.587
        c0.444,0.444,1.159,0.444,1.587,0l9.952-9.952c0.444-0.429,0.444-1.143,0-1.571L21.205,5.007z"
    />
  </svg>
);
