import ErrorHandler from "./ErrorHandler";
import LabelContainer from "./LabelContainer";
class Textarea extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    textFormatting: PropTypes.func,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    inputClassName: PropTypes.string,
    containerClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    errorMessageClassName: PropTypes.string,
    inputStyle: PropTypes.object,
    labelStyle: PropTypes.object,
    errorMessageStyle: PropTypes.object,
    onChange: PropTypes.func,
    resizable: PropTypes.bool,
    innerRef: PropTypes.func,
    errorMessage: PropTypes.string,
    validator: PropTypes.func,
    label: PropTypes.string,
    labelOnBottom: PropTypes.bool,
    formState: PropTypes.object.isRequired,
    formFunctions: PropTypes.object.isRequired,
    formName: PropTypes.string.isRequired
  };

  static defaultProps = {
    resizable: false,
    labelOnBottom: false,
    disabled: false
  };

  constructor(props) {
    super(props);

    this.initialValue = props.defaultValue || "";
  }

  componentDidMount() {
    let hasError = false;
    this.props.formFunctions.updateField(this.props.name, this.initialValue);
    this.props.formFunctions.updateFieldErrors(this.props.name, hasError);
  }

  inputChange = e => {
    let value = e.target.value;
    if (this.props.textFormatting)
      value = this.props.textFormatting(
        value,
        this.props.formState.values[this.props.name]
      );
    this.props.formFunctions.updateField(
      this.props.name,
      value,
      this.props.onChange
    );
  };

  render() {
    const {
      containerClassName,
      inputClassName,
      labelClassName,
      errorMessageClassName,
      label,
      labelOnBottom,
      inputStyle,
      labelStyle,
      errorMessageStyle,
      disabled,
      name,
      formState,
      formFunctions,
      placeholder,
      innerRef,
      validator,
      errorMessage,
      resizable
    } = this.props;
    const displayError =
      formState.errors[name] && formState.submitAttempted && !formState.success;
    const fieldId = `${name}_${this.props.formName}`;
    return (
      <div className={`field ${containerClassName}`}>
        {!labelOnBottom && (
          <LabelContainer
            {...this.props}
            name={fieldId}
            className={labelClassName}
            style={labelStyle}
          >
            {label}
          </LabelContainer>
        )}
        <textarea
          className={`${inputClassName} ${displayError ? "error" : ""}`}
          id={fieldId}
          style={{
            ...inputStyle,
            resize: resizable ? "both" : "none"
          }}
          placeholder={placeholder}
          disabled={disabled}
          name={name}
          onChange={e => this.inputChange(e)}
          value={formState.values[name] || ""}
          ref={innerRef}
          aria-invalid={
            formState.errors[name] &&
            formState.submitAttempted &&
            !formState.success
          }
        />
        {labelOnBottom && (
          <LabelContainer
            {...this.props}
            name={fieldId}
            className={labelClassName}
            style={labelStyle}
          >
            {label}
          </LabelContainer>
        )}
        {validator && (
          <ErrorHandler
            name={name}
            formState={formState}
            errorHandler={validator}
            formFunctions={formFunctions}
            className={errorMessageClassName}
            style={errorMessageStyle}
          >
            {errorMessage}
          </ErrorHandler>
        )}
      </div>
    );
  }
}

export default Textarea;
