import ErrorHandler from "./ErrorHandler";
import LabelContainer from "./LabelContainer";

class RadioButton extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        value: PropTypes.string.isRequired,
        checked: PropTypes.bool,
        radioClassName: PropTypes.string,
        radioStyle: PropTypes.object,
        onChange: PropTypes.func,
        fieldClassName: PropTypes.string,
        fieldStyle: PropTypes.object,
        disabled: PropTypes.bool
      })
    ).isRequired,
    containerClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    groupClassName: PropTypes.string,
    errorMessageClassName: PropTypes.string,
    labelStyle: PropTypes.object,
    groupStyle: PropTypes.object,
    errorMessageStyle: PropTypes.object,
    errorMessage: PropTypes.string,
    validator: PropTypes.func,
    label: PropTypes.string,
    labelOnBottom: PropTypes.bool,
    formState: PropTypes.object.isRequired,
    formFunctions: PropTypes.object.isRequired,
    formName: PropTypes.string.isRequired
  };

  static defaultProps = {
    labelOnBottom: false,
    fields: [
      {
        text: "Test",
        value: "test",
        checked: false,
        radioClassName: null,
        radioStyle: null,
        onChange: null,
        fieldClassName: null,
        fieldStyle: null,
        disabled: false
      }
    ]
  };

  constructor(props) {
    super(props);

    // this.initialValue = props.checked ? props.value : "";
  }

  componentDidMount() {
    this.props.fields.forEach(field => {
      const initialValue = field.checked ? field.value : "";
      this.props.formFunctions.initRadios(this.props.name, initialValue);
    });
  }

  // inputChange = e => {
  //   let value = e.target.checked ? this.props.value : "";
  //   this.props.formFunctions.updateField(this.props.name, value, onChange);
  // };

  inputChange = (value, onChange) => {
    this.props.formFunctions.updateField(this.props.name, value, onChange);
  };

  isChecked = value => {
    const formStateValuesName = this.props.formState.values[this.props.name];
    if (formStateValuesName && formStateValuesName === value) {
      return true;
    } else {
      return false;
    }
  };

  radio = (text, value, onChange, isChecked, radioClassName, radioStyle) => {
    return (
      // user psuedo elements ::before to style box and ::after to style the checked state
      <div
        className={`${radioClassName || ""} ${isChecked ? "checked" : ""}`}
        role="radio"
        aria-checked={isChecked}
        tabIndex={isChecked ? 0 : -1}
        style={radioStyle}
        onClick={() => this.inputChange(value, onChange)}
        onKeyUp={e =>
          e.key === "Enter" ? this.inputChange(value, onChange) : false
        }
      >
        {text}
      </div>
    );
  };

  render() {
    const {
      containerClassName,
      labelClassName,
      groupClassName,
      errorMessageClassName,
      label,
      labelOnBottom,
      labelStyle,
      groupStyle,
      errorMessageStyle,
      name,
      formState,
      formFunctions,
      validator,
      errorMessage,
      fields
    } = this.props;
    const fieldId = `${name}_${this.props.formName}`;
    return (
      <div className={`field ${containerClassName}`}>
        {!labelOnBottom && (
          <LabelContainer
            {...this.props}
            name={fieldId}
            className={labelClassName}
            style={labelStyle}
            legend
          >
            {label}
          </LabelContainer>
        )}
        <ul
          className={groupClassName}
          style={groupStyle}
          role="radiogroup"
          aria-labelledby={fieldId}
        >
          {fields.map((field, i) => {
            const isChecked = this.isChecked(field.value);
            return (
              <li
                className={field.fieldClassName}
                style={field.fieldStyle}
                key={i}
              >
                <input
                  type="radio"
                  style={{ display: "none" }}
                  disabled={field.disabled}
                  name={name}
                  onChange={() => this.inputChange(field.value, field.onChange)}
                  value={field.value}
                  checked={isChecked}
                />
                {this.radio(
                  field.text,
                  field.value,
                  field.onChange,
                  isChecked,
                  field.radioClassName,
                  field.radioStyle
                )}
              </li>
            );
          })}
        </ul>
        {labelOnBottom && (
          <LabelContainer
            {...this.props}
            name={fieldId}
            className={labelClassName}
            style={labelStyle}
            legend
          >
            {label}
          </LabelContainer>
        )}
        {validator && (
          <ErrorHandler
            name={name}
            formState={formState}
            errorHandler={validator}
            formFunctions={formFunctions}
            className={errorMessageClassName}
            style={errorMessageStyle}
          >
            {errorMessage}
          </ErrorHandler>
        )}
      </div>
    );
  }
}

export default RadioButton;
