import axios from "axios";
import moment from "moment";
import xmljs from "xml-js";
import * as Cookies from "./Cookies";
import pkg from "../../../package.json";

const PROD_URL = "https://services.synxis.com/v1/api";
const DEV_URL = PROD_URL || "https://bus-cuat.synxis.com/v1/api";

export default class Synxis {
  constructor() {
    this.baseUrl = this.setBaseUrl();
    this.hotelId = "64537";
    this.chainId = "17718";
    this.token = null;
    this.headers = {
      "Content-Type": "application/json",
      Context: "WBSVC",
      "Accept-Language": "en",
      Accept: "appplication/json",
      ActivityID: "", // set per request
    };
  }

  setBaseUrl = () => {
    const { hostname, port } = window.location;
    if (
      (hostname.match("beachtowntravel.com") && !port) ||
      pkg.config.PROD_TEST
    ) {
      return PROD_URL;
    }

    return DEV_URL;
  };

  activityId = (reqName) => `BTT_${reqName}_${moment().unix()}`;

  // AUTH TOKEN METHODS
  saveToken = (token, expires) => {
    Cookies.set({
      key: "btt_token",
      value: token,
      maxAge: expires * 60, // convert minutes to seconds
    });
  };

  getToken = async () => {
    let token = Cookies.get("btt_token");

    if (!token) {
      let { error, access_token, expires_in } = await this.fetchNewToken();

      if (error) {
        return { error };
      }

      token = access_token;
      // console.log("token in synxis", token);
      this.saveToken(access_token, expires_in);
    }

    this.token = token;
    this.addTokenToHeaders(token);

    return { token };
  };

  fetchNewToken = async () => {
    try {
      const response = await axios.post(`${API_PATH}synxis_auth`);
      // console.log(
      //   "%cToken Recieved",
      //   "color: white; font-size: 16px; background-color: green;",
      //   response.data
      // );
      return response.data.data;
    } catch (e) {
      console.log(
        "%cFailed to Get Token",
        "color: white; font-size: 16px; background-color: red;"
      );
      console.error("Failed to fetch token", e);
      return { error: e.message || e || "failed to fetch new token" };
    }
  };

  addTokenToHeaders = (token) =>
    (this.headers.Authorization = `Bearer ${token}`);

  // API METHODS

  getHotelDetails = async () => {
    try {
      const response = await axios({
        method: "GET",
        url: `${this.baseUrl}/hotel/${this.hotelId}/details`,
        headers: {
          ...this.headers,
          ActivityID: this.activityId("get_hotel_details"),
        },
        params: {
          primaryChannel: "WEB",
          include: [
            "Attributes",
            "ContactInfo",
            "Currency",
            "DiningOptions",
            "Features",
            "HotelGroups",
            "Images",
            "Languages",
            "LocationInfo",
            "MealPlans",
            "PaymentMethods",
            "Recreations",
            "ReferencePoints",
            "Services",
          ].join(","), // axios got beef with regular arrays
        },
        data: {},
      });

      if (response.data.ErrorCode) {
        throw this.handleSynxisError(response.data);
      }

      return response.data;
    } catch (e) {
      console.log(e);
      return { error: e.message || e, code: "SYNXIS_FAILURE" };
    }
  };

  /*
    required:
      chainId,
      hotelId,
      adults,
      numRooms,
      startDate: "YYYY-MM-DD",
      endDate: "YYYY-MM-DD",

      other params:
      https://developer.sabre.com/sabre_hospitality/hotel/availability/reference-documentation#/Search/hotelShop
  */

  // https://gw-cert.synxis.com/v1/api/hotel/availability?adults=2&chainId=17718&hotelId=64537&numRooms=1&startDate=2020-06-01&endDate=2020-06-10&primaryChannel=WEB&SecondaryChannel=WEB
  getAvailabilities = async (params = {}) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${this.baseUrl}/hotel/availability`,
        headers: {
          ...this.headers,
          ActivityID: this.activityId("get_availabilities"),
        },
        params: {
          primaryChannel: "WEB",
          secondaryChannel: "WEB",
          chainId: this.chainId,
          hotelId: this.hotelId,
          numRooms: 1,
          ...params,
          currencyCode: (params.currencyCode || "").toUpperCase() || "USD",
        },
        data: {},
      });

      if (response.data.ErrorCode) {
        throw this.handleSynxisError(response.data);
      }
      // console.log("ResponseData", response.data);
      return response.data && response.data.productAvailability;
    } catch (e) {
      console.log(e);
      return { error: e.message || e, code: "SYNXIS_FAILURE" };
    }
  };

  // https://gw-cert.synxis.com/v1/api/hotel/rooms?chainId=17718&hotelId=64537&primaryChannel=WEB&SecondaryChannel=WBSVC&view=full
  getRooms = async () => {
    const req = (start) => ({
      method: "GET",
      url: `${this.baseUrl}/hotel/rooms`,
      headers: {
        ...this.headers,
        ActivityID: this.activityId("get_rooms"),
      },
      params: {
        primaryChannel: "WEB",
        secondaryChannel: "WBSVC",
        chainId: this.chainId,
        hotelId: this.hotelId,
        view: "full",
        pageSize: 0,
        pageStart: start,
      },
      data: {},
    });

    try {
      let data = null;
      let start = 0;
      let total = null;

      while (total === null || start < total) {
        let response = await axios(req(start));
        total = response.data.paging.Total;

        if (!data) {
          data = response.data;
        } else {
          data.roomList = data.roomList.concat(response.data.roomList);
          data.paging.Size += response.data.paging.Size;
        }

        start += response.data.paging.Size;
      }

      return data;
    } catch (e) {
      console.log(e);
      return { error: e.message || e, code: "SYNXIS_FAILURE" };
    }
  };

  getHotelServices = async (params = {}, url) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${this.baseUrl}/admin/hotels/${this.hotelId}${url}`,
        headers: {
          ...this.headers,
          ActivityID: this.activityId("get_hotel_services"),
        },
        params: {
          primaryChannel: "WEB",
          secondaryChannel: "WSBE",
          chainId: this.chainId,
          hotelId: this.hotelId,

          ...params,
        },
        data: {},
      });

      if (response.data.ErrorCode) {
        throw this.handleSynxisError(response.data);
      }

      return response.data;
    } catch (e) {
      console.log(e);
      return { error: e.message || e, code: "SYNXIS_FAILURE" };
    }
  };

  // https://gw-uat.synxis.com/v1/api/hotel/rates?chainId=17718&hotelId=64537&primaryChannel=WEB&secondaryChannel=SYNXISWS_BE
  getRates = async (params = {}) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${this.baseUrl}/hotel/rates`,
        headers: {
          ...this.headers,
          ActivityID: this.activityId("get_rates"),
        },
        params: {
          primaryChannel: "WEB",
          secondaryChannel: "WBSVC",
          chainId: this.chainId,
          hotelId: this.hotelId,
          ...params,
        },
        data: {},
      });

      if (response.data.ErrorCode) {
        throw this.handleSynxisError(response.data);
      }

      return response.data;
    } catch (e) {
      console.log(
        "%cFailed to Get Rates",
        "color: white; font-size: 20px; background-color: red;",
        e
      );
      console.log(e);
      return { error: e.message || e, code: "SYNXIS_FAILURE" };
    }
  };

  // https://gw-cert.synxis.com/v1/api/admin/product/promotions?hotelId=64537&chainId=17718&primaryChannel=WEB
  /**
   * Gets promotions. INACTIVE
   * a user's confirmationNumber and email before returning their reservation.
   */
  getPromotions = async (params = {}) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${this.baseUrl}/admin/product/promotions`,
        headers: {
          ...this.headers,
          ActivityID: this.activityId("get_promotions"),
        },
        params: {
          primaryChannel: "WEB",
          chainId: this.chainId,
          hotelId: this.hotelId,
          ...params,
        },
        data: {},
      });

      if (response.data.ErrorCode) {
        throw this.handleSynxisError(response.data);
      }

      return response.data;
    } catch (e) {
      console.log(e);
      return { error: e.message || e, code: "SYNXIS_FAILURE" };
    }
  };

  // https://gw-cert.synxis.com/v1/api/admin/product/coupons?hotelId=64537&chainId=17718&primaryChannelCode=WEB
  /**
   * Gets coupons. INACTIVE
   * a user's confirmationNumber and email before returning their reservation.
   */
  getCoupons = async (params = {}) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${this.baseUrl}/admin/product/coupons`,
        headers: {
          ...this.headers,
          ActivityID: this.activityId("get_coupons"),
        },
        params: {
          primaryChannelCode: "WEB",
          chainId: this.chainId,
          hotelId: this.hotelId,
          ...params,
        },
        data: {},
      });

      if (response.data.ErrorCode) {
        throw this.handleSynxisError(response.data);
      }

      return response.data;
    } catch (e) {
      console.log(e);
      return { error: e.message || e, code: "SYNXIS_FAILURE" };
    }
  };

  /**
   * Gets a reservation from Synxis. Proxied through our server first so we can confirm
   * a user's confirmationNumber and email before returning their reservation.
   *
   * @param   {object} params    { confirmationNumber, email }
   * @return  {object}           object { code, data: <reservationObj> }
   */
  getReservation = async ({ confirmationNumber, email }) => {
    try {
      const { data } = await axios.post(
        //`http://local.beachtowntravel.com:5001/api/get_reservation`,
        `${API_PATH}get_reservation`,
        {
          confirmationNumber,
          email,
        }
      );

      if (data.ErrorCode) {
        throw this.handleSynxisError(data);
      }

      return data;
    } catch (e) {
      console.log(e);
      return { error: e.message || e, code: "SYNXIS_FAILURE" };
    }
  };

  /**
   * Fetch lead availability for rendering an availability calendar.
   *
   * @param  {object} params         Params object.
   *
   * Params: Content-Type, chainId, hotelId, startDate, endDate, adults, numRooms, primaryChannel, secondaryChannel required.
   */
  getLeadAvailability = async (params = {}) => {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${this.baseUrl}/hotel/leadAvailability`,
        headers: {
          ...this.headers,
          ActivityID: this.activityId("get_lead_availability"),
        },
        params: {
          primaryChannel: "WEB",
          secondaryChannel: "WEB",
          "Content-Type": "application/json",
          chainId: this.chainId,
          hotelId: this.hotelId,
          ...params,
        },
        data: {},
      });

      if (data.ErrorCode) {
        throw this.handleSynxisError(data);
      }

      return data;
    } catch (e) {
      console.log(e);
      return { error: e.message || e, code: "SYNXIS_FAILURE" };
    }
  };

  // create reservation request is handled on server so we can safely confirm transaction was approved
  createReservation = async (params) => {
    try {
      const { data } = await axios.post(
        //`https://local.beachtowntravel.com:5002/api/create_reservation`,
        `${API_PATH}create_reservation`,
        params
      );
      // console.log("data", data);
      return data;
    } catch (e) {
      console.log(e);
      return { error: e.message || e, code: "SYNXIS_FAILURE" };
    }
  };

  /**
   * Authorizes a transaction with FAC through our server.
   * 3DS transactions should set the secure flag to true.
   *
   * @param   {object} params    object auth details{ payment, confirmationNumber, secure }
   * @return  {object}           regular authorization response
   */
  authorizeTransaction = async (params) => {
    try {
      const { data } = await axios.post(
        //`https://local.beachtowntravel.com:5002/api/authorize_transaction`,
        `${API_PATH}authorize_transaction`,
        params
      );

      return data;
    } catch (e) {
      console.log("error from authorizeTransaction", e);
      return { error: e.message || e, code: "SYNXIS_FAILURE" };
    }
  };

  /**
   * Confirms a reservation in Synxis and captures payment in FAC through our server
   *
   * @param   {object} params    { orderNumber, confirmationNumber }
   * @return  {object}           object of confirmed reservation and captured payment.
   */
  confirmReservation = async (params) => {
    try {
      const { data } = await axios.post(
        //`https://local.beachtowntravel.com:5002/api/confirm_reservation`,
        `${API_PATH}confirm_reservation`,
        params
      );

      return data;
    } catch (e) {
      console.log(e);
      return { error: e.message || e, code: "SYNXIS_FAILURE" };
    }
  };

  /**
   * Updates a reservation. INACTIVE
   *
   * @param   {object} params    { orderNumber, confirmationNumber }
   * @return  {object}           object of confirmed reservation and captured payment.
   */
  updateReservation = async (params = {}) => {
    try {
      const { data } = await axios.post(
        //`http://local.beachtowntravel.com:5001/api/update_reservation`,
        `${API_PATH}update_reservation`,
        params
      );

      return data;
    } catch (e) {
      console.log(e);
      return { error: e.message || e, code: "SYNXIS_FAILURE" };
    }
  };

  /**
   * Cancels a reservation. INACTIVE
   *
   * @param   {object} params    { orderNumber, confirmationNumber }
   * @return  {object}           object of confirmed reservation and captured payment.
   */
  cancelReservation = async ({ confirmationNumber, comments = "" }) => {
    try {
      const { data } = await axios.post(
        //`http://local.beachtowntravel.com:5001/api/cancel_reservation`,
        `${API_PATH}cancel_reservation`,
        {
          confirmationNumber,
          comments,
        }
      );

      return data;
    } catch (e) {
      console.log(e);
      return { error: e.message || e, code: "SYNXIS_FAILURE" };
    }
  };

  /**
   * Gets packages from Synxis. Proxied through our server, which sends a SOAP request
   *
   * CORS fyi - payload is JSON so it triggers an preflight OPTIONS request
   *
   * @param   {object} params    { start, end, guests }
   * @return  {object}           parsed XML as JSON or false if there's an error
   */
  getPackages = async (params) => {
    try {
      const { data } = await axios({
        url: `${API_PATH}synxis_packages`,
        method: "POST",
        data: params,
      });

      const json = xmljs.xml2json(data.data.xml, { compact: true });
      const parsed = JSON.parse(json);

      return parsed;
    } catch (e) {
      console.log("error from synxis", e);
      return { error: e.message || e, code: "SYNXIS_FAILURE" };
    }
  };

  handleSynxisError = ({ ErrorCode, Message }) => {
    // if (ErrorCode.match("SHS.ERR.NO_ACCESS")) {
    //   Cookies.remove("btt_token");
    //   this.getToken();
    // }

    return new Error(ErrorCode + ": " + Message);
  };
}
