import styles from "../../../styles/partials/_cancelled-reservation.scss";
import { _class } from "../../utilities/helpers";
import { Translation } from "../../utilities/Translation";
import Reveal from "../../widgets/Reveal";
import { connect } from "react-redux";
import CTA from "../CTA";
import Image from "../../widgets/Image";
import moment from "moment";
const cl = _class(styles, "cancelled_reservation");

class CancelledReservation extends React.Component {
  static propTypes = {
    lang: PropTypes.string,
    reservation: PropTypes.object,
    bookingDetails: PropTypes.object,
    reset: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.LANG = new Translation(props.lang);
  }

  render() {
    const { reservation, bookingDetails } = this.props;
    const heroImage = bookingDetails.cmsProperty.fieldgroup1[0];
    const room = reservation.RoomStay.Products[0];
    const checkin = moment(room.StartDate).format("ll");
    const checkout = moment(room.EndDate).format("ll");

    return (
      <div className={cl("")}>
        <Reveal cssAnimation={["fade", "top"]}>
          <h4>This reservation has been cancelled.</h4>
        </Reveal>

        <Reveal cssAnimation={["fade", "top"]}>
          <h1>Santarena Hotel</h1>
          <p>
            {checkin} - {checkout}
          </p>
          <div className={cl("hero")}>
            <Image
              src={heroImage.image1}
              alt={heroImage.image1_alt}
              align={heroImage.image1_anchor}
            />
          </div>

          <div>
            <p>Cancellation Number</p>
            <p>{reservation.CRS_cancellationNumber}</p>

            <CTA onClick={this.props.reset} text={"Search Again"} />
          </div>
        </Reveal>
      </div>
    );
  }
}

const mapStateToProps = ({ global, synxis }) => ({
  lang: global.lang,
  reservation: synxis.reservation,
  bookingDetails: synxis.bookingDetails,
});

export default connect(mapStateToProps)(CancelledReservation);
