import { TweenMax, Power2 } from "gsap/all";

class Transition extends React.Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    properties: PropTypes.object,
    mode: PropTypes.string,
    appear: PropTypes.bool,
    onComplete: PropTypes.func,
    duration: PropTypes.number,
    delay: PropTypes.number,
    staggerClassName: PropTypes.string,
    reverse: PropTypes.bool,
  };

  static defaultProps = {
    duration: 500,
    delay: 0,
    className: "",
    properties: {},
    onComplete: () => null,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.appear) {
      this.animateIn();
    } else {
      this.setupAnimation();
    }
  }

  componentDidUpdate(prevProps) {
    const { visible } = this.props;
    if (prevProps.visible !== visible && visible === true) {
      this.animateIn();
    }

    if (prevProps.visible !== visible && visible === false) {
      this.animateOut();
    }
  }

  animationProperties = (start) => {
    const { properties, visible } = this.props;
    return Object.keys(properties).reduce((sum, key) => {
      let startState = properties[key][0];
      let endState = properties[key][1];

      if (start) {
        sum[key] = startState;
      } else {
        sum[key] = visible ? endState : startState;
      }

      return sum;
    }, {});
  };

  setupAnimation = () => {
    const { staggerClassName, mode } = this.props;

    let nodes =
      mode === "stagger"
        ? this.getAll(staggerClassName)
        : [this.refs[`transition_el_0`]];

    TweenMax.set(nodes, this.animationProperties(true));
  };

  get = (className) => document.querySelector(`.${className}`);

  getAll = (className) => [...document.querySelectorAll(`.${className}`)];

  staggerAnimation = () => {
    const {
      staggerClassName,
      duration,
      onComplete,
      delay,
      reverse,
    } = this.props;

    const all = this.getAll(staggerClassName);

    if (all.length > 15) {
      return TweenMax.to(all, duration / 1000, {
        ...this.animationProperties(),
        ease: Power2.easeOut,
        delay: delay / 1000,
        onComplete,
      });
    }

    TweenMax.staggerTo(
      all,
      duration / 1000,
      { ...this.animationProperties(), delay: delay / 1000 },
      reverse ? -0.2 : 0.2,
      onComplete
    );
  };

  // animates out all components at the same time
  exitStaggerAnimation = () => {
    const { staggerClassName, duration, onComplete, delay } = this.props;

    TweenMax.to(this.getAll(staggerClassName), duration / 1000, {
      ...this.animationProperties(),
      ease: Power2.easeOut,
      delay: delay / 1000,
      onComplete,
    });
  };

  singleAnimation = () => {
    const { duration, onComplete, delay } = this.props;

    let node = this.refs[`transition_el_0`];

    TweenMax.to(node, duration / 1000, {
      ...this.animationProperties(),
      ease: Power2.easeOut,
      delay: delay / 1000,
      onComplete,
    });
  };

  animateIn = () => {
    this.setupAnimation();

    this.props.mode === "stagger"
      ? this.staggerAnimation()
      : this.singleAnimation();
  };

  animateOut = () => {
    this.props.mode === "stagger"
      ? this.exitStaggerAnimation()
      : this.singleAnimation();
  };

  render() {
    return React.Children.map(this.props.children, (element, idx) => {
      return React.cloneElement(element, { ref: `transition_el_${idx}` });
    });
  }
}

export default Transition;
