import styles from "../../styles/partials/_nav.scss";
import { _class } from "../utilities/helpers";
import Link from "../widgets/Link";
import AnimateHeight from "react-animate-height";

const cl = _class(styles, "nav");

class Nav extends React.Component {
  static propTypes = {
    CONTENT: PropTypes.object,
    items: PropTypes.array,
  };

  static contextTypes = {
    accessible: PropTypes.bool,
  };

  static defaultProps = {
    items: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      activeId: null,
      subNavOpen: false,
    };
  }

  setActive = (page) => this.setState({ activeId: page.id, subNavOpen: true });

  close = () => this.setState({ activeId: null, subNavOpen: false });

  renderChildren = (pages, visible) => {
    const path = window.location.pathname;
    return (
      <ul className={`${cl("sublist")}`}>
        <AnimateHeight height={visible ? "auto" : 0}>
          {pages.map((page) => {
            const match = path.match(new RegExp(`^${page.path}`));
            const activeStyle = match ? styles.active : "";

            return (
              <li
                key={page.id + "_child"}
                className={`${cl("sublist__item")} ${activeStyle}`}
              >
                <Link
                  path={page.path}
                  title={page.linktitle}
                  onClick={this.close}
                  className={cl("sublist__item__link")}
                />
              </li>
            );
          })}
        </AnimateHeight>
      </ul>
    );
  };

  render() {
    const { accessible } = this.context;
    const { activeId } = this.state;
    const path = window.location.pathname;

    return (
      <nav className={cl("")} ref>
        <ul className={`${cl("list")}`}>
          {this.props.items.map((page) => {
            const children = this.props.CONTENT.childrenById(page.id);
            const showChildren =
              page.template !== "collections" && page.template !== "offers";
            const match = path.match(new RegExp(`^${page.path}`));
            const activeStyle = match ? styles.active : "";
            const openStyle = page.id === activeId ? styles.open : "";
            const childrenVisible = !!children.length && page.id === activeId;

            return (
              <li
                key={page.id + "_main"}
                className={`${cl("list__item")} ${activeStyle} ${openStyle}`}
                onMouseOver={() => this.setActive(page)}
                onMouseLeave={this.close}
              >
                <Link
                  path={page.path}
                  title={page.linktitle}
                  onClick={this.close}
                  className={cl("list__item__link")}
                />
                {showChildren &&
                  this.renderChildren(children, childrenVisible || accessible)}
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}

export default Nav;
