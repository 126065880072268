import styles from "../../styles/partials/_quote.scss";
import { _class } from "../utilities/helpers";
import Reveal from "../widgets/Reveal";

const cl = _class(styles, "quote");

Quote.propTypes = {
  text: PropTypes.string,
  author: PropTypes.string,
  align: PropTypes.string,
};

Quote.defaultProps = {
  align: "center",
};

export default function Quote({ text, author, align }) {
  if (text) {
    return (
      <Reveal className={`${cl("")} ${styles[align]}`} cssAnimation={["fade"]}>
        <p className={cl("text")}>{text}</p>
        <div className={cl("author")}>
          <p>{author}</p>
        </div>
      </Reveal>
    );
  }

  return null;
}
