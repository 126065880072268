export const AppContext = React.createContext({});

class AppContextProvider extends React.Component {
  static propTypes = {
    content: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    children: PropTypes.node,
  };

  constructor(props) {
    super(props);

    this.content = props.content;

    this.appContext = {
      content: {
        all: this.all(),
        forCurrentPage: this.forCurrentPage(),
        byId: this.byId,
        byPath: this.byPath,
        byTemplate: this.byTemplate,
        childrenById: this.childrenById,
        childrenByPath: this.childrenByPath,
        parentById: this.parentById,
        parentByPath: this.parentByPath,
        events: this.events,
        allChildren: this.allChildren,
        options: this.options(),
      },
    };
  }

  options = () => this.byTemplate("options")[0];

  all = () => this.content;

  forCurrentPage = () => {
    return this.byPath(this.props.location.pathname);
  };

  byId = (id) => this.content[id] || {};

  byPath = (path) => {
    path = path.toLowerCase();
    path = path.slice(-1) === "/" && path.length > 1 ? path.slice(0, -1) : path;
    const id = Object.keys(this.content).find(
      (key) => this.content[key].path === path
    );
    return this.content[id] || {};
  };

  byTemplate = (template) => {
    return Object.keys(this.content)
      .filter((key) => this.content[key].template === template)
      .map((key) => this.content[key])
      .sort((a, b) => a.sortorder - b.sortorder);
  };

  childrenById = (id) => {
    return Object.keys(this.content)
      .filter((key) => this.content[key].parentid === id)
      .map((key) => this.content[key])
      .sort((a, b) => a.sortorder - b.sortorder);
  };

  childrenByPath = (path) => {
    const id = this.byPath(path).id;
    return this.childrenById(id);
  };

  parentById = (id) => {
    const ID = this.byId(id).parentid;
    return this.byId(ID);
  };

  parentByPath = (path) => {
    const ID = this.byPath(path).parentid;
    return this.byId(ID);
  };

  events = (calendarID = this.byTemplate("calendar")[0].id) => {
    const events = this.childrenById(calendarID)
      .reduce((acc, event) => {
        if (JSON.parse(event.calendartype).type === "recurring") {
          this.childrenById(event.id).forEach((childEvent) => {
            const clonedEvent = { ...event };
            clonedEvent.id = childEvent.id;
            clonedEvent.parentid = childEvent.parentid;
            clonedEvent.start_date = childEvent.start_date;
            clonedEvent.end_date = childEvent.end_date;
            acc.push(clonedEvent);
          });
        }
        acc.push(event);
        return acc;
      }, [])
      .sort((a, b) => {
        return Date.parse(a.start_date) - Date.parse(b.start_date);
      });
    return events;
  };

  /**
   * Recursively finds all children for a given page and creates
   * a tree that can be traversed.
   *
   * @param {} page             page
   * @returns {array}           array of children
   */
  allChildren(page) {
    const children = this.childrenById(page.id);

    if (children.length) {
      return children.map((child) => {
        child.children = this.allChildren(child);
        return child;
      });
    }

    return [];
  }

  render() {
    return (
      <AppContext.Provider value={this.appContext}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

/*----------------------------------------------------------------------------*\
 EXPORT
\*----------------------------------------------------------------------------*/

export default AppContextProvider;
