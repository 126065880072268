import App from "./containers/App";
import { Suspense } from "react";
import API from "utilities/API";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "@babel/polyfill";
import "url-search-params-polyfill";
import AppContextProvider from "./containers/appContextProvider";
import { Provider } from "react-redux";
import store from "./redux/store";
import "lazysizes";
class Main {
  constructor() {
    this.api = new API({ base: API_PATH });
  }

  async fetch(cb = () => {}) {
    const timeStamp = Date.now();
    const [{ data: content }, { data: slides }] = await Promise.all([
      this.api.get(`content?cacheBuster=${timeStamp}`),
      this.api.get(`slides?cacheBuster=${timeStamp}`),
    ]);

    const data = Object.keys(content).reduce((sum, id) => {
      sum[id] = { ...content[id], ...slides[id], related: null };
      return sum;
    }, {});

    for (let key in content) {
      let page = content[key];

      // save lang related pages on the master record
      if (page.canonical_ref && data[page.canonical_ref]) {
        data[page.canonical_ref].related = page.id;
      }
    }

    cb({ data });
  }

  initialize() {
    this.fetch(({ data }) => {
      ReactDOM.render(
        <Router>
          <Route
            render={(props) => {
              return (
                <Provider store={store}>
                  <AppContextProvider content={data} {...props}>
                    <Suspense fallback={<div />}>
                      <App {...props} />
                    </Suspense>
                  </AppContextProvider>
                </Provider>
              );
            }}
          />
        </Router>,
        document.querySelector(".wrapper")
      );
    });
  }
}

new Main().initialize();
