import styles from "../../styles/views/_experiences.scss";
import {
  _class,
  parse,
  getFieldGroupImage,
  fbTrackEvent,
} from "../utilities/helpers";
import Intro from "../partials/Intro";
import ImageGrid from "../partials/ImageGrid";
import Image from "../widgets/Image";
import VideoPlayer from "../widgets/VideoPlayer";
import SelectInput from "../widgets/SelectInput";
import { Translation } from "../utilities/Translation";
import { connect } from "react-redux";
import Link from "../widgets/Link";
import Reveal from "../widgets/Reveal";
import Hero from "../partials/Hero";

const cl = _class(styles, "experiences");

class Experiences extends React.Component {
  static propTypes = {
    page: PropTypes.object,
    CONTENT: PropTypes.object,
    lang: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.LANG = new Translation(props.lang);

    this.slides = props.page.fieldgroup1 || [];
    this.media = props.page.fieldgroup2 || [];

    this.experiences = props.CONTENT.childrenById(props.page.id).map(
      (item) => ({
        ...getFieldGroupImage(item.fieldgroup1),
        link: item.path,
        title: item.h1,
        type: parse(item.selectgroup1),
      })
    );

    this.experienceOptions = this.createExperienceOptions();

    this.state = {
      experiences: this.experiences,
      experience: undefined,
    };
  }

  createExperienceOptions = () => {
    const object = this.experiences.reduce((sum, exp) => {
      if (exp.type && exp.type[0]) {
        let value = exp.type[0].value;
        sum[value] = { label: this.LANG(exp.type[0].name), value };
      }
      return sum;
    }, {});

    return Object.values(object);
  };

  filterExperiences = (selected) => {
    fbTrackEvent("Search");

    const experiences = this.experiences.filter((exp) => {
      if (selected) {
        return exp.type && exp.type[0] && exp.type[0].value === selected.value;
      }

      return true;
    });

    this.setState({ experience: selected, experiences });
  };

  renderMedia = (data = []) => (
    <div className={cl("media")}>
      {data.map((el) => {
        if (el.h1) {
          return (
            <Reveal
              className={cl("media__video")}
              cssAnimation={["fade", "bottom"]}
              key={el.id}
            >
              <VideoPlayer url={el.h1} playing={false} />
            </Reveal>
          );
        }

        return (
          <Reveal
            className={cl("media__image")}
            cssAnimation={["fade", "bottom"]}
            key={el.id}
          >
            <Image
              src={el.image1}
              alt={el.image1_alt_text}
              align={el.image1_anchor}
            />
          </Reveal>
        );
      })}
    </div>
  );

  renderExperienceSelect = () => {
    return (
      <div className={cl("select")}>
        <SelectInput
          value={this.state.experience}
          placeholder={this.LANG("selectActivity")}
          options={this.experienceOptions}
          onSelect={this.filterExperiences}
          className={"exp_select"}
          classNamePrefix={"exp_select"}
          isSearchable
          isClearable
        />
      </div>
    );
  };

  renderFilteredLayout = (experiences) => (
    <ul className={cl("filtered_list")}>
      {experiences.map((exp, index) => {
        return (
          <Link
            path={exp.link}
            className={cl("filtered_list__image")}
            key={exp.id || index}
          >
            <Image src={exp.src} alt={exp.alt} align={exp.align} />
            <h3>{exp.title}</h3>
          </Link>
        );
      })}
    </ul>
  );

  // push content into array, alternating between groups of experiences and media
  staggerContent = (exps, media) => {
    let i = 0;
    let j = 0;
    const els = [];

    while (i < exps.length || j < media.length) {
      let xp = exps[i];
      let ms = media[j];

      if (xp) {
        els.push({ type: "ImageGrid", data: xp, reversed: i % 2 !== 0 });
      }

      if (ms) {
        els.push({ type: "Media", data: ms, reversed: j % 2 !== 0 });
      }
      i++;
      j++;
    }

    return els;
  };

  reduceContent = (array = [], int = 1) => {
    return array.reduce((sum, item, index) => {
      let newIndex = Math.ceil((index + 1) / int) - 1;
      sum[newIndex] = [...(sum[newIndex] || []), item];
      return sum;
    }, []);
  };

  renderContent = () => {
    const { experiences } = this.state;

    if (experiences.length !== this.experiences.length) {
      return this.renderFilteredLayout(experiences);
    }

    // split into groups of 4 and 2
    const exps = this.reduceContent(this.experiences, 4);
    const media = this.reduceContent(this.media, 2);

    return this.staggerContent(exps, media).map((el) => {
      if (el.type === "ImageGrid") {
        return <ImageGrid images={el.data} reversed={el.reversed} />;
      }

      if (el.type === "Media") {
        return this.renderMedia(el.data);
      }
    });
  };

  render() {
    const { page } = this.props;

    return (
      <div className={cl("")}>
        <Hero
          imageData={{ slides: this.slides, mask: true }}
          title={page.h1}
          videoLinkPresent={page.video_link !== null && page.video_link !== ""}
          videoLink={page.video_link}
        >
          {this.renderExperienceSelect()}
        </Hero>

        <div className={cl("container")}>
          <Intro subtitle={page.h2} />
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  lang: global.lang,
});

export default connect(mapStateToProps)(Experiences);
