import styles from "../../styles/partials/_results-block.scss";
import { _class } from "../utilities/helpers";
import ContentProvider from "../containers/ContentProvider";
import * as SVG from "../widgets/SVG";
import Tabs from "./Tabs";
import Transition from "../widgets/Transition";
import Modal from "../widgets/Modal";
import TownCard from "./TownCard";
import image from "../../images/lc_town_map_3.svg";
import LeafletMap from "./LeafletMap";
import zenscroll from "zenscroll";
import { connect } from "react-redux";
import { Translation } from "../utilities/Translation";

const cl = _class(styles, "results_block");

class TownMap extends React.Component {
  static propTypes = {
    CONTENT: PropTypes.object,
    cards: PropTypes.array,
    categories: PropTypes.array,
    visible: PropTypes.bool,
    mapCoordinates: PropTypes.object,
    mapLocations: PropTypes.arrayOf(
      PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
      })
    ),
    lang: PropTypes.string,
  };

  static contextTypes = {
    history: PropTypes.object,
  };

  static defaultProps = {
    categories: [],
    cards: [],
  };

  constructor(props, context) {
    super(props, context);

    this.LANG = new Translation(props.lang);

    this.ALL = { label: this.LANG("All"), value: this.LANG("All") };

    this.rooms = this.props.CONTENT.byTemplate("room");

    this.state = {
      points: [],
      activeFilter: null,
      rooms: this.rooms,
      filteredCards: props.cards,
      visible: true,
      animating: false,
      nextTab: null,
      tab: {
        [this.ALL.value]: this.ALL,
      },
      mapModalOpen: false,
      activeCardId: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.animating && !this.state.animating) {
      this.scroller && this.scroller.toY(0, 0);
      this.animateIn();
    }

    if (this.props.cards.length !== prevProps.cards.length) {
      this.scroller && this.scroller.toY(0, 0);
      this.animateIn();
    }
  }

  createScroller = () => {
    setTimeout(() => {
      const cards = document.getElementById("cards");
      this.scroller = zenscroll.createScroller(cards, 500, 30);
    }, 500);
  };

  handleMarkerClick = (id) => {
    const target = document.getElementById(`card_${id}`);
    target && this.scroller.to(target);
  };

  onComplete = () => {
    if (!this.scroller) {
      this.createScroller();
    }
    this.setState({ animating: false });
  };

  /**
   * Animates in the current collection of cards.
   * First we filter the cards to generate a new collection,
   * then make them visible (animation)
   *
   */
  animateIn = () => {
    const { nextTab, tab } = this.state;

    let newTab = {
      ...this.state.tab,
    };

    // If switching back to All tab, clear out other values
    // otherwise remove the all tab
    if (nextTab.value === this.ALL.value && !newTab[this.ALL.value]) {
      newTab = {
        [this.ALL.value]: this.ALL,
      };
    } else {
      delete newTab[this.ALL.value];
    }

    if (nextTab.value !== this.ALL.value && tab[nextTab.value]) {
      delete newTab[nextTab.value];
    } else {
      newTab[nextTab.value] = nextTab;
    }

    const filteredCards = this.tabFilter(newTab);

    this.setState({
      tab: newTab,
      visible: true,
      filteredCards,
    });
  };

  /**
   * Filters cards by the selected tabs
   *
   * @param   {object} tab              Object of tabs, eg {All: { label, value }}
   *
   * @return  {array}                   collection of filtered cards
   */
  tabFilter = (tabs) => {
    if (tabs[this.ALL.value]) {
      return this.props.cards;
    }

    return this.props.cards.filter((card) => {
      if (typeof card.category === "string") {
        return tabs[card.category];
      }

      return;
    });
  };

  onTabClick = (tab) =>
    this.setState({ visible: false, animating: true, nextTab: tab });

  toggleMapViewModal = () =>
    this.setState({ mapViewModalOpen: !this.state.mapViewModalOpen });

  renderCards = (view) => {
    return (
      <Transition
        appear
        visible={this.state.visible}
        mode="stagger"
        staggerClassName={cl(view + "__list__item")}
        properties={{
          y: ["50%", "0%"],
          opacity: [0, 1],
          autoAlpha: [0, 1],
        }}
        onComplete={this.onComplete}
      >
        <ul
          className={cl(view + "__list")}
          ref={(r) => (this.cards = r)}
          id="cards"
          onMouseLeave={() => this.setState({ activeCardId: null })}
        >
          {this.state.filteredCards.map((card) => (
            <li
              className={cl(view + "__list__item")}
              key={card.id}
              id={`card_${card.id}`}
              onMouseOver={() => this.setState({ activeCardId: card.id })}
            >
              <TownCard {...card} lang={this.props.lang} />
            </li>
          ))}
        </ul>
      </Transition>
    );
  };

  renderTabs = () => (
    <div className={cl("options")}>
      <div className={cl("options__categories")}>
        <Tabs
          onClick={this.onTabClick}
          tabs={this.props.categories.map((cat) => {
            return {
              label: this.LANG(cat.label),
              value: cat.value,
            };
          })}
          active={this.state.tab}
          fixedWidth
          lang={this.props.lang}
        />
      </div>
    </div>
  );

  renderMapViewModal = () => {
    return (
      <Modal
        open={this.state.mapViewModalOpen}
        close={this.toggleMapViewModal}
        background="grey"
        className={cl("map_modal")}
      >
        <div className={cl("map_view_mobile")}>
          <LeafletMap
            className={cl("map_view_mobile__map")}
            image={image}
            markers={this.state.filteredCards}
            activeId={this.state.activeCardId}
          />
          {this.renderTabs()}
          {this.renderCards("map_view_mobile")}
        </div>
      </Modal>
    );
  };

  render() {
    return (
      <div className={`${cl("")} ${cl("town_map")}`}>
        {this.renderMapViewModal()}

        {this.renderTabs()}

        <div className={cl("map_toggle")} onClick={this.toggleMapViewModal}>
          {SVG.map}
        </div>
        <div className={cl("results")}>
          <div className={cl("map_view")}>
            {this.renderCards("map_view")}
            <LeafletMap
              className={cl("map_view__map")}
              image={image}
              markers={this.state.filteredCards}
              onMarkerClick={this.handleMarkerClick}
              activeId={this.state.activeCardId}
              zoomControls
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  lang: global.lang,
});

export default ContentProvider(connect(mapStateToProps)(TownMap));
