import ContentProvider from "../containers/ContentProvider";
import { gaTrackEvent } from "../utilities/helpers";
import { Translation } from "../utilities/Translation";
import { connect } from "react-redux";

class HubSpotForm extends React.Component {
  static propTypes = {
    CONTENT: PropTypes.object,
    title: PropTypes.string,
    label: PropTypes.string,
    formId: PropTypes.string.isRequired,
    className: PropTypes.string,
    agreeColor: PropTypes.string,
    onSubmit: PropTypes.func,
    lang: PropTypes.string,
  };

  static defaultProps = {
    className: "",
    label: "Hubspot Form",
    onSubmit: () => null,
  };

  constructor(props) {
    super(props);

    this.portalId = props.CONTENT.byTemplate("options")[0].h3;
    this.state = {
      submitted: false,
    };
  }

  componentDidMount() {
    this.initJQuery();

    const { formId } = this.props;

    const script = document.getElementById("__hbspt_script__");

    if (formId) {
      window.hbspt
        ? this.createForm()
        : (script.onload = () => window.hbspt && this.createForm());
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.formId && prevProps.formId !== this.props.formId) {
      this.createForm();
    }
  }

  initJQuery = () => {
    // these are all methods required by HubSpot in order to useonFormSubmitted callback
    window.jQuery =
      window.jQuery ||
      (() => ({
        change: () => {},
        trigger: () => {},
      }));
  };

  onSubmit = () => {
    if (!this.state.submitted) {
      gaTrackEvent({
        label: this.props.label,
        category: "Form",
        action: "Completion",
      });

      this.props.onSubmit();
    }

    this.setState({ submitted: true });
  };

  createForm = () => {
    const { formId } = this.props;

    const form = window.hbspt.forms.create({
      portalId: this.portalId,
      formId,
      onFormSubmitted: this.onSubmit,
    });

    form.onReady(() => {
      // when the form is loaded, remove any existing forms and append the new form

      const child = this.node?.childNodes[0];
      const element = document.getElementById("hsForm_" + form.id);
      const formParent = element.parentElement;

      if (child) {
        this.node?.removeChild(child);
      }

      this.node?.appendChild(formParent);
      this.formParent?.removeChild();
    });
  };

  render() {
    const { title, formId, className, agreeColor, lang } = this.props;

    const LANG = new Translation(lang);

    if (formId && this.portalId && window.hbspt) {
      return (
        <div className={`hubspot_form ${className}`}>
          {title && <h2>{title}</h2>}
          <div className="hubspot_node" ref={(r) => (this.node = r)} />
          <p className={`hubspot_form__agree ${agreeColor}`}>
            {LANG("agreeToMarketing", lang)}
          </p>
        </div>
      );
    }

    return <div className="hubspot_no_form" />;
  }
}

const mapStateToProps = ({ global }) => ({
  lang: global.lang,
});

export default ContentProvider(connect(mapStateToProps)(HubSpotForm));
