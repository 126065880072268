import Loadable from "react-loadable";
import LoadableVisibility from "react-loadable-visibility/react-loadable";
import styles from "../../styles/views/_home.scss";
import { _class, parse, getImage } from "../utilities/helpers";
// const HeroCarousel = lazy(() => import("../partials/HeroCarousel"));
const Loading = () => <div className={`loadable--loading`} />;

const Hero = Loadable({
  loader: () => import("../partials/Hero"),
  loading: Loading,
});
const Intro = Loadable({
  loader: () => import("../partials/Intro"),
  loading: Loading,
});
const Image = Loadable({
  loader: () => import("../widgets/Image"),
  loading: Loading,
});
const CTA = Loadable({
  loader: () => import("../partials/CTA"),
  loading: Loading,
});
const Quote = Loadable({
  loader: () => import("../partials/Quote"),
  loading: Loading,
});
const Link = Loadable({
  loader: () => import("../widgets/Link"),
  loading: Loading,
});
// const OfferCard = lazy(() => import("../partials/OfferCard"));
// const BookingWidget = lazy(() => import("../partials/BookingWidget"));
// const VillaCarousel = lazy(() => import("../partials/VillasCarousel"));
const Reveal = Loadable({
  loader: () => import("../widgets/Reveal"),
  loading: Loading,
});
import SuspenseComponent from "../utilities/SuspenseComponent";
import { Translation } from "../utilities/Translation";
import { connect } from "react-redux";

const OfferCard = LoadableVisibility({
  loader: () =>
    import("../partials/OfferCard" /* webpackChunkName: "offer-card" */),
  loading: Loading,
});
const BookingWidget = LoadableVisibility({
  loader: () =>
    import("../partials/BookingWidget" /* webpackChunkName: "booking-widget */),
  loading: Loading,
});
const VillaCarousel = LoadableVisibility({
  loader: () =>
    import(
      "../partials/VillasCarousel" /* webpackChunkName: "villa-carousel */
    ),
  loading: Loading,
});
const cl = _class(styles, "home");

class Home extends React.Component {
  static propTypes = {
    page: PropTypes.object,
    CONTENT: PropTypes.object,
    lang: PropTypes.string,
  };

  static contextTypes = {
    history: PropTypes.object,
    viewport: PropTypes.object,

    filterByLang: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.LANG = new Translation(props.lang);

    this.slides = props.page.fieldgroup1 || [];
    this.featuredHotels = this.getRelation("buttonswitch1").filter(
      (hotel) => hotel.id
    );
    this.recommendations = props.page.fieldgroup4 || [];
    this.offers = this.getRelation("buttonswitch2");

    this.villasPage = props.CONTENT.byTemplate("villas").filter(
      context.filterByLang
    )[0];

    this.villas = this.getVillas(this.props.page.fieldgroup2);
  }

  getVillas = (fieldgroup = []) => {
    return fieldgroup.reduce((sum, villa) => {
      const page = this.props.CONTENT.byId(villa.buttonlink1);
      // might need to fix ContentRelation field.
      // buttonlink is a single value when it should be parseable

      if (page) {
        const fieldgroup = page.fieldgroup1;
        sum.push({
          title: page.linktitle,
          blurb: villa.blurb1,
          image: {
            src: fieldgroup && fieldgroup[0].image1,
            alt: fieldgroup && fieldgroup[0].image1_alt_text,
            align: fieldgroup && fieldgroup[0].image1_anchor,
          },
        });
      }

      return sum;
    }, []);
  };

  getRelation = (fieldName) => {
    const { page, CONTENT } = this.props;
    const parsed = parse(page[fieldName]) || [];
    return parsed.reduce((sum, item) => {
      const p = CONTENT.byId(item.value);
      if (p && p.id) {
        sum.push(p);
      }
      return sum;
    }, []);
  };

  renderFeaturedHotels = () => {
    return (
      <div className={cl("featured_hotels")}>
        <Reveal cssAnimation={["fade", "down"]}>
          <h2>{this.LANG("featuredHotels")}</h2>
        </Reveal>

        <Reveal
          className={cl("featured_hotels__list")}
          mode={"stagger"}
          staggerClassName={cl("featured_hotels__list__item")}
          properties={{ opacity: [0, 1], autoAlpha: [0, 1], y: ["50%", "0%"] }}
        >
          {this.featuredHotels.map((hotel) => (
            <li className={cl("featured_hotels__list__item")} key={hotel.id}>
              <Link path={hotel.path}>
                <div className={cl("featured_hotels__list__item__image")}>
                  <Image {...getImage(hotel)} />
                </div>
                <h3>{hotel.pagetitle}</h3>
              </Link>
            </li>
          ))}
        </Reveal>
      </div>
    );
  };

  setGridProp = (index) => {
    if (index === 0) {
      return { y: ["50%", "0%"] };
    }

    if (index === 1) {
      return { x: ["50%", "0%"] };
    }

    if (index === 3) {
      return { y: ["50%", "0%"] };
    }
    if (index === 4) {
      return { x: ["50%", "0%"] };
    }
  };

  renderRecommendations = () => {
    return (
      <div className={cl("recommendations")}>
        <Reveal
          properties={{ opacity: [0, 1], autoAlpha: [0, 1], y: ["-50%", "0%"] }}
        >
          <h2>{this.LANG("recommendedForYou")}</h2>
        </Reveal>

        <ul className={cl("recommendations__images")}>
          {this.recommendations.map((item, index) => {
            const parsed = parse(item.buttonlink1) || {};

            return (
              <Link
                path={parsed.link}
                key={item.id}
                className={`${cl("recommendations__image")} ${styles[index]}`}
              >
                <Reveal
                  properties={{
                    opacity: [0, 1],
                    autoAlpha: [0, 1],
                    ...this.setGridProp(index),
                  }}
                  delay={150 * (index + 1)}
                >
                  <Image
                    src={item.image1}
                    alt={item.image1_alt_text}
                    align={item.image1_anchor}
                  />
                  <h3>{parsed.text}</h3>
                </Reveal>
              </Link>
            );
          })}
        </ul>
      </div>
    );
  };

  renderLasCatalinas = () => {
    const { page } = this.props;
    return (
      <div className={cl("las_catalinas")}>
        <Reveal
          className={cl("las_catalinas__image")}
          properties={{ opacity: [0, 1], autoAlpha: [0, 1] }}
        >
          <Image
            src={page.image1}
            alt={page.image1_alt_text}
            align={page.image1_anchor}
          />
        </Reveal>
        <Reveal
          className={cl("las_catalinas__content")}
          properties={{ opacity: [0, 1], autoAlpha: [0, 1], x: ["100%", "0%"] }}
          duration={1000}
        >
          <h2>{page.h4}</h2>
          <p>{page.blurb2}</p>
          <CTA buttonLink={page.buttonlink1} type="dark" />
        </Reveal>
      </div>
    );
  };

  renderGroupTravel = () => {
    const { page } = this.props;

    return (
      <Reveal className={cl("group_travel")} cssAnimation={["fade"]}>
        <div
          className={cl("group_travel__image")}
          properties={{ opacity: [0, 1], autoAlpha: [0, 1] }}
        >
          <Image
            src={page.image2}
            alt={page.image2_alt_text}
            align={page.image2_anchor}
          />
        </div>
        <div
          className={cl("group_travel__content")}
          properties={{ opacity: [0, 1], autoAlpha: [0, 1], y: ["25%", "0%"] }}
          delay={500}
        >
          <h2>{page.h5}</h2>
          <p>{page.blurb3}</p>
          <CTA buttonLink={page.buttonlink2} type="dark" />
        </div>
      </Reveal>
    );
  };

  renderVillas = () => (
    <div className={cl("villas")}>
      <VillaCarousel
        slides={[...this.villas, ...this.villas]}
        CONTENT={this.props.CONTENT}
      />
    </div>
  );

  renderOffers = () => {
    return (
      <div className={cl("offers")}>
        <Reveal cssAnimation={["fade", "down"]}>
          <h2>{this.LANG("offers")}</h2>
        </Reveal>

        <Reveal
          className={cl("offers__list")}
          mode={"stagger"}
          staggerClassName={cl("offers__list__item")}
          properties={{ opacity: [0, 1], autoAlpha: [0, 1], x: ["-50%", "0%"] }}
        >
          {this.offers.slice(0, 3).map((offer) => (
            <li className={cl("offers__list__item")} key={offer.id}>
              <OfferCard
                title={offer.h1}
                blurb={offer.blurb1}
                image={{
                  src: offer.fieldgroup1[0].image1,
                  alt: offer.fieldgroup1[0].image1_alt_text,
                  align: offer.fieldgroup1[0].image1_anchor,
                }}
                cta={{ text: this.LANG("learnMore"), link: offer.path }}
                lang={this.props.lang}
              />
            </li>
          ))}
        </Reveal>
      </div>
    );
  };

  renderQuotes = () => (
    <div className={cl("quotes")}>
      <Quote text={this.props.page.blurb1} author={this.props.page.h3} />
    </div>
  );

  renderHeroCarousel = () => {
    const { page } = this.props;
    const hasVideo = page.video_link !== null && page.video_link !== "";
    return (
      <Hero
        videoLinkPresent={hasVideo}
        videoLink={page.video_link}
        title={page.h1}
        imageData={{ slides: this.slides, mask: true, parallax: true }}
        titleDelay={5000}
      >
        <Reveal
          className={cl("booking_widget")}
          properties={{
            opacity: [0, 1],
            autoAlpha: [0, 1],
            y: ["50%", "0%"],
          }}
          delay={5000}
          duration={1000}
        >
          <BookingWidget type="small" CONTENT={this.props.CONTENT} />
        </Reveal>
      </Hero>
    );
  };

  render() {
    const { page } = this.props;
    return (
      <SuspenseComponent>
        <div className={cl("")}>
          {this.renderHeroCarousel()}

          <div className={cl("intro")}>
            <Intro subtitle={page.h2} />
          </div>

          {this.renderVillas()}

          <div className={cl("container")}>
            {this.renderFeaturedHotels()}
            {this.renderRecommendations()}
            {this.renderQuotes()}
          </div>

          {this.renderLasCatalinas()}

          <div className={cl("container")}>
            {this.renderOffers()}
            {this.renderGroupTravel()}
          </div>
        </div>
      </SuspenseComponent>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  lang: global.lang,
});

export default connect(mapStateToProps)(Home);
