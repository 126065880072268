function action(action, name) {
  return [
    `${action}_${name}_pending`,
    `${action}_${name}_success`,
    `${action}_${name}_fail`,
  ];
}

export default [
  ...action("get", "token"),
  ...action("get", "rooms"),
  ...action("get", "hotel_details"),
  ...action("get", "availabilities"),
  ...action("get", "single_availability"),
  ...action("get", "promotions"),
  ...action("get", "coupons"),
  ...action("create", "reservation"),
  ...action("get", "reservation"),
  ...action("cancel", "reservation"),
  ...action("update", "reservation"),
  ...action("confirm", "reservation"),
  ...action("get", "rates"),
  ...action("get", "packages"),
  ...action("get", "lead_availability"),
  ...action("authorize", "transaction"),
  "delete_availabilities",
  "delete_single_availability",
  "delete_booking_details",
  "update_booking_details",
  "delete_reservation",
  "create_booking_details",
  "update_booking_form",
  "clear_booking_form",
  "clear_error",
  "hold_reservation",
].reduce((sum, type) => (sum[type.toUpperCase()] = type) && sum, {});
