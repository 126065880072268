import { parse } from "./helpers";

export function setItem(key, value) {
  const btt = getRoot();
  btt[key] = value;
  setRoot(btt);
}

export function getItem(key) {
  const btt = getRoot();
  return btt[key];
}

export function removeItem(key) {
  const btt = getRoot();
  delete btt[key];
  setRoot(btt);
}

export function getRoot() {
  const btt = window.localStorage.getItem("btt");
  return (btt && parse(btt)) || {};
}

export function setRoot(obj = { lang: "en" }) {
  window.localStorage.setItem("btt", JSON.stringify(obj));
}
