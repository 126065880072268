import styles from "../../styles/partials/_add-ons.scss";
import { _class, parse, numberWithCommas } from "../utilities/helpers";
import { connect } from "react-redux";
import { getPackages, addPackage } from "../redux/synxis/SynxisActions";
import moment from "moment";
import { Translation } from "../utilities/Translation";
import CTA from "../partials/CTA";
import Loader from "./Loader";
import * as SVG from "../widgets/SVG";
import ContentProvider from "../containers/ContentProvider";

const cl = _class(styles, "add_ons");

class AddOns extends React.Component {
  static propTypes = {
    CONTENT: PropTypes.object,
    getPackages: PropTypes.func,
    packages: PropTypes.object,
    packagesPending: PropTypes.bool,
    bookingDetails: PropTypes.object,
    addPackage: PropTypes.func,
    currency: PropTypes.object,
    lang: PropTypes.string,
  };

  static contextTypes = {
    filterByLang: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.LANG = new Translation(props.lang);
    this.offers = props.CONTENT.byTemplate("offer_detail").filter(
      context.filterByLang
    );
  }

  componentDidMount() {
    const { bookingDetails, getPackages } = this.props;

    getPackages({
      start: moment(bookingDetails.from).format("YYYY-MM-DD"),
      end: moment(bookingDetails.to).format("YYYY-MM-DD"),
      guests: bookingDetails.guests,
    });
  }

  renderPackageItem = (pkg) => {
    const { currency } = this.props;
    const { packages } = this.props.bookingDetails;

    const added = packages.find(
      (p) => p.ServiceInventoryCode === pkg.ServiceInventoryCode
    );

    return (
      <li className={cl("item")} key={pkg.ServiceInventoryCode}>
        <div className={cl("item__details")}>
          <p className={cl("item__details__title")}>{pkg.Title}</p>
          <p className={cl("item__details__description")}>{pkg.Description}</p>
        </div>
        <div className={cl("item__pricing")}>
          <h3>
            {currency.symbol}
            {numberWithCommas(
              pkg.Price[0].TaxInclusive == "false"
                ? pkg.Price[0].AmountBeforeTax
                : pkg.Price[0].AmountAfterTax
            )}
          </h3>
          <p>{pkg.ServicePricingType}</p>
          <p>
            {pkg.Price[0].TaxInclusive == "false"
              ? this.LANG("excludingTaxesFees")
              : this.LANG("includingTaxesFees")}
          </p>
          {added && (
            <p className={cl("item__selected_text")}>{SVG.checkmark}</p>
          )}
          {!added && (
            <CTA
              text={this.LANG("addToReservation")}
              onClick={() => this.props.addPackage(pkg)}
              type="orange"
              className={cl("item__cta")}
            />
          )}
        </div>
      </li>
    );
  };

  renderPackages = () => {
    const { packages, bookingDetails, packagesPending } = this.props;

    if (packages) {
      const list = Object.keys(packages).reduce((sum, key) => {
        const pkg = packages[key];
        const offer = this.offers.find((o) => o.h5 === key);

        if (offer) {
          const lodging = parse(offer.buttonswitch1, []);
          const id = bookingDetails.cmsProperty.id;
          const isAssociatedLodging = lodging.find((item) => item.value == id);
          const item = this.renderPackageItem(pkg);

          if (lodging.length) {
            isAssociatedLodging && item && sum.push(item);
          } else {
            item && sum.push(item);
          }
        }

        return sum;
      }, []);

      return list.length ? list : <p>{this.LANG("noPackages")}</p>;
    }

    if (packagesPending) {
      return <Loader />;
    }

    return <p>{this.LANG("noPackages")}</p>;
  };

  render() {
    return (
      <div className={`${cl("")}`}>
        <h2>{this.LANG("packages")}</h2>
        <ul className={cl("list")}>{this.renderPackages()}</ul>
      </div>
    );
  }
}

const mapStateToProps = ({ synxis, global }) => ({
  bookingDetails: synxis.bookingDetails,
  packages: synxis.packages,
  packagesPending: synxis.packagesPending,
  lang: global.lang,
  currency: global.currency,
});

const connectedAddons = connect(mapStateToProps, {
  getPackages,
  addPackage,
})(AddOns);

export default ContentProvider(connectedAddons);
