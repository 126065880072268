export default class ThankYou extends React.Component {
  static propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  static defaultProps = {
    text: "Thank you.",
    className: "",
    style: {
      display: "inline-block"
    }
  };

  render() {
    const { text, className, style } = this.props;
    return (
      <p
        aria-live="assertive"
        aria-atomic="true"
        className={className}
        style={style}
      >
        {text}
      </p>
    );
  }
}
