import { StandardInput, Textarea, SelectInput } from "../form/index";
import styles from "../../styles/partials/_forms.scss";
import { _class } from "../utilities/helpers";
import { Translation } from "../utilities/Translation";
import BookingFormContainer from "./BookingFormContainer";
import { connect } from "react-redux";
import { createReservation } from "../redux/synxis/SynxisActions";

const cl = _class(styles, "form");

class CreateBookingForm extends React.Component {
  static propTypes = {
    lang: PropTypes.string,
    createReservation: PropTypes.func,
  };

  static contextTypes = {
    accessible: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);

    this.LANG = new Translation(props.lang);
  }

  renderForm = ({ shared, utils, countryOptions, cardOptions }) => {
    return (
      <div className={cl("inner")}>
        <div className={cl("row")}>
          <h3>{this.LANG("contactInformation")}</h3>
        </div>

        <div className={cl("row")}>
          <StandardInput
            name="first_name"
            validator={utils.validateNotBlank}
            errorMessage={`${this.LANG("firstName")} ${this.LANG("required")}`}
            containerClassName={cl("input_container")}
            inputClassName={`${cl("input")} form_input`}
            placeholder={`${this.LANG("firstName")}*`}
            errorMessageClassName={cl("input_error")}
            {...shared}
          />
          <StandardInput
            name="last_name"
            validator={utils.validateNotBlank}
            errorMessage={`${this.LANG("lastName")} ${this.LANG("required")}`}
            containerClassName={cl("input_container")}
            inputClassName={`${cl("input")} form_input`}
            placeholder={`${this.LANG("lastName")}*`}
            errorMessageClassName={cl("input_error")}
            {...shared}
          />
        </div>

        <div className={cl("row")}>
          <StandardInput
            name="email"
            validator={utils.validateNotBlank}
            errorMessage={`${this.LANG("email")} ${this.LANG("required")}`}
            containerClassName={cl("input_container")}
            inputClassName={`${cl("input")} form_input`}
            placeholder={`${this.LANG("email")}*`}
            errorMessageClassName={cl("input_error")}
            {...shared}
          />
          <StandardInput
            name="phone"
            validator={utils.validatePhoneFormat}
            errorMessage={`${this.LANG("contactNumber")} ${this.LANG(
              "notValid"
            )}`}
            containerClassName={cl("input_container")}
            inputClassName={`${cl("input")} form_input`}
            placeholder={`${this.LANG("contactNumber")}*`}
            errorMessageClassName={cl("input_error")}
            {...shared}
          />
        </div>

        <div className={cl("row")}>
          <h3>{this.LANG("address")}</h3>
        </div>
        <div className={cl("row")}>
          <StandardInput
            name="address"
            validator={utils.validateNotBlank}
            errorMessage={`${this.LANG("address")} ${this.LANG("required")}`}
            containerClassName={cl("input_container")}
            inputClassName={`${cl("input")} form_input`}
            placeholder={`${this.LANG("address")}*`}
            errorMessageClassName={cl("input_error")}
            {...shared}
          />
          <StandardInput
            name="city"
            validator={utils.validateNotBlank}
            errorMessage={`${this.LANG("city")} ${this.LANG("required")}`}
            containerClassName={cl("input_container")}
            inputClassName={`${cl("input")} form_input`}
            placeholder={`${this.LANG("city")}*`}
            errorMessageClassName={cl("input_error")}
            {...shared}
          />
        </div>
        <div className={cl("row")} style={{ zIndex: 1 }}>
          <SelectInput
            name={"country"}
            options={countryOptions}
            validator={utils.validateNotBlank}
            errorMessage={`${this.LANG("country")} ${this.LANG("required")}`}
            placeholder={`${this.LANG("country")}`}
            inputClassName={`${cl("select")} form_input`}
            containerClassName={`${cl("select_container")}`}
            errorMessageClassName={cl("select_error")}
            arrow={<div className={"Select-arrow"} />}
            {...shared}
          />
          <StandardInput
            name="postal_code"
            validator={utils.validateNotBlank}
            errorMessage={`${this.LANG("postalCode")} ${this.LANG("required")}`}
            containerClassName={cl("input_container")}
            inputClassName={`${cl("input")} form_input`}
            placeholder={`${this.LANG("postalCode")}*`}
            errorMessageClassName={cl("input_error")}
            {...shared}
          />
        </div>

        <div className={cl("row")}>
          <h3>{this.LANG("passport")}</h3>
        </div>
        <div className={cl("row")}>
          <StandardInput
            name="passport"
            validator={utils.validateNotBlank}
            errorMessage={`${this.LANG("idNumber")} ${this.LANG("required")}`}
            containerClassName={cl("input_container")}
            inputClassName={`${cl("input")} form_input`}
            placeholder={`${this.LANG("idNumber")}*`}
            errorMessageClassName={cl("input_error")}
            {...shared}
          />
        </div>

        <div className={cl("row")}>
          <Textarea
            name="comments"
            errorMessage={`Comments ${this.LANG("required")}`}
            placeholder={`${this.LANG("additionalComments")}`}
            containerClassName={cl("textarea_container")}
            inputClassName={`${cl("textarea")} form_input`}
            errorMessageClassName={cl("textarea_error")}
            {...shared}
          />
        </div>

        <div className={cl("row")}>
          <h3>{this.LANG("paymentInfo")}</h3>
        </div>

        <div className={cl("row")} style={{ zIndex: 1 }}>
          <SelectInput
            name={"cc_type"}
            validator={utils.validateNotBlank}
            options={cardOptions}
            errorMessage={`${this.LANG("cardType")} ${this.LANG("required")}`}
            placeholder={`${this.LANG("cardType")}`}
            inputClassName={`${cl("select")} form_input`}
            containerClassName={`${cl("select_container")}`}
            errorMessageClassName={cl("select_error")}
            arrow={<div className={"Select-arrow"} />}
            {...shared}
          />
        </div>

        <div className={cl("row")}>
          <StandardInput
            name="cc_number"
            validator={utils.validateCC}
            textFormatting={utils.CCNumberMask}
            errorMessage={`${this.LANG("cardNumber")} ${this.LANG("notValid")}`}
            containerClassName={cl("input_container")}
            inputClassName={`${cl("input")} form_input`}
            placeholder={`${this.LANG("cardNumber")}*`}
            errorMessageClassName={cl("input_error")}
            {...shared}
          />
        </div> 

        <div className={cl("row")}>
          <StandardInput
            name="cc_exp"
            validator={utils.validateCCExp}
            textFormatting={utils.CCExpMask}
            errorMessage={`${this.LANG("cardExpiration")} ${this.LANG(
              "notValid"
            )}`}
            containerClassName={cl("input_container")}
            inputClassName={`${cl("input")} form_input`}
            placeholder={`${this.LANG("cardExpiration")}*`}
            errorMessageClassName={cl("input_error")}
            {...shared}
          />
          <StandardInput
            name="cc_cvv"
            validator={utils.validateCVV}
            textFormatting={utils.CVVMask}
            errorMessage={`CVV ${this.LANG("notValid")}`}
            containerClassName={cl("input_container")}
            inputClassName={`${cl("input")} form_input`}
            placeholder={`CVV*`}
            errorMessageClassName={cl("input_error")}
            {...shared}
          />
        </div>

        <div className={cl("row")}>
          <StandardInput
            name="cc_name"
            validator={utils.validateNotBlank}
            errorMessage={`${this.LANG("cardName")} ${this.LANG("required")}`}
            containerClassName={cl("input_container")}
            inputClassName={`${cl("input")} form_input`}
            placeholder={`${this.LANG("cardName")}*`}
            errorMessageClassName={cl("input_error")}
            {...shared}
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className={cl("")}>
        <BookingFormContainer
          render={this.renderForm}
          submit={this.props.createReservation}
          successMessage={this.LANG("bookingSuccessMessage")}
          submitText={this.LANG("bookingSubmitText")}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  lang: global.lang,
});

export default connect(mapStateToProps, { createReservation })(
  CreateBookingForm
);
