import styles from "../../styles/views/_reservation.scss";
import {
  _class,
  stayTaxes,
  stayFees,
  staySubtotal,
  stayTotal,
  packagesTotal,
  isCancelled,
} from "../utilities/helpers";
import Reveal from "../widgets/Reveal";
import { connect } from "react-redux";
import {
  getReservation,
  deleteReservation,
  createBookingDetailsFromReservation,
  updateBookingDetails,
  deleteAvailability,
  deleteBookingDetails,
  getAvailability,
  getAvailabilities,
} from "../redux/synxis/SynxisActions";
import Loader from "../partials/Loader";
import ViewReservation from "../partials/ViewReservation";
// import CTA from "../partials/CTA";
import ReservationDetailsWidget from "../partials/ReservationDetailsWidget";
import moment from "moment";
import CancelledReservation from "../partials/checkout/CancelledReservation";
import { Translation } from "../utilities/Translation";

const cl = _class(styles, "reservation");

class Reservation extends React.Component {
  static propTypes = {
    page: PropTypes.object,
    CONTENT: PropTypes.object,
    reservation: PropTypes.object,
    reservationPending: PropTypes.bool,
    getReservation: PropTypes.func,
    deleteReservation: PropTypes.func,
    rooms: PropTypes.object,
    createBookingDetailsFromReservation: PropTypes.func,
    updateBookingDetails: PropTypes.func,
    bookingDetails: PropTypes.object,
    singleAvailability: PropTypes.object,
    deleteAvailability: PropTypes.func,
    getAvailability: PropTypes.func,
    deleteBookingDetails: PropTypes.func,
    currency: PropTypes.object,
    lang: PropTypes.string,
    getAvailabilities: PropTypes.func,
    reservationError: PropTypes.string,
  };

  static contextTypes = {
    filterByLang: PropTypes.func,
    viewport: PropTypes.object,
    history: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      confirmationNumber: "",
      email: "",
      // tab: "modify",
      errors: {
        unit: null,
        guests: null,
      },
    };

    this.LANG = new Translation(props.lang);
  }

  checkRate = () => {
    const { roomCode, guests, from, to } = this.props.bookingDetails;

    if (!guests || !roomCode) {
      return this.setState({ errors: { guests: !guests, unit: !roomCode } });
    } else {
      this.setState({ errors: {} });
    }

    this.props.deleteAvailability();

    this.props.getAvailability(roomCode, {
      adults: guests,
      startDate: moment(from).format("YYYY-MM-DD"),
      endDate: moment(to).format("YYYY-MM-DD"),
    });

    this.props.getAvailabilities({
      adults: guests,
      startDate: moment(from).format("YYYY-MM-DD"),
      endDate: moment(to).format("YYYY-MM-DD"),
      currencyCode: this.props.currency.code,
      content: "full",
      roomCode,
      onlyCheckRequested: true,
    });
  };

  componentDidUpdate(prevProps) {
    const {
      bookingDetails,
      rooms,
      reservation,
      singleAvailability,
      updateBookingDetails,
    } = this.props;

    if (!bookingDetails && rooms && reservation) {
      return this.setBookingDetails();
    }

    if (
      !prevProps.bookingDetails &&
      bookingDetails &&
      bookingDetails.status === "Booked" &&
      moment(bookingDetails.from).toDate() <= moment().toDate()
    ) {
      const checkoutPage = this.props.CONTENT.byTemplate("checkout").filter(
        this.context.filterByLang
      )[0];
      this.context.history.pushTransition(checkoutPage.path);
    }

    if (singleAvailability && prevProps.singleAvailability === null) {
      updateBookingDetails({
        availability: singleAvailability,
        subtotal: staySubtotal(singleAvailability),
        taxes: stayTaxes(singleAvailability),
        total:
          parseFloat(stayTotal(singleAvailability)) +
          parseFloat(packagesTotal(reservation.Packages)),
        fees: stayFees(singleAvailability),
        rate: singleAvailability.Product.Rate.Code,
      });
    }
  }

  componentWillUnmount() {
    if (
      this.props.bookingDetails &&
      this.props.bookingDetails.status !== "Booked"
    ) {
      this.props.deleteReservation();
      this.props.deleteBookingDetails();
    }
  }

  resetForm = () => {
    this.props.deleteReservation();
    this.props.deleteBookingDetails();
    this.setState({ confirmationNumber: "", email: "" });
  };

  // setTab = (tab) => this.setState({ tab });

  setBookingDetails = () => {
    const { reservation, rooms, CONTENT } = this.props;
    console.log('reservation', reservation)
    const roomCode = reservation.RoomStay.Products[0].Product.RoomCode;
    const room = rooms[roomCode];
    const cmsRoom =
      CONTENT.byTemplate("room")
        .filter(this.context.filterByLang)
        .find((r) => r.h4 === roomCode) || {};
    const cmsProperty = CONTENT.byId(cmsRoom.parentid);

    this.props.createBookingDetailsFromReservation({
      room,
      cmsRoom,
      cmsProperty,
      reservation,
    });
  };

  getReservation = (e) => {
    e.preventDefault();
    this.props.getReservation(this.state);
  };

  // renderCTAs = () => {
  //   const { tab } = this.state;
  //   return (
  //     <Reveal cssAnimation={["fade", "bottom"]} className={cl("ctas")}>
  //       <CTA
  //         text={"Modify Reservation"}
  //         onClick={() => this.setTab("modify")}
  //         arrow={tab === "modify"}
  //       />
  //       <CTA
  //         text={"Cancel Reservation"}
  //         onClick={() => this.setTab("cancel")}
  //         arrow={tab === "cancel"}
  //       />
  //     </Reveal>
  //   );
  // };

  renderReservationLookupForm = () => {
    const { page, reservation, reservationError } = this.props;

    if (!reservation) {
      return (
        <Reveal cssAnimation={["fade", "top"]}>
          <h1>{page.h1}</h1>

          <form onSubmit={this.getReservation} className={cl("form")}>
            <input
              className={cl("form__input")}
              type="text"
              value={this.state.confirmationNumber}
              onChange={(e) =>
                this.setState({ confirmationNumber: e.target.value })
              }
              placeholder={this.LANG("reservationNumber")}
            />
            <input
              className={cl("form__input")}
              type="text"
              value={this.state.email}
              onChange={(e) => this.setState({ email: e.target.value })}
              placeholder={this.LANG("emailForBooking")}
            />
            <input type="submit" value="Go" className={cl("form__submit")} />
          </form>

          <div className={cl("search_error")}>
            {/* <div>//check</div> */}
            <p>{reservationError}</p>
          </div>
        </Reveal>
      );
    }
  };

  renderConfirmations = () => {
    const { reservation } = this.props;

    return (
      <div className={cl("reservation_confirmation")}>
        <p className={cl("reservation_confirmation__title")}>
          Reservation Number
        </p>
        <p className={cl("reservation_confirmation__number")}>
          {reservation.CRS_confirmationNumber}
        </p>
        <p className={cl("reservation_confirmation__title")}>
          Itinerary Number
        </p>
        <p className={cl("reservation_confirmation__number")}>
          {reservation.ItineraryNumber}
        </p>
      </div>
    );
  };

  renderReservationDetails = () => {
    const {
      reservationPending,
      bookingDetails,
      reservation,
      rooms,
    } = this.props;

    // const { tab } = this.state;

    if (reservation && reservation.updated) {
      window.scrollTo(0, 0);
      return (
        <div>
          <div className={cl("columns")}>
            <div className={cl("columns__left")}>
              {this.renderConfirmations()}

              <h2>Reservation updated successfully.</h2>
            </div>
            <div className={cl("columns__right")}>
              <div className={cl("reservations_form")}>
                <ReservationDetailsWidget viewport={this.context.viewport} />
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (reservation && isCancelled(reservation) && bookingDetails) {
      return <CancelledReservation reset={this.resetForm} />;
    }

    // if (reservation && bookingDetails && rooms) {
    //   return (
    //     <div className={cl("details")}>
    //       {this.renderCTAs()}
    //       <div className={cl("columns")}>
    //         <div className={cl("columns__left")}>
    //           {tab === "modify" && (
    //             <ModifyReservation
    //               CONTENT={CONTENT}
    //               errors={this.state.errors}
    //               checkRate={this.checkRate}
    //             />
    //           )}
    //           {tab === "cancel" && <CancelReservation CONTENT={CONTENT} />}
    //         </div>
    //         <div className={cl("columns__right")}>
    //           <div className={cl("reservations_form")}>
    //             <ReservationDetailsWidget viewport={this.context.viewport} />
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   );
    // }

    if (reservation && bookingDetails && rooms) {
      return (
        <div className={cl("details")}>
          <div className={cl("columns")}>
            <div className={cl("columns__left")}>
              <ViewReservation />
            </div>
            <div className={cl("columns__right")}>
              <div className={cl("reservations_form")}>
                <ReservationDetailsWidget viewport={this.context.viewport} />
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (reservationPending) {
      return <Loader />;
    }

    return null;
  };

  render() {
    return (
      <div className={cl("")}>
        <div className={cl("container")}>
          {this.renderReservationLookupForm()}
          {this.renderReservationDetails()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ synxis, global }) => {
  return {
    reservation: synxis.reservation,
    reservationPending: synxis.reservationPending,
    rooms: synxis.rooms,
    bookingDetails: synxis.bookingDetails,
    singleAvailability: synxis.singleAvailability,
    reservationError: synxis.error,
    currency: global.currency,
  };
};

export default connect(mapStateToProps, {
  getReservation,
  deleteReservation,
  createBookingDetailsFromReservation,
  updateBookingDetails,
  deleteAvailability,
  getAvailability,
  getAvailabilities,
  deleteBookingDetails,
})(Reservation);
