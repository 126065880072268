import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import styles from "../../styles/widgets/_modal.scss";
import { TweenMax, CSSPlugin } from "gsap/all";
import { Transition } from "react-transition-group";
import Hamburger from "../partials/Hamburger";
import { _class } from "../utilities/helpers";

const cl = _class(styles, "modal");

export default class Modal extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    open: PropTypes.bool,
    close: PropTypes.func,
    background: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    open: false,
    className: "",
  };

  constructor(props) {
    super(props);

    this.CSS = CSSPlugin;
  }

  componentDidMount() {
    window.addEventListener("keydown", this.toggleEsc);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.toggleEsc);
    enableBodyScroll();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      const el = document.querySelector(
        `.${cl("")} button[class*="hamburger"]`
      );

      setTimeout(() => el.focus(), 300);
    }
  }

  toggleEsc = (e) => {
    if (this.props.open && e.keyCode == 27) {
      this.props.close && this.props.close();
    }
  };

  render() {
    const { open, children, close, background, className } = this.props;

    open ? disableBodyScroll() : enableBodyScroll();

    const bgColor = styles[background] || "";

    return (
      <Transition
        timeout={250}
        mountOnEnter
        unmountOnExit
        in={open}
        addEndListener={(node, done) => {
          TweenMax.to(node, 0.25, {
            opacity: open ? 1 : 0,
            autoAlpha: 1,
            onComplete: done,
          });
        }}
      >
        <div
          className={`${cl("")} ${bgColor} ${className}`}
          onClick={(e) => {
            if (e.target.classList.contains(cl(""))) {
              close && close();
            }
          }}
        >
          <div
            className={cl("container")}
            aria-label="alertdialog"
            tabIndex="1"
          >
            {close && (
              <div className={cl("close")}>
                <Hamburger open={true} onClick={close} color={"black"} />
              </div>
            )}
            {children}
          </div>
        </div>
      </Transition>
    );
  }
}
