import styles from "../../styles/partials/_result-card.scss";
import { _class, numberWithCommas } from "../utilities/helpers";
import Image from "../widgets/Image";
import CTA from "./CTA";
import { Translation } from "../utilities/Translation";

const cl = _class(styles, "result_card");

class ResultCard extends React.Component {
  static propTypes = {
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      align: PropTypes.string,
    }),
    title: PropTypes.string,
    subtitle: PropTypes.string,
    blurb: PropTypes.string,
    cta: PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string,
    }),
    bedrooms: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    occupancy: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    price: PropTypes.string,
    category: PropTypes.string,
    lang: PropTypes.string.isRequired,
    small: PropTypes.bool,
    location: PropTypes.string,
    path: PropTypes.string,
    currency: PropTypes.object,
  };

  static defaultProps = {
    title: "",
    image: {},
    blurb: "",
    category: "",
    onClick: () => null,
  };

  constructor(props) {
    super(props);

    this.LANG = new Translation(props.lang);
  }

  renderPrice() {
    const { price, currency } = this.props;

    if (price) {
      return (
        <div className={cl("bottom__price")}>
          <p>{this.LANG("startingAt")}</p>
          <p>
            {currency.symbol}
            {numberWithCommas(price, 0)} {this.LANG("perNight")}
          </p>
        </div>
      );
    }
  }

  render() {
    const {
      image,
      title,
      subtitle,
      blurb,
      cta,
      bedrooms,
      occupancy,
      category,
      small,
      location,
      path,
    } = this.props;

    return (
      <div className={`${cl("")} ${small ? styles.small : ""}`}>
        <div className={cl("image")}>
          <Image src={image.src} alt={image.alt} align={image.align} />
        </div>
        <div className={cl("content")}>
          <span className={cl("location")}>{location || category}</span>
          <h3 className={cl("title")}>{title}</h3>
          {small && subtitle && <h4 className={cl("subtitle")}>{subtitle}</h4>}
          {!small && (
            <div
              dangerouslySetInnerHTML={{
                __html: blurb,
              }}
              className={cl("blurb")}
            />
          )}
          {!small && (
            <div className={cl("details")}>
              <span>
                {this.LANG("bedroom")}s {bedrooms}
              </span>
              <span>
                {this.LANG("sleeps")} {occupancy}
              </span>
            </div>
          )}
          <div className={cl("bottom")}>
            {this.renderPrice()}

            <div className={cl("cta")}>
              <CTA link={path || cta.link} text={cta.text} size="small" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResultCard;
