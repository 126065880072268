import styles from "../../styles/partials/_image-cards.scss";
import { _class, getImage } from "../utilities/helpers";
import Image from "../widgets/Image";
import Link from "../widgets/Link";

const cl = _class(styles, "image_cards");

ImageCards.propTypes = {
  cards: PropTypes.array,
  onClick: PropTypes.func,
};

ImageCards.defaultProps = {
  cards: [],
  onClick: () => null,
};

export default function ImageCards({ cards, onClick }) {
  const lengthStyle = cards.length === 4 ? styles.four : "";

  return (
    <ul className={cl("")}>
      {cards.map((item) => {
        const { src, alt, align } = getImage(item);

        return (
          <li className={`${cl("item")} ${lengthStyle}`} key={item.id}>
            <Link onClick={onClick.bind(null, item)}>
              <div className={cl("item__image")}>
                <Image src={src} alt={alt} align={align} />
              </div>
              <p>{item.linktitle}</p>
            </Link>
          </li>
        );
      })}
    </ul>
  );
}
