import styles from "../../styles/views/_properties.scss";
import {
  _class,
  createResultCard,
  parse,
  getFieldGroupImage,
} from "../utilities/helpers";
import Intro from "../partials/Intro";
import ResultsBlock from "../partials/ResultsBlock";
import { connect } from "react-redux";
import Loader from "../partials/Loader";

const cl = _class(styles, "hotels");

class Hotels extends React.Component {
  static propTypes = {
    page: PropTypes.object,
    CONTENT: PropTypes.object,
    rooms: PropTypes.object,
    lang: PropTypes.string,
    currency: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.imageCards = props.CONTENT.childrenById(props.page.id) || [];
    this.rooms = this.imageCards.reduce((sum, brand) => {
      return sum.concat(this.props.CONTENT.childrenById(brand.id));
    }, []);

    this.state = {
      resultCards: undefined,
    };
  }

  componentDidMount() {
    if (this.props.rooms) {
      this.init();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.rooms && this.props.rooms) {
      this.init();
    }
  }

  init = () => this.setState({ resultCards: this.getResultCards() });

  getResultCards = () => {
    const { CONTENT, rooms, currency, lang } = this.props;

    return this.rooms
      .reduce((sum, room) => {
        const parent = CONTENT.byId(room.parentid);
        const isPrivate = room.checkbox1;

        if (!isPrivate) {
          const card = createResultCard({
            page: room,
            category: parent.h1,
            collections: parse(room.blurb6, []).map((coll) => coll.value),
            lang,
            currency,
            synxisRoom: rooms[room.h4],
            icon: "stay",
            image: getFieldGroupImage(room.fieldgroup2),
            price: false,
          });

          sum.push(card);
        }

        return sum;
      }, [])
      .sort((a, b) => parseInt(a.bedrooms) - parseInt(b.bedrooms));
  };

  renderResultsBlock = () => {
    const { resultCards } = this.state;

    if (resultCards) {
      return (
        <ResultsBlock
          filters={[]}
          tabs={"children"}
          resultCards={resultCards}
          imageCards={this.imageCards}
          view={"list"}
          page={this.props.page}
          checkboxOptions={["Amenities", "Collections", "Accommodations"]}
        />
      );
    }

    return (
      <div style={{ minHeight: "30vh" }}>
        <Loader />
      </div>
    );
  };

  render() {
    const { page } = this.props;

    return (
      <div className={cl("")}>
        <div className={cl("container")}>
          <Intro title={page.h1} blurb={page.blurb1} />
          {this.renderResultsBlock()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ synxis, global }) => ({
  rooms: synxis.rooms,
  lang: global.lang,
  currency: global.currency,
});

export default connect(mapStateToProps)(Hotels);
