import moment from "moment";
import { Translation } from "./Translation";
import API from "./API";

// Parse JSON.
export const parse = (json, fallback = false) => {
  try {
    if (json === null || json === "") {
      return fallback;
    }

    if (json === "[]" && fallback) {
      return fallback;
    }

    return JSON.parse(json) || fallback;
  } catch (e) {
    console.error(e);
    return fallback;
  }
};

// Creates a range (array) of numbers.
export const range = (integer, start = 0) =>
  [...Array(parseInt(integer)).keys()].map((i) => i + parseInt(start));

// Capitalize a string.
export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s
    .split(" ")
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
    .join(" ");
};

export const unique = (array) => {
  return array.filter((item, index) => {
    const _item = JSON.stringify(item);
    return index === array.findIndex((obj) => JSON.stringify(obj) === _item);
  });
};

// abbreviate class name with a prefix
export const _class = (styles, prefix) => (name) => {
  return name ? styles[`${prefix}__${name}`] : styles[`${prefix}`];
};

export const numberWithCommas = (num, digits = 2) => {
  return parseFloat(num || 0)
    .toFixed(digits)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const setQueryParams = (obj) => {
  if (!obj) {
    return "";
  }

  const params = Object.keys(obj).reduce((sum, key) => {
    const val = obj[key];

    sum.push(`${key}=${Array.isArray(val) ? val.join(",") : val}`);

    return sum;
  }, []);

  return "?" + params.join("&");
};

export const getQueryParams = (searchString, fallback) => {
  if (searchString) {
    const params = new URLSearchParams(searchString);

    const obj = {};
    for (const [key, value] of params.entries()) {
      obj[key] = value.match(",") ? value.split(",") : value;
    }

    return obj;
  }

  return fallback;
};

export const lodgingOptions = (lang) => {
  const LANG = new Translation(lang);
  return [
    {
      label: LANG("hotels"),
      value: "hotel",
    },
    {
      label: LANG("vacationRentals"),
      value: "vacation_rental",
    },
    {
      label: LANG("all"),
      value: "All",
    },
  ];
};

export const reservationOptions = (label, integer = 1, start = 1, lang) => {
  const LANG = new Translation(lang);
  return range(integer, start).map((i) => {
    const formattedLabel = LANG(label + (i !== 1 ? "s" : ""));

    return {
      label: `${i} ${formattedLabel}`,
      value: i,
    };
  });
};

export const getImage = (page) => {
  if (page.fieldgroup1 && page.fieldgroup1[0]) {
    return {
      src: page.fieldgroup1[0].image1,
      alt: page.fieldgroup1[0].image1_alt_text,
      align: page.fieldgroup1[0].image1_anchor,
    };
  }

  if (page.image1) {
    return {
      src: page.image1,
      alt: page.image1_alt_text,
      align: page.image1_anchor,
    };
  }

  return { src: "", alt: "", align: "" };
};

export const getFieldGroupImage = (fieldgroup) => {
  if (fieldgroup && fieldgroup[0]) {
    return {
      src: fieldgroup[0].image1,
      alt: fieldgroup[0].image1_alt_text,
      align: fieldgroup[0].image1_anchor,
    };
  }

  return { src: null, alt: null, align: null };
};

export const truncate = (string = "", length = 0) => {
  if (string.length > length) {
    return string.slice(0, length).split(" ").slice(0, -1).join(" ") + "...";
  }

  return string;
};

export const stayLength = (a, b) =>
  Math.abs(moment(b).startOf("day").diff(moment(a).startOf("day"), "days"));

export const staySubtotal = (availability) => {
  return availability && availability.Product.Prices.Total.Price.Total.Amount;
};

export const stayTaxes = (availability) => {
  if (availability) {
    return availability.Product.Prices.Total.Price.Tax.Amount;
  }
};

export const stayFees = (availability) => {
  if (availability) {
    return availability.Product.Prices.Total.Price.Fees.Amount;
  }
};

export const stayFeesBreakdown = (availability) => {
  if (availability) {
    return availability.Product.Prices.Total.Price.Fees.BreakDown.map(
      (fee) => ({
        Code: capitalize(fee.Code.toLowerCase()),
        Amount: fee.Amount,
      })
    );
  }
};

export const stayTotal = (availability) => {
  return (
    availability &&
    availability.Product.Prices.Total.Price.Total.AmountWithTaxesFees
  );
};

export const stayNightlyRate = (availability) => {
  return (
    availability && availability.Product.Prices.PerNight.Price.Total.Amount
  );
};

export const isCancelled = (reservation) =>
  reservation && reservation.CRS_cancellationNumber;

export const packagesTotal = (packages = []) =>
  packages.reduce((sum, pkg) => {
    // if (pkg.Price) {
    //   return sum + pkg.Price.TotalAmountIncludingTaxesFees;
    // }
    const price = pkg.Total
      ? pkg.Total.AmountAfterTax
      : pkg.Price.TotalAmountIncludingTaxesFees;

    return sum + price;
  }, 0);

export const createResultCard = ({
  page,
  category, // can be string or array - see tab filtering in ResultsBlock
  collections,
  currency,
  lang,
  location,
  path,
  synxisRoom,
  icon,
  cta,
  image,
  price,
}) => ({
  // CMS attributes
  id: page.id + Math.random(),
  page_id: page.id,
  latitude: page.latitude,
  longitude: page.longitude,
  title: page.linktitle,
  subtitle: page.buttonblurb1,
  cta: cta || { link: page.path, text: lang === "es" ? "Ver" : "View" },
  image: image || getImage(page),
  blurb: truncate(page.blurb1 || "", 200),
  bedrooms: page.h5,
  price: price === false || price ? price : page.h6,
  currency,
  lang,
  category, //aka tab
  collections,
  type: parse(page.selectgroup1, []).length
    ? parse(page.selectgroup1, [])[0].name
    : "",
  location,
  path,
  icon,

  // SYNXIS ATTRIBUTES
  amenities: (synxisRoom && synxisRoom.Details.FeatureList) || [],
  occupancy: synxisRoom && synxisRoom.Details.GuestLimit.GuestLimitTotal,
});

export const centerCoordinates = (options) => ({
  lat: parseFloat(options.latitude),
  lng: parseFloat(options.longitude),
});

export const formatPackages = (formData, bookingDetails) => {
  if (bookingDetails.packages && bookingDetails.packages.length) {
    return bookingDetails.packages.map((pkg) => ({
      Code: pkg.ServiceInventoryCode,
      Date: moment(bookingDetails.from).format("YYYY-MM-DD"),
      Time: moment().format("HH:mm:ssZ"),
      GuestCount: [
        {
          AgeQualifyingCode: "Adult",
          NumGuests: parseInt(bookingDetails.guests),
        },
      ],
    }));
  }

  return [];
};

export const formatRoomStay = (formData, bookingDetails) => {
  return {
    StartDate: moment(bookingDetails.from).format("YYYY-MM-DD"),
    EndDate: moment(bookingDetails.to).format("YYYY-MM-DD"),
    CheckInDate: moment(bookingDetails.from).format("YYYY-MM-DD"),
    CheckOutDate: moment(bookingDetails.to).format("YYYY-MM-DD"),
    GuestCount: [
      {
        AgeQualifyingCode: "Adult",
        NumGuests: parseInt(bookingDetails.guests),
      },
    ],
    NumRooms: 1,
    Products: [
      {
        StartDate: moment(bookingDetails.from).format("YYYY-MM-DD"),
        EndDate: moment(bookingDetails.to).format("YYYY-MM-DD"),
        Primary: true,
        Product: {
          RateCode: bookingDetails.rate, // not sure about this yet...
          RoomCode: bookingDetails.roomCode, // use synxis room code?
        },
      },
    ],
  };
};

export const formatPromotion = (formData, bookingDetails) => {
  return {
    AccessKey: {
      Password: bookingDetails.promo,
    },
    Type: "Promotion",
  };
};

export const formatGuests = (formData) => {
  return [
    {
      Role: "Primary",
      PersonName: {
        GivenName: formData.first_name,
        Surname: formData.last_name,
      },
      Payments: [
        {
          PaymentCard: {
            CardCode: formData.cc_type,
            CardHolder: formData.cc_name,
            CardNumber: formData.cc_number,
            CardSecurityCode: formData.cc_cvv,
            ExpireDate: formData.cc_exp,
          },
          Type: "CreditCard",
        },
      ],
      TravelDocuments: [
        {
          Type: "Passport",
          Number: formData.passport,
        },
      ],
      Locations: [
        {
          Address: {
            AddressLine: [formData.address],
            City: formData.city,
            Country: {
              Code: formData.country,
            },
            Default: true,
            PostalCode: formData.postal_code,
            // StateProv: {
            //   Code: "TX",
            // },
          },
        },
      ],
      ContactNumbers: [
        {
          Number: formData.phone,
        },
      ],
      EmailAddress: [
        {
          Type: "Primary",
          Value: formData.email,
        },
      ],
    },
  ];
};

export const orderNumber = () => `BTT_$${moment().unix()}`;

export const renderSynxisError = ({ status, info, date }) => {
  if (synxisErrors[status]) {
    let formattedDate = date ? moment(date).format("YYYY-MM-DD") : "";

    return synxisErrors[status](info, formattedDate);
  }

  return status + (info ? ` ${info}` : "");
};

export const synxisErrors = {
  MinStayThrough: (info) => `Minimum stay of ${info} nights is required`,
  MaxStayThrough: (info) => `Maximum stay of ${info} nights is permitted`,
  NoAvailableInventory: (info, date) => `Not available on ${date}`,
  MinStayArrive: (info) =>
    `Arrival only valid with minimum stay of ${info} nights`,
  MaxStayArrive: (info) =>
    `Arrival only valid with maximum stay of ${info} nights`,
  NoArrive: (info, date) => `No arrival on ${date}`,
  NoDeparture: (info, date) => `No departure  on ${date}`,
  Closed: (info, date) => `Closed on ${date}`,
};

/**
 * Executes an async function multiple times until it succeeds.
 *
 * @param   {Function} asyncFunc     an async function that returns a promise
 * @param   {object} opts            object of options for retrying a request
 *
 * @return  {Promise}                resolves to an object { error, data }
 */
export const asyncRetry = (asyncFunc, opts = { times: 5 }) => {
  return new Promise(async (resolve, reject) => {
    let data, error;

    for (let i = 0; i < opts.times; i++) {
      let response = await asyncFunc();

      if (response.error) {
        error = response.error;
      } else {
        data = response;
        break;
      }
    }

    data
      ? resolve({ data, error })
      : reject({ data, error: error || "AsyncRetry: failure" });
  });
};

/**
 * Track Google Analytics events.
 *
 * @param   {object} params     label, category, action
 *
 */
export const gaTrackEvent = ({ label, category, action }) => {
  if (window.ga && label && category && action) {
    ga("send", {
      hitType: "event",
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: 0,
    });
  }
};

/**
 * Track Google Analytics page views for goal funnels.
 *
 * @param   {string} label     page name
 *
 */
export const gaTrackPage = (label) => {
  if (window.ga && label) {
    ga("send", "pageview", label);
  }
};

/**
 * Track Facebook events via Facebook Pixel and Conversions API
 * EventName is required
 * https://developers.facebook.com/docs/marketing-api/conversions-api/get-started
 *
 * @param   {string} eventName      event name
 * @param   {object} data           optional pixel and server data
 *
 */
export const fbTrackEvent = (eventName, data = {}) => {
  if (!eventName) {
    console.error("Facebook tracking requires an event name.");
    return false;
  }

  // used for deduplicating browser & server events
  const eventID = `${eventName}__${new Date().getTime()}`;

  //server tracking
  const api = new API({ base: API_PATH });

  api.post("facebook_events", {
    ...data.serverData,
    event: eventName,
    url: window.location.href,
    path: window.location.path,
    event_id: eventID,
    fbp: getCookie("_fbp"),
    fbc: getCookie("_fbc"),
  });

  // browser tracking
  if (window.fbq) {
    fbq("track", eventName, data.pixelData, { eventID: eventID });
  }
};

/**
 * Track Google Tag Manager events
 *
 * @param   {string} label     event name
 * @param   {object} data      optional data
 * @param   {object} url       optional url
 *
 */
export const gtagTrackEvent = (label, data, url) => {
  if (window.gtag && label) {
    gtag("event", "conversion", {
      send_to: label,
      event_callback: () => {
        if (typeof url != "undefined") {
          window.location = url;
        }
      },
      ...data,
    });

    return false;
  }
};

/**
 * Track Google Tag Manager ecommerce events. Sends to Adwords
 *
 * @param   {object} data      transaction data
 *
 */
export const gtagTrackECommerce = (data) => {
  if (window.gtag) {
    gtag("event", "purchase", data);
  }
};

/**
 * Track Google Analytics ecommerce events. Sends to GA
 *
 * @param   {object} transaction      transaction data
 * @param   {array}  items            item data
 *
 */
export const gaTrackECommerce = ({ transaction, items = [] }) => {
  if (window.ga && transaction) {
    ga("ecommerce:addTransaction", transaction);

    items.forEach((item) => ga("ecommerce:addItem", item));

    ga("ecommerce:send");
  }
};

/**
 * Retrieve the value of the specified cookie
 *
 * @param   {string} name      name of cookie
 *
 */
export const getCookie = (name) => {
  var cookieArr = document.cookie.split(";");

  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");

    if (name == cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }

  return null;
};
