import Modal from "../widgets/Modal";
import Slick from "react-slick";
import Image from "../widgets/Image";
import styles from "../../styles/partials/_gallery-modal.scss";
import { _class } from "../utilities/helpers";
import VideoPlayer from "../widgets/VideoPlayer";
import { useEffect, useRef } from "react";

const cl = _class(styles, "gallery_modal");

GalleryModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  slides: PropTypes.array.isRequired,
  settings: PropTypes.object,
  afterChange: PropTypes.func,
  initialSlide: PropTypes.number,
  keyboardControl: PropTypes.bool,
};

GalleryModal.defaultProps = {
  open: false,
  onClose: () => null,
  slides: [],
  settings: {
    arrows: true,
    slidesToShow: 1,
    infinite: true,
  },
  afterChange: () => null,
  initialSlide: 0,
};

export default function GalleryModal({
  open,
  onClose,
  slides,
  settings,
  keyboardControl,
}) {
  const _settings = { settings, keyboardControl };
  const slick = useRef(null);

  const handleKeyDown = (e) => {
    if (keyboardControl && slick.current) {
      if (
        !document.activeElement ||
        document.activeElement.tagName !== "INPUT"
      ) {
        if (e.key === "ArrowLeft" || e.key === "Left") {
          slick.current.slickPrev();
        } else if (e.key === "ArrowRight" || e.key === "Right") {
          slick.current.slickNext();
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  const imageComponentFor = (slide) => {
    if (slide.video_url) {
      return <VideoPlayer url={slide.video_url} playing={false} />;
    }
    const imgProps = {
      align: slide.image1_anchor,
      src: slide.image1,
      alt: slide.image1_alt_text,
    };
    return <Image {...imgProps} />;
  };

  return (
    <Modal open={open} close={onClose} className={cl("")}>
      <div className={`carousel`}>
        <Slick {..._settings} ref={slick}>
          {slides.map((slide, index) => (
            <div key={index}>{imageComponentFor(slide)}</div>
          ))}
        </Slick>
      </div>
    </Modal>
  );
}
