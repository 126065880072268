import Select from "react-select";
import ErrorHandler from "./ErrorHandler";
import LabelContainer from "./LabelContainer";

class SelectInput extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    arrow: PropTypes.node.isRequired,
    inputClassName: PropTypes.string,
    containerClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    errorMessageClassName: PropTypes.string,
    inputStyle: PropTypes.object,
    labelStyle: PropTypes.object,
    errorMessageStyle: PropTypes.object,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    searchable: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    clearable: PropTypes.bool,
    onChange: PropTypes.func,
    handleOnBlur: PropTypes.func,
    errorMessage: PropTypes.string,
    validator: PropTypes.func,
    label: PropTypes.string,
    labelOnBottom: PropTypes.bool,
    formState: PropTypes.object.isRequired,
    formFunctions: PropTypes.object.isRequired,
    formName: PropTypes.string.isRequired,
  };

  static defaultProps = {
    searchable: false,
    clearable: false,
    labelOnBottom: false,
    handleOnBlur: () => {},
  };

  constructor(props) {
    super(props);

    this.initialValue =
      props.formState.values[props.name] || props.defaultValue || "";
    this.placeholder = props.placeholder || "";
  }

  componentDidMount() {
    let hasError = false;
    this.props.formFunctions.updateField(this.props.name, this.initialValue);
    this.props.formFunctions.updateFieldErrors(this.props.name, hasError);

    [...document.querySelectorAll(".Select-input")].forEach(
      (div) => (div.style.width = "0px")
    );

    [...document.querySelectorAll(".Select-placeholder")].forEach((div) => {
      div.setAttribute("aria-live", "assertive");
      div.setAttribute("aria-atomic", true);
    });
  }

  inputChange = (e) => {
    this.selectedOption = e;
    let value = e.value;
    this.props.formFunctions.updateField(
      this.props.name,
      value,
      this.props.onChange
    );
  };

  renderArrow = () => {
    return this.props.arrow;
  };

  handleOnBlur = () => {
    this.props.handleOnBlur();
    document.removeEventListener("keydown", this.scrollOptionIntoView);
  };

  handleOnFocus = () => {
    document.addEventListener("keydown", this.scrollOptionIntoView);
  };

  scrollOptionIntoView = () => {
    const optionFocused = document.getElementsByClassName(
      "Select-option is-focused"
    )[0];
    optionFocused &&
      optionFocused.scrollIntoView({ behavior: "smooth", block: "nearest" });
  };

  render() {
    //comment
    const {
      containerClassName,
      inputClassName,
      labelClassName,
      errorMessageClassName,
      label,
      labelOnBottom,
      inputStyle,
      labelStyle,
      errorMessageStyle,
      disabled,
      name,
      formState,
      formFunctions,
      validator,
      errorMessage,
      options,
      searchable,
      clearable,
    } = this.props;
    const displayError =
      formState.errors[name] && formState.submitAttempted && !formState.success;
    const fieldId = `${name}_${this.props.formName}`;
    return (
      <div className={`field ${containerClassName}`}>
        {!labelOnBottom && (
          <LabelContainer
            {...this.props}
            name={fieldId}
            className={labelClassName}
            style={labelStyle}
          >
            {label}
          </LabelContainer>
        )}
        <Select
          id={fieldId}
          className={`${inputClassName} ${
            displayError ? "error" : ""
          } form_select`}
          style={inputStyle}
          disabled={disabled}
          name={name}
          options={options}
          onChange={(e) => this.inputChange(e)}
          inputProps={{ readOnly: true }}
          searchable={searchable}
          clearable={clearable}
          arrowRenderer={this.renderArrow}
          value={
            formState.values[name]
              ? options.find((o) => o.value === formState.values[name])
              : ""
          }
          placeholder={this.placeholder}
          onBlur={this.handleOnBlur}
          onFocus={this.handleOnFocus}
          classNamePrefix={"form_select"}
        />
        {labelOnBottom && (
          <LabelContainer
            {...this.props}
            name={fieldId}
            className={labelClassName}
            style={labelStyle}
          >
            {label}
          </LabelContainer>
        )}
        {validator && (
          <ErrorHandler
            name={name}
            formState={formState}
            errorHandler={validator}
            formFunctions={formFunctions}
            className={errorMessageClassName}
            style={errorMessageStyle}
          >
            {errorMessage}
          </ErrorHandler>
        )}
      </div>
    );
  }
}

export default SelectInput;
