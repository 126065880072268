import styles from "../../styles/views/_text-and-grid.scss";
import { _class, parse } from "../utilities/helpers";
import Hero from "../partials/Hero";
import Intro from "../partials/Intro";
import ImageAndText from "../partials/ImageAndText";

const cl = _class(styles, "text_and_grid");

class TextAndGrid extends React.Component {
  static propTypes = {
    page: PropTypes.object,
    CONTENT: PropTypes.object,
  };

  static contextTypes = {
    history: PropTypes.object,
    viewport: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.slides = props.page.fieldgroup1 || [];
    this.items = props.page.fieldgroup2 || [];
  }

  renderImageAndTextBlocks = () => {
    return this.items.map((item, i) => (
      <ImageAndText
        key={item.id}
        title={item.h1}
        blurb={item.blurb1}
        image={{
          src: item.image1,
          alt: item.image1_alt,
          align: item.image1_anchor,
        }}
        cta={parse(item.buttonlink1)}
        imageAlign={i % 2 === 0 ? "left" : "right"}
      />
    ));
  };

  render() {
    const { page } = this.props;

    return (
      <div className={cl("")}>
        <Hero
          imageData={{ slides: this.slides }}
          videoLinkPresent={page.video_link !== null && page.video_link !== ""}
          videoLink={page.video_link}
        />
        <div className={cl("container")}>
          <Intro title={page.h1} subtitle={page.h2} blurb={page.blurb1} />
          {this.renderImageAndTextBlocks()}
        </div>
      </div>
    );
  }
}

export default TextAndGrid;
