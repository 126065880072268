import styles from "../../styles/views/_experience_detail.scss";
import { _class, parse } from "../utilities/helpers";
import Intro from "../partials/Intro";
import ImageGrid from "../partials/ImageGrid2";
import HubSpotForm from "../partials/HubSpotForm";
import Hero from "../partials/Hero";

const cl = _class(styles, "experience_detail");

//Experience Detail has same layout but different fields as Group Travel, so here we are...
class ExperienceDetail extends React.Component {
  static propTypes = {
    page: PropTypes.object,
    CONTENT: PropTypes.object,
  };

  static contextTypes = {
    activeLangPage: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.slides = props.page.fieldgroup1 || [];
    this.gridImages = props.page.fieldgroup2 || [];
  }

  RenderHubSpotForm = (id) => {
    return (
      <div className={cl("form_container")}>
        <HubSpotForm formId={id} label="Experience" />
      </div>
    );
  };

  render() {
    const { page } = this.props;
    const hubSpotFormId = this.context.activeLangPage(page, "h3");
    const hasVideo = page.video_link !== null && page.video_link !== "";
    // console.log("page", page, hasVideo);
    return (
      <div className={cl("")}>
        <Hero
          imageData={{ slides: this.slides }}
          videoLinkPresent={hasVideo}
          videoLink={page.video_link}
        />
        <div className={cl("container")}>
          <Intro
            title={page.h1}
            blurb={page.blurb1}
            cta={parse(page.buttonlink1)}
          />
          {page.slug === "live-well-couples-retreat" &&
            this.RenderHubSpotForm(hubSpotFormId)}
          <ImageGrid images={this.gridImages.slice(0, 4)} />
          {page.slug !== "live-well-couples-retreat" &&
            this.RenderHubSpotForm(hubSpotFormId)}
        </div>
      </div>
    );
  }
}

export default ExperienceDetail;
