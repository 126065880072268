import styles from "../../../styles/views/_checkout.scss";
import { _class, numberWithCommas } from "../../utilities/helpers";
import { Translation } from "../../utilities/Translation";
import Reveal from "../../widgets/Reveal";
import { connect } from "react-redux";
import moment from "moment";
import * as SVG from "../../widgets/SVG";
import Link from "../../widgets/Link";
import { removePackage } from "../../redux/synxis/SynxisActions";
import Modal from "../../widgets/Modal";
import * as Storage from "../../utilities/Storage";

const cl = _class(styles, "checkout");

class CheckoutDetailsWidget extends React.Component {
  static propTypes = {
    bookingDetails: PropTypes.object,
    hotelDetails: PropTypes.object,
    viewport: PropTypes.object,
    step: PropTypes.number,
    lang: PropTypes.string,
    currency: PropTypes.object,
    rates: PropTypes.object,
    removePackage: PropTypes.func,
  };

  static contextTypes = {
    history: PropTypes.object,
    viewport: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.LANG = new Translation(props.lang);

    this.state = {
      priceModalOpen: false,
    };
  }

  togglePriceModal = () =>
    this.setState({ priceModalOpen: !this.state.priceModalOpen });

  renderPriceModal = () => {
    const { bookingDetails, currency } = this.props;

    return (
      <Modal
        open={this.state.priceModalOpen}
        close={this.togglePriceModal}
        className={cl("price_modal")}
        background="grey"
      >
        {this.renderDetailsBreakdown()}
        <div className={cl("details__total")}>
          <p>{this.LANG("total")}</p>
          <div>
            <p>
              {currency.symbol}
              {numberWithCommas(bookingDetails.total)}
            </p>
          </div>
        </div>
      </Modal>
    );
  };

  editable = () => this.props.step === 1;

  clearStoredBookingDetails = () => Storage.removeItem("booking_details");

  renderEditLink = () => {
    const { bookingDetails } = this.props;

    if (this.editable()) {
      return (
        <Link
          title="Edit"
          path={bookingDetails.cmsRoom.path}
          onClick={this.clearStoredBookingDetails}
        />
      );
    }
  };

  renderDetailsBreakdown = () => {
    const { currency } = this.props;
    const {
      cmsProperty,
      cmsRoom,
      from,
      to,
      guests,
      nights,
      subtotal,
      taxes,
      rate,
      rates,
      feesBreakdown,
    } = this.props.bookingDetails;

    return (
      <div>
        <h2>{this.LANG("yourStay")}</h2>
        <h3>{cmsProperty.linktitle}</h3>
        <h4>{cmsRoom.h1}</h4>
        <div className={cl("details__dates")}>
          <div>
            <label>{this.LANG("checkIn")}</label>
            <p>{moment(from).format("LL")}</p>
          </div>
          <div>
            <label>{this.LANG("checkOut")}</label>
            <p>{moment(to).format("LL")}</p>
          </div>
        </div>
        <div className={cl("details__guests")}>
          <p>
            {guests} {this.LANG("guests")}
          </p>
          <p>
            {cmsRoom.h5}{" "}
            {cmsRoom.h5 != 1 ? this.LANG("bedrooms") : this.LANG("bedroom")}
          </p>

          {this.renderEditLink()}
        </div>

        <div className={cl("details__fees")}>
          <div className={cl("details__fees__row")}>
            <p>{this.LANG("rate")}</p>
            <p>{rates ? rates[rate].Name : rate}</p>
          </div>
          <div className={cl("details__fees__row")}>
            <p>
              {nights} {nights !== 1 ? this.LANG("nights") : this.LANG("night")}
            </p>
            <p>
              {currency.symbol}
              {numberWithCommas(subtotal)}
            </p>
          </div>
          <div className={cl("details__fees__row")}>
            <p>{this.LANG("taxes")}</p>
            <p>
              {currency.symbol}
              {numberWithCommas(taxes)}
            </p>
          </div>
          {feesBreakdown &&
            feesBreakdown.map((fee) => (
              <div className={cl("details__fees__row")} key={fee.Code}>
                <p>{this.LANG(fee.Code)}</p>
                <p>
                  {currency.symbol}
                  {numberWithCommas(fee.Amount)}
                </p>
              </div>
            ))}
        </div>
        {this.renderPackages()}
      </div>
    );
  };

  removePkgButton = (pkg) => {
    const { removePackage } = this.props;
    if (this.editable()) {
      return (
        <div onClick={() => removePackage(pkg.ServiceInventoryCode)}>
          {SVG.xmark}
        </div>
      );
    }
  };

  renderPackages = () => {
    const { bookingDetails, currency } = this.props;
    if (bookingDetails.packages && bookingDetails.packages.length) {
      return (
        <div className={cl("details__fees")}>
          <h3>Packages</h3>
          {bookingDetails.packages.map((pkg) => {
            return (
              <div className={cl("details__packages__row")} key={pkg.Title}>
                {this.removePkgButton(pkg)}
                <p>{pkg.Title}</p>
                <p>
                  {currency.symbol}
                  {numberWithCommas(pkg.Total.AmountAfterTax)}
                </p>
              </div>
            );
          })}
        </div>
      );
    }
  };

  render() {
    const { bookingDetails, viewport, currency } = this.props;

    return (
      <div>
        {this.renderPriceModal()}
        <Reveal cssAnimation={["fade", "right"]} className={cl("details")}>
          {!viewport.is("tablet") && this.renderDetailsBreakdown()}
          <div className={cl("details__total")}>
            <p>{this.LANG("total")}</p>
            <div className={cl("details__total__inner")}>
              <p>
                {currency.symbol}
                {numberWithCommas(bookingDetails.total)}
              </p>

              {viewport.is("tablet") && (
                <span onClick={this.togglePriceModal}>
                  View Price Breakdown
                </span>
              )}
            </div>
          </div>
        </Reveal>
      </div>
    );
  }
}

const mapStateToProps = ({ synxis, global }) => ({
  hotelDetails: synxis.hotelDetails,
  bookingDetails: synxis.bookingDetails,
  currency: global.currency,
  lang: global.lang,
  rates: synxis.rates,
});

export default connect(mapStateToProps, {
  removePackage,
})(CheckoutDetailsWidget);
