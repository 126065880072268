import styles from "../../styles/partials/_hamburger.scss";

Hamburger.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string
};

Hamburger.defaultProps = {
  open: false,
  onClick: () => null,
  className: "",
  color: "black",
  type: ""
};

export default function Hamburger({ open, onClick, className, color, type }) {
  return (
    <button
      className={`${styles.hamburger} ${styles[color]} ${className} ${styles[
        type
      ] || ""}`}
      onClick={onClick}
    >
      <div className={`${styles.line} ${open ? styles.line_1 : ""}`} />
      <div className={`${styles.line} ${open ? styles.line_2 : ""}`} />
      <div className={`${styles.line} ${open ? styles.line_3 : ""}`} />
    </button>
  );
}
