import styles from "../../styles/views/_partnership.scss";
import { _class } from "../utilities/helpers";
import Intro from "../partials/Intro";
import Hero from "../partials/Hero";
import HubSpotForm from "../partials/HubSpotForm";
import { Translation } from "../utilities/Translation";
import { connect } from "react-redux";

const cl = _class(styles, "partnership");

class Partnership extends React.Component {
  static propTypes = {
    page: PropTypes.object,
    CONTENT: PropTypes.object,
    lang: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.slides = props.page.fieldgroup1 || [];
    this.options = props.CONTENT.byTemplate("options")[0];
    this.LANG = new Translation(props.lang);
  }

  render() {
    const { page } = this.props;

    return (
      <div className={cl("")}>
        <Hero
          imageData={{ slides: this.slides }}
          videoLinkPresent={page.video_link !== null && page.video_link !== ""}
          videoLink={page.video_link}
        />
        <div className={cl("container")}>
          <Intro title={page.h1} blurb={page.blurb1} />
          <div className={cl("form_container")}>
            <Intro subtitle={page.h2} blurb={page.blurb2} />
            <HubSpotForm formId={page.h3} label="Partnership" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  lang: global.lang,
});

export default connect(mapStateToProps)(Partnership);
