import React from "react";
import HeroCarousel from "./HeroCarousel";
import VideoPlayer from "../widgets/VideoPlayer";
import styles from "../../styles/partials/_hero.scss";
import { _class } from "../utilities/helpers";
import Reveal from "../widgets/Reveal";

const cl = _class(styles, "hero");

Hero.propTypes = {
  videoLinkPresent: PropTypes.bool.isRequired,
  videoLink: PropTypes.string,
  imageData: PropTypes.object,
  children: PropTypes.node,
  title: PropTypes.string,
  titleDelay: PropTypes.number,
};

Hero.contextTypes = {
  accessible: PropTypes.bool,
};

export default function Hero(
  { videoLink, videoLinkPresent, imageData, children, title, titleDelay },
  { accessible }
) {
  if (accessible) {
    return (
      <div className={cl("")}>
        {title && <h1 className={cl("title")}>{title}</h1>}
        {children}
      </div>
    );
  }
  // console.log("VideoLink", videoLink, videoLinkPresent);

  return (
    <div className={cl("container")}>
      {videoLinkPresent && (
        <VideoPlayer
          className="video-player"
          url={videoLink}
          volume={0}
          controls={false}
        />
      )}
      {!videoLinkPresent && (
        <HeroCarousel
          slides={imageData.slides}
          title={imageData.title}
          children={children}
          mask={imageData.mask}
          parallax={imageData.parallax}
          className={imageData.className}
          settings={imageData.settings}
          accessible={imageData.accessible}
        />
      )}

      {title && (
        <div className={cl("content_container")}>
          <Reveal
            properties={{
              opacity: [0, 1],
              autoAlpha: [0, 1],
              y: ["-50%", "0%"],
            }}
            delay={titleDelay ? titleDelay : 1000}
          >
            <h1 className={cl("title")}>{title}</h1>
          </Reveal>

          {children}
        </div>
      )}
    </div>
  );
}
