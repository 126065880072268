import Link from "../widgets/Link";
import styles from "../../styles/partials/_cta.scss";
import { parse } from "../utilities/helpers";

CTA.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  buttonLink: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf([
    "dark",
    "light",
    "transparent",
    "underline",
    "book",
    "orange",
    "mediumgrey",
  ]),
  size: PropTypes.string,
  className: PropTypes.string,
  force: PropTypes.bool,
  search: PropTypes.string,
  arrow: PropTypes.bool,
  newTab: PropTypes.bool,
};

CTA.defaultProps = {
  text: "",
  link: "",
  buttonLink: "",
  onClick: () => null,
  size: "small",
  force: false,
  type: "dark",
  arrow: false,
};

export default function CTA({
  text,
  link,
  buttonLink,
  onClick,
  type,
  size,
  className,
  search,
  arrow,
  newTab,
}) {
  let formattedText = text;
  let formattedLink = link;

  if (buttonLink) {
    const parsed = parse(buttonLink);
    formattedText = parsed.text;
    formattedLink = parsed.link;
  }

  if (!formattedLink && !onClick) {
    return null;
  }

  const _classes = `${styles.cta} ${styles[type] || ""} ${styles[size] || ""} ${
    (arrow && styles.arrow) || ""
  } ${className || ""}`;

  return (
    <Link
      title={formattedText}
      className={_classes}
      path={formattedLink}
      search={search}
      onClick={onClick}
      newTab={newTab}
    />
  );
}
