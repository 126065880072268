export default function WindowContainer(Component) {
  return class _windowContainer extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        state: "",
      };
    }

    breakpoints = {
      xsmall: 480 + 1,
      small: 600 + 1,
      mobile: 768 + 1,
      tablet: 1025 + 1,
      medium: 1180 + 1,
      large: 1300 + 1,
      xlarge: 1600 + 1,
    };

    componentDidMount() {
      window.addEventListener("resize", this.throttle(150, this.onResize));
      this.setState({ state: this.viewport(window.innerWidth) });
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.throttle(150, this.onResize));
    }

    throttle = (delay, fn) => {
      let time = Date.now();

      return function throttled(e) {
        if (time + delay - Date.now() < 0) {
          fn(e);
          time = Date.now();
        }
      };
    };

    viewport = (width) => {
      let state = null;

      for (let key in this.breakpoints) {
        width < this.breakpoints[key] && !state ? (state = key) : null;
      }

      if (state === null) {
        return "xlarge";
      }

      return state !== this.state.state ? state : false;
    };

    onResize = () => {
      const state = this.viewport(window.innerWidth);
      state && this.setState({ state });
    };

    /*
      use non arrow function to use `this` context from function invocation.
      this allows us to access the state on the viewport object, not
      the state of the _windowContainer class. This is useful for 
      corretly determining what the previous viewport state was, eg
      when looking at prevProps.
    */
    is(name) {
      return this.breakpoints[this.state] <= this.breakpoints[name];
    }

    not(name) {
      return this.breakpoints[this.state] > this.breakpoints[name];
    }

    render() {
      return (
        <Component
          {...this.props}
          windowState={this.state.state}
          mobile={this.is("mobile")}
          viewport={{
            ...this.state,
            breakpoints: this.breakpoints,
            is: this.is,
            not: this.not,
          }}
        />
      );
    }
  };
}
