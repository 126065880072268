import styles from "../../styles/views/_properties.scss";
import {
  _class,
  createResultCard,
  parse,
  getFieldGroupImage,
} from "../utilities/helpers";
import Intro from "../partials/Intro";
import ResultsBlock from "../partials/ResultsBlock";
import { connect } from "react-redux";
import Loader from "../partials/Loader";
import zenscroll from "zenscroll";

const cl = _class(styles, "collections");

class Collections extends React.Component {
  static propTypes = {
    page: PropTypes.object,
    CONTENT: PropTypes.object,
    rooms: PropTypes.object,
    lang: PropTypes.string,
    currency: PropTypes.object,
    location: PropTypes.object,
  };

  static contextTypes = {
    filterByLang: PropTypes.func,
    history: PropTypes.object,
    activeLangPage: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.imageCards = props.CONTENT.childrenById(props.page.id) || [];
    this.rooms = props.CONTENT.byTemplate("room").filter(context.filterByLang);

    this.state = {
      resultCards: undefined,
    };
  }

  componentDidMount() {
    if (this.props.rooms) {
      this.init();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.rooms && this.props.rooms) {
      this.init();
    }
  }

  init = () => this.setState({ resultCards: this.getResultCards() });

  getResultCards = () => {
    const { CONTENT, rooms, currency, lang } = this.props;

    return this.rooms
      .reduce((sum, room) => {
        let collections = parse(room.blurb6, []).map((item) => {
          let page = CONTENT.byId(item.value);
          return this.context.activeLangPage(page, "h1", lang === "es");
        });

        const isPrivate = room.checkbox1;

        if (!isPrivate) {
          const card = createResultCard({
            price: false,
            page: room,
            location: CONTENT.byId(room.parentid).h1,
            category: collections,
            lang,
            currency,
            synxisRoom: rooms[room.h4],
            icon: "stay",
            image: getFieldGroupImage(room.fieldgroup2),
          });

          sum.push(card);
        }

        return sum;
      }, [])
      .sort((a, b) => parseInt(a.bedrooms) - parseInt(b.bedrooms));
  };

  onImageCardClick = (item) => {
    this.context.history.push(item.path);
    zenscroll.to(document.getElementById("results_wrapper"), 1000);
  };

  renderResultsBlock = () => {
    const { resultCards } = this.state;

    if (resultCards) {
      return (
        <ResultsBlock
          tabs={"children"}
          resultCards={resultCards}
          imageCards={this.imageCards}
          view={"map"}
          filters={["View", "Bedrooms", "MoreFilters", "Sort"]}
          checkboxOptions={["Amenities", "Accommodations"]}
          page={this.props.page}
          onImageCardClick={this.onImageCardClick}
        />
      );
    }

    return (
      <div style={{ minHeight: "30vh" }}>
        <Loader />
      </div>
    );
  };

  render() {
    const { page } = this.props;

    return (
      <div className={cl("")}>
        <div className={cl("container")}>
          <Intro title={page.h1} blurb={page.blurb1} />
          {this.renderResultsBlock()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ synxis, global }) => ({
  rooms: synxis.rooms,
  lang: global.lang,
  currency: global.currency,
});

export default connect(mapStateToProps)(Collections);
