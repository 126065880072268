import styles from "../../styles/views/_default.scss";
import { _class } from "../utilities/helpers";
const cl = _class(styles, "default");

class Default extends React.Component {
  static propTypes = {
    page: PropTypes.object,
    CONTENT: PropTypes.object,
  };

  render() {
    const { page } = this.props;

    return (
      <div className={cl("")}>
        <div className={cl("container")}>
          <h1>{page.h1}</h1>
          <div
            dangerouslySetInnerHTML={{ __html: page.blurb1 }}
            className={cl("content")}
          />
        </div>
      </div>
    );
  }
}

export default Default;
