import TYPES from "./GlobalTypes";
import { setLanguage } from "../../utilities/Language";
import * as Currency from "../../utilities/Currency";

export const setCurrency = (currencyCode) => {
  Currency.setCurrency(currencyCode);

  return {
    type: TYPES.SET_CURRENCY,
    payload: currencyCode,
  };
};

export const setLang = (lang) => {
  setLanguage(lang);

  return {
    type: TYPES.SET_LANG,
    payload: lang,
  };
};

export const registerLanguages = (languages) => ({
  type: TYPES.REGISTER_LANGUAGES,
  payload: languages,
});
