import { _class } from "../utilities/helpers";
import styles from "../../styles/partials/_grid.scss";
import * as SVG from "../widgets/AmenityIcons";
import Reveal from "../widgets/Reveal";
import { AmenityTranslation } from "../utilities/Translation";
import { connect } from "react-redux";

const cl = _class(styles, "grid");

class AmenitiesGrid extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    decoration: PropTypes.bool,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        amenity: PropTypes.string,
        icon: PropTypes.string,
        description: PropTypes.string,
        order: PropTypes.string,
      })
    ),
    short: PropTypes.bool,
    lang: PropTypes.string,
  };

  static defaultProps = {
    items: [],
  };

  constructor(props) {
    super(props);

    this.LANG = new AmenityTranslation(props.lang);
  }

  renderTitle(title, decoration, short) {
    return (
      <div className={`${cl("title_wrapper")}`}>
        {decoration && (
          <Reveal
            className={cl("decor")}
            cssAnimation={["fade", "left"]}
            delay={750}
          />
        )}
        <Reveal
          className={`${cl("title")} ${short ? styles.short : ""}`}
          cssAnimation={["fade", "top"]}
        >
          <h2>{title}</h2>
        </Reveal>

        {decoration && (
          <Reveal
            cssAnimation={["fade", "right"]}
            className={cl("decor")}
            delay={750}
          />
        )}
      </div>
    );
  }

  render() {
    const { title, decoration, items, short } = this.props;
    return (
      <Reveal
        className={`${cl("")} ${short ? styles.short : ""}`}
        mode="stagger"
        staggerClassName={cl("item")}
        properties={{
          y: ["50%", "0%"],
          opacity: [0, 1],
          autoAlpha: [0, 1],
        }}
      >
        {this.renderTitle(title, decoration, short)}
        <div className={cl("inner")}>
          {items.map(({ icon, amenity, description }, index) => {
            return (
              <div
                className={`${cl("item")} ${short ? styles.short : ""}`}
                key={index}
              >
                <div className={cl("item__icon")}>{SVG[icon]}</div>
                <h3 className={cl("item__title")}>{this.LANG(amenity)}</h3>
                {!short && <p className={cl("item__text")}>{description}</p>}
              </div>
            );
          })}
        </div>
      </Reveal>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  lang: global.lang,
});

export default connect(mapStateToProps)(AmenitiesGrid);
