import Slick from "react-slick";
import Image from "widgets/Image";
import styles from "../../styles/partials/_villa-carousel.scss";
import { _class, unique } from "../utilities/helpers";
import CTA from "./CTA";
import Reveal from "../widgets/Reveal";
import { Translation } from "../utilities/Translation";
import { connect } from "react-redux";

const cl = _class(styles, "villa_carousel");

class VillaCarousel extends React.Component {
  static propTypes = {
    slides: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        blurb: PropTypes.string,
        image: PropTypes.shape({
          src: PropTypes.string,
          alt: PropTypes.string,
          align: PropTypes.string,
        }),
      })
    ),
    settings: PropTypes.object,
    className: PropTypes.string,
    keyboardControl: PropTypes.bool,
    lang: PropTypes.string,
    CONTENT: PropTypes.object,
  };

  static contextTypes = {
    viewport: PropTypes.object,
    accessible: PropTypes.bool,
    filterByLang: PropTypes.func,
  };

  static defaultProps = {
    slides: [],
  };

  constructor(props, context) {
    super(props, context);

    this.LANG = new Translation(props.lang);
    this.state = {
      index: 0,
    };

    this.villasPage = this.props.CONTENT.byTemplate("villas").filter(
      this.context.filterByLang
    )[0];
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("keydown", this.handleKeyDown);
    }
  }

  handleKeyDown = (e) => {
    if (this.props.keyboardControl && this.slick) {
      if (
        !document.activeElement ||
        document.activeElement.tagName !== "INPUT"
      ) {
        if (e.key === "ArrowLeft" || e.key === "Left") {
          this.slick.slickPrev();
        } else if (e.key === "ArrowRight" || e.key === "Right") {
          this.slick.slickNext();
        }
      }
    }
  };

  secondSlide = (idx) => {
    const { index } = this.state;
    const secondSlide = index + 1 === idx || (idx === 0 && idx !== index);
    return secondSlide ? styles.second : "";
  };

  renderContentContainer = (villa = {}) => {
    return (
      <Reveal
        className={cl("content_container")}
        properties={{ opacity: [0, 1], autoAlpha: [0, 1], x: ["-50%", "0%"] }}
        scrollableAncestor={null}
        bottomOffset={"-25%"}
      >
        <div>
          <h3 className={cl("title")}>{this.LANG("ourHomes")}</h3>
          <h2>{villa.title}</h2>
          <p>{villa.blurb}</p>
          <CTA link={this.villasPage.path} text={this.LANG("viewHomes")} />
        </div>
      </Reveal>
    );
  };

  renderMobileContent = (villa) => {
    if (this.context.viewport.is("tablet")) {
      return (
        <div className={cl("mobile_content")}>
          <p>{villa.blurb}</p>
          <CTA link={this.villasPage.path} text={this.LANG("viewHomes")} />
        </div>
      );
    }
  };

  renderSlide = (villa, index) => {
    const activeClass = index === this.state.index ? styles.active : "";

    return (
      <li
        className={`${cl("item")} ${activeClass} ${this.secondSlide(index)}`}
        key={index}
      >
        <div>
          <div className={`${cl("item__image")} ${activeClass}`}>
            <Image
              src={villa.image.src}
              alt={villa.image.alt}
              align={villa.image.align}
            />
          </div>
          <h3>{villa.title}</h3>
          {this.renderMobileContent(villa)}
        </div>
      </li>
    );
  };

  render() {
    const { slides } = this.props;
    const { index } = this.state;
    const villa = slides[index];

    const settings = {
      arrows: true,
      slidesToShow: this.context.viewport.is("tablet") ? 1 : 3,
      infinite: true,
      afterChange: (index) => this.setState({ index }),
    };

    if (this.context.accessible) {
      return (
        <div className={cl("")}>
          <h3 className={cl("title")}>{this.LANG("ourHomes")}</h3>
          <ul>
            {unique(slides).map((slide, index) => (
              <li key={slide.id || index}>
                <h2>{slide.title}</h2>
                <p>{slide.blurb}</p>
              </li>
            ))}
          </ul>
          <CTA link={this.villasPage.path} text={this.LANG("viewHomes")} />
        </div>
      );
    }

    return (
      <div className={cl("")}>
        <div className={cl("inner")}>
          {this.renderContentContainer(villa)}

          {this.context.viewport.is("tablet") && (
            <h3 className={cl("title")}>{this.LANG("ourVacationHomes")}</h3>
          )}
          <Reveal
            className={cl("slick_container")}
            properties={{
              opacity: [0, 1],
              autoAlpha: [0, 1],
              y: ["50%", "0%"],
            }}
            delay={0}
            duration={1000}
            scrollableAncestor={null}
            bottomOffset={"-25%"}
          >
            <div>
              <Slick ref={(r) => (this.slick = r)} {...settings}>
                {slides.map(this.renderSlide)}
              </Slick>
            </div>
          </Reveal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  lang: global.lang,
});

export default connect(mapStateToProps)(VillaCarousel);
