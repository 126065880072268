import styles from "../../../styles/views/_checkout.scss";
import {
  _class,
  gaTrackPage,
  fbTrackEvent,
  gtagTrackEvent,
  gtagTrackECommerce,
  gaTrackECommerce,
} from "../../utilities/helpers";
import { Translation } from "../../utilities/Translation";
import { connect } from "react-redux";
import CTA from "../CTA";
import moment from "moment";
import { SojernTracking_Conversion } from "../../utilities/SojernTracking";
import { trackingDisabled } from "../../config/appConfig";
const cl = _class(styles, "checkout");

class CheckoutConfirmation extends React.Component {
  static propTypes = {
    page: PropTypes.object,
    lang: PropTypes.string,
    currency: PropTypes.object,
    CONTENT: PropTypes.object,
    bookingDetails: PropTypes.object,
    bookingForm: PropTypes.object,
    reservation: PropTypes.object,
  };

  static contextTypes = {
    history: PropTypes.object,
    filterByLang: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.LANG = new Translation(props.lang);
  }

  componentDidMount() {
    const { bookingDetails, reservation, currency } = this.props;
    if (!trackingDisabled) {
      SojernTracking_Conversion(
        bookingDetails.confirmationNumber,
        parseInt(bookingDetails.total).toFixed(2)
      );
    }
    gaTrackPage("/btt/booking-engine/guest-confirmation");

    fbTrackEvent("Purchase", {
      pixelData: {
        value: bookingDetails.total,
        currency: currency.code.toUpperCase(),
      },
      serverData: {
        value: bookingDetails.total,
        currency: currency.code.toUpperCase(),
        room_id: bookingDetails.roomCode,
        quantity: 1,
        email: bookingDetails.guestInfo.email,
        phone: bookingDetails.guestInfo.phone,
      },
    });

    const transactionId =
      reservation && reservation.reservations[0].CRS_confirmationNumber;

    gtagTrackEvent("AW-817146118/z9r7CM_0j-4BEIbS0oUD", {
      value: bookingDetails.total,
      currency: currency.code.toUpperCase(),
      transaction_id: transactionId,
    });

    gtagTrackECommerce({
      transaction_id: transactionId,
      affiliation: "BeachTownTravel online reservation",
      value: bookingDetails.total,
      currency: currency.code.toUpperCase(),
      tax: bookingDetails.taxes,
      shipping: 0,
      items: [
        {
          id: transactionId,
          name: bookingDetails.cmsRoom.linktitle,
          list_name: bookingDetails.cmsProperty.linktitle,
          quantity: bookingDetails.nights,
          price: bookingDetails.nightly,
        },
      ],
    });

    gaTrackECommerce({
      transaction: {
        id: transactionId,
        affiliation: "BeachTownTravel online reservation",
        revenue: bookingDetails.total,
        currency: currency.code.toUpperCase(),
        tax: bookingDetails.taxes,
        shipping: 0,
      },
      items: [
        {
          id: transactionId,
          name: bookingDetails.cmsRoom.linktitle,
          category: bookingDetails.cmsProperty.linktitle,
          sku: transactionId,
          quantity: bookingDetails.nights,
          price: bookingDetails.nightly,
        },
      ],
    });
  }

  goHome = () => {
    const home = this.props.CONTENT.byTemplate("home").filter(
      this.context.filterByLang
    )[0];
    this.context.history.pushTransition(home.path);
  };

  render() {
    const { bookingDetails, bookingForm, reservation, page } = this.props;

    return (
      <div className={`${cl("step")} ${styles.three}`}>
        <h1>{this.LANG("bookingComplete")}</h1>
        <h2>
          {moment(bookingDetails.from).format("LL")} -{" "}
          {moment(bookingDetails.to).format("LL")}
        </h2>
        <div className={`${cl("confirmation_text")}`}>
          <p>
            {this.LANG("confirmation")}:{" "}
            {reservation && reservation.reservations[0].CRS_confirmationNumber}
          </p>
          <p>
            {this.LANG("confirmationEmailed")} {bookingForm.email}.
          </p>
        </div>

        <div dangerouslySetInnerHTML={{ __html: page.blurb2 }} />
        <div className={cl("cta")}>
          <CTA
            text={this.LANG("backToHome")}
            onClick={this.goHome}
            type="orange"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ synxis, global }) => ({
  bookingDetails: synxis.bookingDetails,
  bookingForm: synxis.bookingForm,
  reservation: synxis.reservation,
  lang: global.lang,
  currency: global.currency,
});

export default connect(mapStateToProps)(CheckoutConfirmation);
