import styles from "../../styles/partials/_intro.scss";
import CTA from "./CTA";
import { _class } from "../utilities/helpers";
import Reveal from "../widgets/Reveal";

const cl = _class(styles, "intro");

Intro.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  blurb: PropTypes.string,
  cta: PropTypes.object,
  onCtaClick: PropTypes.func,
};

Intro.defaultProps = {
  title: "",
  subtitle: "",
  blurb: "",
  onCtaClick: () => null,
};

Intro.contextTypes = {
  accessible: PropTypes.bool,
};

export default function Intro(
  { title, subtitle, blurb, cta, onCtaClick },
  { accessible }
) {
  // if (accesssible) {
  //   return (
  //     <div className={cl("")}>
  //     {title && <h1 className={cl("title")}>{title}</h1>}
  //     {subtitle && <h2 className={cl("subtitle")}>{subtitle}</h2>}
  //     {blurb && (
  //       <div
  //         className={cl("blurb")}
  //         dangerouslySetInnerHTML={{ __html: blurb }}
  //       />
  //     )}

  //     {cta && cta.link && (
  //       <div className={cl("cta")}>
  //         <CTA link={cta.link} text={cta.text} type="dark" size="small" />
  //       </div>
  //     )}
  //   </div>
  //   )
  // }
  return (
    <Reveal
      cssAnimation={accessible ? undefined : ["fade", "down"]}
      className={cl("")}
      scrollableAncestor={null}
    >
      {title && <h1 className={cl("title")}>{title}</h1>}
      {subtitle && <h2 className={cl("subtitle")}>{subtitle}</h2>}
      {blurb && (
        <div
          className={cl("blurb")}
          dangerouslySetInnerHTML={{ __html: blurb }}
        />
      )}

      {cta && cta.link && (
        <div className={cl("cta")}>
          <CTA
            link={cta.link}
            text={cta.text}
            type="dark"
            size="small"
            onClick={onCtaClick}
          />
        </div>
      )}
    </Reveal>
  );
}
