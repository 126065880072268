//SWITCH: There is a Switch to turn off the tracking with one variable set in the appConfig.js file
export const getParametersFromUrl = (param) => {
  const search = window.location.search;

  const params = new URLSearchParams(search);
  let result = "";

  if (search.includes(param)) {
    result = params.get(param);

    return result;
  } else if (Object.keys(params).length === 0) {
    result = "";
    return result;
  }
};

export const SojernTracking_HomePage = () => {
  // *** From the doc: Necessary parameters: None ***
  var params = {};
  /* Please do not modify the below code. */
  var cid = [];
  var paramsArr = [];
  var cidParams = [];
  var pl = document.createElement("script");
  var defaultParams = { vid: "hot" };
  for (const key in defaultParams) {
    params[key] = defaultParams[key];
  }
  for (const key in cidParams) {
    cid.push(params[cidParams[key]]);
  }
  params.cid = cid.join("|");
  for (const key in params) {
    paramsArr.push(key + "=" + encodeURIComponent(params[key]));
  }
  pl.type = "text/javascript";
  pl.async = true;
  pl.src =
    "https://beacon.sojern.com/pixel/p/339135?f_v=v6_js&p_v=1&" +
    paramsArr.join("&");
  (
    document.getElementsByTagName("head")[0] ||
    document.getElementsByTagName("body")[0]
  ).appendChild(pl);
};

export const SojernTracking_Product = () => {
  // *** From the doc: Necessary parameters: None ***
  /* Please fill the following values. */
  var params = {
    hd1: getParametersFromUrl(
      "from"
    ) /* Check In Date. Format yyyy-mm-dd. Ex: 2015-02-14 */,
    hd2: getParametersFromUrl(
      "to"
    ) /* Check Out Date. Format yyyy-mm-dd. Ex: 2015-02-14 */,
    hc1: "Las Catalinas" /* Destination City */,
    hs1: "Guanacaste" /* Destination State or Region */,
    hn1: "Costa Rica" /* Destination Country */,
    hb: "Las Catalinas" /* Hotel Brand */,
    hpr: "BTT" /* Hotel Property */,
    hpid: "Beachtown Travel" /* Property ID */,
    hp: "" /* Hotel Rate */,
    t: "" /* Number of Travelers */,
    sha256_eml: "" /* Hashed Email SHA256 */,
    sha1_eml: "" /* Hashed Email SHA1 */,
    md5_eml: "" /* Hashed Email MD5 */,
    ccid: "" /* Client Cookie id */,
  };

  /* Please do not modify the below code. */
  var cid = [];
  var paramsArr = [];
  var cidParams = [];
  var pl = document.createElement("script");
  var defaultParams = { vid: "hot", et: "hpr" };
  for (const key in defaultParams) {
    params[key] = defaultParams[key];
  }
  for (const key in cidParams) {
    cid.push(params[cidParams[key]]);
  }
  params.cid = cid.join("|");
  for (const key in params) {
    paramsArr.push(key + "=" + encodeURIComponent(params[key]));
  }
  pl.type = "text/javascript";
  pl.async = true;
  pl.src =
    "https://beacon.sojern.com/pixel/p/339136?f_v=v6_js&p_v=1&" +
    paramsArr.join("&");
  (
    document.getElementsByTagName("head")[0] ||
    document.getElementsByTagName("body")[0]
  ).appendChild(pl);
};

export const SojernTracking_Tracking = () => {
  /* Please fill the following values. */

  var params = {
    pc: "" /* Page Category */,
    sha256_eml: "" /* Hashed Email SHA256 */,
    sha1_eml: "" /* Hashed Email SHA1 */,
    md5_eml: "" /* Hashed Email MD5 */,
    ccid: "" /* Client Cookie id */,
  };
  /* Please do not modify the below code. */

  var cid = [];
  var paramsArr = [];
  var cidParams = [];
  var pl = document.createElement("script");
  var defaultParams = { vid: "hot" };
  for (const key in defaultParams) {
    params[key] = defaultParams[key];
  }
  for (const key in cidParams) {
    cid.push(params[cidParams[key]]);
  }
  params.cid = cid.join("|");
  for (const key in params) {
    paramsArr.push(key + "=" + encodeURIComponent(params[key]));
  }
  pl.type = "text/javascript";
  pl.async = true;
  pl.src =
    "https://beacon.sojern.com/pixel/p/339139?f_v=v6_js&p_v=1&" +
    paramsArr.join("&");
  (
    document.getElementsByTagName("head")[0] ||
    document.getElementsByTagName("body")[0]
  ).appendChild(pl);
};

export const SojernTracking_Search = () => {
  // *** From the doc: Necessary parameters: None ***
  /* Please fill the following values. */

  var params = {
    hd1: getParametersFromUrl(
      "from"
    ) /* Check In Date. Format yyyy-mm-dd. Ex: 2015-02-14 */,
    hd2: getParametersFromUrl(
      "to"
    ) /* Check Out Date. Format yyyy-mm-dd. Ex: 2015-02-14 */,
    hc1: "" /* Destination City */,
    hs1: "Guanacaste" /* Destination State or Region */,
    hn1: "Costa Rica" /* Destination Country */,
    hb: "Las Catalinas" /* Hotel Brand */,
    t: "" /* Number of Travelers */,
    sha256_eml: "" /* Hashed Email SHA256 */,
    sha1_eml: "" /* Hashed Email SHA1 */,
    md5_eml: "" /* Hashed Email MD5 */,
    ccid: "" /* Client Cookie id */,
  };

  /* Please do not modify the below code. */
  var cid = [];
  var paramsArr = [];
  var cidParams = [];
  var pl = document.createElement("script");
  var defaultParams = { vid: "hot", et: "hs" };
  for (const key in defaultParams) {
    params[key] = defaultParams[key];
  }
  for (const key in cidParams) {
    cid.push(params[cidParams[key]]);
  }
  params.cid = cid.join("|");
  for (const key in params) {
    paramsArr.push(key + "=" + encodeURIComponent(params[key]));
  }
  pl.type = "text/javascript";
  pl.async = true;
  pl.src =
    "https://beacon.sojern.com/pixel/p/339137?f_v=v6_js&p_v=1&" +
    paramsArr.join("&");
  (
    document.getElementsByTagName("head")[0] ||
    document.getElementsByTagName("body")[0]
  ).appendChild(pl);
};
export const SojernTracking_ShoppingCart = (price) => {
  /* Please fill the following values. */
  var params = {
    hd1: "" /* Check In Date. Format yyyy-mm-dd. Ex: 2015-02-14 */,
    hd2: "" /* Check Out Date. Format yyyy-mm-dd. Ex: 2015-02-14 */,
    hc1: "Las Catalinas" /* Destination City */,
    hs1: "Guanacaste" /* Destination State or Region */,
    hn1: "Costa Rica" /* Destination Country */,
    hb: "Las Catalinas" /* Hotel Brand */,
    hpr: "BTT" /* Hotel Property */,
    hpid: "Beachtown Travel" /* Property ID */,
    hp: price && Number(price).toFixed(2) /* Hotel Rate */,
    t: "" /* Number of Travelers */,
    sha256_eml: "" /* Hashed Email SHA256 */,
    sha1_eml: "" /* Hashed Email SHA1 */,
    md5_eml: "" /* Hashed Email MD5 */,
    ccid: "" /* Client Cookie id */,
  };

  /* Please do not modify the below code. */
  var cid = [];
  var paramsArr = [];
  var cidParams = [];
  var pl = document.createElement("script");
  var defaultParams = { vid: "hot", et: "hcart" };
  for (const key in defaultParams) {
    params[key] = defaultParams[key];
  }
  for (const key in cidParams) {
    cid.push(params[cidParams[key]]);
  }
  params.cid = cid.join("|");
  for (const key in params) {
    paramsArr.push(key + "=" + encodeURIComponent(params[key]));
  }
  pl.type = "text/javascript";
  pl.async = true;
  pl.src =
    "https://beacon.sojern.com/pixel/p/339138?f_v=v6_js&p_v=1&" +
    paramsArr.join("&");
  (
    document.getElementsByTagName("head")[0] ||
    document.getElementsByTagName("body")[0]
  ).appendChild(pl);

  // console.log(
  //   "SojernTracking_ShoppingCart fired successfully",
  //   price && Number(price).toFixed(2)
  // );
};

export const SojernTracking_Conversion = (transactionId, total) => {
  /* Please fill the following values. */
  var params = {
    hd1: "" /* Check In Date. Format yyyy-mm-dd. Ex: 2015-02-14 */,
    hd2: "" /* Check Out Date. Format yyyy-mm-dd. Ex: 2015-02-14 */,
    hc1: "Las Catalinas" /* Destination City */,
    hs1: "Guanacaste" /* Destination State or Region */,
    hn1: "Costa Rica" /* Destination Country */,
    hb: "Las Catalinas" /* Hotel Brand */,
    hpr: "BTT" /* Hotel Property */,
    hpid: "Beachtown Travel" /* Property ID */,
    t: "" /* Number of Travelers */,
    hp: total && Number(total).toFixed(2) /* Price */,
    hcu: "USD" /* Purchase Currency */,
    hconfno: transactionId /* Confirmation Number */,
    sha256_eml: "" /* Hashed Email SHA256 */,
    sha1_eml: "" /* Hashed Email SHA1 */,
    md5_eml: "" /* Hashed Email MD5 */,
    ccid: "" /* Client Cookie id */,
  };

  /* Please do not modify the below code. */
  var cid = [];
  var paramsArr = [];
  var cidParams = [];
  var pl = document.createElement("script");
  var defaultParams = { vid: "hot", et: "hc" };
  for (const key in defaultParams) {
    params[key] = defaultParams[key];
  }
  for (const key in cidParams) {
    cid.push(params[cidParams[key]]);
  }
  params.cid = cid.join("|");
  for (const key in params) {
    paramsArr.push(key + "=" + encodeURIComponent(params[key]));
  }
  pl.type = "text/javascript";
  pl.async = true;
  pl.src =
    "https://beacon.sojern.com/pixel/p/339134?f_v=v6_js&p_v=1&" +
    paramsArr.join("&");
  (
    document.getElementsByTagName("head")[0] ||
    document.getElementsByTagName("body")[0]
  ).appendChild(pl);
  // console.log(
  //   "SojernTracking_Conversion fired successfully",
  //   total && Number(total).toFixed(2)
  // );
};
