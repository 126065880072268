import styles from "../../styles/partials/_search-form.scss";
import { _class } from "../utilities/helpers";
import Transition from "../widgets/Transition";
import { connect } from "react-redux";
import { Translation } from "../utilities/Translation";

const cl = _class(styles, "search_form");

class SearchForm extends React.Component {
  static propTypes = {
    open: PropTypes.bool,
    lang: PropTypes.string,
    CONTENT: PropTypes.object,
  };

  static contextTypes = {
    history: PropTypes.object,
    filterByLang: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.LANG = new Translation(props.lang);

    this.state = {
      value: "",
    };
  }

  onSubmitSearch = (e) => {
    e.preventDefault();
    const searchPage = this.props.CONTENT.byTemplate("search_results").filter(
      this.context.filterByLang
    )[0];

    this.context.history.pushTransition(
      `${searchPage.path}?s=${encodeURI(this.state.value)}`
    );
  };

  render() {
    return (
      <Transition
        visible={this.props.open}
        properties={{
          y: ["-50%", "0%"],
          height: [0, "54px"],
          overflow: ["hidden", "visible"],
          opacity: [0, 1],
          autoAlpha: [0, 1],
        }}
      >
        <div className={cl("")}>
          <form onSubmit={this.onSubmitSearch} className={cl("inner")}>
            <input
              className={cl("input")}
              type="text"
              value={this.state.value}
              onChange={(e) => this.setState({ value: e.target.value })}
              placeholder={"Search..."}
            />
            <input type="submit" value="Go" className={cl("submit")} />
          </form>
        </div>
      </Transition>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  lang: global.lang,
});

export default connect(mapStateToProps)(SearchForm);
