import TYPES from "./SynxisTypes";
import Synxis from "../../utilities/Synxis";
import {
  packagesTotal,
  formatPackages,
  formatGuests,
  formatPromotion,
  formatRoomStay,
  stayLength,
  asyncRetry,
} from "../../utilities/helpers";
import store from "../store";
import { logCheckoutDetails } from " ../../utilities/Logger";

const synxis = new Synxis();

/**
 * Authorizes with Synxis and save an auth token
 *
 * @return  {function}           dispatches a synxis auth token
 */
export const getToken = () => async (dispatch) => {
  dispatch({ type: TYPES.GET_TOKEN_PENDING });

  const data = await synxis.getToken();
  // console.log(
  //   "%cToken",
  //   "color: white; font-size: 16px; background-color: green;",
  //   data
  // );

  if (data.error) {
    console.log("getToken Error");
    console.log(
      "%cFailed to Get Token",
      "color: white; font-size: 16px; background-color: red;"
    );
    return dispatch({
      type: TYPES.GET_TOKEN_FAIL,
      error: data.error,
    });
  }

  dispatch({
    type: TYPES.GET_TOKEN_SUCCESS,
    payload: data.token,
  });
};
//SMall Change
/**
 * Creates a new reservation with a status of Booked in Synxis
 *
 * @return  {function}           dispatches a reservation object
 */
export const createReservation = (formData, bookingDetails) => async (
  dispatch
) => {
  // dispatch({ type: TYPES.CREATE_RESERVATION_PENDING });
  // console.log("formData", formData);
  // console.log("bookingDetails", bookingDetails);

  try {
    // logCheckoutDetails({
    //   bookingDetails,
    //   singleAvailability: store.getState().synxis.singleAvailability,
    //   step: "CREATE_RESERVATION",
    // });

    const guests = formatGuests(formData, bookingDetails);
    const currency = store.getState().global.currency;

    // console.log({
    //   Notification: {
    //     PublicComment: formData.comments,
    //   },
    //   Promotion: formatPromotion(formData, bookingDetails),
    //   Packages: formatPackages(formData, bookingDetails),
    //   Guests: guests,
    //   RoomStay: formatRoomStay(formData, bookingDetails),
    //   Currency: currency.code, // currency string
    // });

    const response = await synxis.createReservation({
      reservation: {
        Notification: {
          PublicComment: formData.comments,
        },
        Promotion: formatPromotion(formData, bookingDetails),
        Packages: formatPackages(formData, bookingDetails),
        Guests: guests,
        RoomStay: formatRoomStay(formData, bookingDetails),
        Currency: currency.code, // currency string
      },
      confirmationNumber: bookingDetails.confirmationNumber,
    });

    // console.log("createReservation response", response);

    if (response.code === "POST_SUCCESS") {
      dispatch({
        type: TYPES.CREATE_RESERVATION_SUCCESS,
        payload: response.data.confirmationNumber,
      });

      authorizeTransaction(response.data.confirmationNumber)(dispatch);
    } else {
      console.log("createReservation Error");
      return dispatch({
        type: TYPES.CREATE_RESERVATION_FAIL,
        error: response.data.error,
      });
    }
  } catch (error) {
    console.log(error);
    console.log("createReservation catch Error", error);
    dispatch({
      type: TYPES.CREATE_RESERVATION_FAIL,
      error: error.message || error,
    });
  }
};

/**
 * Authorizes a transaction with FAC via PowerTranz. Authorization will be 3DS
 *
 * @return  {function}           dispatches an authorization object
 */
export const authorizeTransaction = (confirmationNumber) => async (
  dispatch
) => {
  try {
    dispatch({ type: TYPES.AUTHORIZE_TRANSACTION_PENDING });
    const { bookingForm, bookingDetails } = store.getState().synxis;

    const guests = formatGuests(bookingForm, bookingDetails);
    const currency = store.getState().global.currency;

    const secure = true; //bookingForm.cc_type.match(/VI|MC/); // 3DS transactions only for Visa & Mastercard

    const payload = {
      confirmationNumber: confirmationNumber,
      payment: {
        CurrencyCode: currency.iso, // currency ISO code
        Card: guests[0].Payments[0].PaymentCard,
        BillingAddress: guests[0].Locations[0].Address,
      },
      secure,
    };

    const { data, code } = await synxis.authorizeTransaction(payload);

    if (code === "POST_SUCCESS") {
      const { orderNumber, authorization } = data;

      return dispatch({
        type: TYPES.AUTHORIZE_TRANSACTION_SUCCESS,
        payload: {
          markup: authorization.RedirectData,
          secure,
          orderNumber,
          spiToken: authorization.SpiToken,
        },
      });
    }

    dispatch({
      type: TYPES.AUTHORIZE_TRANSACTION_FAIL,
      error: data.error,
    });
  } catch (e) {
    console.log(e);

    dispatch({
      type: TYPES.AUTHORIZE_TRANSACTION_FAIL,
      error: e.message || e,
    });
  }
};

/**
 * Confirms the reservation on the server. Capture payment through PowerTRanz on server
 * and update reservation status to "Confirmed" in Synxis
 *
 * @return  {function}           dispatches an object with captured payment and reservation
 */
export const confirmReservation = (authResponseParams) => async (dispatch) => {
  const { lang } = store.getState().global;

  dispatch({ type: TYPES.CONFIRM_RESERVATION_PENDING });
  // let params;

  if (window.location.search) {
    // params = getQueryParams(window.location.search, {});

    // clear out potentially sensitive information form URL
    window.history.replaceState(
      null,
      "",
      `${lang === "es" ? "/es" : ""}/checkout`
    );
  }

  const response = await synxis.confirmReservation(authResponseParams);

  if (response.code === "POST_SUCCESS") {
    logCheckoutDetails({
      bookingDetails: store.getState().synxis.bookingDetails,
      confirmed: response.data.confirmed,
      step: "RESERVATION_SUCCESS",
    });

    return dispatch({
      type: TYPES.CONFIRM_RESERVATION_SUCCESS,
      payload: response.data.confirmed,
    });
  }

  dispatch({
    type: TYPES.CONFIRM_RESERVATION_FAIL,
    error: response.data.error,
  });
};

/**
 * Updates a reservation. INACTIVE
 *
 * @return  {function}           dispatches an object with a reservation confirmation
 */
export const updateReservation = (
  bookingForm,
  bookingDetails,
  reservationDetails
) => async (dispatch) => {
  dispatch({ type: TYPES.UPDATE_RESERVATION_PENDING });

  const response = await synxis.updateReservation({
    status: "Confirmed",
    CRSConfirmationNumber: reservationDetails.CRS_confirmationNumber,
    // Notification: {
    //   PublicComment: bookingForm.comments,
    // },
    // Guests: formatGuests(bookingForm, bookingDetails),
    RoomStay: formatRoomStay(bookingForm, bookingDetails),
  });

  if (response.code === "POST_SUCCESS") {
    return dispatch({
      type: TYPES.UPDATE_RESERVATION_SUCCESS,
      payload: response.data.updated,
    });
  }

  dispatch({
    type: TYPES.UPDATE_RESERVATION_FAIL,
    error: response.data.error,
  });
};

/**
 * Fetches a reservation from synxis.
 *
 * @return  {function}           dispatches an object with a reservation
 */
export const getReservation = ({ confirmationNumber, email }) => async (
  dispatch
) => {
  dispatch({ type: TYPES.GET_RESERVATION_PENDING });

  const { data } = await synxis.getReservation({
    confirmationNumber,
    email,
  });

  if (!data.error) {
    dispatch({ type: TYPES.DELETE_BOOKING_DETAILS });

    return dispatch({
      type: TYPES.GET_RESERVATION_SUCCESS,
      payload: data,
    });
  }

  dispatch({ type: TYPES.GET_RESERVATION_FAIL, error: data.error });
};

/**
 * Cancels a reservation. INACTIVE
 *
 * @return  {function}           dispatches an object with a reservation
 */
export const cancelReservation = ({ confirmationNumber, comments }) => async (
  dispatch
) => {
  dispatch({ type: TYPES.CANCEL_RESERVATION_PENDING });

  const response = await synxis.cancelReservation({
    confirmationNumber,
    comments,
  });

  if (response.code === "POST_SUCCESS") {
    return dispatch({
      type: TYPES.CANCEL_RESERVATION_SUCCESS,
      payload: response.data.cancelled.CRS_cancellationNumber,
    });
  }

  dispatch({
    type: TYPES.CANCEL_RESERVATION_FAIL,
    error: response.data.error,
  });
};

/**
 * Deletes a reservation from the store
 *
 * @return  {object}           dispatches an action with no payload
 */
export const deleteReservation = () => ({ type: TYPES.DELETE_RESERVATION });

/**
 * Fetches all rooms from Synxis
 *
 * @return  {object}           dispatches an action with a payload of rooms object
 */
export const getRooms = () => async (dispatch) => {
  dispatch({ type: TYPES.GET_ROOMS_PENDING });

  try {
    const { error, data } = await asyncRetry(synxis.getRooms);

    if (error) {
      dispatch({ type: TYPES.GET_ROOMS_FAIL });
    } else {
      const roomsObj = data.roomList.reduce((sum, room) => {
        sum[room.Code] = room;
        return sum;
      }, {});

      dispatch({
        type: TYPES.GET_ROOMS_SUCCESS,
        payload: roomsObj,
      });
      // console.log("roomsObj", roomsObj);
    }
  } catch (e) {
    console.log(e);
    dispatch({ type: TYPES.GET_ROOMS_FAIL });
  }
};

/**
 * Fetches hotel details Synxis
 *
 * @return  {object}           dispatches an action with a payload of hotel details object
 */
export const getHotelDetails = () => async (dispatch) => {
  dispatch({ type: TYPES.GET_HOTEL_DETAILS_PENDING });

  const { error, data } = await asyncRetry(synxis.getHotelDetails);

  if (error) {
    dispatch({ type: TYPES.GET_HOTEL_DETAILS_FAIL });
  } else {
    dispatch({
      type: TYPES.GET_HOTEL_DETAILS_SUCCESS,
      payload: data,
    });
  }
};
/**
 * Fetches all availabilities from Synxis with provided parameters
 * @param   {object} object    params for filtering results in Synxis
 * @return  {object}           dispatches an action with a payload of availability object
 */
export const getAvailabilities = (params) => async (dispatch) => {
  dispatch({ type: TYPES.GET_AVAILABILITIES_PENDING });

  try {
    const { error, data } = await asyncRetry(
      synxis.getAvailabilities.bind(null, params)
    );
    // console.log("Availabilities", data);

    if (error) {
      dispatch({ type: TYPES.GET_AVAILABILITIES_FAIL });
    } else {
      dispatch({
        type: TYPES.GET_AVAILABILITIES_SUCCESS,
        payload: data.Prices,
      });
      // console.log("availabilities", data);
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: TYPES.GET_AVAILABILITIES_FAIL });
  }
};

/**
 * Deletes all availabilities from the store
 *
 * @return  {object}           dispatches an action with no payload
 */
export const deleteAvailabilities = () => ({
  type: TYPES.DELETE_AVAILABILITIES,
});

/**
 * Fetches availability for one particular room from Synxis
 *
 * @param   {string} roomCode       room code
 * @param   {object} params         params for filtering results in Synxis
 * @return  {object}                dispatches an action with a payload of hotel details object
 */
export const getAvailability = (roomCode, params) => async (dispatch) => {
  dispatch({ type: TYPES.GET_SINGLE_AVAILABILITY_PENDING });

  try {
    const data = await synxis.getAvailabilities({
      ...params,
      roomCode,
      onlyCheckRequested: true,
    });

    if (data.error) {
      dispatch({ type: TYPES.GET_SINGLE_AVAILABILITY_FAIL });
    } else {
      const failure = data.LeastRestrictiveFailure;

      dispatch({
        type: TYPES.GET_SINGLE_AVAILABILITY_SUCCESS,
        payload: {
          data:
            data.Prices.find((a) => a.Product.Rate.Code === "RACK2") ||
            data.Prices[0] ||
            false,
          failure: failure && {
            status: failure.ProductStatus,
            info: failure.AdditionalInformation,
            date: failure.Date,
          },
        },
      });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: TYPES.GET_SINGLE_AVAILABILITY_FAIL });
  }
};

/**
 * Deletes the single availability stored in the store
 *
 * @return  {object}           dispatches an action with no payload
 */
export const deleteAvailability = () => ({
  type: TYPES.DELETE_SINGLE_AVAILABILITY,
});

/**
 * Updates the booking details object in the store
 *
 * @param   {object} payload   payload of properties to merge into existing booking details
 * @return  {object}           dispatches an action with the newly merged object
 */
export const updateBookingDetails = (payload) => {
  const { synxis } = store.getState();

  return {
    type: TYPES.UPDATE_BOOKING_DETAILS,
    payload: {
      ...synxis.bookingDetails,
      ...payload,
    },
  };
};

/**
 * Creates the booking details object in the store
 *
 * @param   {object} bookingdetails   payload of properties to overwrite / add to the store
 * @return  {object}           dispatches an action with payload of bookingDetails
 */
export const createBookingDetails = (bookingDetails) => ({
  type: TYPES.CREATE_BOOKING_DETAILS,
  payload: bookingDetails,
});

/**
 * Creates booking details from reservation details.
 *
 * @param   {object} bookingdetails   { room, cmsRoom, cmsProperty, reservation }
 * @return  {object}                  new booking details
 */
export const createBookingDetailsFromReservation = ({
  room,
  cmsRoom,
  cmsProperty,
  reservation,
}) => {
  const startDate = reservation.RoomStay.Products[0].StartDate;
  const endDate = reservation.RoomStay.Products[0].EndDate;
  const guests = reservation.RoomStay.GuestCount.find(
    (g) => g.AgeQualifyingCode === "Adult"
  ).NumGuests;
  const roomCode = reservation.RoomStay.Products[0].Product.RoomCode;

  const primary = reservation.Guests[0];
  const rate = reservation.RoomStay.Products[0].Product.RateCode;

  const taxes = reservation.RoomPrices.TotalPrice.Price.Tax.Amount;
  const bookingDetails = {
    from: startDate,
    to: endDate,
    guests: guests,
    rate,
    roomCode,
    synxisRoom: room,
    cmsRoom,
    cmsProperty,
    packages: reservation.Packages,
    nights: stayLength(startDate, endDate),
    subtotal: reservation.RoomPrices.TotalPrice.Price.TotalAmount,
    taxes,
    total: parseFloat(
      reservation.RoomPrices.TotalPrice.Price.TotalAmountIncludingTaxesFees
    ),
    feesBreakdown: reservation.RoomPrices.TotalPrice.Price.Fees.Breakdown,
    numRooms: reservation.RoomStay.NumRooms,
    bedrooms: cmsRoom.h5,
    guestInfo: {
      first_name: primary.PersonName.GivenName,
      last_name: primary.PersonName.Surname,
      email: primary.EmailAddress[0].Value,
      phone: primary.ContactNumbers[0].Number,
      address: primary.Locations[0].Address.AddressLine[0],
      city: primary.Locations[0].Address.City,
      country: primary.Locations[0].Address.Country.Value,
      postal_code: primary.Locations[0].Address.PostalCode,
      passport: primary.TravelDocuments[0].Number,
      comments: reservation.Notification.PublicComment,

      // cc_type: primary.Payments[0].PaymentCard.CardCode,
      // cc_number: "",
      // cc_exp: primary.Payments[0].PaymentCard.ExpireDate,
      // cc_cvv: "",
      // cc_name: primary.Payments[0].PaymentCard.CardHolder,
    },
    status: reservation.status,
    confirmationNumber: reservation.CRS_confirmationNumber,
  };

  return {
    type: TYPES.CREATE_BOOKING_DETAILS,
    payload: bookingDetails,
  };
};

export const deleteBookingDetails = () => ({
  type: TYPES.DELETE_BOOKING_DETAILS,
});

export const addPackage = (pkg) => {
  const { synxis } = store.getState();

  const packages = [...synxis.bookingDetails.packages, pkg];

  return {
    type: TYPES.UPDATE_BOOKING_DETAILS,
    payload: {
      ...synxis.bookingDetails,
      packages,
      total:
        parseFloat(synxis.bookingDetails.total) +
        parseFloat(packagesTotal(packages)),
    },
  };
};

export const removePackage = (id) => {
  const { synxis } = store.getState();

  const pkg = synxis.packages[id];

  const packages = synxis.bookingDetails.packages.filter(
    (pkg) => pkg.ServiceInventoryCode !== id
  );

  return {
    type: TYPES.UPDATE_BOOKING_DETAILS,
    payload: {
      ...synxis.bookingDetails,
      packages,
      total:
        parseFloat(synxis.bookingDetails.total) -
        parseFloat(pkg.Total.AmountAfterTax),
    },
  };
};

export const getPromotions = () => async (dispatch) => {
  dispatch({ type: TYPES.GET_PROMOTIONS_PENDING });

  try {
    const promotions = await synxis.getPromotions();

    if (!promotions.PromotionList || !promotions.PromotionList.length) {
      return dispatch({
        type: TYPES.GET_PROMOTIONS_SUCCESS,
        payload: {},
      });
    }

    const promosObj = promotions.PromotionList.reduce((sum, promo) => {
      sum[promo.Code] = promo;
      return sum;
    }, {});

    dispatch({
      type: TYPES.GET_PROMOTIONS_SUCCESS,
      payload: promosObj,
    });
  } catch (error) {
    console.error(error);
    dispatch({ type: TYPES.GET_PROMOTIONS_FAIL });
  }
};

export const getCoupons = () => async (dispatch) => {
  dispatch({ type: TYPES.GET_COUPONS_PENDING });

  try {
    const coupons = await synxis.getCoupons();

    if (!coupons.CouponOfferList || !coupons.CouponOfferList.length) {
      return dispatch({
        type: TYPES.GET_COUPONS_SUCCESS,
        payload: {},
      });
    }

    const couponsObj = coupons.CouponOfferList.reduce((sum, promo) => {
      sum[promo.Code] = promo;
      return sum;
    }, {});

    dispatch({
      type: TYPES.GET_COUPONS_SUCCESS,
      payload: couponsObj,
    });
  } catch (error) {
    console.error(error);
    dispatch({ type: TYPES.GET_COUPONS_FAIL });
  }
};

export const getRates = () => async (dispatch) => {
  dispatch({ type: TYPES.GET_RATES_PENDING });

  try {
    const { error, data } = await asyncRetry(synxis.getRates);

    if (error) {
      dispatch({ type: TYPES.GET_RATES_FAIL });
    } else {
      const ratesObj = data.rateList.reduce((sum, room) => {
        sum[room.Code] = room;
        return sum;
      }, {});

      dispatch({
        type: TYPES.GET_RATES_SUCCESS,
        payload: ratesObj,
      });
    }
  } catch (e) {
    console.log(e);
    dispatch({ type: TYPES.GET_RATES_FAIL });
  }
};

export const getPackages = (params) => async (dispatch) => {
  dispatch({ type: TYPES.GET_PACKAGES_PENDING });

  try {
    const packages = await synxis.getPackages(params);

    const response = packages["s:Envelope"]["s:Body"]["OTA_HotelAvailRS"];

    if (response.Errors) {
      return dispatch({
        type: TYPES.GET_PACKAGES_FAIL,
        error: response.Errors.Error[0].attributes.ShortText,
      });
    }

    const service = response["Services"]["Service"];

    const serviceArray = Array.isArray(service) ? service : [service];

    const payload = serviceArray.reduce((sum, svc) => {
      sum[svc._attributes.ServiceInventoryCode] = {
        ...svc._attributes,

        Price: Array.isArray(svc.Price)
          ? svc.Price.reduce((acc, price) => {
              acc.push({
                ...price.Base._attributes,
                ...price._attributes,
              });
              return acc;
            }, [])
          : [
              {
                ...svc.Price.Base._attributes,
                ...svc.Price._attributes,
              },
            ],

        ...svc.ServiceDetails.Comments.Comment.reduce((acc, com) => {
          com.Text ? (acc[com._attributes.Name] = com.Text._text) : null;
          com.URL ? (acc[com._attributes.Name] = com.URL._text) : null;
          return acc;
        }, {}),
      };
      return sum;
    }, {});

    Object.keys(payload).forEach((key) => {
      const pkg = payload[key];
      pkg.Total = {
        AmountBeforeTax: pkg.Price.reduce((total, price) => {
          return total + parseFloat(price.AmountBeforeTax);
        }, 0),
        AmountAfterTax: pkg.Price.reduce((total, price) => {
          return total + parseFloat(price.AmountAfterTax);
        }, 0),
      };
    });

    dispatch({
      type: TYPES.GET_PACKAGES_SUCCESS,
      payload,
    });
  } catch (error) {
    console.error(error);
    dispatch({ type: TYPES.GET_PACKAGES_FAIL });
  }
};

export const updateBookingForm = (payload) => ({
  type: TYPES.UPDATE_BOOKING_FORM,
  payload,
});

export const clearBookingForm = () => ({
  type: TYPES.CLEAR_BOOKING_FORM,
});

export const clearError = () => ({
  type: TYPES.CLEAR_ERROR,
});

/**
 * Fetches lead availability from Synxis to display nightly
 * availability in QuickBooking calendar
 *
 * @param   {pbject} params      synxis parameters
 *
 * @return  {function}           dispatches lead availability object, with a key for month
 */
export const getLeadAvailability = (params) => async (dispatch) => {
  dispatch({ type: TYPES.GET_LEAD_AVAILABILITY_PENDING });

  try {
    const { error, data } = await asyncRetry(
      synxis.getLeadAvailability.bind(null, params)
    );

    // console.log("Synxis Lead Availability Data", data, params);

    if (error) {
      dispatch({ type: TYPES.GET_LEAD_AVAILABILITY_FAIL });
    } else {
      dispatch({
        type: TYPES.GET_LEAD_AVAILABILITY_SUCCESS,
        payload: {
          key: params.key,
          leadAvailability: data.leadAvailabilityList,
        },
      });
    }
  } catch (e) {
    console.log(e);
    dispatch({ type: TYPES.GET_LEAD_AVAILABILITY_FAIL });
  }
};
