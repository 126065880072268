import styles from "../../styles/partials/_view-reservation.scss";
import { _class } from "../utilities/helpers";
import Reveal from "../widgets/Reveal";
import { Translation } from "../utilities/Translation";
import { connect } from "react-redux";
import ContentProvider from "../containers/ContentProvider";
import Link from "../widgets/Link";

const cl = _class(styles, "view_reservation");

class ViewReservation extends React.Component {
  static propTypes = {
    CONTENT: PropTypes.object,
    reservation: PropTypes.object,
    bookingDetails: PropTypes.object,
    bookingForm: PropTypes.object,
    rooms: PropTypes.object,
    lang: PropTypes.string,
  };

  static contextTypes = {
    filterByLang: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.LANG = new Translation(props.lang);
    this.options = props.CONTENT.byTemplate("options")[0];
  }

  renderTerms = () => {
    const { reservation } = this.props;

    return (
      <div className={cl("terms")}>
        <h4>{this.LANG("terms")}</h4>
        <h6>{this.LANG("bookingPolicy")}</h6>
        <p>{reservation.BookingPolicy.Description}</p>
        <h6>{this.LANG("cancelPolicy")}</h6>
        <p>{reservation.CancelPolicy.Description}</p>
      </div>
    );
  };

  renderConfirmations = () => {
    const { reservation } = this.props;

    return (
      <div className={cl("reservation")}>
        <p className={cl("reservation__title")}>
          {this.LANG("reservationNumber")}
        </p>
        <p className={cl("reservation__number")}>
          {reservation.CRS_confirmationNumber}
        </p>
        <p className={cl("reservation__title")}>
          {this.LANG("itineraryNumber")}
        </p>
        <p className={cl("reservation__number")}>
          {reservation.ItineraryNumber}
        </p>
      </div>
    );
  };

  renderGuestDetails = () => {
    const { bookingDetails } = this.props;

    if (bookingDetails) {
      const { guestInfo } = bookingDetails;

      return (
        <div className={cl("guest_details")}>
          <h3>{this.LANG("guestDetails")}</h3>
          <p>
            {guestInfo.first_name} {guestInfo.last_name}
          </p>
          <p>{guestInfo.email}</p>
          <p>{guestInfo.phone}</p>
          <p>
            {guestInfo.address}, {guestInfo.city}, {guestInfo.postal_code},{" "}
            {guestInfo.country}
          </p>
        </div>
      );
    }
  };

  renderContact = () => (
    <div className={cl("contact")}>
      <p>{this.LANG("modifyOrCancel")}</p>
      <p>
        <Link
          path={`tel:${this.options.h1}`}
          title={`US Toll Free: ${this.options.h1}`}
        />
      </p>
      <p>
        <Link
          path={`tel:${this.options.h2}`}
          title={`Costa Rica: ${this.options.h1}`}
        />
      </p>
    </div>
  );

  render() {
    return (
      <Reveal cssAnimation={["fade", "left"]} className={cl("")}>
        <h2>{this.LANG("viewReservation")}</h2>
        {this.renderConfirmations()}
        {this.renderGuestDetails()}
        {this.renderTerms()}
        {this.renderContact()}
      </Reveal>
    );
  }
}

const mapStateToProps = ({ synxis, global }) => ({
  bookingDetails: synxis.bookingDetails,
  bookingForm: synxis.bookingForm,
  reservation: synxis.reservation,
  rooms: synxis.rooms,
  hotelDetails: synxis.hotelDetails,
  lang: global.lang,
});

export default connect(mapStateToProps)(ContentProvider(ViewReservation));
