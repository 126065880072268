import styles from "../../styles/partials/_booking-modal.scss";
import {
  _class,
  reservationOptions,
  lodgingOptions,
  setQueryParams,
  gaTrackPage,
  fbTrackEvent,
  gtagTrackEvent,
} from "../utilities/helpers";
import moment from "moment";
import CTA from "./CTA";
import SelectInput from "../widgets/SelectInput";
import Modal from "../widgets/Modal";
import DayPicker, { DateUtils } from "react-day-picker";
import { Translation } from "../utilities/Translation";
import { connect } from "react-redux";
import MomentLocaleUtils from "react-day-picker/moment";
import "moment/locale/es";
import { logBookingSearchDetails } from "../utilities/Logger";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const cl = _class(styles, "booking_modal");

class BookingModal extends React.Component {
  static propTypes = {
    open: PropTypes.bool,
    toggle: PropTypes.func,
    lang: PropTypes.string,
    CONTENT: PropTypes.object,
    guests: PropTypes.number,
    lodging: PropTypes.array,
  };

  static contextTypes = {
    history: PropTypes.object,
    viewport: PropTypes.object,
    filterByLang: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.LANG = new Translation(props.lang);

    this.lodgingOptions = lodgingOptions(props.lang);
    this.guestOptions = reservationOptions("guest", 20, 1, props.lang);
    this.roomOptions = reservationOptions("bedroom", 7, 1, props.lang);

    this.today = moment().startOf("day").toDate();

    this.state = {
      ...this.initialState(),
    };
  }

  toggleEsc = (e) => e.keyCode == 27 && this.props.toggle();

  componentDidUpdate(prevProps) {
    const { lang } = this.props;
    this.LANG = new Translation(lang);
    this.lodgingOptions = lodgingOptions(lang);
    this.guestOptions = reservationOptions("guest", 20, 1, lang);
    this.roomOptions = reservationOptions("bedroom", 7, 1, lang);

    if (!prevProps.open && this.props.open) {
      window.addEventListener("keydown", this.toggleEsc);
      disableBodyScroll();
    }

    if (prevProps.open && !this.props.open) {
      window.removeEventListener("keydown", this.toggleEsc);
      enableBodyScroll();
    }
  }

  initialState = () => ({
    from: this.initialDate("from"),
    to: null,
    lodging: null,
    bedrooms: null,
    guests: null,
    fromMonth: moment().startOf("day").toDate(),
    toMonth: moment().startOf("day").add(1, "months").toDate(),
    activeDate: "from",
    errors: {
      lodging: false,
      guests: false,
      beds: false,
    },
  });

  initialDate = (dateType) => {
    const date = this.props[dateType];
    if (date) {
      return moment(date).startOf("day").toDate();
    }

    if (dateType === "to") {
      return moment(date).startOf("day").add(1, "days").toDate();
    }

    return this.today;
  };

  initialGuests = () => {
    const { guests } = this.props;
    if (guests) {
      return this.guestOptions.find(
        (opt) => parseInt(opt.value) === parseInt(guests)
      );
    }
  };

  initialLodging = () => {
    const { lodging } = this.props;
    if (lodging) {
      if (Array.isArray(lodging)) {
        return this.lodgingOptions.filter((opt) =>
          lodging.find((l) => l === opt.value)
        );
      } else {
        return this.lodgingOptions.filter((l) => l.value === lodging);
      }
    }
  };

  weekdayElement = ({ weekday, className }) => {
    return (
      <div className={className}>
        <span>{weekday}</span>
      </div>
    );
  };

  dayElement = (date) => (
    <div className={"DayPicker-Day--inner"}>
      <span>{moment(date).format("D")}</span>
    </div>
  );

  select = (field, value) => {
    this.setState({ ...value, [field]: value });
  };

  selectDate = (field, day) => {
    const { from, to, activeDate } = this.state;

    const dayBefore = moment(day).startOf("day").subtract(1, "days").toDate();

    const dayAfter = moment(day).startOf("day").add(1, "days").toDate();

    if (activeDate === "from") {
      this.setState({
        from: day,
        activeDate: "to",
        to: day >= to ? dayAfter : to,
      });
    } else {
      this.setState({
        to: day,
        activeDate: "from",
        from: day <= from ? dayBefore : from,
      });
    }
  };

  search = () => {
    const { lodging, guests, from, to, bedrooms } = this.state;

    this.setState({
      errors: {
        lodging: false,
        guests: false,
        beds: false,
      },
    });
    if (!lodging || !guests || !from || !to || !bedrooms) {
      this.setState({
        errors: {
          lodging: !lodging || !lodging.length,
          guests: !guests,
          dates: !from || !to,
          beds: !bedrooms,
        },
      });
      return false;
    }

    gaTrackPage("/btt/Step1/selectDates");

    fbTrackEvent("BookingIntent");

    gtagTrackEvent("AW-817146118/4_E8CM3Zn-4BEIbS0oUD");

    let checkin = moment(from).format("YYYY-MM-DD");
    let checkout = moment(to).format("YYYY-MM-DD");

    let params = {
      lodging: [lodging].map((l) => l.value),
      guests: guests.value,
      from: checkin,
      to: checkout,
      bedrooms: bedrooms.value,
    };

    logBookingSearchDetails({
      checkinDate: checkin,
      checkoutDate: checkout,
      guests: guests.value,
      bedrooms: bedrooms.value,
      path: window.location.pathname,
      accommodation: [lodging].map((l) => l.value).join(", "),
    });

    const bookingPage = this.props.CONTENT.byTemplate("booking_results").filter(
      this.context.filterByLang
    )[0];

    const url = `${bookingPage.path}${setQueryParams(params)}`;
    this.context.history.pushTransition(url);
    this.props.toggle();
    this.setState(this.initialState());
  };

  renderCalendars = () => {
    const { from, to, fromMonth, toMonth } = this.state;
    const modifiers = { start: from, end: to };

    return (
      <div className={cl("calendars")}>
        <div className={cl("calendars__previous")}>
          <button
            onClick={() =>
              this.setState({
                fromMonth: moment(this.state.fromMonth)
                  .subtract(1, "months")
                  .toDate(),
                toMonth: moment(this.state.toMonth)
                  .subtract(1, "months")
                  .toDate(),
              })
            }
          />
        </div>
        <div className={cl("calendars__inner")}>
          <label>{this.LANG("checkInDate")}</label>
          <DayPicker
            className={`ReservationsDayPicker ${cl("calendar")}`}
            onDayClick={this.selectDate.bind(null, "from")}
            numberOfMonths={1}
            modifiers={modifiers}
            selectedDays={[from, { from: from, to: to }]}
            initialMonth={moment().toDate()}
            fromMonth={fromMonth}
            month={fromMonth}
            disabledDays={(day) => DateUtils.isPastDay(day)}
            canChangeMonth={false}
            fixedWeeks
            renderDay={this.dayElement}
            localeUtils={MomentLocaleUtils}
            locale={this.props.lang}
          />
        </div>
        <div className={cl("calendars__inner")}>
          <label>{this.LANG("checkOutDate")}</label>
          <DayPicker
            className={`ReservationsDayPicker ${cl("calendar")}`}
            onDayClick={this.selectDate.bind(null, "to")}
            numberOfMonths={1}
            modifiers={modifiers}
            selectedDays={[from, { from: from, to: to }]}
            initialMonth={moment().add(1, "months").toDate()}
            fromMonth={toMonth}
            month={toMonth}
            disabledDays={(day) => DateUtils.isPastDay(day)}
            canChangeMonth={false}
            fixedWeeks
            renderDay={this.dayElement}
            localeUtils={MomentLocaleUtils}
            locale={this.props.lang}
          />
        </div>

        <div className={cl("calendars__next")}>
          <button
            onClick={() => {
              this.setState({
                fromMonth: moment(this.state.fromMonth)
                  .add(1, "months")
                  .toDate(),
                toMonth: moment(this.state.toMonth).add(1, "months").toDate(),
              });
            }}
          />
        </div>
      </div>
    );
  };

  renderLodgingSelect = () => {
    const lodgingErrorStyle = this.state.errors.lodging ? styles.error : "";

    return (
      <div className={`${cl("select")} ${lodgingErrorStyle}`}>
        <label>{this.LANG("accommodation")}</label>
        <SelectInput
          value={this.state.lodging}
          placeholder={this.LANG("lodging")}
          options={this.lodgingOptions}
          onSelect={this.select.bind(null, "lodging")}
          className={"booking_modal_select"}
          classNamePrefix={"booking_modal_select"}
          isSearchable
          isClearable
        />
      </div>
    );
  };

  renderGuestsAndBeds = () => {
    const bedsErrorStyle = this.state.errors.beds ? styles.error : "";
    const guestErrorStyle = this.state.errors.guests ? styles.error : "";
    return (
      <div className={cl("inputs__flex")}>
        <div className={`${cl("select")} ${guestErrorStyle}`}>
          <label>{this.LANG("guests")}</label>
          <SelectInput
            value={this.state.guests}
            placeholder={this.LANG("guests")}
            options={this.guestOptions}
            onSelect={this.select.bind(null, "guests")}
            className={"booking_modal_select"}
            classNamePrefix={"booking_modal_select"}
          />
        </div>
        <div className={`${cl("select")} ${bedsErrorStyle}`}>
          <label>{this.LANG("bedrooms")}</label>
          <SelectInput
            value={this.state.bedrooms}
            placeholder={this.LANG("bedrooms")}
            options={this.roomOptions}
            onSelect={this.select.bind(null, "bedrooms")}
            className={"booking_modal_select"}
            classNamePrefix={"booking_modal_select"}
          />
        </div>
      </div>
    );
  };

  render() {
    const { open, toggle } = this.props;

    return (
      <Modal open={open} close={toggle} className={cl("wrapper")}>
        <div className={cl("")}>
          {this.renderCalendars()}
          <div className={cl("inputs")}>
            <h3>{this.LANG("whereTo")}</h3>
            {this.renderLodgingSelect()}
            {this.renderGuestsAndBeds()}

            <div>
              <CTA
                text={this.LANG("checkAvailability")}
                onClick={this.search}
                type="book"
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  lang: global.lang,
});

export default connect(mapStateToProps)(BookingModal);
