import styles from "../../styles/views/_venues.scss";
import { _class, parse } from "../utilities/helpers";
import Hero from "../partials/Hero";
import Intro from "../partials/Intro";
import OfferCard from "../partials/OfferCard";
import Tabs from "../partials/Tabs";
import Reveal from "../widgets/Reveal";
import ResultsFilters from "../partials/ResultsFilters";
import { Translation } from "../utilities/Translation";
import { connect } from "react-redux";

const cl = _class(styles, "venues");

class Venues extends React.Component {
  static propTypes = {
    page: PropTypes.object,
    CONTENT: PropTypes.object,
    lang: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.LANG = new Translation(props.lang);

    this.ALL = { label: this.LANG("All"), value: "All" };

    this.slides = props.page.fieldgroup1 || [];
    this.venues = props.CONTENT.childrenById(props.page.id) || [];
    this.tabs = this.getTabs();

    this.state = {
      venues: this.venues,
      animating: false,
      visible: false,
      capacity: {},
      tab: {
        [this.ALL.value]: this.ALL,
      },
      nextTab: this.ALL,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.animating && !this.state.animating) {
      this.animateIn();
    }
  }

  /**
   * Callback function for Transition - called on done.
   */
  onComplete = () => this.setState({ animating: false });

  animateOut = (state = {}) =>
    this.setState({ visible: false, animating: true, ...state });

  /**
   * Animates in the current collection of cards.
   * First we filter the cards to generate a new collection,
   * then make them visible (animation)
   *
   */
  animateIn = () => {
    const { nextTab, tab } = this.state;

    let newTab = { ...tab };

    // If switching back to All tab, clear out other values
    // otherwise remove the all tab
    if (nextTab.value === this.ALL.value && !newTab[this.ALL.value]) {
      newTab = {
        [this.ALL.value]: this.ALL,
      };
    } else {
      delete newTab[this.ALL.value];
    }

    if (nextTab.value !== this.ALL.value && tab[nextTab.value]) {
      delete newTab[nextTab.value];
    } else {
      newTab[nextTab.value] = nextTab;
    }

    let tabVenues = this.tabFilter(newTab);
    let capacityVenues = this.capacityFilter(tabVenues);

    this.setState({
      tab: newTab,
      venues: capacityVenues,
      visible: true,
    });
  };

  /**
   * Handles click event for applying filters on Capacity modal.
   * Animate out the current collection of venues
   * Kicks off filtering process
   *
   * @param   {object} state       Object of current state from ResultsFilters
   */
  applyFilters = (state) =>
    this.animateOut({
      capacity: state.People,
      visible: false,
      animating: true,
    });

  updatePeople = (field, value) => this.setState({ people: value });

  /**
   * Handles click event for a tab.
   * Animate out the current collection of venues
   * Kicks off filtering process
   *
   * @param   {object} nextTab       Object of { label, value }
   */
  onTabSelect = (nextTab) =>
    this.setState({
      visible: false,
      animating: true,
      nextTab,
    });

  /**
   * Filters venues by the selected tabs
   *
   * @param   {object} tabs              Object of tabs, eg {All: { label, value }}
   *
   * @return  {array}                    collection of filtered venues by tab
   */
  tabFilter = (tabs) => {
    if (tabs[this.ALL.value]) {
      return this.venues;
    }

    return this.venues.filter((venue) => {
      const type = parse(venue.selectgroup1, [{}])[0].name;
      const env = parse(venue.selectgroup2, [{}])[0].name;
      return tabs[type] || tabs[env];
    });
  };

  /**
   * Filters venues by capacity checkboxes.
   *
   * @param   {object} venues            unfiltered array of venues
   *
   * @return  {array}                    collection of venues filtered by capacity
   */
  capacityFilter = (venues) => {
    const { capacity } = this.state;
    const checkboxes = Object.keys(capacity);

    // if all checkboxes unchecked, return all venues
    if (checkboxes.every((key) => !capacity[key])) {
      return venues;
    }

    return venues.filter((venue) => {
      const roomCapacity = parseInt(venue.h3);

      // venue needs to match at least one active checkbox
      return checkboxes.reduce((match, key, index, array) => {
        if (capacity[key]) {
          const lastCriteria = index + 1 === array.length;
          let _capacity = key.split(" - ");
          let min = parseInt(_capacity[0]);
          let max = parseInt(_capacity[1]);

          //eg key is 150+
          if (lastCriteria && roomCapacity >= min) {
            return true;
          }

          // eg key is "30 - 60"
          if (roomCapacity >= min && roomCapacity <= max) {
            return true;
          }
        }

        return match;
      }, false); // venues don't match criteria by default
    });
  };

  getTabs = () => {
    const tabs = this.venues.reduce((sum, venue) => {
      const type = parse(venue.selectgroup1, [{}])[0].name;
      const env = parse(venue.selectgroup2, [{}])[0].name;

      type ? (sum[type] = { label: this.LANG(type), value: type }) : null;
      env ? (sum[env] = { label: this.LANG(env), value: env }) : null;

      return sum;
    }, {});

    return Object.values(tabs);
  };

  renderVenues = () => {
    return (
      <Reveal
        className={cl("list")}
        mode="stagger"
        staggerClassName={cl("list__item")}
        properties={{
          y: ["50%", "0%"],
          opacity: [0, 1],
          autoAlpha: [0, 1],
        }}
        visible={this.state.visible}
        onComplete={this.onComplete}
        useRevealProp
        onReveal={() => this.setState({ visible: true })}
      >
        {this.state.venues.map((venue) => (
          <li className={cl("list__item")} key={venue.id}>
            <OfferCard
              title={venue.h1}
              blurb={venue.blurb1}
              image={{
                src: venue.image1,
                alt: venue.image1_alt_text,
                align: venue.image1_anchor,
              }}
              cta={parse(venue.buttonlink1, {})}
              lang={this.props.lang}
            />
          </li>
        ))}
      </Reveal>
    );
  };

  renderCategories = () => (
    <ul className={cl("categories")}>
      <Tabs
        onClick={this.onTabSelect}
        tabs={this.tabs}
        active={this.state.tab}
        fixedWidth
        lang={this.props.lang}
      />
    </ul>
  );

  renderFilters = () => (
    <div className={cl("options__filters")}>
      <ResultsFilters
        filters={["People"]}
        onUpdate={this.updatePeople}
        onApply={this.applyFilters}
      />
    </div>
  );

  render() {
    const { page } = this.props;

    return (
      <div className={cl("")}>
        <Hero
          imageData={{ slides: this.slides }}
          videoLinkPresent={page.video_link !== null && page.video_link !== ""}
          videoLink={page.video_link}
        />

        <div className={cl("container")}>
          <Intro
            title={page.h1}
            subtitle={page.h2}
            blurb={page.blurb2}
            cta={parse(page.buttonlink1)}
          />
          <div className={cl("options")}>
            {this.renderCategories()}
            {this.renderFilters()}
          </div>
          {this.renderVenues()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  lang: global.lang,
});

export default connect(mapStateToProps)(Venues);
