import styles from "../../styles/partials/_booking-widget.scss";
import {
  _class,
  reservationOptions,
  lodgingOptions,
  setQueryParams,
  getQueryParams,
  gaTrackEvent,
  gaTrackPage,
  fbTrackEvent,
  gtagTrackEvent,
} from "../utilities/helpers";
import CTA from "./CTA";
import SelectInput from "../widgets/SelectInput";
import DayPickerInput from "react-day-picker/DayPickerInput";
import moment from "moment";
import { Translation } from "../utilities/Translation";
import { connect } from "react-redux";
import MomentLocaleUtils from "react-day-picker/moment";
import "moment/locale/es";
import { logBookingSearchDetails } from "../utilities/Logger";

const cl = _class(styles, "booking_widget");

class BookingWidget extends React.Component {
  static propTypes = {
    lang: PropTypes.string,
    CONTENT: PropTypes.object,
  };

  static contextTypes = {
    history: PropTypes.object,
    filterByLang: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.LANG = new Translation(props.lang);

    this.lodgingOptions = lodgingOptions(props.lang);
    this.guestOptions = reservationOptions("guest", 20, 1, props.lang);

    this.today = moment().startOf("day").toDate();

    this.state = {
      ...this.initialState(),
      errors: {
        lodging: false,
        guests: false,
        dates: false,
      },
    };
  }

  initialState = () => {
    const { from, to, guests, lodging } = getQueryParams(
      window.location.search,
      {}
    );

    return {
      from: from ? moment(from).startOf("day").toDate() : undefined,
      to: to ? moment(to).startOf("day").toDate() : undefined,
      guests: guests,
      lodging: lodging
        ? Array.isArray(lodging)
          ? lodging.join(",")
          : lodging
        : "",
    };
  };

  guestsValue = () =>
    this.guestOptions.find(
      (opt) => parseInt(opt.value) === parseInt(this.state.guests)
    );

  lodgingValue = () => {
    const { lodging } = this.state;
    return this.lodgingOptions.filter((opt) => {
      return lodging && lodging.split(",").find((l) => l === opt.value);
    });
  };

  search = () => {
    const { lodging, guests, from, to } = this.state;

    if (!lodging || !lodging.length || !guests || !from || !to) {
      this.setState({
        errors: {
          lodging: !lodging || !lodging.length,
          guests: !guests,
          dates: !from || !to,
        },
      });
      return false;
    }

    gaTrackPage("/btt/Step1/selectDates");

    fbTrackEvent("BookingIntent");

    gtagTrackEvent("AW-817146118/4_E8CM3Zn-4BEIbS0oUD");

    let checkin = moment(from).format("YYYY-MM-DD");
    let checkout = moment(to).format("YYYY-MM-DD");

    let params = {
      lodging,
      guests,
      from: checkin,
      to: checkout,
    };

    logBookingSearchDetails({
      checkinDate: checkin,
      checkoutDate: checkout,
      guests,
      path: window.location.pathname,
      accommodation: lodging,
    });

    const bookingPage = this.props.CONTENT.byTemplate("booking_results").filter(
      this.context.filterByLang
    )[0];

    const url = `${bookingPage.path}${setQueryParams(params)}`;

    this.context.history.pushTransition(url);
  };

  selectGuests = (selected) => {
    this.setState({ guests: selected && selected.value });
  };

  selectLodging = (selected) => {
    gaTrackEvent({
      category: "Click",
      action: "Completion",
      label: "BookingHome",
    });

    return this.setState({
      lodging: selected && [selected].map((s) => s.value).join(","),
    });
  };

  isSelectingFirstDay = (from, to, day) => {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  };

  handleDayChange = (field, day) => {
    const { from, to } = this.state;
    const dayBefore = moment(day).startOf("day").subtract(1, "days").toDate();
    const dayAfter = moment(day).startOf("day").add(1, "days").toDate();

    if (field === "from") {
      this.setState({
        from: day,
        to: day >= to ? dayAfter : to,
      });
    } else {
      this.setState({
        to: day,
        from: day <= from ? dayBefore : from,
      });
    }
  };

  renderDatePicker = (name) => {
    const { from, to } = this.state;

    return (
      <div className={cl("date_picker")}>
        <DayPickerInput
          label={this.LANG("selectDates")}
          numberOfMonths={1}
          fromMonth={from}
          value={this.state[name]}
          onDayChange={this.handleDayChange.bind(null, name)}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            month: from,
            fromMonth: name === "to" ? from : this.today,
            disabledDays: {
              before: name === "to" ? from : this.today,
            },
            modifiers: { from, to },
            locale: this.props.lang,
            localeUtils: MomentLocaleUtils,
          }}
          placeholder={this.LANG(name)}
          localeUtils={MomentLocaleUtils}
          locale={this.props.lang}
        />
      </div>
    );
  };

  render() {
    const { errors } = this.state;
    const lodgingErrorStyle = errors.lodging ? styles.error : "";
    const dateErrorStyle = errors.dates ? styles.error : "";
    const guestErrorStyle = errors.guests ? styles.error : "";

    return (
      <div className={cl("")}>
        <div className={`${cl("lodging")} ${lodgingErrorStyle}`}>
          <SelectInput
            value={this.lodgingValue()}
            placeholder={this.LANG("lodging")}
            options={this.lodgingOptions}
            onSelect={this.selectLodging}
            className={"booking_select"}
            classNamePrefix={"booking_select"}
          />
        </div>
        <div className={`${cl("dates")} ${dateErrorStyle}`}>
          {this.renderDatePicker("from")}
          {this.renderDatePicker("to")}
        </div>
        <div className={`${cl("guests")} ${guestErrorStyle}`}>
          <SelectInput
            value={this.guestsValue()}
            placeholder={this.LANG("guests")}
            options={this.guestOptions}
            onSelect={this.selectGuests}
            className={"booking_select"}
            classNamePrefix={"booking_select"}
          />
        </div>
        <div className={cl("submit")}>
          <CTA
            text={this.LANG("checkAvailability")}
            onClick={this.search}
            type="book"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  lang: global.lang,
});

export default connect(mapStateToProps)(BookingWidget);
