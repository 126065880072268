import styles from "../../styles/partials/_cookie-popup.scss";
import CTA from "./CTA";
import * as Storage from "../utilities/Storage";
import { _class } from "../utilities/helpers";
// import Hamburger from "./Hamburger";

const cl = _class(styles, "cookie_popup");

class CookiePopup extends React.Component {
  static propTypes = {
    CONTENT: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.page = this.props.CONTENT.byTemplate("options")[0];
    this.cookiesAllowed = Storage.getItem("cookiesAccepted");

    this.state = {
      hidden: this.cookiesAllowed,
    };
  }
  componentDidMount() {
    const sessionCookie = localStorage.getItem("cookiesAccepted");
    const expirationDate = this.getLocalStorageCookieDate();
    const now = new Date();

    if (sessionCookie && expirationDate >= now) {
      this.saveCookiePreference();
    }
  }
  enableGoogleConsent = () => {
    if (window.gtag) {
      window.gtag("consent", "update", {
        ad_storage: "granted",
        ad_user_data: "granted",
        ad_personalization: "granted",
        analytics_storage: "granted",
      });
    }
  };
  getLocalStorageCookieDate = () => {
    const expiration = localStorage.getItem("cookiesAcceptedExpiration");
    return new Date(expiration);
  };
  saveCookiePreference = () => {
    this.enableGoogleConsent();
    this.setState({ hidden: true });
    this.setCookieAcceptedLocalStorage();
  };
  setCookieAcceptedLocalStorage = () => {
    const expiration = new Date();
    expiration.setDate(expiration.getDate() + 30);
    localStorage.setItem("cookiesAccepted", true);
    localStorage.setItem("cookiesAcceptedExpiration", expiration.toISOString());
  };

  render() {
    const { hidden } = this.state;

    return (
      <div className={`${cl("")} ${hidden ? cl("hidden") : ""}`}>
        <div className={cl("container")}>
          <div
            className={cl("blurb")}
            dangerouslySetInnerHTML={{ __html: this.page.blurb1 }}
          />
          <CTA
            text={"Allow Cookies"}
            onClick={this.saveCookiePreference}
            type="orange"
            size="xsmall"
          />

          {/* <div className={cl("close")}>
            <Hamburger
              open
              onClick={this.saveCookiePreference}
              color={"black"}
            />
          </div> */}
        </div>
      </div>
    );
  }
}
export default CookiePopup;
