import styles from "../../styles/partials/_alternate-rates.scss";
import {
  _class,
  staySubtotal,
  stayTotal,
  packagesTotal,
  stayTaxes,
  stayFees,
  stayFeesBreakdown,
  numberWithCommas,
} from "../utilities/helpers";
import { connect } from "react-redux";
import { getAvailabilities } from "../redux/synxis/SynxisActions";
import moment from "moment";
import { Translation } from "../utilities/Translation";
import CTA from "../partials/CTA";
import {
  updateBookingDetails,
  deleteAvailabilities,
} from "../redux/synxis/SynxisActions";
import Loader from "./Loader";
import * as SVG from "../widgets/SVG";

const cl = _class(styles, "alternate_rates");

class AlternateRates extends React.Component {
  static propTypes = {
    availabilities: PropTypes.array,
    getAvailabilities: PropTypes.func,
    deleteAvailabilities: PropTypes.func,
    availabilitiesPending: PropTypes.bool,
    bookingDetails: PropTypes.object,
    rates: PropTypes.object,
    updateBookingDetails: PropTypes.func,
    selectedRate: PropTypes.string,
    currency: PropTypes.object,
    lang: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);

    this.LANG = new Translation(props.lang);
  }

  componentDidMount() {
    const { bookingDetails, availabilities, getAvailabilities } = this.props;

    if (!availabilities) {
      getAvailabilities({
        adults: bookingDetails.guests,
        startDate: moment(bookingDetails.from).format("YYYY-MM-DD"),
        endDate: moment(bookingDetails.to).format("YYYY-MM-DD"),
        currencyCode: this.props.currency.code,
        content: "full",
        roomCode: bookingDetails.roomCode,
        onlyCheckRequested: true,
      });
    }
  }

  componentWillUnmount() {
    this.props.deleteAvailabilities();
  }

  selectRate = (availability, rate) => {
    const { bookingDetails, updateBookingDetails } = this.props;

    const total =
      parseFloat(stayTotal(availability)) +
      parseFloat(packagesTotal(bookingDetails.packages));

    updateBookingDetails({
      availability,
      rate: rate.Code,
      total,
      taxes: stayTaxes(availability),
      fees: stayFees(availability),
      feesBreakdown: stayFeesBreakdown(availability),
      subtotal: staySubtotal(availability),
    });
  };

  renderAvailabilities = () => {
    const {
      availabilities,
      rates,
      selectedRate,
      bookingDetails,
      availabilitiesPending,
      currency,
    } = this.props;

    if (availabilities && availabilities.length && rates) {
      return availabilities
        .filter((a) => a.Product.Room.Code === bookingDetails.roomCode)
        .map((avail) => {
          const rate = rates[avail.Product.Rate.Code];

          if (rate) {
            return (
              <li className={cl("item")} key={rate.Name}>
                <div className={cl("item__details")}>
                  <p className={cl("item__details__title")}>{rate.Name}</p>
                  <p className={cl("item__details__description")}>
                    {rate.Details.Description}
                  </p>
                </div>
                <div className={cl("item__pricing")}>
                  <h3>
                    {currency.symbol}
                    {numberWithCommas(
                      avail.Product.Prices.PerNight.Price.Amount,
                      0
                    )}
                  </h3>
                  <p>{this.LANG("perNight")}</p>
                  <p>{this.LANG("excludingTaxesFees")}</p>
                  {rate.Code === selectedRate && (
                    <p className={cl("item__selected_text")}>{SVG.checkmark}</p>
                  )}
                  {rate.Code !== selectedRate && (
                    <CTA
                      text={this.LANG("select")}
                      onClick={() => this.selectRate(avail, rate)}
                      type="orange"
                      className={cl("item__cta")}
                    />
                  )}
                </div>
              </li>
            );
          }
        });
    }

    if (availabilitiesPending) {
      return <Loader />;
    }

    if (availabilities && !availabilities.length) {
      return <p>{this.LANG("noRates")}</p>;
    }

    return <p>{this.LANG("pleaseCheckAvailability")}</p>;
  };

  render() {
    return (
      <div className={`${cl("")}`}>
        <h2>Rates</h2>
        <ul className={cl("list")}>{this.renderAvailabilities()}</ul>
      </div>
    );
  }
}

const mapStateToProps = ({ synxis, global }) => ({
  bookingDetails: synxis.bookingDetails,
  availabilities: synxis.availabilities,
  availabilitiesPending: synxis.availabilitiesPending,
  rates: synxis.rates,
  lang: global.lang,
  currency: global.currency,
});

export default connect(mapStateToProps, {
  getAvailabilities,
  updateBookingDetails,
  deleteAvailabilities,
})(AlternateRates);
