import DayPickerInput from "react-day-picker/DayPickerInput";
import moment from "moment";
import { formatDate, parseDate } from "react-day-picker/moment";
import ErrorHandler from "./ErrorHandler";
import LabelContainer from "./LabelContainer";

class DayPickerInputField extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    inputClassName: PropTypes.string,
    containerClassName: PropTypes.string,
    errorMessageClassName: PropTypes.string,
    datePickerStyle: PropTypes.object,
    errorMessageStyle: PropTypes.object,
    labelStyle: PropTypes.object,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    dayPickerProps: PropTypes.object,
    dateFormat: PropTypes.string,
    errorMessage: PropTypes.string,
    validator: PropTypes.func,
    label: PropTypes.string,
    labelOnBottom: PropTypes.bool,
    inputRef: PropTypes.func,
    inputProps: PropTypes.object,
    formState: PropTypes.object.isRequired,
    formFunctions: PropTypes.object.isRequired,
    formName: PropTypes.string.isRequired,
    labelClassName: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.initialValue = props.defaultValue || "";
    this.placeholder = props.placeholder || "";
    this.state = {
      dateIsOpen: false,
    };
  }

  componentDidMount() {
    window.addEventListener("click", this.handleWindowClick);
    let hasError = false;
    this.props.formFunctions.updateField(this.props.name, this.initialValue);
    this.props.formFunctions.updateFieldErrors(this.props.name, hasError);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleWindowClick);
  }

  handleWindowClick = (e) => {
    const { $date } = this;
    if (
      e.target !== $date &&
      !$date.contains(e.target) &&
      this.state.dateIsOpen
    ) {
      this.setState({ dateIsOpen: false });
    }
  };
  handleDateClick = () => {
    if (!this.state.dateIsOpen) {
      this.setState({ dateIsOpen: true });
    }
  };

  inputChange = (d, m) => {
    // null check. validatenotblank can't handle objects. possibly refactor
    // validatenotblank to handle objects
    d = moment(d).format(
      this.props.dateFormat ? this.props.dateFormat : "MM/DD/YYYY"
    );
    let value = d;
    this.props.formFunctions.updateField(
      this.props.name,
      value,
      this.props.onChange
    );
    if (!m.disabled) {
      this.setState({ dateIsOpen: false });
    }
    this.setState({ selectedDay: m.selected ? undefined : d });
  };

  render() {
    const { dateIsOpen } = this.state;
    const {
      dateFormat,
      containerClassName,
      inputClassName,
      errorMessageClassName,
      labelClassName,
      label,
      labelOnBottom,
      name,
      datePickerStyle,
      errorMessageStyle,
      labelStyle,
      formState,
      formFunctions,
      placeholder,
      validator,
      errorMessage,
      inputRef,
      inputProps,
    } = this.props;
    const now = new Date();
    const dateClassNames = `date-picker${
      dateIsOpen ? " date-picker--open" : ""
    }`;
    const displayError =
      formState.errors[name] && formState.submitAttempted && !formState.success;
    const fieldId = `${name}_${this.props.formName}`;
    return (
      <div
        ref={(e) => (this.$date = e)}
        className={`field ${dateClassNames} ${
          displayError ? "error" : ""
        } ${containerClassName}`}
      >
        {!labelOnBottom && (
          <LabelContainer
            {...this.props}
            name={fieldId}
            className={labelClassName}
            style={labelStyle}
          >
            {label}
          </LabelContainer>
        )}
        <DayPickerInput
          formatDate={formatDate}
          parseDate={parseDate}
          placeholder={placeholder || ""}
          dayPickerProps={{
            disabledDays: {
              before: new Date(),
            },
            month: new Date(),
            fromMonth: new Date(),
          }}
          className={`${inputClassName} ${displayError ? "error" : ""}`}
          initialMonth={now}
          fromMonth={now}
          format={dateFormat ? dateFormat : "MM/DD/YYYY"}
          onDayChange={this.inputChange}
          pagedNavigation={true}
          style={datePickerStyle}
          inputProps={{
            ...inputProps,
            ref: inputRef,
            id: fieldId,
            name: name,
            "aria-invalid":
              formState.errors[name] &&
              formState.submitAttempted &&
              !formState.success,
          }}
          {...this.props}
        />
        {labelOnBottom && (
          <LabelContainer
            {...this.props}
            name={fieldId}
            className={labelClassName}
            style={labelStyle}
          >
            {label}
          </LabelContainer>
        )}
        {validator && (
          <ErrorHandler
            name={name}
            formState={formState}
            errorHandler={validator}
            formFunctions={formFunctions}
            className={errorMessageClassName}
            style={errorMessageStyle}
          >
            {errorMessage}
          </ErrorHandler>
        )}
      </div>
    );
  }
}
export default DayPickerInputField;
